const types = {
  //Get Template name
  GET_SUB_QUALITY_MANAGMENT_START: "GET_SUB_QUALITY_MANAGMENT_START",
  GET_SUB_QUALITY_MANAGMENT_SUCCESS: "GET_SUB_QUALITY_MANAGMENT_SUCCESS",
  GET_SUB_QUALITY_MANAGMENT_ERROR: "GET_SUB_QUALITY_MANAGMENT_ERROR",
  GET_SUB_QUALITY_MANAGMENT_RESET: "GET_SUB_QUALITY_MANAGMENT_RESET",

  //Create Template name
  CREATE_SUB_QUALITY_MANAGMENT_START: "CREATE_SUB_QUALITY_MANAGMENT_START",
  CREATE_SUB_QUALITY_MANAGMENT_SUCCESS: "CREATE_SUB_QUALITY_MANAGMENT_SUCCESS",
  CREATE_SUB_QUALITY_MANAGMENT_ERROR: "CREATE_SUB_QUALITY_MANAGMENT_ERROR",
  CREATE_SUB_QUALITY_MANAGMENT_RESET: "CREATE_SUB_QUALITY_MANAGMENT_RESET",

  //delete Template name
  DELETE_SUB_QUALITY_MANAGMENT_START: "DELETE_SUB_QUALITY_MANAGMENT_START",
  DELETE_SUB_QUALITY_MANAGMENT_SUCCESS: "DELETE_SUB_QUALITY_MANAGMENT_SUCCESS",
  DELETE_SUB_QUALITY_MANAGMENT_ERROR: "DELETE_SUB_QUALITY_MANAGMENT_ERROR",
  DELETE_SUB_QUALITY_MANAGMENT_RESET: "DELETE_SUB_QUALITY_MANAGMENT_RESET",

  //update Template name
  UPDATE_SUB_QUALITY_MANAGMENT_START: "UPDATE_SUB_QUALITY_MANAGMENT_START",
  UPDATE_SUB_QUALITY_MANAGMENT_SUCCESS: "UPDATE_SUB_QUALITY_MANAGMENT_SUCCESS",
  UPDATE_SUB_QUALITY_MANAGMENT_ERROR: "UPDATE_SUB_QUALITY_MANAGMENT_ERROR",
  UPDATE_SUB_QUALITY_MANAGMENT_RESET: "UPDATE_SUB_QUALITY_MANAGMENT_RESET",

  //single item  name
  SINGLE_SUB_QUALITY_MANAGMENT_START: "SINGLE_SUB_QUALITY_MANAGMENT_START",
  SINGLE_SUB_QUALITY_MANAGMENT_SUCCESS: "SINGLE_SUB_QUALITY_MANAGMENT_SUCCESS",
  SINGLE_SUB_QUALITY_MANAGMENT_ERROR: "SINGLE_SUB_QUALITY_MANAGMENT_ERROR",
  SINGLE_SUB_QUALITY_MANAGMENT_RESET: "SINGLE_SUB_QUALITY_MANAGMENT_RESET",
};

export default types;
