import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endponits from "../../backend/endpoints";

/**
 *
 * @param {*} action
 * getting menu list
 */

function* getmenuHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(apiClient.get, endponits.menu);
		yield put({
			type: types.GET_MENU_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_MENU_ERROR,
			payload,
		});
	}
}

export function* getmenuWatcherSaga() {
	yield takeLatest(types.GET_MENU_START, getmenuHandlerSaga);
}

//get
function* getpagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endponits.dynamicList(action.payload),
		);
		yield put({
			type: types.GET_PAGES_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_PAGES_ERROR,
			payload,
		});
	}
}

export function* getpagesWatcherSaga() {
	yield takeLatest(types.GET_PAGES_START, getpagesHandlerSaga);
}

/**
 *
 * @param {Object} action
 * add data of from
 */

function* createpagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endponits.fromAddData(action.payload.path),
			action.payload.body,
		);
		yield put({
			type: types.CREATE_PAGES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_PAGES_ERROR,
			payload,
		});
	}
}

export function* createpagesWatcherSaga() {
	yield takeLatest(types.CREATE_PAGES_START, createpagesHandlerSaga);
}

//delete

function* deletepagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endponits.deleteDyanamicItem(action.payload),
		);
		yield put({
			type: types.DELETE_PAGES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_PAGES_ERROR,
			payload,
		});
	}
}

export function* deletepagesWatcherSaga() {
	yield takeLatest(types.DELETE_PAGES_START, deletepagesHandlerSaga);
}

//update

function* updatepagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endponits.dynamicUpdateItem({
				path: action.payload.path,
				dataId: action.payload.dataId,
			}),
			action.payload.body,
		);
		yield put({
			type: types.UPDATE_PAGES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_PAGES_ERROR,
			payload,
		});
	}
}

export function* updatepagesWatcherSaga() {
	yield takeLatest(types.UPDATE_PAGES_START, updatepagesHandlerSaga);
}
