import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getEmployeeDetailsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({
				path: action.payload.path,
				...action.payload,
				options: action.payload.options,
			}),
		);
		yield put({
			type: types.GET_EMPLOYEE_DETAILS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_EMPLOYEE_DETAILS_ERROR,
			payload,
		});
	}
}

export function* getEmployeeDetailsWatcherSaga() {
	yield takeLatest(
		types.GET_EMPLOYEE_DETAILS_START,
		getEmployeeDetailsHandlerSaga,
	);
}

//create

//delete

function* deleteEmployeeDetailsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem(action.payload.path, action.payload.id),
		);
		yield put({
			type: types.DELETE_EMPLOYEE_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_EMPLOYEE_DETAILS_ERROR,
			payload,
		});
	}
}

export function* deleteEmployeeDetailsWatcherSaga() {
	yield takeLatest(
		types.DELETE_EMPLOYEE_DETAILS_START,
		deleteEmployeeDetailsHandlerSaga,
	);
}

//update
