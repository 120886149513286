import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getVendorDetailsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({
				path: action.payload.path,
				...action.payload,
				options: action.payload.options,
			}),
		);
		yield put({
			type: types.GET_VENDOR_DETAILS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_VENDOR_DETAILS_ERROR,
			payload,
		});
	}
}

export function* getVendorDetailsWatcherSaga() {
	yield takeLatest(types.GET_VENDOR_DETAILS_START, getVendorDetailsHandlerSaga);
}

//create

//delete

function* deleteVendorDetailsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem(action.payload.path, action.payload.id),
		);
		yield put({
			type: types.DELETE_VENDOR_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_VENDOR_DETAILS_ERROR,
			payload,
		});
	}
}

export function* deleteVendorDetailsWatcherSaga() {
	yield takeLatest(
		types.DELETE_VENDOR_DETAILS_START,
		deleteVendorDetailsHandlerSaga,
	);
}

//update
