const types = {
  //Get Template name
  GET_DOCUMENTS_START: "GET_DOCUMENTS_START",
  GET_DOCUMENTS_SUCCESS: "GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_ERROR: "GET_DOCUMENTS_ERROR",
  GET_DOCUMENTS_RESET: "GET_DOCUMENTS_RESET",

  //Create Template name
  CREATE_DOCUMENTS_START: "CREATE_DOCUMENTS_START",
  CREATE_DOCUMENTS_SUCCESS: "CREATE_DOCUMENTS_SUCCESS",
  CREATE_DOCUMENTS_ERROR: "CREATE_DOCUMENTS_ERROR",
  CREATE_DOCUMENTS_RESET: "CREATE_DOCUMENTS_RESET",

  //delete Template name
  DELETE_DOCUMENTS_START: "DELETE_DOCUMENTS_START",
  DELETE_DOCUMENTS_SUCCESS: "DELETE_DOCUMENTS_SUCCESS",
  DELETE_DOCUMENTS_ERROR: "DELETE_DOCUMENTS_ERROR",
  DELETE_DOCUMENTS_RESET: "DELETE_DOCUMENTS_RESET",

  //update Template name
  UPDATE_DOCUMENTS_START: "UPDATE_DOCUMENTS_START",
  UPDATE_DOCUMENTS_SUCCESS: "UPDATE_DOCUMENTS_SUCCESS",
  UPDATE_DOCUMENTS_ERROR: "UPDATE_DOCUMENTS_ERROR",
  UPDATE_DOCUMENTS_RESET: "UPDATE_DOCUMENTS_RESET",

  //single item  name
  SINGLE_DOCUMENTS_START: "SINGLE_DOCUMENTS_START",
  SINGLE_DOCUMENTS_SUCCESS: "SINGLE_DOCUMENTS_SUCCESS",
  SINGLE_DOCUMENTS_ERROR: "SINGLE_DOCUMENTS_ERROR",
  SINGLE_DOCUMENTS_RESET: "SINGLE_DOCUMENTS_RESET",
};

export default types;
