const types = {
  //Get Template name
  GET_QUALITY_MANUAL_START: "GET_QUALITY_MANUAL_START",
  GET_QUALITY_MANUAL_SUCCESS: "GET_QUALITY_MANUAL_SUCCESS",
  GET_QUALITY_MANUAL_ERROR: "GET_QUALITY_MANUAL_ERROR",
  GET_QUALITY_MANUAL_RESET: "GET_QUALITY_MANUAL_RESET",

  //Create Template name
  CREATE_QUALITY_MANUAL_START: "CREATE_QUALITY_MANUAL_START",
  CREATE_QUALITY_MANUAL_SUCCESS: "CREATE_QUALITY_MANUAL_SUCCESS",
  CREATE_QUALITY_MANUAL_ERROR: "CREATE_QUALITY_MANUAL_ERROR",
  CREATE_QUALITY_MANUAL_RESET: "CREATE_QUALITY_MANUAL_RESET",

  //delete Template name
  DELETE_QUALITY_MANUAL_START: "DELETE_QUALITY_MANUAL_START",
  DELETE_QUALITY_MANUAL_SUCCESS: "DELETE_QUALITY_MANUAL_SUCCESS",
  DELETE_QUALITY_MANUAL_ERROR: "DELETE_QUALITY_MANUAL_ERROR",
  DELETE_QUALITY_MANUAL_RESET: "DELETE_QUALITY_MANUAL_RESET",

  //update Template name
  UPDATE_QUALITY_MANUAL_START: "UPDATE_QUALITY_MANUAL_START",
  UPDATE_QUALITY_MANUAL_SUCCESS: "UPDATE_QUALITY_MANUAL_SUCCESS",
  UPDATE_QUALITY_MANUAL_ERROR: "UPDATE_QUALITY_MANUAL_ERROR",
  UPDATE_QUALITY_MANUAL_RESET: "UPDATE_QUALITY_MANUAL_RESET",

  //single item  name
  SINGLE_QUALITY_MANUAL_START: "SINGLE_QUALITY_MANUAL_START",
  SINGLE_QUALITY_MANUAL_SUCCESS: "SINGLE_QUALITY_MANUAL_SUCCESS",
  SINGLE_QUALITY_MANUAL_ERROR: "SINGLE_QUALITY_MANUAL_ERROR",
  SINGLE_QUALITY_MANUAL_RESET: "SINGLE_QUALITY_MANUAL_RESET",
};

export default types;
