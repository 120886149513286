import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getadminFormBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.adminFromList(action.payload),
		);
		yield put({
			type: types.GET_ADMINFORMLIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_ADMINFORMLIST_ERROR,
			payload,
		});
	}
}

export function* getadminFormBuilderWatcherSaga() {
	yield takeLatest(
		types.GET_ADMINFORMLIST_START,
		getadminFormBuilderHandlerSaga,
	);
}

//get/id

//create
