import { combineReducers } from "redux";
import AuthReducer from "./Auth/reducer";
import ExternalUserReducer from "./ExternalUser/reducer";
import InternalUserReducer from "./InternalUser/reducer";
import EmployeeReducer from "./Employee/reducer";
import CustomerReducer from "./Customer/reducer";
import EquipmentReducer from "./Equipment/reducer";
import FromReducer from "./FromCreator/reducer";
import MethodReducer from "./Method/reducer";
import SampleReducer from "./SampleName/reducer";
import pageReducer from "./Pages/reducer";
import SampleListReducer from "./SampleList/reducer";
import RoleAndPermissionReducer from "./Roles&permission/reducer";
import Service from "./service/reducer";
import CustomOrderReducer from "./CustomerOrder/reducer";
import WorkorderReducer from "./WorkOrder/reducer";
import MangageReducer from "./ManageJob/reducer";
import jobsReducer from "./Job/reducer";
import dataEntryReducer from "./DataEntry/reducer";
import EquipmentsServiceReducer from "./EquipmentService/reducer";
import ExpiredItemsReducer from "./ExpiredItems/reducer";
import ReagentReducer from "./ReagentsAndChemicals/reducer";
import StoreReducer from "./StoreReducer/reducer";
import ReportReducer from "./Reports/reducer";
import GroupReducer from "./Groups/reducer";
import CalibrationReducer from "./Calibration/reducer";
import MaintenanceReducer from "./Maintenance/reducer";
import SubscriberReducer from "./subscriber/reducer";
import SubscriptionPlan from "./subscriptionPlan/reducer";
import AdminFromReducer from "./AdminFormList/reducer";
import CMSPageReducer from "./PageList/reducer";
import ContentPageReducer from "./PageContent/reducer";
import SiteSettingReducer from "./SiteSetting/reducer";
import GeneralInventoryReducer from "./GeneralInventory/reducer";
import UserProfileReducer from "./UserProfile/reducer";
import EmployeeFromReducer from "./EmployeeFormList/reducer";
import EmployeeDetailsReducer from "./EmployeeDetails/reducer";
import NonConformityReducerReducer from "./NonConformityReport/reducer";
import NonConformityReportTicketReducer from "./NonConformityTicketReview/reducer";
import ArchieveReducer from "./Archieve/reducer";
import AccommodationReducer from "./Accommodation/reducer";
import BuildingReducer from "./Building/reducer";
import ClientFromReducer from "./ClientFormList/reducer";
import ClientDetailsReducer from "./ClientDetails/reducer";
import VendorReducer from "./vendor/reducer";
import VendorFromReducer from "./VendorFormList/reducer";
import VendorDetailsReducer from "./VendorDetails/reducer";
import ApprovalListReducer from "./Approvallist/reducer";
import GlobalAppReducer from "./appGlobal/reducer";
import VendorCategoriesReducer from "./VendorCategories/reducer";
import VendorSubCategoriesReducer from "./VendorSubCategories/reducer";
import formBuilderReducer from "./FormBuilder/reducer";
import DocumentsReducer from "./Document/reducer";
import DocumentCalibrationReducer from "./DocumentCalibration/reducer";
import DocumentMaintenanceReducer from "./DocumentMaintenance/reducer";
import CustomReportReducer from "./CustomReports/reducer";
import QualityManagmentReducer from "./QualityManagment/reducer";
import SubQualityManagmentReducer from "./SubQualityManagment/reducer";
import QualityManualReducer from "./QualityManual/reducer";
import userActivityReducer from "./UserActivity/reducer";

import StandardDocumentReducer from "./StandardDocument/reducer";
import GeneraldocumentReducer from "./GeneralDocument/reducer";
import MasterNameListReducer from "./MasterNameList/reducer";
import ClientDocumentListReducer from "./ClientDocumentList/reducer";
import MethodPackagesReducer from "./MethodPackages/reducer";
import AdminUserReducer from "./AdminUsers/reducer";
import ManagmentReviewReducer from "./ManagementReview/reducer";
import InternalAuditReducer from "./InternalAudit/reducer";
import TraningReducer from "./Traning/reducer";
import PlanTraningReducer from "./PlanTraining/reducer";
import ManageParticipantsReducer from "./ManageParticipants/reducer";
import IntraLabProgramReducer from "./IntraLaboratoryProgram/reducer";
import InterLabProgramReducer from "./InterLaboratoryProgram/reducer";
import PtProgramReducer from "./PTProgram/reducer";

const appReducer = combineReducers({
	AppSetting: GlobalAppReducer,
	AdminFormBuilder: AdminFromReducer,
	Auth: AuthReducer,
	ExternalUser: ExternalUserReducer,
	InternalUser: InternalUserReducer,
	Employee: EmployeeReducer,
	Customer: CustomerReducer,
	ClientFormBuilder: ClientFromReducer,
	Equipments: EquipmentReducer,
	FromBuilder: FromReducer,
	Method: MethodReducer,
	SampleName: SampleReducer,
	SampleList: SampleListReducer,
	Pages: pageReducer,
	RoleAndPermission: RoleAndPermissionReducer,
	Service: Service,
	CustomerOrder: CustomOrderReducer,
	WorkOrder: WorkorderReducer,
	ManageJob: MangageReducer,
	Jobs: jobsReducer,
	DataEntry: dataEntryReducer,
	EquipmentService: EquipmentsServiceReducer,
	ExpiredItems: ExpiredItemsReducer,
	ReagentsAndChemicals: ReagentReducer,
	Store: StoreReducer,
	Reports: ReportReducer,
	Groups: GroupReducer,
	Calibration: CalibrationReducer,
	Maintenance: MaintenanceReducer,
	Subscriber: SubscriberReducer,
	SubscriptionPlan: SubscriptionPlan,
	CMSPageList: CMSPageReducer,
	ContentPage: ContentPageReducer,
	SiteSetting: SiteSettingReducer,
	GeneralInventory: GeneralInventoryReducer,
	UserProfile: UserProfileReducer,
	EmployeeFormBuilder: EmployeeFromReducer,
	EmployeeDynamicDetails: EmployeeDetailsReducer,
	NonConformityReport: NonConformityReducerReducer,
	NonConfirmityTicket: NonConformityReportTicketReducer,
	archieve: ArchieveReducer,
	Accommodation: AccommodationReducer,
	Building: BuildingReducer,
	ClientDynamicDetails: ClientDetailsReducer,
	Vendor: VendorReducer,
	VendorFormBuilder: VendorFromReducer,
	VendorDynamicDetails: VendorDetailsReducer,
	ApprovalList: ApprovalListReducer,
	VendorCategories: VendorCategoriesReducer,
	VendorSubCategories: VendorSubCategoriesReducer,
	FormBuilder: formBuilderReducer,
	Documents: DocumentsReducer,
	DocumentCalibration: DocumentCalibrationReducer,
	DocumentMaintenance: DocumentMaintenanceReducer,
	CustomReports: CustomReportReducer,
	QualityManagment: QualityManagmentReducer,
	SubQualityManagment: SubQualityManagmentReducer,
	QualityManual: QualityManualReducer,
	userActivity: userActivityReducer,
	StandardDocument: StandardDocumentReducer,
	GeneralDocument: GeneraldocumentReducer,
	MasterNameList: MasterNameListReducer,
	ClientDocumentList: ClientDocumentListReducer,
	MethodPackages: MethodPackagesReducer,
	AdminUser: AdminUserReducer,
	ManagmentReview: ManagmentReviewReducer,
	InternalAudit: InternalAuditReducer,
	Traning: TraningReducer,
	PlanTraining: PlanTraningReducer,
	ManageParticipants: ManageParticipantsReducer,
	IntraLabProgram: IntraLabProgramReducer,
	InterLabProgram: InterLabProgramReducer,
	PTProgram: PtProgramReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "SESSION_RESET") {
		window.onload = () => {
			console.log("loaded");
		};
		return appReducer(undefined, { type: undefined });
	} else {
		return appReducer(state, action);
	}
};

const whitelist = ["AdminFormBuilder", "FromBuilder"];

export { rootReducer, whitelist };
