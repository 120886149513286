import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

function* getAdminHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(apiClient.post, endPoint.login, action.payload);
		localStorage.removeItem("authToken");
		localStorage.setItem("authToken", data.response.authToken);

		yield put({
			type: types.USER_LOGIN_SUCCESS,
			payload: data,
		});
	} catch (e) {
		console.log(18, e);
		const payload = e.response.data;
		yield put({
			type: types.USER_LOGIN_ERROR,
			payload,
		});
	}
}

export function* getAdminWatcherSaga() {
	yield takeLatest(types.USER_LOGIN_START, getAdminHandlerSaga);
}

function* getAdminOTPHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(apiClient.post, endPoint.otp, action.payload);
		localStorage.removeItem("userToken");
		localStorage.removeItem("User");
		localStorage.setItem("userToken", data.response.jwt);
		localStorage.setItem("User", JSON.stringify(data.response));

		yield put({
			type: types.USER_OTP_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.USER_OTP_ERROR,
			payload,
		});
	}
}

export function* getAdminOTPWatcherSaga() {
	yield takeLatest(types.USER_OTP_START, getAdminOTPHandlerSaga);
}
