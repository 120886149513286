import types from "./types";

const INITIAL_STATE = {
  CustomReport: null,
  CustomReportError: null,
  CustomReportLoading: false,

  //create
  createCustomReport: null,
  createCustomReportError: null,
  createCustomReportLoading: false,

  //update
  updateCustomReport: null,
  updateCustomReportError: null,
  updateCustomReportLoading: false,

  //delete
  deleteCustomReport: null,
  deleteCustomReportError: null,
  deleteCustomReportLoading: false,

  //single item

  singleItem: null,
};

const CustomReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOM_REPORT_START:
      return {
        ...state,
        CustomReport: null,
        CustomReportError: null,
        CustomReportLoading: true,
      };
    case types.GET_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        CustomReport: action.payload,
        CustomReportLoading: false,
      };
    case types.GET_CUSTOM_REPORT_ERROR:
      return {
        ...state,
        CustomReportError: action.payload,
        CustomReportLoading: false,
      };
    case types.GET_CUSTOM_REPORT_RESET:
      return {
        ...state,
        CustomReportError: null,
        CustomReportLoading: false,
      };
    //create

    case types.CREATE_CUSTOM_REPORT_START:
      return {
        ...state,
        createCustomReport: null,
        createCustomReportError: null,
        createCustomReportLoading: true,
      };
    case types.CREATE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        createCustomReport: action.payload,
        createCustomReportLoading: false,
      };
    case types.CREATE_CUSTOM_REPORT_ERROR:
      return {
        ...state,
        createCustomReportError: action.payload,
        createCustomReportLoading: false,
      };
    case types.CREATE_CUSTOM_REPORT_RESET:
      return {
        ...state,
        createCustomReportError: null,
        createCustomReportLoading: false,
      };
    //delete

    case types.DELETE_CUSTOM_REPORT_START:
      return {
        ...state,
        deleteCustomReport: null,
        deleteCustomReportError: null,
        deleteCustomReportLoading: true,
      };
    case types.DELETE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        deleteCustomReport: action.payload,
        deleteCustomReportLoading: false,
      };
    case types.DELETE_CUSTOM_REPORT_ERROR:
      return {
        ...state,
        deleteCustomReportError: action.payload,
        deleteCustomReportLoading: false,
      };
    case types.DELETE_CUSTOM_REPORT_RESET:
      return {
        ...state,
        deleteCustomReport: null,
        deleteCustomReportError: null,
        deleteCustomReportLoading: false,
      };

    //update
    case types.UPDATE_CUSTOM_REPORT_START:
      return {
        ...state,
        updateCustomReport: null,
        updateCustomReportError: null,
        updateCustomReportLoading: true,
      };
    case types.UPDATE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        updateCustomReport: action.payload,
        updateCustomReportLoading: false,
      };
    case types.UPDATE_CUSTOM_REPORT_ERROR:
      return {
        ...state,
        updateCustomReportError: action.payload,
        updateCustomReportLoading: false,
      };
    case types.UPDATE_CUSTOM_REPORT_RESET:
      return {
        ...state,
        updateCustomReport: null,
        updateCustomReportError: null,
        updateCustomReportLoading: false,
      };
    //singleItem
    // case types.SINGLE_CUSTOM_REPORT_START:
    // 	return {
    // 		...state,
    // 		updateCustomReport: null,
    // 		updateCustomReportError: null,
    // 		updateCustomReportLoading: true,
    // 	};
    // case types.SINGLE_CUSTOM_REPORT_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateCustomReport: action.payload,
    // 		updateCustomReportLoading: false,
    // 	};
    // case types.SINGLE_CUSTOM_REPORT_ERROR:
    // 	return {
    // 		...state,
    // 		updateCustomReportError: action.payload,
    // 		updateCustomReportLoading: false,
    // 	};
    // case types.SINGLE_CUSTOM_REPORT_RESET:
    // 	return {
    // 		...state,
    // 		updateCustomReportError: null,
    // 		updateCustomReportLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default CustomReportReducer;
