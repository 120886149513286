import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

const LazyLoader = (props) => {
	return (
		<Suspense
			fallback={
				<div style={{ margin: "30% 45%" }}>
					<Spinner animation='border' />
				</div>
			}>
			{props.children}
		</Suspense>
	);
};

export default LazyLoader;
