import types from "./types";

const INITIAL_STATE = {
	generaldocument: null,
	generaldocumentError: null,
	generaldocumentLoading: false,

	//create
	creategeneraldocument: null,
	creategeneraldocumentError: null,
	creategeneraldocumentLoading: false,

	//update
	updategeneraldocument: null,
	updategeneraldocumentError: null,
	updategeneraldocumentLoading: false,

	//delete
	deletegeneraldocument: null,
	deletegeneraldocumentError: null,
	deletegeneraldocumentLoading: false,

	//single item

	singleItem: null,
};

const GeneraldocumentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_GENERALDOCUMENT_START:
			return {
				...state,
				generaldocument: null,
				generaldocumentError: null,
				generaldocumentLoading: true,
			};
		case types.GET_GENERALDOCUMENT_SUCCESS:
			return {
				...state,
				generaldocument: action.payload,
				generaldocumentLoading: false,
			};
		case types.GET_GENERALDOCUMENT_ERROR:
			return {
				...state,
				generaldocumentError: action.payload,
				generaldocumentLoading: false,
			};
		case types.GET_GENERALDOCUMENT_RESET:
			return {
				...state,
				generaldocumentError: null,
				generaldocumentLoading: false,
			};
		//create

		case types.CREATE_GENERALDOCUMENT_START:
			return {
				...state,
				creategeneraldocument: null,
				creategeneraldocumentError: null,
				creategeneraldocumentLoading: true,
			};
		case types.CREATE_GENERALDOCUMENT_SUCCESS:
			return {
				...state,
				creategeneraldocument: action.payload,
				creategeneraldocumentLoading: false,
			};
		case types.CREATE_GENERALDOCUMENT_ERROR:
			return {
				...state,
				creategeneraldocumentError: action.payload,
				creategeneraldocumentLoading: false,
			};
		case types.CREATE_GENERALDOCUMENT_RESET:
			return {
				...state,
				creategeneraldocumentError: null,
				creategeneraldocumentLoading: false,
			};
		//delete

		case types.DELETE_GENERALDOCUMENT_START:
			return {
				...state,
				deletegeneraldocument: null,
				deletegeneraldocumentError: null,
				deletegeneraldocumentLoading: true,
			};
		case types.DELETE_GENERALDOCUMENT_SUCCESS:
			return {
				...state,
				deletegeneraldocument: action.payload,
				deletegeneraldocumentLoading: false,
			};
		case types.DELETE_GENERALDOCUMENT_ERROR:
			return {
				...state,
				deletegeneraldocumentError: action.payload,
				deletegeneraldocumentLoading: false,
			};
		case types.DELETE_GENERALDOCUMENT_RESET:
			return {
				...state,
				deletegeneraldocument: null,
				deletegeneraldocumentError: null,
				deletegeneraldocumentLoading: false,
			};

		//update
		case types.UPDATE_GENERALDOCUMENT_START:
			return {
				...state,
				updategeneraldocument: null,
				updategeneraldocumentError: null,
				updategeneraldocumentLoading: true,
			};
		case types.UPDATE_GENERALDOCUMENT_SUCCESS:
			return {
				...state,
				updategeneraldocument: action.payload,
				updategeneraldocumentLoading: false,
			};
		case types.UPDATE_GENERALDOCUMENT_ERROR:
			return {
				...state,
				updategeneraldocumentError: action.payload,
				updategeneraldocumentLoading: false,
			};
		case types.UPDATE_GENERALDOCUMENT_RESET:
			return {
				...state,
				updategeneraldocument: null,
				updategeneraldocumentError: null,
				updategeneraldocumentLoading: false,
			};
		

		default:
			return { ...state };
	}
};

export default GeneraldocumentReducer;
