import types from "./types";

const INITIAL_STATE = {
	vendorCategories: null,
	vendorCategoriesError: null,
	vendorCategoriesLoading: false,

	//create
	createvendorCategories: null,
	createvendorCategoriesError: null,
	createvendorCategoriesLoading: false,

	//update
	updatevendorCategories: null,
	updatevendorCategoriesError: null,
	updatevendorCategoriesLoading: false,

	//delete
	deletevendorCategories: null,
	deletevendorCategoriesError: null,
	deletevendorCategoriesLoading: false,

	//single item

	singleItem: null,
};

const VendorCategoriesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_VENDOR_CATEGORIES_START:
			return {
				...state,
				vendorCategories: null,
				vendorCategoriesError: null,
				vendorCategoriesLoading: true,
			};
		case types.GET_VENDOR_CATEGORIES_SUCCESS:
			return {
				...state,
				vendorCategories: action.payload,
				vendorCategoriesLoading: false,
			};
		case types.GET_VENDOR_CATEGORIES_ERROR:
			return {
				...state,
				vendorCategoriesError: action.payload,
				vendorCategoriesLoading: false,
			};
		case types.GET_VENDOR_CATEGORIES_RESET:
			return {
				...state,
				vendorCategoriesError: null,
				vendorCategoriesLoading: false,
			};
		//create

		case types.CREATE_VENDOR_CATEGORIES_START:
			return {
				...state,
				createvendorCategories: null,
				createvendorCategoriesError: null,
				createvendorCategoriesLoading: true,
			};
		case types.CREATE_VENDOR_CATEGORIES_SUCCESS:
			return {
				...state,
				createvendorCategories: action.payload,
				createvendorCategoriesLoading: false,
			};
		case types.CREATE_VENDOR_CATEGORIES_ERROR:
			return {
				...state,
				createvendorCategoriesError: action.payload,
				createvendorCategoriesLoading: false,
			};
		case types.CREATE_VENDOR_CATEGORIES_RESET:
			return {
				...state,
				createvendorCategoriesError: null,
				createvendorCategoriesLoading: false,
			};
		//delete

		case types.DELETE_VENDOR_CATEGORIES_START:
			return {
				...state,
				deletevendorCategories: null,
				deletevendorCategoriesError: null,
				deletevendorCategoriesLoading: true,
			};
		case types.DELETE_VENDOR_CATEGORIES_SUCCESS:
			return {
				...state,
				deletevendorCategories: action.payload,
				deletevendorCategoriesLoading: false,
			};
		case types.DELETE_VENDOR_CATEGORIES_ERROR:
			return {
				...state,
				deletevendorCategoriesError: action.payload,
				deletevendorCategoriesLoading: false,
			};
		case types.DELETE_VENDOR_CATEGORIES_RESET:
			return {
				...state,
				deletevendorCategories: null,
				deletevendorCategoriesError: null,
				deletevendorCategoriesLoading: false,
			};

		//update
		case types.UPDATE_VENDOR_CATEGORIES_START:
			return {
				...state,
				updatevendorCategories: null,
				updatevendorCategoriesError: null,
				updatevendorCategoriesLoading: true,
			};
		case types.UPDATE_VENDOR_CATEGORIES_SUCCESS:
			return {
				...state,
				updatevendorCategories: action.payload,
				updatevendorCategoriesLoading: false,
			};
		case types.UPDATE_VENDOR_CATEGORIES_ERROR:
			return {
				...state,
				updatevendorCategoriesError: action.payload,
				updatevendorCategoriesLoading: false,
			};
		case types.UPDATE_VENDOR_CATEGORIES_RESET:
			return {
				...state,
				updatevendorCategories: null,
				updatevendorCategoriesError: null,
				updatevendorCategoriesLoading: false,
			};
		//singleItem
		// case types.SINGLE_VENDOR_CATEGORIES_START:
		// 	return {
		// 		...state,
		// 		updatevendorCategories: null,
		// 		updatevendorCategoriesError: null,
		// 		updatevendorCategoriesLoading: true,
		// 	};
		// case types.SINGLE_VENDOR_CATEGORIES_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatevendorCategories: action.payload,
		// 		updatevendorCategoriesLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_CATEGORIES_ERROR:
		// 	return {
		// 		...state,
		// 		updatevendorCategoriesError: action.payload,
		// 		updatevendorCategoriesLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_CATEGORIES_RESET:
		// 	return {
		// 		...state,
		// 		updatevendorCategoriesError: null,
		// 		updatevendorCategoriesLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default VendorCategoriesReducer;
