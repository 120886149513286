import types from "./types";

const INITIAL_STATE = {
	groups: null,
	groupsError: null,
	groupsLoading: false,

	manageGroup: null,
	manageGroupError: null,
	manageGroupLoading: false,
};

const GroupReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		//get

		case types.GET_GROUPS_START:
			return {
				...state,
				groups: null,
				groupsError: null,
				groupsLoading: true,
			};
		case types.GET_GROUPS_SUCCESS:
			return {
				...state,
				groups: action.payload,
				groupsLoading: false,
			};
		case types.GET_GROUPS_ERROR:
			return {
				...state,
				groupsError: action.payload,
				groupsLoading: false,
			};
		case types.GET_GROUPS_RESET:
			return {
				...state,
				groups: null,
				groupsError: null,
				groupsLoading: false,
			};

		case types.GET_MANAGE_GROUP_START:
			return {
				...state,
				manageGroup: null,
				manageGroupError: null,
				manageGroupLoading: true,
			};
		case types.GET_MANAGE_GROUP_SUCCESS:
			return {
				...state,
				manageGroup: action.payload,
				manageGroupLoading: false,
			};
		case types.GET_MANAGE_GROUP_ERROR:
			return {
				...state,
				manageGroupError: action.payload,
				manageGroupLoading: false,
			};
		case types.GET_MANAGE_GROUP_RESET:
		default:
			return { ...state };
	}
};

export default GroupReducer;
