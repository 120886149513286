import types from "./types";

const INITIAL_STATE = {
	contentPage: null,
	contentPageError: null,
	contentPageLoading: false,

	//create
	createcontentPage: null,
	createcontentPageError: null,
	createcontentPageLoading: false,

	//update
	updatecontentPage: null,
	updatecontentPageError: null,
	updatecontentPageLoading: false,

	//delete
	deletecontentPage: null,
	deletecontentPageError: null,
	deletecontentPageLoading: false,

	//single item

	singleItem: null,
};

const ContentPageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_CONTENT_PAGE_START:
			return {
				...state,
				contentPage: null,
				contentPageError: null,
				contentPageLoading: true,
			};
		case types.GET_CONTENT_PAGE_SUCCESS:
			return {
				...state,
				contentPage: action.payload,
				contentPageLoading: false,
			};
		case types.GET_CONTENT_PAGE_ERROR:
			return {
				...state,
				contentPageError: action.payload,
				contentPageLoading: false,
			};
		case types.GET_CONTENT_PAGE_RESET:
			return {
				...state,
				contentPageError: null,
				contentPageLoading: false,
			};
		//create

		case types.CREATE_CONTENT_PAGE_START:
			return {
				...state,
				createcontentPage: null,
				createcontentPageError: null,
				createcontentPageLoading: true,
			};
		case types.CREATE_CONTENT_PAGE_SUCCESS:
			return {
				...state,
				createcontentPage: action.payload,
				createcontentPageLoading: false,
			};
		case types.CREATE_CONTENT_PAGE_ERROR:
			return {
				...state,
				createcontentPageError: action.payload,
				createcontentPageLoading: false,
			};
		case types.CREATE_CONTENT_PAGE_RESET:
			return {
				...state,
				createcontentPageError: null,
				createcontentPageLoading: false,
			};
		//delete

		case types.DELETE_CONTENT_PAGE_START:
			return {
				...state,
				deletecontentPage: null,
				deletecontentPageError: null,
				deletecontentPageLoading: true,
			};
		case types.DELETE_CONTENT_PAGE_SUCCESS:
			return {
				...state,
				deletecontentPage: action.payload,
				deletecontentPageLoading: false,
			};
		case types.DELETE_CONTENT_PAGE_ERROR:
			return {
				...state,
				deletecontentPageError: action.payload,
				deletecontentPageLoading: false,
			};
		case types.DELETE_CONTENT_PAGE_RESET:
			return {
				...state,
				deletecontentPage: null,
				deletecontentPageError: null,
				deletecontentPageLoading: false,
			};

		//update
		case types.UPDATE_CONTENT_PAGE_START:
			return {
				...state,
				updatecontentPage: null,
				updatecontentPageError: null,
				updatecontentPageLoading: true,
			};
		case types.UPDATE_CONTENT_PAGE_SUCCESS:
			return {
				...state,
				updatecontentPage: action.payload,
				updatecontentPageLoading: false,
			};
		case types.UPDATE_CONTENT_PAGE_ERROR:
			return {
				...state,
				updatecontentPageError: action.payload,
				updatecontentPageLoading: false,
			};
		case types.UPDATE_CONTENT_PAGE_RESET:
			return {
				...state,
				updatecontentPage: null,
				updatecontentPageError: null,
				updatecontentPageLoading: false,
			};
		//singleItem
		// case types.SINGLE_CONTENT_PAGE_START:
		// 	return {
		// 		...state,
		// 		updatecontentPage: null,
		// 		updatecontentPageError: null,
		// 		updatecontentPageLoading: true,
		// 	};
		// case types.SINGLE_CONTENT_PAGE_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatecontentPage: action.payload,
		// 		updatecontentPageLoading: false,
		// 	};
		// case types.SINGLE_CONTENT_PAGE_ERROR:
		// 	return {
		// 		...state,
		// 		updatecontentPageError: action.payload,
		// 		updatecontentPageLoading: false,
		// 	};
		// case types.SINGLE_CONTENT_PAGE_RESET:
		// 	return {
		// 		...state,
		// 		updatecontentPageError: null,
		// 		updatecontentPageLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default ContentPageReducer;
