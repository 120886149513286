import types from "./types";

const INITIAL_STATE = {
  store: null,
  storeError: null,
  storeLoading: false,

  //create
  createstore: null,
  createstoreError: null,
  createstoreLoading: false,

  //update
  updatestore: null,
  updatestoreError: null,
  updatestoreLoading: false,

  //delete
  deletestore: null,
  deletestoreError: null,
  deletestoreLoading: false,

  //single item

  singleItem: null,
};

const StoreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_STORE_START:
      return {
        ...state,
        store: null,
        storeError: null,
        storeLoading: true,
      };
    case types.GET_STORE_SUCCESS:
      return {
        ...state,
        store: action.payload,
        storeLoading: false,
      };
    case types.GET_STORE_ERROR:
      return {
        ...state,
        storeError: action.payload,
        storeLoading: false,
      };
    case types.GET_STORE_RESET:
      return {
        ...state,
        storeError: null,
        storeLoading: false,
      };
    //create

    case types.CREATE_STORE_START:
      return {
        ...state,
        createstore: null,
        createstoreError: null,
        createstoreLoading: true,
      };
    case types.CREATE_STORE_SUCCESS:
      return {
        ...state,
        createstore: action.payload,
        createstoreLoading: false,
      };
    case types.CREATE_STORE_ERROR:
      return {
        ...state,
        createstoreError: action.payload,
        createstoreLoading: false,
      };
    case types.CREATE_STORE_RESET:
      return {
        ...state,
        createstoreError: null,
        createstoreLoading: false,
      };
    //delete

    case types.DELETE_STORE_START:
      return {
        ...state,
        deletestore: null,
        deletestoreError: null,
        deletestoreLoading: true,
      };
    case types.DELETE_STORE_SUCCESS:
      return {
        ...state,
        deletestore: action.payload,
        deletestoreLoading: false,
      };
    case types.DELETE_STORE_ERROR:
      return {
        ...state,
        deletestoreError: action.payload,
        deletestoreLoading: false,
      };
    case types.DELETE_STORE_RESET:
      return {
        ...state,
        deletestore: null,
        deletestoreError: null,
        deletestoreLoading: false,
      };

    //update
    case types.UPDATE_STORE_START:
      return {
        ...state,
        updatestore: null,
        updatestoreError: null,
        updatestoreLoading: true,
      };
    case types.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        updatestore: action.payload,
        updatestoreLoading: false,
      };
    case types.UPDATE_STORE_ERROR:
      return {
        ...state,
        updatestoreError: action.payload,
        updatestoreLoading: false,
      };
    case types.UPDATE_STORE_RESET:
      return {
        ...state,
        updatestore: null,
        updatestoreError: null,
        updatestoreLoading: false,
      };
    //singleItem
    // case types.SINGLE_STORE_START:
    // 	return {
    // 		...state,
    // 		updatestore: null,
    // 		updatestoreError: null,
    // 		updatestoreLoading: true,
    // 	};
    // case types.SINGLE_STORE_SUCCESS:
    // 	return {
    // 		...state,
    // 		updatestore: action.payload,
    // 		updatestoreLoading: false,
    // 	};
    // case types.SINGLE_STORE_ERROR:
    // 	return {
    // 		...state,
    // 		updatestoreError: action.payload,
    // 		updatestoreLoading: false,
    // 	};
    // case types.SINGLE_STORE_RESET:
    // 	return {
    // 		...state,
    // 		updatestoreError: null,
    // 		updatestoreLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default StoreReducer;
