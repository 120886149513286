import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endponits from "../../backend/endpoints";

/**
 *
 * @param {*} action
 * getting menu list
 */

function* getreportsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endponits.dynamicList(action.payload),
		);
		yield put({
			type: types.GET_REPORTS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_REPORTS_ERROR,
			payload,
		});
	}
}

export function* getreportsWatcherSaga() {
	yield takeLatest(types.GET_REPORTS_START, getreportsHandlerSaga);
}
