import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getCmsPageListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "cms", ...action.payload }),
		);
		yield put({
			type: types.GET_CMS_PAGE_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_CMS_PAGE_LIST_ERROR,
			payload,
		});
	}
}

export function* getCmsPageListWatcherSaga() {
	yield takeLatest(types.GET_CMS_PAGE_LIST_START, getCmsPageListHandlerSaga);
}

//create

//delete

function* deleteCmsPageListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("cms", action.payload),
		);
		yield put({
			type: types.DELETE_CMS_PAGE_LIST_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_CMS_PAGE_LIST_ERROR,
			payload,
		});
	}
}

export function* deleteCmsPageListWatcherSaga() {
	yield takeLatest(
		types.DELETE_CMS_PAGE_LIST_START,
		deleteCmsPageListHandlerSaga,
	);
}

//update
