import { LazyLoader } from "../Components";
import { Mainlayout, PageNotFound, SiteSetting } from "../Pages";
import { Routes, Route } from "react-router-dom";
import routes from "./main.routes";

import {
	Login,
	ForgetPassword,
	CreatePassword,
	OneTimePassword,
} from "../Pages/Auth";
import useUser from "hooks/useUser";
import PublicRoutes from "./PublicRoute";
import ProtectRoutes from "./ProtectRoutes";

const AppRouter = () => {
	const { user } = useUser();
	const ENV = process.env.NODE_ENV;

	const mapRouter = (routes, mainRouter = []) => {
		routes.forEach((route) => {
			// if (route.env === "dev" && ENV === "production") return;
			if (Array.isArray(route.subItem)) {
				return mapRouter(route.subItem, mainRouter);
			} else {
				if (user.role === "admin") {
					mainRouter.push(
						<Route
							key={route.path}
							path={route.path}
							element={<LazyLoader>{route.component}</LazyLoader>}
						/>,
					);
				} else if (user.role === "employee") {
					user.accesspoints
						.map((key) => key.toLowerCase())
						.forEach((module) => {
							if (module === route.key) {
								mainRouter.push(
									<Route
										key={route.path}
										path={route.path}
										element={<LazyLoader>{route.component}</LazyLoader>}
									/>,
								);
							}
						});
				}
			}
		});
		return mainRouter;
	};

	return (
		<Routes>
			<Route element={<PublicRoutes />}>
				<Route
					path='/'
					element={
						<LazyLoader>
							<Login />
						</LazyLoader>
					}
				/>
				<Route
					path='/login'
					element={
						<LazyLoader>
							<Login />
						</LazyLoader>
					}
				/>
				<Route
					path='/passwordReset'
					element={
						<LazyLoader>
							<CreatePassword />
						</LazyLoader>
					}
				/>
				<Route
					path='/forgetpassword'
					element={
						<LazyLoader>
							<ForgetPassword />
						</LazyLoader>
					}
				/>
				<Route
					path='/templogin'
					element={
						<LazyLoader>
							<Login />
						</LazyLoader>
					}
				/>
				<Route
					path='/forget-password'
					element={
						<LazyLoader>
							<ForgetPassword />
						</LazyLoader>
					}
				/>

				<Route
					path='/otp'
					element={
						<LazyLoader>
							<OneTimePassword />
						</LazyLoader>
					}
				/>
			</Route>

			{/**App Routes start from here */}
			<Route element={<ProtectRoutes />}>
				<Route
					element={
						<LazyLoader>
							<Mainlayout />
						</LazyLoader>
					}>
					{mapRouter(routes)}

					<Route
						path='/sitesettings'
						element={
							<LazyLoader>
								<SiteSetting />
							</LazyLoader>
						}
					/>

					<Route
						path='*'
						element={
							<LazyLoader>
								<PageNotFound />
							</LazyLoader>
						}
					/>
				</Route>
			</Route>
		</Routes>
	);
};

export default AppRouter;
