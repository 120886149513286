const types = {
	GET_JOBS_START: "GET_JOBS_START",
	GET_JOBS_SUCCESS: "GET_JOBS_SUCCESS",
	GET_JOBS_ERROR: "GET_JOBS_ERROR",
	GET_JOBS_RESET: "GET_JOBS_RESET",
	GET_MASTER_JOBS_START: "GET_MASTER_JOBS_START",
	GET_MASTER_JOBS_SUCCESS: "GET_MASTER_JOBS_SUCCESS",
	GET_MASTER_JOBS_ERROR: "GET_MASTER_JOBS_ERROR",
	GET_MASTER_JOBS_RESET: "GET_MASTER_JOBS_RESET",
};

export default types;
