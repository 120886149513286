import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getSampleNameHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "samplename", ...action.payload }),
		);
		yield put({
			type: types.GET_SAMPLE_NAME_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_SAMPLE_NAME_ERROR,
			payload,
		});
	}
}

export function* getSampleNameWatcherSaga() {
	yield takeLatest(types.GET_SAMPLE_NAME_START, getSampleNameHandlerSaga);
}

//create
/**
 * 
 * function* createSampleNameHandlerSaga(action) {
	const apiClient=apiClientFactory();
	try {
		const { data } = yield call(apiClient.post, endPoint.addSample,action.payload);
		yield put({
			type: types.CREATE_SAMPLE_NAME_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_SAMPLE_NAME_ERROR,
			payload,
		});
	}
}

export function* createSampleNameWatcherSaga() {
	yield  takeLatest(
		types.CREATE_SAMPLE_NAME_START,
		createSampleNameHandlerSaga,
	);
}
 */

//delete

function* deleteSampleNameHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("samplename", action.payload),
		);
		yield put({
			type: types.DELETE_SAMPLE_NAME_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_SAMPLE_NAME_ERROR,
			payload,
		});
	}
}

export function* deleteSampleNameWatcherSaga() {
	yield takeLatest(types.DELETE_SAMPLE_NAME_START, deleteSampleNameHandlerSaga);
}

function* updateSampleNameHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			action.payload.path,
			action.payload.body,
		);
		yield put({
			type: types.UPDATE_SAMPLE_NAME_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_SAMPLE_NAME_ERROR,
			payload,
		});
	}
}

export function* updateSampleNameWatcherSaga() {
	yield takeLatest(types.UPDATE_SAMPLE_NAME_START, updateSampleNameHandlerSaga);
}
