import { format } from "date-fns";

export default function useUser(accessname) {
	const user = JSON.parse(localStorage.getItem("user")) || {
		jwt: null,
		id: null,
	};

	const accessPath = (() => {
		if (user.role === "employee" && user.accesspoints.includes(accessname)) {
			if (user.accessState) {
				return user.accessState[accessname];
			} else {
				return { view: false, edit: false, delete: false, create: false };
			}
		} else if (user.role === "admin") {
			return { view: true, edit: true, delete: true, create: true };
		} else {
			return { view: false, edit: false, delete: false, create: false };
		}
	})();

	function logout() {
		localStorage.removeItem("user");
		window.location.reload();
	}

	return { user, logout, accessPath };
}
