import types from "./types";

const INITIAL_STATE = {
  EquipmentsService: null,
  EquipmentsServiceError: null,
  EquipmentsServiceLoading: false,

  //create
  createEquipmentsService: null,
  createEquipmentsServiceError: null,
  createEquipmentsServiceLoading: false,

  //update
  updateEquipmentsService: null,
  updateEquipmentsServiceError: null,
  updateEquipmentsServiceLoading: false,

  //delete
  deleteEquipmentsService: null,
  deleteEquipmentsServiceError: null,
  deleteEquipmentsServiceLoading: false,

  //single item

  singleItem: null,
};

const EquipmentsServiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EQUIPMENTS_SERVICE_START:
      return {
        ...state,
        EquipmentsService: null,
        EquipmentsServiceError: null,
        EquipmentsServiceLoading: true,
      };
    case types.GET_EQUIPMENTS_SERVICE_SUCCESS:
      return {
        ...state,
        EquipmentsService: action.payload,
        EquipmentsServiceLoading: false,
      };
    case types.GET_EQUIPMENTS_SERVICE_ERROR:
      return {
        ...state,
        EquipmentsServiceError: action.payload,
        EquipmentsServiceLoading: false,
      };
    case types.GET_EQUIPMENTS_SERVICE_RESET:
      return {
        ...state,
        EquipmentsServiceError: null,
        EquipmentsServiceLoading: false,
      };
    //create

    case types.CREATE_EQUIPMENTS_SERVICE_START:
      return {
        ...state,
        createEquipmentsService: null,
        createEquipmentsServiceError: null,
        createEquipmentsServiceLoading: true,
      };
    case types.CREATE_EQUIPMENTS_SERVICE_SUCCESS:
      return {
        ...state,
        createEquipmentsService: action.payload,
        createEquipmentsServiceLoading: false,
      };
    case types.CREATE_EQUIPMENTS_SERVICE_ERROR:
      return {
        ...state,
        createEquipmentsServiceError: action.payload,
        createEquipmentsServiceLoading: false,
      };
    case types.CREATE_EQUIPMENTS_SERVICE_RESET:
      return {
        ...state,
        createEquipmentsService: null,
        createEquipmentsServiceError: null,
        createEquipmentsServiceLoading: false,
      };
    //delete

    case types.DELETE_EQUIPMENTS_SERVICE_START:
      return {
        ...state,
        deleteEquipmentsService: null,
        deleteEquipmentsServiceError: null,
        deleteEquipmentsServiceLoading: true,
      };
    case types.DELETE_EQUIPMENTS_SERVICE_SUCCESS:
      return {
        ...state,
        deleteEquipmentsService: action.payload,
        deleteEquipmentsServiceLoading: false,
      };
    case types.DELETE_EQUIPMENTS_SERVICE_ERROR:
      return {
        ...state,
        deleteEquipmentsServiceError: action.payload,
        deleteEquipmentsServiceLoading: false,
      };
    case types.DELETE_EQUIPMENTS_SERVICE_RESET:
      return {
        ...state,
        deleteEquipmentsService: null,
        deleteEquipmentsServiceError: null,
        deleteEquipmentsServiceLoading: false,
      };

    //update
    case types.UPDATE_EQUIPMENTS_SERVICE_START:
      return {
        ...state,
        updateEquipmentsService: null,
        updateEquipmentsServiceError: null,
        updateEquipmentsServiceLoading: true,
      };
    case types.UPDATE_EQUIPMENTS_SERVICE_SUCCESS:
      return {
        ...state,
        updateEquipmentsService: action.payload,
        updateEquipmentsServiceLoading: false,
      };
    case types.UPDATE_EQUIPMENTS_SERVICE_ERROR:
      return {
        ...state,
        updateEquipmentsServiceError: action.payload,
        updateEquipmentsServiceLoading: false,
      };
    case types.UPDATE_EQUIPMENTS_SERVICE_RESET:
      return {
        ...state,
        updateEquipmentsService: null,
        updateEquipmentsServiceError: null,
        updateEquipmentsServiceLoading: false,
      };
    //singleItem
    // case types.SINGLE_EQUIPMENTS_SERVICE_START:
    // 	return {
    // 		...state,
    // 		updateEquipmentsService: null,
    // 		updateEquipmentsServiceError: null,
    // 		updateEquipmentsServiceLoading: true,
    // 	};
    // case types.SINGLE_EQUIPMENTS_SERVICE_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateEquipmentsService: action.payload,
    // 		updateEquipmentsServiceLoading: false,
    // 	};
    // case types.SINGLE_EQUIPMENTS_SERVICE_ERROR:
    // 	return {
    // 		...state,
    // 		updateEquipmentsServiceError: action.payload,
    // 		updateEquipmentsServiceLoading: false,
    // 	};
    // case types.SINGLE_EQUIPMENTS_SERVICE_RESET:
    // 	return {
    // 		...state,
    // 		updateEquipmentsServiceError: null,
    // 		updateEquipmentsServiceLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default EquipmentsServiceReducer;
