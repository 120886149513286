const types = {
	//Get Template name
	GET_VENDOR_CATEGORIES_START: "GET_VENDOR_CATEGORIES_START",
	GET_VENDOR_CATEGORIES_SUCCESS: "GET_VENDOR_CATEGORIES_SUCCESS",
	GET_VENDOR_CATEGORIES_ERROR: "GET_VENDOR_CATEGORIES_ERROR",
	GET_VENDOR_CATEGORIES_RESET: "GET_VENDOR_CATEGORIES_RESET",

	//Create Template name
	CREATE_VENDOR_CATEGORIES_START: "CREATE_VENDOR_CATEGORIES_START",
	CREATE_VENDOR_CATEGORIES_SUCCESS: "CREATE_VENDOR_CATEGORIES_SUCCESS",
	CREATE_VENDOR_CATEGORIES_ERROR: "CREATE_VENDOR_CATEGORIES_ERROR",
	CREATE_VENDOR_CATEGORIES_RESET: "CREATE_VENDOR_CATEGORIES_RESET",

	//delete Template name
	DELETE_VENDOR_CATEGORIES_START: "DELETE_VENDOR_CATEGORIES_START",
	DELETE_VENDOR_CATEGORIES_SUCCESS: "DELETE_VENDOR_CATEGORIES_SUCCESS",
	DELETE_VENDOR_CATEGORIES_ERROR: "DELETE_VENDOR_CATEGORIES_ERROR",
	DELETE_VENDOR_CATEGORIES_RESET: "DELETE_VENDOR_CATEGORIES_RESET",

	//update Template name
	UPDATE_VENDOR_CATEGORIES_START: "UPDATE_VENDOR_CATEGORIES_START",
	UPDATE_VENDOR_CATEGORIES_SUCCESS: "UPDATE_VENDOR_CATEGORIES_SUCCESS",
	UPDATE_VENDOR_CATEGORIES_ERROR: "UPDATE_VENDOR_CATEGORIES_ERROR",
	UPDATE_VENDOR_CATEGORIES_RESET: "UPDATE_VENDOR_CATEGORIES_RESET",

	//single item  name
	SINGLE_VENDOR_CATEGORIES_START: "SINGLE_VENDOR_CATEGORIES_START",
	SINGLE_VENDOR_CATEGORIES_SUCCESS: "SINGLE_VENDOR_CATEGORIES_SUCCESS",
	SINGLE_VENDOR_CATEGORIES_ERROR: "SINGLE_VENDOR_CATEGORIES_ERROR",
	SINGLE_VENDOR_CATEGORIES_RESET: "SINGLE_VENDOR_CATEGORIES_RESET",
};

export default types;
