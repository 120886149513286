const types = {
	//Get Template name
	GET_FROMBUILDER_START: "GET_FROMBUILDER_START",
	GET_FROMBUILDER_SUCCESS: "GET_FROMBUILDER_SUCCESS",
	GET_FROMBUILDER_ERROR: "GET_FROMBUILDER_ERROR",
	GET_FROMBUILDER_RESET: "GET_FROMBUILDER_RESET",

	//Create Template name
	CREATE_FROMBUILDER_START: "CREATE_FROMBUILDER_START",
	CREATE_FROMBUILDER_SUCCESS: "CREATE_FROMBUILDER_SUCCESS",
	CREATE_FROMBUILDER_ERROR: "CREATE_FROMBUILDER_ERROR",
	CREATE_FROMBUILDER_RESET: "CREATE_FROMBUILDER_RESET",

	//delete Template name
	DELETE_FROMBUILDER_START: "DELETE_FROMBUILDER_START",
	DELETE_FROMBUILDER_SUCCESS: "DELETE_FROMBUILDER_SUCCESS",
	DELETE_FROMBUILDER_ERROR: "DELETE_FROMBUILDER_ERROR",
	DELETE_FROMBUILDER_RESET: "DELETE_FROMBUILDER_RESET",

	//update Template name
	UPDATE_FROMBUILDER_START: "UPDATE_FROMBUILDER_START",
	UPDATE_FROMBUILDER_SUCCESS: "UPDATE_FROMBUILDER_SUCCESS",
	UPDATE_FROMBUILDER_ERROR: "UPDATE_FROMBUILDER_ERROR",
	UPDATE_FROMBUILDER_RESET: "UPDATE_FROMBUILDER_RESET",

	//single item  name
	SINGLE_FROMBUILDER_START: "SINGLE_FROMBUILDER_START",
	SINGLE_FROMBUILDER_SUCCESS: "SINGLE_FROMBUILDER_SUCCESS",
	SINGLE_FROMBUILDER_ERROR: "SINGLE_FROMBUILDER_ERROR",
	SINGLE_FROMBUILDER_RESET: "SINGLE_FROMBUILDER_RESET",

	//single item  name
	SELECT_TOUPDATE_ITEM: "SELECT_TOUPDATE_ITEM",
};

export default types;
