import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getstoreHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "store", ...action.payload }),
		);
		yield put({
			type: types.GET_STORE_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_STORE_ERROR,
			payload,
		});
	}
}

export function* getstoreWatcherSaga() {
	yield takeLatest(types.GET_STORE_START, getstoreHandlerSaga);
}

//create

//delete

function* deletestoreHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("store", action.payload),
		);
		yield put({
			type: types.DELETE_STORE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_STORE_ERROR,
			payload,
		});
	}
}

export function* deletestoreWatcherSaga() {
	yield takeLatest(types.DELETE_STORE_START, deletestoreHandlerSaga);
}

//update
