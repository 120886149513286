import types from "./types";

const INITIAL_STATE = {
	samplelist: null,
	samplelistError: null,
	samplelistLoading: false,

	//create
	createSampleList: null,
	createSampleListError: null,
	createSampleListLoading: false,

	//update
	updateSampleList: null,
	updateSampleListError: null,
	updateSampleListLoading: false,

	//delete
	deleteSampleList: null,
	deleteSampleListError: null,
	deleteSampleListLoading: false,

	//single item

	singleItem: null,
};

const SampleListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_SAMPLE_LIST_START:
			return {
				...state,
				samplelist: null,
				samplelistError: null,
				samplelistLoading: true,
			};
		case types.GET_SAMPLE_LIST_SUCCESS:
			return {
				...state,
				samplelist: action.payload,
				samplelistLoading: false,
			};
		case types.GET_SAMPLE_LIST_ERROR:
			return {
				...state,
				samplelistError: action.payload,
				samplelistLoading: false,
			};
		case types.GET_SAMPLE_LIST_RESET:
			return {
				...state,
				samplelist: null,
				samplelistError: null,
				samplelistLoading: false,
			};
		//create

		case types.CREATE_SAMPLE_LIST_START:
			return {
				...state,
				createSampleList: null,
				createSampleListError: null,
				createSampleListLoading: true,
			};
		case types.CREATE_SAMPLE_LIST_SUCCESS:
			return {
				...state,
				createSampleList: action.payload,
				createSampleListLoading: false,
			};
		case types.CREATE_SAMPLE_LIST_ERROR:
			return {
				...state,
				createSampleListError: action.payload,
				createSampleListLoading: false,
			};
		case types.CREATE_SAMPLE_LIST_RESET:
			return {
				...state,
				createSampleList: null,
				createSampleListError: null,
				createSampleListLoading: false,
			};
		//delete

		case types.DELETE_SAMPLE_LIST_START:
			return {
				...state,
				deleteSampleList: null,
				deleteSampleListError: null,
				deleteSampleListLoading: true,
			};
		case types.DELETE_SAMPLE_LIST_SUCCESS:
			return {
				...state,
				deleteSampleList: action.payload,
				deleteSampleListLoading: false,
			};
		case types.DELETE_SAMPLE_LIST_ERROR:
			return {
				...state,
				deleteSampleListError: action.payload,
				deleteSampleListLoading: false,
			};
		case types.DELETE_SAMPLE_LIST_RESET:
			return {
				...state,
				deleteSampleList: null,
				deleteSampleListError: null,
				deleteSampleListLoading: false,
			};

		//update
		case types.UPDATE_SAMPLE_LIST_START:
			return {
				...state,
				updateSampleList: null,
				updateSampleListError: null,
				updateSampleListLoading: true,
			};
		case types.UPDATE_SAMPLE_LIST_SUCCESS:
			return {
				...state,
				updateSampleList: action.payload,
				updateSampleListLoading: false,
			};
		case types.UPDATE_SAMPLE_LIST_ERROR:
			return {
				...state,
				updateSampleListError: action.payload,
				updateSampleListLoading: false,
			};
		case types.UPDATE_SAMPLE_LIST_RESET:
			return {
				...state,
				updateSampleList: null,
				updateSampleListError: null,
				updateSampleListLoading: false,
			};
		//singleItem
		// case types.SINGLE_SAMPLE_LIST_START:
		// 	return {
		// 		...state,
		// 		updateSampleList: null,
		// 		updateSampleListError: null,
		// 		updateSampleListLoading: true,
		// 	};
		// case types.SINGLE_SAMPLE_LIST_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateSampleList: action.payload,
		// 		updateSampleListLoading: false,
		// 	};
		// case types.SINGLE_SAMPLE_LIST_ERROR:
		// 	return {
		// 		...state,
		// 		updateSampleListError: action.payload,
		// 		updateSampleListLoading: false,
		// 	};
		// case types.SINGLE_SAMPLE_LIST_RESET:
		// 	return {
		// 		...state,
		// 		updateSampleListError: null,
		// 		updateSampleListLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default SampleListReducer;
