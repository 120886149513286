import types from "./types";

const INITIAL_STATE = {
	vendor: null,
	vendorError: null,
	vendorLoading: false,

	//create
	createvendor: null,
	createvendorError: null,
	createvendorLoading: false,

	//update
	updatevendor: null,
	updatevendorError: null,
	updatevendorLoading: false,

	//delete
	deletevendor: null,
	deletevendorError: null,
	deletevendorLoading: false,

	//single item

	singleItem: null,
};

const VendorReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_VENDOR_START:
			return {
				...state,
				vendor: null,
				vendorError: null,
				vendorLoading: true,
			};
		case types.GET_VENDOR_SUCCESS:
			return {
				...state,
				vendor: action.payload,
				vendorLoading: false,
			};
		case types.GET_VENDOR_ERROR:
			return {
				...state,
				vendorError: action.payload,
				vendorLoading: false,
			};
		case types.GET_VENDOR_RESET:
			return {
				...state,
				vendorError: null,
				vendorLoading: false,
			};
		//create

		case types.CREATE_VENDOR_START:
			return {
				...state,
				createvendor: null,
				createvendorError: null,
				createvendorLoading: true,
			};
		case types.CREATE_VENDOR_SUCCESS:
			return {
				...state,
				createvendor: action.payload,
				createvendorLoading: false,
			};
		case types.CREATE_VENDOR_ERROR:
			return {
				...state,
				createvendorError: action.payload,
				createvendorLoading: false,
			};
		case types.CREATE_VENDOR_RESET:
			return {
				...state,
				createvendorError: null,
				createvendorLoading: false,
			};
		//delete

		case types.DELETE_VENDOR_START:
			return {
				...state,
				deletevendor: null,
				deletevendorError: null,
				deletevendorLoading: true,
			};
		case types.DELETE_VENDOR_SUCCESS:
			return {
				...state,
				deletevendor: action.payload,
				deletevendorLoading: false,
			};
		case types.DELETE_VENDOR_ERROR:
			return {
				...state,
				deletevendorError: action.payload,
				deletevendorLoading: false,
			};
		case types.DELETE_VENDOR_RESET:
			return {
				...state,
				deletevendor: null,
				deletevendorError: null,
				deletevendorLoading: false,
			};

		//update
		case types.UPDATE_VENDOR_START:
			return {
				...state,
				updatevendor: null,
				updatevendorError: null,
				updatevendorLoading: true,
			};
		case types.UPDATE_VENDOR_SUCCESS:
			return {
				...state,
				updatevendor: action.payload,
				updatevendorLoading: false,
			};
		case types.UPDATE_VENDOR_ERROR:
			return {
				...state,
				updatevendorError: action.payload,
				updatevendorLoading: false,
			};
		case types.UPDATE_VENDOR_RESET:
			return {
				...state,
				updatevendor: null,
				updatevendorError: null,
				updatevendorLoading: false,
			};
		//singleItem
		// case types.SINGLE_VENDOR_START:
		// 	return {
		// 		...state,
		// 		updatevendor: null,
		// 		updatevendorError: null,
		// 		updatevendorLoading: true,
		// 	};
		// case types.SINGLE_VENDOR_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatevendor: action.payload,
		// 		updatevendorLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_ERROR:
		// 	return {
		// 		...state,
		// 		updatevendorError: action.payload,
		// 		updatevendorLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_RESET:
		// 	return {
		// 		...state,
		// 		updatevendorError: null,
		// 		updatevendorLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default VendorReducer;
