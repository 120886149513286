/**
 *
 * @param {*} object
 * @return Array
 */

export function tableColumnParser(components, column = []) {
	if (Array.isArray(components)) {
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			let extractColumn = {};
			const keys = Object.keys(component);

			if (keys.includes("components")) {
				tableColumnParser(component.components, column);
			} else if (keys.includes("columns")) {
				tableColumnParser(component.columns, column);
			} else if (component.type !== "button") {
				extractColumn = {
					Header: component.label,
					accessor: component.key,
				};
				column.push(extractColumn);
			}
		}
	}

	return column;
}
