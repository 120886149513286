import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getCustomerHandlerSaga(action) {
	const apiClient = apiClientFactory();

	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "customer", ...action.payload }),
		);
		yield put({
			type: types.GET_CUSTOMER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_CUSTOMER_ERROR,
			payload,
		});
	}
}

export function* getCustomerWatcherSaga() {
	yield takeLatest(types.GET_CUSTOMER_START, getCustomerHandlerSaga);
}

//create

/** 
 * function* createCustomerHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endPoint.addCustomer,
			action.payload,
		);
		yield put({
			type: types.CREATE_CUSTOMER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_CUSTOMER_ERROR,
			payload,
		});
	}
}


export function* createCustomerWatcherSaga() {
	yield  takeLatest(types.CREATE_CUSTOMER_START, createCustomerHandlerSaga);
}

*/

//delete

function* deleteCustomerHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("customer", action.payload),
		);
		yield put({
			type: types.DELETE_CUSTOMER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_CUSTOMER_ERROR,
			payload,
		});
	}
}

export function* deleteCustomerWatcherSaga() {
	yield takeLatest(types.DELETE_CUSTOMER_START, deleteCustomerHandlerSaga);
}

//update

/** 
 * function* updateCustomerHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endpoints.updateCustomer,
			action.payload,
		);
		yield put({
			type: types.UPDATE_CUSTOMER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_CUSTOMER_ERROR,
			payload,
		});
	}
}

export function* updateCustomerWatcherSaga() {
	yield  takeLatest(types.UPDATE_CUSTOMER_START, updateCustomerHandlerSaga);
}
*/
