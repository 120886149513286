import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getdocumentsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "document", ...action.payload }),
		);
		yield put({
			type: types.GET_DOCUMENTS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_DOCUMENTS_ERROR,
			payload,
		});
	}
}

export function* getdocumentsWatcherSaga() {
	yield takeLatest(types.GET_DOCUMENTS_START, getdocumentsHandlerSaga);
}

//delete

function* deletedocumentsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("document", action.payload),
		);
		yield put({
			type: types.DELETE_DOCUMENTS_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_DOCUMENTS_ERROR,
			payload,
		});
	}
}

export function* deletedocumentsWatcherSaga() {
	yield takeLatest(types.DELETE_DOCUMENTS_START, deletedocumentsHandlerSaga);
}
