const types = {
	//Get Template name
	GET_CLIENTFORMLIST_START: "GET_CLIENTFORMLIST_START",
	GET_CLIENTFORMLIST_SUCCESS: "GET_CLIENTFORMLIST_SUCCESS",
	GET_CLIENTFORMLIST_ERROR: "GET_CLIENTFORMLIST_ERROR",
	GET_CLIENTFORMLIST_RESET: "GET_CLIENTFORMLIST_RESET",

	//single item  name
	SELECT_TOUPDATE_ITEM: "SELECT_TOUPDATE_ITEM",
};

export default types;
