const types = {
	//Get Template name
	GET_NON_CONFORMITY_REPORT_TICKET_START:
		"GET_NON_CONFORMITY_REPORT_TICKET_START",
	GET_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		"GET_NON_CONFORMITY_REPORT_TICKET_SUCCESS",
	GET_NON_CONFORMITY_REPORT_TICKET_ERROR:
		"GET_NON_CONFORMITY_REPORT_TICKET_ERROR",
	GET_NON_CONFORMITY_REPORT_TICKET_RESET:
		"GET_NON_CONFORMITY_REPORT_TICKET_RESET",

	//Create Template name
	CREATE_NON_CONFORMITY_REPORT_TICKET_START:
		"CREATE_NON_CONFORMITY_REPORT_TICKET_START",
	CREATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		"CREATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS",
	CREATE_NON_CONFORMITY_REPORT_TICKET_ERROR:
		"CREATE_NON_CONFORMITY_REPORT_TICKET_ERROR",
	CREATE_NON_CONFORMITY_REPORT_TICKET_RESET:
		"CREATE_NON_CONFORMITY_REPORT_TICKET_RESET",

	//delete Template name
	DELETE_NON_CONFORMITY_REPORT_TICKET_START:
		"DELETE_NON_CONFORMITY_REPORT_TICKET_START",
	DELETE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		"DELETE_NON_CONFORMITY_REPORT_TICKET_SUCCESS",
	DELETE_NON_CONFORMITY_REPORT_TICKET_ERROR:
		"DELETE_NON_CONFORMITY_REPORT_TICKET_ERROR",
	DELETE_NON_CONFORMITY_REPORT_TICKET_RESET:
		"DELETE_NON_CONFORMITY_REPORT_TICKET_RESET",

	//update Template name
	UPDATE_NON_CONFORMITY_REPORT_TICKET_START:
		"UPDATE_NON_CONFORMITY_REPORT_TICKET_START",
	UPDATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		"UPDATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS",
	UPDATE_NON_CONFORMITY_REPORT_TICKET_ERROR:
		"UPDATE_NON_CONFORMITY_REPORT_TICKET_ERROR",
	UPDATE_NON_CONFORMITY_REPORT_TICKET_RESET:
		"UPDATE_NON_CONFORMITY_REPORT_TICKET_RESET",

	//single item  name
	SINGLE_NON_CONFORMITY_REPORT_TICKET_START:
		"SINGLE_NON_CONFORMITY_REPORT_TICKET_START",
	SINGLE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		"SINGLE_NON_CONFORMITY_REPORT_TICKET_SUCCESS",
	SINGLE_NON_CONFORMITY_REPORT_TICKET_ERROR:
		"SINGLE_NON_CONFORMITY_REPORT_TICKET_ERROR",
	SINGLE_NON_CONFORMITY_REPORT_TICKET_RESET:
		"SINGLE_NON_CONFORMITY_REPORT_TICKET_RESET",
};

export default types;
