import types from "./types";

const INITIAL_STATE = {
  Documents: null,
  DocumentsError: null,
  DocumentsLoading: false,

  //create
  createDocuments: null,
  createDocumentsError: null,
  createDocumentsLoading: false,

  //update
  updateDocuments: null,
  updateDocumentsError: null,
  updateDocumentsLoading: false,

  //delete
  deleteDocuments: null,
  deleteDocumentsError: null,
  deleteDocumentsLoading: false,

  //single item

  singleItem: null,
};

const DocumentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_DOCUMENTS_START:
      return {
        ...state,
        Documents: null,
        DocumentsError: null,
        DocumentsLoading: true,
      };
    case types.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        Documents: action.payload,
        DocumentsLoading: false,
      };
    case types.GET_DOCUMENTS_ERROR:
      return {
        ...state,
        DocumentsError: action.payload,
        DocumentsLoading: false,
      };
    case types.GET_DOCUMENTS_RESET:
      return {
        ...state,
        DocumentsError: null,
        DocumentsLoading: false,
      };
    //create

    case types.CREATE_DOCUMENTS_START:
      return {
        ...state,
        createDocuments: null,
        createDocumentsError: null,
        createDocumentsLoading: true,
      };
    case types.CREATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        createDocuments: action.payload,
        createDocumentsLoading: false,
      };
    case types.CREATE_DOCUMENTS_ERROR:
      return {
        ...state,
        createDocumentsError: action.payload,
        createDocumentsLoading: false,
      };
    case types.CREATE_DOCUMENTS_RESET:
      return {
        ...state,
        createDocuments: null,
        createDocumentsError: null,
        createDocumentsLoading: false,
      };
    //delete

    case types.DELETE_DOCUMENTS_START:
      return {
        ...state,
        deleteDocuments: null,
        deleteDocumentsError: null,
        deleteDocumentsLoading: true,
      };
    case types.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deleteDocuments: action.payload,
        deleteDocumentsLoading: false,
      };
    case types.DELETE_DOCUMENTS_ERROR:
      return {
        ...state,
        deleteDocumentsError: action.payload,
        deleteDocumentsLoading: false,
      };
    case types.DELETE_DOCUMENTS_RESET:
      return {
        ...state,
        deleteDocuments: null,
        deleteDocumentsError: null,
        deleteDocumentsLoading: false,
      };

    //update
    case types.UPDATE_DOCUMENTS_START:
      return {
        ...state,
        updateDocuments: null,
        updateDocumentsError: null,
        updateDocumentsLoading: true,
      };
    case types.UPDATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        updateDocuments: action.payload,
        updateDocumentsLoading: false,
      };
    case types.UPDATE_DOCUMENTS_ERROR:
      return {
        ...state,
        updateDocumentsError: action.payload,
        updateDocumentsLoading: false,
      };
    case types.UPDATE_DOCUMENTS_RESET:
      return {
        ...state,
        updateDocuments: null,
        updateDocumentsError: null,
        updateDocumentsLoading: false,
      };
  
    default:
      return { ...state };
  }
};

export default DocumentsReducer;
