import types from "./types";

const INITIAL_STATE = {
  expiredItems: null,
  expiredItemsError: null,
  expiredItemsLoading: false,

  //create
  createexpiredItems: null,
  createexpiredItemsError: null,
  createexpiredItemsLoading: false,

  //update
  updateexpiredItems: null,
  updateexpiredItemsError: null,
  updateexpiredItemsLoading: false,

  //delete
  deleteexpiredItems: null,
  deleteexpiredItemsError: null,
  deleteexpiredItemsLoading: false,

  //single item

  singleItem: null,
};

const ExpiredItemsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EXPIRED_ITEMS_START:
      return {
        ...state,
        expiredItems: null,
        expiredItemsError: null,
        expiredItemsLoading: true,
      };
    case types.GET_EXPIRED_ITEMS_SUCCESS:
      return {
        ...state,
        expiredItems: action.payload,
        expiredItemsLoading: false,
      };
    case types.GET_EXPIRED_ITEMS_ERROR:
      return {
        ...state,
        expiredItemsError: action.payload,
        expiredItemsLoading: false,
      };
    case types.GET_EXPIRED_ITEMS_RESET:
      return {
        ...state,
        expiredItemsError: null,
        expiredItemsLoading: false,
      };
    //create

    case types.CREATE_EXPIRED_ITEMS_START:
      return {
        ...state,
        createexpiredItems: null,
        createexpiredItemsError: null,
        createexpiredItemsLoading: true,
      };
    case types.CREATE_EXPIRED_ITEMS_SUCCESS:
      return {
        ...state,
        createexpiredItems: action.payload,
        createexpiredItemsLoading: false,
      };
    case types.CREATE_EXPIRED_ITEMS_ERROR:
      return {
        ...state,
        createexpiredItemsError: action.payload,
        createexpiredItemsLoading: false,
      };
    case types.CREATE_EXPIRED_ITEMS_RESET:
      return {
        ...state,
        createexpiredItemsError: null,
        createexpiredItemsLoading: false,
      };
    //delete

    case types.DELETE_EXPIRED_ITEMS_START:
      return {
        ...state,
        deleteexpiredItems: null,
        deleteexpiredItemsError: null,
        deleteexpiredItemsLoading: true,
      };
    case types.DELETE_EXPIRED_ITEMS_SUCCESS:
      return {
        ...state,
        deleteexpiredItems: action.payload,
        deleteexpiredItemsLoading: false,
      };
    case types.DELETE_EXPIRED_ITEMS_ERROR:
      return {
        ...state,
        deleteexpiredItemsError: action.payload,
        deleteexpiredItemsLoading: false,
      };
    case types.DELETE_EXPIRED_ITEMS_RESET:
      return {
        ...state,
        deleteexpiredItems: null,
        deleteexpiredItemsError: null,
        deleteexpiredItemsLoading: false,
      };

    //update
    case types.UPDATE_EXPIRED_ITEMS_START:
      return {
        ...state,
        updateexpiredItems: null,
        updateexpiredItemsError: null,
        updateexpiredItemsLoading: true,
      };
    case types.UPDATE_EXPIRED_ITEMS_SUCCESS:
      return {
        ...state,
        updateexpiredItems: action.payload,
        updateexpiredItemsLoading: false,
      };
    case types.UPDATE_EXPIRED_ITEMS_ERROR:
      return {
        ...state,
        updateexpiredItemsError: action.payload,
        updateexpiredItemsLoading: false,
      };
    case types.UPDATE_EXPIRED_ITEMS_RESET:
      return {
        ...state,
        updateexpiredItems: null,
        updateexpiredItemsError: null,
        updateexpiredItemsLoading: false,
      };
    //singleItem
    // case types.SINGLE_EXPIRED_ITEMS_START:
    // 	return {
    // 		...state,
    // 		updateexpiredItems: null,
    // 		updateexpiredItemsError: null,
    // 		updateexpiredItemsLoading: true,
    // 	};
    // case types.SINGLE_EXPIRED_ITEMS_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateexpiredItems: action.payload,
    // 		updateexpiredItemsLoading: false,
    // 	};
    // case types.SINGLE_EXPIRED_ITEMS_ERROR:
    // 	return {
    // 		...state,
    // 		updateexpiredItemsError: action.payload,
    // 		updateexpiredItemsLoading: false,
    // 	};
    // case types.SINGLE_EXPIRED_ITEMS_RESET:
    // 	return {
    // 		...state,
    // 		updateexpiredItemsError: null,
    // 		updateexpiredItemsLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default ExpiredItemsReducer;
