import types from "./types";

const INITIAL_STATE = {
  Employee: null,
  EmployeeError: null,
  EmployeeLoading: false,

  //create
  createEmployee: null,
  createEmployeeError: null,
  createEmployeeLoading: false,

  //update
  updateEmployee: null,
  updateEmployeeError: null,
  updateEmployeeLoading: false,

  //delete
  deleteEmployee: null,
  deleteEmployeeError: null,
  deleteEmployeeLoading: false,

  //single item

  singleItem: null,
};

const employeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_START:
      return {
        ...state,
        Employee: null,
        EmployeeError: null,
        EmployeeLoading: true,
      };
    case types.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        Employee: action.payload,
        EmployeeLoading: false,
      };
    case types.GET_EMPLOYEE_ERROR:
      return {
        ...state,
        EmployeeError: action.payload,
        EmployeeLoading: false,
      };
    case types.GET_EMPLOYEE_RESET:
      return {
        ...state,
        employeeError: null,
        employeeLoading: false,
      };
    //create

    case types.CREATE_EMPLOYEE_START:
      return {
        ...state,
        createEmployee: null,
        createEmployeeError: null,
        createEmployeeLoading: true,
      };
    case types.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        createEmployee: action.payload,
        createEmployeeLoading: false,
      };
    case types.CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        createEmployeeError: action.payload,
        createEmployeeLoading: false,
      };
    case types.CREATE_EMPLOYEE_RESET:
      return {
        ...state,
        createEmployeeError: null,
        createEmployeeLoading: false,
      };
    //delete

    case types.DELETE_EMPLOYEE_START:
      return {
        ...state,
        deleteEmployee: null,
        deleteEmployeeError: null,
        deleteEmployeeLoading: true,
      };
    case types.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        deleteEmployee: action.payload,
        deleteEmployeeLoading: false,
      };
    case types.DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        deleteEmployeeError: action.payload,
        deleteEmployeeLoading: false,
      };
    case types.DELETE_EMPLOYEE_RESET:
      return {
        ...state,
        deleteEmployee: null,
        deleteEmployeeError: null,
        deleteEmployeeLoading: false,
      };

    //update
    case types.UPDATE_EMPLOYEE_START:
      return {
        ...state,
        updateEmployee: null,
        updateEmployeeError: null,
        updateEmployeeLoading: true,
      };
    case types.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateEmployee: action.payload,
        updateEmployeeLoading: false,
      };
    case types.UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        updateEmployeeError: action.payload,
        updateEmployeeLoading: false,
      };
    case types.UPDATE_EMPLOYEE_RESET:
      return {
        ...state,
        updateEmployeeError: null,
        updateEmployeeLoading: false,
      };
    //singleItem
    // case types.SINGLE_EMPLOYEE_START:
    // 	return {
    // 		...state,
    // 		updateTemplatename: null,
    // 		updateTemplatenameError: null,
    // 		updateTemplatenameLoading: true,
    // 	};
    // case types.SINGLE_EMPLOYEE_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateTemplatename: action.payload,
    // 		updateTemplatenameLoading: false,
    // 	};
    // case types.SINGLE_TEMPLATE_NAME_ERROR:
    // 	return {
    // 		...state,
    // 		updateTemplatenameError: action.payload,
    // 		updateTemplatenameLoading: false,
    // 	};
    // case types.SINGLE_TEMPLATE_NAME_RESET:
    // 	return {
    // 		...state,
    // 		updateTemplatenameError: null,
    // 		updateTemplatenameLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default employeeReducer;
