import types from "./types";

const INITIAL_STATE = {
	maintenance: null,
	maintenanceError: null,
	maintenanceLoading: false,

	//create
	createmaintenance: null,
	createmaintenanceError: null,
	createmaintenanceLoading: false,

	//update
	updatemaintenance: null,
	updatemaintenanceError: null,
	updatemaintenanceLoading: false,

	//delete
	deletemaintenance: null,
	deletemaintenanceError: null,
	deletemaintenanceLoading: false,

	//single item

	singleItem: null,
};

const StoreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_MAINTENANCE_START:
			return {
				...state,
				maintenance: null,
				maintenanceError: null,
				maintenanceLoading: true,
			};
		case types.GET_MAINTENANCE_SUCCESS:
			return {
				...state,
				maintenance: action.payload,
				maintenanceLoading: false,
			};
		case types.GET_MAINTENANCE_ERROR:
			return {
				...state,
				maintenanceError: action.payload,
				maintenanceLoading: false,
			};
		case types.GET_MAINTENANCE_RESET:
			return {
				...state,
				maintenanceError: null,
				maintenanceLoading: false,
			};
		//create

		case types.CREATE_MAINTENANCE_START:
			return {
				...state,
				createmaintenance: null,
				createmaintenanceError: null,
				createmaintenanceLoading: true,
			};
		case types.CREATE_MAINTENANCE_SUCCESS:
			return {
				...state,
				createmaintenance: action.payload,
				createmaintenanceLoading: false,
			};
		case types.CREATE_MAINTENANCE_ERROR:
			return {
				...state,
				createmaintenanceError: action.payload,
				createmaintenanceLoading: false,
			};
		case types.CREATE_MAINTENANCE_RESET:
			return {
				...state,
				createmaintenanceError: null,
				createmaintenanceLoading: false,
			};
		//delete

		case types.DELETE_MAINTENANCE_START:
			return {
				...state,
				deletemaintenance: null,
				deletemaintenanceError: null,
				deletemaintenanceLoading: true,
			};
		case types.DELETE_MAINTENANCE_SUCCESS:
			return {
				...state,
				deletemaintenance: action.payload,
				deletemaintenanceLoading: false,
			};
		case types.DELETE_MAINTENANCE_ERROR:
			return {
				...state,
				deletemaintenanceError: action.payload,
				deletemaintenanceLoading: false,
			};
		case types.DELETE_MAINTENANCE_RESET:
			return {
				...state,
				deletemaintenance: null,
				deletemaintenanceError: null,
				deletemaintenanceLoading: false,
			};

		//update
		case types.UPDATE_MAINTENANCE_START:
			return {
				...state,
				updatemaintenance: null,
				updatemaintenanceError: null,
				updatemaintenanceLoading: true,
			};
		case types.UPDATE_MAINTENANCE_SUCCESS:
			return {
				...state,
				updatemaintenance: action.payload,
				updatemaintenanceLoading: false,
			};
		case types.UPDATE_MAINTENANCE_ERROR:
			return {
				...state,
				updatemaintenanceError: action.payload,
				updatemaintenanceLoading: false,
			};
		case types.UPDATE_MAINTENANCE_RESET:
			return {
				...state,
				updatemaintenance: null,
				updatemaintenanceError: null,
				updatemaintenanceLoading: false,
			};
		//singleItem
		// case types.SINGLE_MAINTENANCE_START:
		// 	return {
		// 		...state,
		// 		updatemaintenance: null,
		// 		updatemaintenanceError: null,
		// 		updatemaintenanceLoading: true,
		// 	};
		// case types.SINGLE_MAINTENANCE_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatemaintenance: action.payload,
		// 		updatemaintenanceLoading: false,
		// 	};
		// case types.SINGLE_MAINTENANCE_ERROR:
		// 	return {
		// 		...state,
		// 		updatemaintenanceError: action.payload,
		// 		updatemaintenanceLoading: false,
		// 	};
		// case types.SINGLE_MAINTENANCE_RESET:
		// 	return {
		// 		...state,
		// 		updatemaintenanceError: null,
		// 		updatemaintenanceLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default StoreReducer;
