import types from "./types";

const INITIAL_STATE = {
  Quality_Manual: null,
  Quality_ManualError: null,
  Quality_ManualLoading: false,

  //create
  createQuality_Manual: null,
  createQuality_ManualError: null,
  createQuality_ManualLoading: false,

  //update
  updateQuality_Manual: null,
  updateQuality_ManualError: null,
  updateQuality_ManualLoading: false,

  //delete
  deleteQuality_Manual: null,
  deleteQuality_ManualError: null,
  deleteQuality_ManualLoading: false,

  //single item

  singleItem: null,
};

const QualityManualReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_QUALITY_MANUAL_START:
      return {
        ...state,
        Quality_Manual: null,
        Quality_ManualError: null,
        Quality_ManualLoading: true,
      };
    case types.GET_QUALITY_MANUAL_SUCCESS:
      return {
        ...state,
        Quality_Manual: action.payload,
        Quality_ManualLoading: false,
      };
    case types.GET_QUALITY_MANUAL_ERROR:
      return {
        ...state,
        Quality_ManualError: action.payload,
        Quality_ManualLoading: false,
      };
    case types.GET_QUALITY_MANUAL_RESET:
      return {
        ...state,
        Quality_ManualError: null,
        Quality_ManualLoading: false,
      };
    //create

    case types.CREATE_QUALITY_MANUAL_START:
      return {
        ...state,
        createQuality_Manual: null,
        createQuality_ManualError: null,
        createQuality_ManualLoading: true,
      };
    case types.CREATE_QUALITY_MANUAL_SUCCESS:
      return {
        ...state,
        createQuality_Manual: action.payload,
        createQuality_ManualLoading: false,
      };
    case types.CREATE_QUALITY_MANUAL_ERROR:
      return {
        ...state,
        createQuality_ManualError: action.payload,
        createQuality_ManualLoading: false,
      };
    case types.CREATE_QUALITY_MANUAL_RESET:
      return {
        ...state,
        createQuality_ManualError: null,
        createQuality_ManualLoading: false,
      };
    //delete

    case types.DELETE_QUALITY_MANUAL_START:
      return {
        ...state,
        deleteQuality_Manual: null,
        deleteQuality_ManualError: null,
        deleteQuality_ManualLoading: true,
      };
    case types.DELETE_QUALITY_MANUAL_SUCCESS:
      return {
        ...state,
        deleteQuality_Manual: action.payload,
        deleteQuality_ManualLoading: false,
      };
    case types.DELETE_QUALITY_MANUAL_ERROR:
      return {
        ...state,
        deleteQuality_ManualError: action.payload,
        deleteQuality_ManualLoading: false,
      };
    case types.DELETE_QUALITY_MANUAL_RESET:
      return {
        ...state,
        deleteQuality_Manual: null,
        deleteQuality_ManualError: null,
        deleteQuality_ManualLoading: false,
      };

    //update
    case types.UPDATE_QUALITY_MANUAL_START:
      return {
        ...state,
        updateQuality_Manual: null,
        updateQuality_ManualError: null,
        updateQuality_ManualLoading: true,
      };
    case types.UPDATE_QUALITY_MANUAL_SUCCESS:
      return {
        ...state,
        updateQuality_Manual: action.payload,
        updateQuality_ManualLoading: false,
      };
    case types.UPDATE_QUALITY_MANUAL_ERROR:
      return {
        ...state,
        updateQuality_ManualError: action.payload,
        updateQuality_ManualLoading: false,
      };
    case types.UPDATE_QUALITY_MANUAL_RESET:
      return {
        ...state,
        updateQuality_Manual: null,
        updateQuality_ManualError: null,
        updateQuality_ManualLoading: false,
      };
    //singleItem
    // case types.SINGLE_QUALITY_MANUAL_START:
    // 	return {
    // 		...state,
    // 		updateQuality_Manual: null,
    // 		updateQuality_ManualError: null,
    // 		updateQuality_ManualLoading: true,
    // 	};
    // case types.SINGLE_QUALITY_MANUAL_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateQuality_Manual: action.payload,
    // 		updateQuality_ManualLoading: false,
    // 	};
    // case types.SINGLE_QUALITY_MANUAL_ERROR:
    // 	return {
    // 		...state,
    // 		updateQuality_ManualError: action.payload,
    // 		updateQuality_ManualLoading: false,
    // 	};
    // case types.SINGLE_QUALITY_MANUAL_RESET:
    // 	return {
    // 		...state,
    // 		updateQuality_ManualError: null,
    // 		updateQuality_ManualLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default QualityManualReducer;
