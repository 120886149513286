import types from "./types";

const INITIAL_STATE = {
	fromBuilder: null,

	fromBuilderError: null,
	fromBuilderLoading: false,

	//create
	createFromBuilder: null,
	createFromBuilderError: null,
	createFromBuilderLoading: false,

	//update
	updateFromBuilder: null,
	updateFromBuilderError: null,
	updateFromBuilderLoading: false,

	//delete
	deleteFromBuilder: null,
	deleteFromBuilderError: null,
	deleteFromBuilderLoading: false,

	//single item

	singleItem: null,
	singleItemError: null,
	singleItemLoading: false,

	//select item

	selectItem: null,
};

const FromReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_FROMBUILDER_START:
			return {
				...state,
				fromBuilder: null,
				fromBuilderError: null,
				fromBuilderLoading: true,
			};
		case types.GET_FROMBUILDER_SUCCESS:
			return {
				...state,
				fromBuilder: action.payload,
				fromBuilderLoading: false,
			};
		case types.GET_FROMBUILDER_ERROR:
			return {
				...state,
				fromBuilderError: action.payload,
				fromBuilderLoading: false,
			};
		case types.GET_FROMBUILDER_RESET:
			return {
				...state,
				fromBuilderError: null,
				fromBuilderLoading: false,
			};
		//create

		case types.CREATE_FROMBUILDER_START:
			return {
				...state,
				createFromBuilder: null,
				createFromBuilderError: null,
				createFromBuilderLoading: true,
			};
		case types.CREATE_FROMBUILDER_SUCCESS:
			return {
				...state,
				createFromBuilder: action.payload,
				createFromBuilderLoading: false,
			};
		case types.CREATE_FROMBUILDER_ERROR:
			return {
				...state,
				createFromBuilderError: action.payload,
				createFromBuilderLoading: false,
			};
		case types.CREATE_FROMBUILDER_RESET:
			return {
				...state,
				createFromBuilder: null,
				createFromBuilderError: null,
				createFromBuilderLoading: false,
			};
		//delete

		case types.DELETE_FROMBUILDER_START:
			return {
				...state,
				deleteFromBuilder: null,
				deleteFromBuilderError: null,
				deleteFromBuilderLoading: true,
			};
		case types.DELETE_FROMBUILDER_SUCCESS:
			return {
				...state,
				deleteFromBuilder: action.payload,
				deleteFromBuilderLoading: false,
			};
		case types.DELETE_FROMBUILDER_ERROR:
			return {
				...state,
				deleteFromBuilderError: action.payload,
				deleteFromBuilderLoading: false,
			};
		case types.DELETE_FROMBUILDER_RESET:
			return {
				...state,
				deleteFromBuilder: null,
				deleteFromBuilderError: null,
				deleteFromBuilderLoading: false,
			};

		//update
		case types.UPDATE_FROMBUILDER_START:
			return {
				...state,
				updateFromBuilder: null,
				updateFromBuilderError: null,
				updateFromBuilderLoading: true,
			};
		case types.UPDATE_FROMBUILDER_SUCCESS:
			return {
				...state,
				updateFromBuilder: action.payload,
				updateFromBuilderLoading: false,
			};
		case types.UPDATE_FROMBUILDER_ERROR:
			return {
				...state,
				updateFromBuilderError: action.payload,
				updateFromBuilderLoading: false,
			};
		case types.UPDATE_FROMBUILDER_RESET:
			return {
				...state,
				updateFromBuilder: null,
				updateFromBuilderError: null,
				updateFromBuilderLoading: false,
			};
		//singleItem
		case types.SINGLE_FROMBUILDER_START:
			return {
				...state,
				singleItem: null,
				singleItemError: null,
				singleItemLoading: true,
			};
		case types.SINGLE_FROMBUILDER_SUCCESS:
			return {
				...state,
				singleItem: action.payload,
				singleItemLoading: false,
			};
		case types.SINGLE_FROMBUILDER_ERROR:
			return {
				...state,

				singleItemError: action.payload,
				singleItemLoading: false,
			};
		case types.SINGLE_FROMBUILDER_RESET:
			return {
				...state,
				singleItem: null,
				singleItemError: action.payload,
				singleItemLoading: false,
			};

		case types.SELECT_TOUPDATE_ITEM:
			return {
				...state,
				selectItem: action.payload,
			};

		default:
			return { ...state };
	}
};

export default FromReducer;
