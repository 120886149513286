import types from "./types";

const INITIAL_STATE = {
	traning: null,
	traningError: null,
	traningLoading: false,

	//create
	createtraning: null,
	createtraningError: null,
	createtraningLoading: false,

	//update
	updatetraning: null,
	updatetraningError: null,
	updatetraningLoading: false,

	//delete
	deletetraning: null,
	deletetraningError: null,
	deletetraningLoading: false,

	//single item

	singleItem: null,
};

const TraningReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_TRANING_START:
			return {
				...state,
				traning: null,
				traningError: null,
				traningLoading: true,
			};
		case types.GET_TRANING_SUCCESS:
			return {
				...state,
				traning: action.payload,
				traningLoading: false,
			};
		case types.GET_TRANING_ERROR:
			return {
				...state,
				traningError: action.payload,
				traningLoading: false,
			};
		case types.GET_TRANING_RESET:
			return {
				...state,
				traningError: null,
				traningLoading: false,
			};
		//create

		case types.CREATE_TRANING_START:
			return {
				...state,
				createtraning: null,
				createtraningError: null,
				createtraningLoading: true,
			};
		case types.CREATE_TRANING_SUCCESS:
			return {
				...state,
				createtraning: action.payload,
				createtraningLoading: false,
			};
		case types.CREATE_TRANING_ERROR:
			return {
				...state,
				createtraningError: action.payload,
				createtraningLoading: false,
			};
		case types.CREATE_TRANING_RESET:
			return {
				...state,
				createtraningError: null,
				createtraningLoading: false,
			};
		//delete

		case types.DELETE_TRANING_START:
			return {
				...state,
				deletetraning: null,
				deletetraningError: null,
				deletetraningLoading: true,
			};
		case types.DELETE_TRANING_SUCCESS:
			return {
				...state,
				deletetraning: action.payload,
				deletetraningLoading: false,
			};
		case types.DELETE_TRANING_ERROR:
			return {
				...state,
				deletetraningError: action.payload,
				deletetraningLoading: false,
			};
		case types.DELETE_TRANING_RESET:
			return {
				...state,
				deletetraning: null,
				deletetraningError: null,
				deletetraningLoading: false,
			};

		//update
		case types.UPDATE_TRANING_START:
			return {
				...state,
				updatetraning: null,
				updatetraningError: null,
				updatetraningLoading: true,
			};
		case types.UPDATE_TRANING_SUCCESS:
			return {
				...state,
				updatetraning: action.payload,
				updatetraningLoading: false,
			};
		case types.UPDATE_TRANING_ERROR:
			return {
				...state,
				updatetraningError: action.payload,
				updatetraningLoading: false,
			};
		case types.UPDATE_TRANING_RESET:
			return {
				...state,
				updatetraning: null,
				updatetraningError: null,
				updatetraningLoading: false,
			};
		//singleItem
		// case types.SINGLE_TRANING_START:
		// 	return {
		// 		...state,
		// 		updatetraning: null,
		// 		updatetraningError: null,
		// 		updatetraningLoading: true,
		// 	};
		// case types.SINGLE_TRANING_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatetraning: action.payload,
		// 		updatetraningLoading: false,
		// 	};
		// case types.SINGLE_TRANING_ERROR:
		// 	return {
		// 		...state,
		// 		updatetraningError: action.payload,
		// 		updatetraningLoading: false,
		// 	};
		// case types.SINGLE_TRANING_RESET:
		// 	return {
		// 		...state,
		// 		updatetraningError: null,
		// 		updatetraningLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default TraningReducer;
