import types from "./types";

const INITIAL_STATE = {
  workOrder: null,
  workOrderError: null,
  workOrderLoading: false,

  //create
  createworkOrder: null,
  createworkOrderError: null,
  createworkOrderLoading: false,

  //update
  updateworkOrder: null,
  updateworkOrderError: null,
  updateworkOrderLoading: false,

  //delete
  deleteworkOrder: null,
  deleteworkOrderError: null,
  deleteworkOrderLoading: false,

  //single item

  singleItem: null,
};

const workOrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_WORK_ORDER_START:
      return {
        ...state,
        workOrder: null,
        workOrderError: null,
        workOrderLoading: true,
      };
    case types.GET_WORK_ORDER_SUCCESS:
      return {
        ...state,
        workOrder: action.payload,
        workOrderLoading: false,
      };
    case types.GET_WORK_ORDER_ERROR:
      return {
        ...state,
        workOrderError: action.payload,
        workOrderLoading: false,
      };
    case types.GET_WORK_ORDER_RESET:
      return {
        ...state,
        workOrderError: null,
        workOrderLoading: false,
      };
    //create

    case types.CREATE_WORK_ORDER_START:
      return {
        ...state,
        createworkOrder: null,
        createworkOrderError: null,
        createworkOrderLoading: true,
      };
    case types.CREATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        createworkOrder: action.payload,
        createworkOrderLoading: false,
      };
    case types.CREATE_WORK_ORDER_ERROR:
      return {
        ...state,
        createworkOrderError: action.payload,
        createworkOrderLoading: false,
      };
    case types.CREATE_WORK_ORDER_RESET:
      return {
        ...state,
        createworkOrder: null,
        createworkOrderError: null,
        createworkOrderLoading: false,
      };
    //delete

    case types.DELETE_WORK_ORDER_START:
      return {
        ...state,
        deleteworkOrder: null,
        deleteworkOrderError: null,
        deleteworkOrderLoading: true,
      };
    case types.DELETE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        deleteworkOrder: action.payload,
        deleteworkOrderLoading: false,
      };
    case types.DELETE_WORK_ORDER_ERROR:
      return {
        ...state,
        deleteworkOrderError: action.payload,
        deleteworkOrderLoading: false,
      };
    case types.DELETE_WORK_ORDER_RESET:
      return {
        ...state,
        deleteworkOrder: null,
        deleteworkOrderError: null,
        deleteworkOrderLoading: false,
      };

    //update
    case types.UPDATE_WORK_ORDER_START:
      return {
        ...state,
        updateworkOrder: null,
        updateworkOrderError: null,
        updateworkOrderLoading: true,
      };
    case types.UPDATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        updateworkOrder: action.payload,
        updateworkOrderLoading: false,
      };
    case types.UPDATE_WORK_ORDER_ERROR:
      return {
        ...state,
        updateworkOrderError: action.payload,
        updateworkOrderLoading: false,
      };
    case types.UPDATE_WORK_ORDER_RESET:
      return {
        ...state,
        updateworkOrder: null,
        updateworkOrderError: null,
        updateworkOrderLoading: false,
      };
    //singleItem
    // case types.SINGLE_WORK_ORDER_START:
    // 	return {
    // 		...state,
    // 		updateworkOrder: null,
    // 		updateworkOrderError: null,
    // 		updateworkOrderLoading: true,
    // 	};
    // case types.SINGLE_WORK_ORDER_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateworkOrder: action.payload,
    // 		updateworkOrderLoading: false,
    // 	};
    // case types.SINGLE_WORK_ORDER_ERROR:
    // 	return {
    // 		...state,
    // 		updateworkOrderError: action.payload,
    // 		updateworkOrderLoading: false,
    // 	};
    // case types.SINGLE_WORK_ORDER_RESET:
    // 	return {
    // 		...state,
    // 		updateworkOrderError: null,
    // 		updateworkOrderLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default workOrderReducer;
