import types from "./types";

const INITIAL_STATE = {
  Customer: null,
  CustomerError: null,
  CustomerLoading: false,

  //create
  createCustomer: null,
  createCustomerError: null,
  createCustomerLoading: false,

  //update
  updateCustomer: null,
  updateCustomerError: null,
  updateCustomerLoading: false,

  //delete
  deleteCustomer: null,
  deleteCustomerError: null,
  deleteCustomerLoading: false,

  //single item

  singleItem: null,
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_START:
      return {
        ...state,
        Customer: null,
        CustomerError: null,
        CustomerLoading: true,
      };
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        Customer: action.payload,
        CustomerLoading: false,
      };
    case types.GET_CUSTOMER_ERROR:
      return {
        ...state,
        CustomerError: action.payload,
        CustomerLoading: false,
      };
    case types.GET_CUSTOMER_RESET:
      return {
        ...state,
        Customer: null,
        CustomerError: null,
        CustomerLoading: false,
      };
    //create

    case types.CREATE_CUSTOMER_START:
      return {
        ...state,
        createCustomer: null,
        createCustomerError: null,
        createCustomerLoading: true,
      };
    case types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createCustomer: action.payload,
        createCustomerLoading: false,
      };
    case types.CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        createCustomerError: action.payload,
        createCustomerLoading: false,
      };
    case types.CREATE_CUSTOMER_RESET:
      return {
        ...state,
        createCustomer: null,
        createCustomerError: null,
        createCustomerLoading: false,
      };
    //delete

    case types.DELETE_CUSTOMER_START:
      return {
        ...state,
        deleteCustomer: null,
        deleteCustomerError: null,
        deleteCustomerLoading: true,
      };
    case types.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomer: action.payload,
        deleteCustomerLoading: false,
      };
    case types.DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        deleteCustomerError: action.payload,
        deleteCustomerLoading: false,
      };
    case types.DELETE_CUSTOMER_RESET:
      return {
        ...state,
        deleteCustomer: null,
        deleteCustomerError: null,
        deleteCustomerLoading: false,
      };

    //update
    case types.UPDATE_CUSTOMER_START:
      return {
        ...state,
        updateCustomer: null,
        updateCustomerError: null,
        updateCustomerLoading: true,
      };
    case types.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomer: action.payload,
        updateCustomerLoading: false,
      };
    case types.UPDATE_CUSTOMER_ERROR:
      return {
        ...state,
        updateCustomerError: action.payload,
        updateCustomerLoading: false,
      };
    case types.UPDATE_CUSTOMER_RESET:
      return {
        ...state,
        updateCustomer: null,
        updateCustomerError: null,
        updateCustomerLoading: false,
      };
    //singleItem
    // case types.SINGLE_CUSTOMER_START:
    // 	return {
    // 		...state,
    // 		updateCustomer: null,
    // 		updateCustomerError: null,
    // 		updateCustomerLoading: true,
    // 	};
    // case types.SINGLE_CUSTOMER_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateCustomer: action.payload,
    // 		updateCustomerLoading: false,
    // 	};
    // case types.SINGLE_CUSTOMER_ERROR:
    // 	return {
    // 		...state,
    // 		updateCustomerError: action.payload,
    // 		updateCustomerLoading: false,
    // 	};
    // case types.SINGLE_CUSTOMER_RESET:
    // 	return {
    // 		...state,
    // 		updateCustomerError: null,
    // 		updateCustomerLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default customerReducer;
