import types from "./types";

const INITIAL_STATE = {
  DocumentList: null,
  DocumentListError: null,
  DocumentListLoading: false,

  //create
  createDocumentList: null,
  createDocumentListError: null,
  createDocumentListLoading: false,

  //update
  updateDocumentList: null,
  updateDocumentListError: null,
  updateDocumentListLoading: false,

  //delete
  deleteDocumentList: null,
  deleteDocumentListError: null,
  deleteDocumentListLoading: false,

  //single item

  singleItem: null,
};

const ClientDocumentListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_LIST_START:
      return {
        ...state,
        DocumentList: null,
        DocumentListError: null,
        DocumentListLoading: true,
      };
    case types.GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        DocumentList: action.payload,
        DocumentListLoading: false,
      };
    case types.GET_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        DocumentListError: action.payload,
        DocumentListLoading: false,
      };
    case types.GET_DOCUMENT_LIST_RESET:
      return {
        ...state,
        DocumentListError: null,
        DocumentListLoading: false,
      };
    //create

    case types.CREATE_DOCUMENT_LIST_START:
      return {
        ...state,
        createDocumentList: null,
        createDocumentListError: null,
        createDocumentListLoading: true,
      };
    case types.CREATE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        createDocumentList: action.payload,
        createDocumentListLoading: false,
      };
    case types.CREATE_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        createDocumentListError: action.payload,
        createDocumentListLoading: false,
      };
    case types.CREATE_DOCUMENT_LIST_RESET:
      return {
        ...state,
        createDocumentListError: null,
        createDocumentListLoading: false,
      };
    //delete

    case types.DELETE_DOCUMENT_LIST_START:
      return {
        ...state,
        deleteDocumentList: null,
        deleteDocumentListError: null,
        deleteDocumentListLoading: true,
      };
    case types.DELETE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        deleteDocumentList: action.payload,
        deleteDocumentListLoading: false,
      };
    case types.DELETE_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        deleteDocumentListError: action.payload,
        deleteDocumentListLoading: false,
      };
    case types.DELETE_DOCUMENT_LIST_RESET:
      return {
        ...state,
        deleteDocumentList: null,
        deleteDocumentListError: null,
        deleteDocumentListLoading: false,
      };

    //update
    case types.UPDATE_DOCUMENT_LIST_START:
      return {
        ...state,
        updateDocumentList: null,
        updateDocumentListError: null,
        updateDocumentListLoading: true,
      };
    case types.UPDATE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        updateDocumentList: action.payload,
        updateDocumentListLoading: false,
      };
    case types.UPDATE_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        updateDocumentListError: action.payload,
        updateDocumentListLoading: false,
      };
    case types.UPDATE_DOCUMENT_LIST_RESET:
      return {
        ...state,
        updateDocumentList: null,
        updateDocumentListError: null,
        updateDocumentListLoading: false,
      };
    //singleItem
    // case types.SINGLE_DOCUMENT_LIST_START:
    // 	return {
    // 		...state,
    // 		updateDocumentList: null,
    // 		updateDocumentListError: null,
    // 		updateDocumentListLoading: true,
    // 	};
    // case types.SINGLE_DOCUMENT_LIST_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateDocumentList: action.payload,
    // 		updateDocumentListLoading: false,
    // 	};
    // case types.SINGLE_DOCUMENT_LIST_ERROR:
    // 	return {
    // 		...state,
    // 		updateDocumentListError: action.payload,
    // 		updateDocumentListLoading: false,
    // 	};
    // case types.SINGLE_DOCUMENT_LIST_RESET:
    // 	return {
    // 		...state,
    // 		updateDocumentListError: null,
    // 		updateDocumentListLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default ClientDocumentListReducer;
