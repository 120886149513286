import types from "./types";

const INITIAL_STATE = {
	report: null,
	reportError: null,
	reportLoading: false,
};

const ReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		//get

		case types.GET_REPORTS_START:
			return {
				...state,
				report: null,
				reportError: null,
				reportLoading: true,
			};
		case types.GET_REPORTS_SUCCESS:
			return {
				...state,
				report: action.payload,
				reportLoading: false,
			};
		case types.GET_REPORTS_ERROR:
			return {
				...state,
				reportError: action.payload,
				reportLoading: false,
			};
		case types.GET_REPORTS_RESET:
			return {
				...state,
				report: null,
				reportError: null,
				reportLoading: false,
			};

		default:
			return { ...state };
	}
};

export default ReportReducer;
