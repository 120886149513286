import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getgeneralInventoryHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "generalinventory", ...action.payload }),
		);
		yield put({
			type: types.GET_GENERAL_INVENTORY_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_GENERAL_INVENTORY_ERROR,
			payload,
		});
	}
}

export function* getgeneralInventoryWatcherSaga() {
	yield takeLatest(
		types.GET_GENERAL_INVENTORY_START,
		getgeneralInventoryHandlerSaga,
	);
}

//delete

function* deletegeneralInventoryHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("generalinventory", action.payload),
		);
		yield put({
			type: types.DELETE_GENERAL_INVENTORY_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_GENERAL_INVENTORY_ERROR,
			payload,
		});
	}
}

export function* deletegeneralInventoryWatcherSaga() {
	yield takeLatest(
		types.DELETE_GENERAL_INVENTORY_START,
		deletegeneralInventoryHandlerSaga,
	);
}
