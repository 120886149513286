const types = {
	//Get Template name
	GET_ADMINFORMLIST_START: "GET_ADMINFORMLIST_START",
	GET_ADMINFORMLIST_SUCCESS: "GET_ADMINFORMLIST_SUCCESS",
	GET_ADMINFORMLIST_ERROR: "GET_ADMINFORMLIST_ERROR",
	GET_ADMINFORMLIST_RESET: "GET_ADMINFORMLIST_RESET",

	//single item  name
	SELECT_TOUPDATE_ITEM: "SELECT_TOUPDATE_ITEM",
};

export default types;
