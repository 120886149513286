import useForms from "../../../context/forms/useForms";
import { tableColumnParser } from "../../../util/tableColumnParser";
import useApi from "hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import TableAction from "Components/TableAction";
import ViewForm from "Components/ViewForm";
import useUser from "hooks/useUser";
import {
	Button,
	ListGroup,
	ListGroupItem,
	OverlayTrigger,
	Popover,
} from "react-bootstrap";

const API_NAME = "sampleparameter";

export default function useTable({ page, action }) {
	const { GET } = useApi();

	const { getForm, loading } = useForms();
	const form = getForm(API_NAME);
	const { accessPath } = useUser(form?.formName);
	const response = useQuery({
		queryKey: [
			API_NAME,
			page.page,
			page.size,
			page.column,
			page.keyword,
			page.mongoQuery,
		],
		queryFn: async () =>
			await GET(
				`/${API_NAME}?page=${page.page}&size=${page.size}&columnName=${page.column}&keyword=${page.keyword}&mongoQuery=${page.mongoQuery}`,
			),
		enabled: accessPath.view,
	});

	const parseColumn = form ? tableColumnParser(form.formComponents) : []; //gets the columns from the form for table with component key mapping to accessor and label to header ;
	const ignoreFields = ["parameter","methods", "sample", "cost"];

	const filter = parseColumn
		.filter((item) => !ignoreFields.includes(item.accessor))
		.map((item) => ({
			label: item.Header,
			key: item.accessor,
		}))
		.concat([{ label: "Sample Name", key: "sampleName" }]);

	const additionalColumn = [
		{
			Header: "Sample Name",
			accessor: "sampleName",
			Cell: ({ row }) => {
				const value = row.original;
				return value.sampleName;
			},
		},
		{
			Header: "Parameter List With Cost",
			accessor: "parameter",
			Cell: ({ row }) => {
				const value = row.original;

				const List = (
					<Popover>
						<Popover.Body>
							<ListGroup>
								{value.parameter.map((item, index) => (
									<ListGroupItem
										key={item + index}
										className='d-flex justify-content-between'>
										<span>{item}</span>
										{value.cost && (
											<span className='ms-2'>Cost:{value?.cost[index]}</span>
										)}
										{value.methods && (
											<span className='ms-2'>{value?.methods[index]}</span>
										)}
									</ListGroupItem>
								))}
							</ListGroup>
						</Popover.Body>
					</Popover>
				);
				return (
					<OverlayTrigger placement='bottom' overlay={List}>
						<Button variant='outline-secondary'>
							Parameter List With Cost
						</Button>
					</OverlayTrigger>
				);
			},
		},
		{
			Header: "View",
			accessor: "view",
			Cell: ({ row }) => {
				const value = row.original;
				return <ViewForm data={value} formName={API_NAME} />;
			},
		},
		{
			Header: "Action",
			accessor: "action",
			Cell: ({ row }) => {
				const value = row.original;

				return (
					<TableAction
						path={API_NAME}
						value={value}
						editAction={action.onEdit}
						accesspoint='customer'
					/>
				);
			},
		},
	];

	const columns = [...parseColumn]
		.filter((item) => !ignoreFields.includes(item.accessor))
		.concat(additionalColumn);
	const rows = response.data ? response.data.response.data : [];

	const pagination = response.data
		? response.data.response
		: { page: 0, showing: 0, total: 0 };
	return {
		columns,
		rows,
		loading: loading || response.isLoading,
		filter,
		pagination,
	};
}
