import types from "./types";

const INITIAL_STATE = {
	NonConfirmityReport: null,
	NonConfirmityReportError: null,
	NonConfirmityReportLoading: false,

	//create
	createNonConfirmityReport: null,
	createNonConfirmityReportError: null,
	createNonConfirmityReportLoading: false,

	//update
	updateNonConfirmityReport: null,
	updateNonConfirmityReportError: null,
	updateNonConfirmityReportLoading: false,

	//delete
	deleteNonConfirmityReport: null,
	deleteNonConfirmityReportError: null,
	deleteNonConfirmityReportLoading: false,

	//single item

	singleItem: null,
};

const NonConformityReducerReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_NON_CONFORMITY_REPORT_START:
			return {
				...state,
				NonConfirmityReport: null,
				NonConfirmityReportError: null,
				NonConfirmityReportLoading: true,
			};
		case types.GET_NON_CONFORMITY_REPORT_SUCCESS:
			return {
				...state,
				NonConfirmityReport: action.payload,
				NonConfirmityReportLoading: false,
			};
		case types.GET_NON_CONFORMITY_REPORT_ERROR:
			return {
				...state,
				NonConfirmityReportError: action.payload,
				NonConfirmityReportLoading: false,
			};
		case types.GET_NON_CONFORMITY_REPORT_RESET:
			return {
				...state,
				NonConfirmityReportError: null,
				NonConfirmityReportLoading: false,
			};
		//create

		case types.CREATE_NON_CONFORMITY_REPORT_START:
			return {
				...state,
				createNonConfirmityReport: null,
				createNonConfirmityReportError: null,
				createNonConfirmityReportLoading: true,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_SUCCESS:
			return {
				...state,
				createNonConfirmityReport: action.payload,
				createNonConfirmityReportLoading: false,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_ERROR:
			return {
				...state,
				createNonConfirmityReportError: action.payload,
				createNonConfirmityReportLoading: false,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_RESET:
			return {
				...state,
				createNonConfirmityReportError: null,
				createNonConfirmityReportLoading: false,
			};
		//delete

		case types.DELETE_NON_CONFORMITY_REPORT_START:
			return {
				...state,
				deleteNonConfirmityReport: null,
				deleteNonConfirmityReportError: null,
				deleteNonConfirmityReportLoading: true,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_SUCCESS:
			return {
				...state,
				deleteNonConfirmityReport: action.payload,
				deleteNonConfirmityReportLoading: false,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_ERROR:
			return {
				...state,
				deleteNonConfirmityReportError: action.payload,
				deleteNonConfirmityReportLoading: false,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_RESET:
			return {
				...state,
				deleteNonConfirmityReport: null,
				deleteNonConfirmityReportError: null,
				deleteNonConfirmityReportLoading: false,
			};

		//update
		case types.UPDATE_NON_CONFORMITY_REPORT_START:
			return {
				...state,
				updateNonConfirmityReport: null,
				updateNonConfirmityReportError: null,
				updateNonConfirmityReportLoading: true,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_SUCCESS:
			return {
				...state,
				updateNonConfirmityReport: action.payload,
				updateNonConfirmityReportLoading: false,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_ERROR:
			return {
				...state,
				updateNonConfirmityReportError: action.payload,
				updateNonConfirmityReportLoading: false,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_RESET:
			return {
				...state,
				updateNonConfirmityReport: null,
				updateNonConfirmityReportError: null,
				updateNonConfirmityReportLoading: false,
			};
		//singleItem
		// case types.SINGLE_NON_CONFORMITY_REPORT_START:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReport: null,
		// 		updateNonConfirmityReportError: null,
		// 		updateNonConfirmityReportLoading: true,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReport: action.payload,
		// 		updateNonConfirmityReportLoading: false,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_ERROR:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportError: action.payload,
		// 		updateNonConfirmityReportLoading: false,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_RESET:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportError: null,
		// 		updateNonConfirmityReportLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default NonConformityReducerReducer;
