import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getexpiredItemsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "expireditems", ...action.payload }),
		);
		yield put({
			type: types.GET_EXPIRED_ITEMS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_EXPIRED_ITEMS_ERROR,
			payload,
		});
	}
}

export function* getexpiredItemsWatcherSaga() {
	yield takeLatest(types.GET_EXPIRED_ITEMS_START, getexpiredItemsHandlerSaga);
}

//create

//delete

function* deleteexpiredItemsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("expireditems", action.payload),
		);
		yield put({
			type: types.DELETE_EXPIRED_ITEMS_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_EXPIRED_ITEMS_ERROR,
			payload,
		});
	}
}

export function* deleteexpiredItemsWatcherSaga() {
	yield takeLatest(
		types.DELETE_EXPIRED_ITEMS_START,
		deleteexpiredItemsHandlerSaga,
	);
}

//update
