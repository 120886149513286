import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getvendorSubCategoriesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "vendorSubCategories", ...action.payload }),
		);
		yield put({
			type: types.GET_VENDOR_SUB__CATEGORIES_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_VENDOR_SUB__CATEGORIES_ERROR,
			payload,
		});
	}
}

export function* getvendorSubCategoriesWatcherSaga() {
	yield takeLatest(
		types.GET_VENDOR_SUB__CATEGORIES_START,
		getvendorSubCategoriesHandlerSaga,
	);
}

//create

//delete

function* deletevendorSubCategoriesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("vendorSubCategories", action.payload),
		);
		yield put({
			type: types.DELETE_VENDOR_SUB__CATEGORIES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_VENDOR_SUB__CATEGORIES_ERROR,
			payload,
		});
	}
}

export function* deletevendorSubCategoriesWatcherSaga() {
	yield takeLatest(
		types.DELETE_VENDOR_SUB__CATEGORIES_START,
		deletevendorSubCategoriesHandlerSaga,
	);
}

//update
