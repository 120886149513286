import types from "./types";

const INITIAL_STATE = {
	adminUser: null,
	adminUserError: null,
	adminUserLoading: false,

	//create
	createadminUser: null,
	createadminUserError: null,
	createadminUserLoading: false,

	//update
	updateadminUser: null,
	updateadminUserError: null,
	updateadminUserLoading: false,

	//delete
	deleteadminUser: null,
	deleteadminUserError: null,
	deleteadminUserLoading: false,

	//single item

	singleItem: null,
};

const AdminUserReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_ADMIN_USERS_START:
			return {
				...state,
				adminUser: null,
				adminUserError: null,
				adminUserLoading: true,
			};
		case types.GET_ADMIN_USERS_SUCCESS:
			return {
				...state,
				adminUser: action.payload,
				adminUserLoading: false,
			};
		case types.GET_ADMIN_USERS_ERROR:
			return {
				...state,
				adminUserError: action.payload,
				adminUserLoading: false,
			};
		case types.GET_ADMIN_USERS_RESET:
			return {
				...state,
				adminUserError: null,
				adminUserLoading: false,
			};
		//create

		case types.CREATE_ADMIN_USERS_START:
			return {
				...state,
				createadminUser: null,
				createadminUserError: null,
				createadminUserLoading: true,
			};
		case types.CREATE_ADMIN_USERS_SUCCESS:
			return {
				...state,
				createadminUser: action.payload,
				createadminUserLoading: false,
			};
		case types.CREATE_ADMIN_USERS_ERROR:
			return {
				...state,
				createadminUserError: action.payload,
				createadminUserLoading: false,
			};
		case types.CREATE_ADMIN_USERS_RESET:
			return {
				...state,
				createadminUserError: null,
				createadminUserLoading: false,
			};
		//delete

		case types.DELETE_ADMIN_USERS_START:
			return {
				...state,
				deleteadminUser: null,
				deleteadminUserError: null,
				deleteadminUserLoading: true,
			};
		case types.DELETE_ADMIN_USERS_SUCCESS:
			return {
				...state,
				deleteadminUser: action.payload,
				deleteadminUserLoading: false,
			};
		case types.DELETE_ADMIN_USERS_ERROR:
			return {
				...state,
				deleteadminUserError: action.payload,
				deleteadminUserLoading: false,
			};
		case types.DELETE_ADMIN_USERS_RESET:
			return {
				...state,
				deleteadminUser: null,
				deleteadminUserError: null,
				deleteadminUserLoading: false,
			};

		//update
		case types.UPDATE_ADMIN_USERS_START:
			return {
				...state,
				updateadminUser: null,
				updateadminUserError: null,
				updateadminUserLoading: true,
			};
		case types.UPDATE_ADMIN_USERS_SUCCESS:
			return {
				...state,
				updateadminUser: action.payload,
				updateadminUserLoading: false,
			};
		case types.UPDATE_ADMIN_USERS_ERROR:
			return {
				...state,
				updateadminUserError: action.payload,
				updateadminUserLoading: false,
			};
		case types.UPDATE_ADMIN_USERS_RESET:
			return {
				...state,
				updateadminUser: null,
				updateadminUserError: null,
				updateadminUserLoading: false,
			};
		//singleItem
		// case types.SINGLE_ADMIN_USERS_START:
		// 	return {
		// 		...state,
		// 		updateadminUser: null,
		// 		updateadminUserError: null,
		// 		updateadminUserLoading: true,
		// 	};
		// case types.SINGLE_ADMIN_USERS_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateadminUser: action.payload,
		// 		updateadminUserLoading: false,
		// 	};
		// case types.SINGLE_ADMIN_USERS_ERROR:
		// 	return {
		// 		...state,
		// 		updateadminUserError: action.payload,
		// 		updateadminUserLoading: false,
		// 	};
		// case types.SINGLE_ADMIN_USERS_RESET:
		// 	return {
		// 		...state,
		// 		updateadminUserError: null,
		// 		updateadminUserLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default AdminUserReducer;
