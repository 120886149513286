import { Table, SearchBar, QueryFilter } from "Components";
import useTable from "./data/useTableData";
import { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import MainForm from "./form";
import useUser from "hooks/useUser";

export default function SampleParameter() {
	const { accessPath } = useUser("sampleParameter");
	const [formModal, setFormModal] = useState(false);

	const [page, setPage] = useState({
		page: 0,
		size: 10,
		column: "",
		keywoard: "",
		mongoQuery: "",
	});

	const { columns, rows, pagination, filter, loading } = useTable({
		page,
		action: { onEdit },
	});
	const [edit, setEdit] = useState({ data: {}, state: false });

	function pageChangeHandler(page, size) {
		setPage((prev) => ({ ...prev, page, size }));
	}

	function searchHandler(column, keyword) {
		setPage((prev) => ({ ...prev, column, keyword }));
	}

	const closeForm = () => {
		if (edit.state) {
			setEdit({ data: {}, state: false });
		}
		setFormModal(false);
	};

	function onEdit(data) {
		setEdit({ data: data, state: true });
		setFormModal(true);
	}

	function UserForm() {
		if (!accessPath.create) return;

		return (
			<>
				<Modal backdrop='static' size='lg' show={formModal} onHide={closeForm}>
					<Modal.Header closeButton>Sample Parameter</Modal.Header>
					<Modal.Body>
						<MainForm
							updatemode={edit.state}
							data={edit.data}
							closeForm={closeForm}
						/>
					</Modal.Body>
				</Modal>
				<Button onClick={() => setFormModal(true)}>Add Parameter</Button>
			</>
		);
	}

	return (
		<div>
			<Row className='mb-3'>
				<Col md={9}>
					<SearchBar filter={filter} onChange={searchHandler} />
				</Col>

				<Col md={3} className='d-flex justify-content-end'>
					<UserForm />
				</Col>
			</Row>

			<Table
				table={{ columns, data: rows }}
				pagination={{ ...pagination }}
				onPageChangeHandler={pageChangeHandler}
				loading={loading}
				disabled={!accessPath.view}
			/>
		</div>
	);
}
