import types from "./types";

const INITIAL_STATE = {
	managementReview: null,
	managementReviewError: null,
	managementReviewLoading: false,

	//create
	createmanagementReview: null,
	createmanagementReviewError: null,
	createmanagementReviewLoading: false,

	//update
	updatemanagementReview: null,
	updatemanagementReviewError: null,
	updatemanagementReviewLoading: false,

	//delete
	deletemanagementReview: null,
	deletemanagementReviewError: null,
	deletemanagementReviewLoading: false,

	//single item

	singleItem: null,
};

const ManagmentReviewReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_MANAGEMENT_REVIEW_START:
			return {
				...state,
				managementReview: null,
				managementReviewError: null,
				managementReviewLoading: true,
			};
		case types.GET_MANAGEMENT_REVIEW_SUCCESS:
			return {
				...state,
				managementReview: action.payload,
				managementReviewLoading: false,
			};
		case types.GET_MANAGEMENT_REVIEW_ERROR:
			return {
				...state,
				managementReviewError: action.payload,
				managementReviewLoading: false,
			};
		case types.GET_MANAGEMENT_REVIEW_RESET:
			return {
				...state,
				managementReviewError: null,
				managementReviewLoading: false,
			};
		//create

		case types.CREATE_MANAGEMENT_REVIEW_START:
			return {
				...state,
				createmanagementReview: null,
				createmanagementReviewError: null,
				createmanagementReviewLoading: true,
			};
		case types.CREATE_MANAGEMENT_REVIEW_SUCCESS:
			return {
				...state,
				createmanagementReview: action.payload,
				createmanagementReviewLoading: false,
			};
		case types.CREATE_MANAGEMENT_REVIEW_ERROR:
			return {
				...state,
				createmanagementReviewError: action.payload,
				createmanagementReviewLoading: false,
			};
		case types.CREATE_MANAGEMENT_REVIEW_RESET:
			return {
				...state,
				createmanagementReviewError: null,
				createmanagementReviewLoading: false,
			};
		//delete

		case types.DELETE_MANAGEMENT_REVIEW_START:
			return {
				...state,
				deletemanagementReview: null,
				deletemanagementReviewError: null,
				deletemanagementReviewLoading: true,
			};
		case types.DELETE_MANAGEMENT_REVIEW_SUCCESS:
			return {
				...state,
				deletemanagementReview: action.payload,
				deletemanagementReviewLoading: false,
			};
		case types.DELETE_MANAGEMENT_REVIEW_ERROR:
			return {
				...state,
				deletemanagementReviewError: action.payload,
				deletemanagementReviewLoading: false,
			};
		case types.DELETE_MANAGEMENT_REVIEW_RESET:
			return {
				...state,
				deletemanagementReview: null,
				deletemanagementReviewError: null,
				deletemanagementReviewLoading: false,
			};

		//update
		case types.UPDATE_MANAGEMENT_REVIEW_START:
			return {
				...state,
				updatemanagementReview: null,
				updatemanagementReviewError: null,
				updatemanagementReviewLoading: true,
			};
		case types.UPDATE_MANAGEMENT_REVIEW_SUCCESS:
			return {
				...state,
				updatemanagementReview: action.payload,
				updatemanagementReviewLoading: false,
			};
		case types.UPDATE_MANAGEMENT_REVIEW_ERROR:
			return {
				...state,
				updatemanagementReviewError: action.payload,
				updatemanagementReviewLoading: false,
			};
		case types.UPDATE_MANAGEMENT_REVIEW_RESET:
			return {
				...state,
				updatemanagementReview: null,
				updatemanagementReviewError: null,
				updatemanagementReviewLoading: false,
			};

		default:
			return { ...state };
	}
};

export default ManagmentReviewReducer;
