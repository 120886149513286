import types from "./types";

const INITIAL_STATE = {
  MethodPackages: null,
  MethodPackagesError: null,
  MethodPackagesLoading: false,

  //create
  createMethodPackages: null,
  createMethodPackagesError: null,
  createMethodPackagesLoading: false,

  createForm: null,

  //update
  updateMethodPackages: null,
  updateMethodPackagesError: null,
  updateMethodPackagesLoading: false,

  //delete
  deleteMethodPackages: null,
  deleteMethodPackagesError: null,
  deleteMethodPackagesLoading: false,

  //
  form: null,
  formError: null,
  FormLoading: false,
  //
  formEdit: null,
  formEditError: null,
  formEditLoading: false,
};

const methodPackagesPackagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
		case types.GET_METHOD_PACKAGES_START:
			return {
				...state,
				MethodPackages: null,
				MethodPackagesError: null,
				MethodPackagesLoading: true,
			};
		case types.GET_METHOD_PACKAGES_SUCCESS:
			return {
				...state,
				MethodPackages: action.payload,
				MethodPackagesLoading: false,
			};
		case types.GET_METHOD_PACKAGES_ERROR:
			return {
				...state,
				MethodPackagesError: action.payload,
				MethodPackagesLoading: false,
			};
		case types.GET_METHOD_PACKAGES_RESET:
			return {
				...state,
				MethodPackagesError: null,
				MethodPackagesLoading: false,
			};
		//create

		case types.CREATE_METHOD_PACKAGES_START:
			return {
				...state,
				createMethodPackages: null,
				createMethodPackagesError: null,
				createMethodPackagesLoading: true,
			};
		case types.CREATE_METHOD_PACKAGES_SUCCESS:
			return {
				...state,
				createForm: action.payload?.reponse?.data,
				createMethodPackages: action.payload,
				createMethodPackagesLoading: false,
			};
		case types.CREATE_METHOD_PACKAGES_ERROR:
			return {
				...state,
				createMethodPackagesError: action.payload,
				createMethodPackagesLoading: false,
			};
		case types.CREATE_METHOD_PACKAGES_RESET:
			return {
				...state,
				createMethodPackages: null,
				createMethodPackagesError: null,
				createMethodPackagesLoading: false,
			};
		//delete

		case types.DELETE_METHOD_PACKAGES_START:
			return {
				...state,
				deleteMethodPackages: null,
				deleteMethodPackagesError: null,
				deleteMethodPackagesLoading: true,
			};
		case types.DELETE_METHOD_PACKAGES_SUCCESS:
			return {
				...state,
				deleteMethodPackages: action.payload,
				deleteMethodPackagesLoading: false,
			};
		case types.DELETE_METHOD_PACKAGES_ERROR:
			return {
				...state,
				deleteMethodPackagesError: action.payload,
				deleteMethodPackagesLoading: false,
			};
		case types.DELETE_METHOD_PACKAGES_RESET:
			return {
				...state,
				deleteMethodPackages: null,
				deleteMethodPackagesError: null,
				deleteMethodPackagesLoading: false,
			};

		//update
		case types.UPDATE_METHOD_PACKAGES_START:
			return {
				...state,
				updateMethodPackages: null,
				updateMethodPackagesError: null,
				updateMethodPackagesLoading: true,
			};
		case types.UPDATE_METHOD_PACKAGES_SUCCESS:
			return {
				...state,
				updateMethodPackages: action.payload,
				updateMethodPackagesLoading: false,
			};
		case types.UPDATE_METHOD_PACKAGES_ERROR:
			return {
				...state,
				updateMethodPackagesError: action.payload,
				updateMethodPackagesLoading: false,
			};
		case types.UPDATE_METHOD_PACKAGES_RESET:
			return {
				...state,
				updateMethodPackages: null,
				updateMethodPackagesError: null,
				updateMethodPackagesLoading: false,
			};
		//form edit
		case types.METHOD_PACKAGES_FORM_START:
			return {
				...state,
				form: null,
				formError: null,
				formLoading: true,
			};
		case types.METHOD_PACKAGES_FORM_SUCCESS:
			//console.log("payload", action.payload);

			return {
				...state,
				form: action.payload,
				formLoading: false,
			};
		case types.METHOD_PACKAGES_FORM_ERROR:
			return {
				...state,
				formError: action.payload,
				formLoading: false,
			};
		case types.METHOD_PACKAGES_FORM_RESET:
			return {
				...state,
				form: null,
				formError: null,
				formLoading: false,
			};
		//form edit
		case types.METHOD_PACKAGES_FORM_EDIT_START:
			return {
				...state,
				formEdit: null,
				formEditError: null,
				formEditLoading: true,
			};
		case types.METHOD_PACKAGES_FORM_EDIT_SUCCESS:
			return {
				...state,
				formEdit: action.payload,
				formEditLoading: false,
			};
		case types.METHOD_PACKAGES_FORM_EDIT_ERROR:
			return {
				...state,
				formEditError: action.payload,
				formEditLoading: false,
			};
		case types.METHOD_PACKAGES_FORM_EDIT_RESET:
			return {
				...state,
				formEdit: null,
				formEditError: null,
				formEditLoading: false,
			};

		default:
			return { ...state };
	}
};

export default methodPackagesPackagesReducer;
