import types from "./types";

const INITIAL_STATE = {
	internalAudit: null,
	internalAuditError: null,
	internalAuditLoading: false,

	//create
	createinternalAudit: null,
	createinternalAuditError: null,
	createinternalAuditLoading: false,

	//update
	updateinternalAudit: null,
	updateinternalAuditError: null,
	updateinternalAuditLoading: false,

	//delete
	deleteinternalAudit: null,
	deleteinternalAuditError: null,
	deleteinternalAuditLoading: false,

	//single item

	singleItem: null,
};

const InternalAuditReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_INTERNAL_AUDIT_START:
			return {
				...state,
				internalAudit: null,
				internalAuditError: null,
				internalAuditLoading: true,
			};
		case types.GET_INTERNAL_AUDIT_SUCCESS:
			return {
				...state,
				internalAudit: action.payload,
				internalAuditLoading: false,
			};
		case types.GET_INTERNAL_AUDIT_ERROR:
			return {
				...state,
				internalAuditError: action.payload,
				internalAuditLoading: false,
			};
		case types.GET_INTERNAL_AUDIT_RESET:
			return {
				...state,
				internalAuditError: null,
				internalAuditLoading: false,
			};
		//create

		case types.CREATE_INTERNAL_AUDIT_START:
			return {
				...state,
				createinternalAudit: null,
				createinternalAuditError: null,
				createinternalAuditLoading: true,
			};
		case types.CREATE_INTERNAL_AUDIT_SUCCESS:
			return {
				...state,
				createinternalAudit: action.payload,
				createinternalAuditLoading: false,
			};
		case types.CREATE_INTERNAL_AUDIT_ERROR:
			return {
				...state,
				createinternalAuditError: action.payload,
				createinternalAuditLoading: false,
			};
		case types.CREATE_INTERNAL_AUDIT_RESET:
			return {
				...state,
				createinternalAuditError: null,
				createinternalAuditLoading: false,
			};
		//delete

		case types.DELETE_INTERNAL_AUDIT_START:
			return {
				...state,
				deleteinternalAudit: null,
				deleteinternalAuditError: null,
				deleteinternalAuditLoading: true,
			};
		case types.DELETE_INTERNAL_AUDIT_SUCCESS:
			return {
				...state,
				deleteinternalAudit: action.payload,
				deleteinternalAuditLoading: false,
			};
		case types.DELETE_INTERNAL_AUDIT_ERROR:
			return {
				...state,
				deleteinternalAuditError: action.payload,
				deleteinternalAuditLoading: false,
			};
		case types.DELETE_INTERNAL_AUDIT_RESET:
			return {
				...state,
				deleteinternalAudit: null,
				deleteinternalAuditError: null,
				deleteinternalAuditLoading: false,
			};

		//update
		case types.UPDATE_INTERNAL_AUDIT_START:
			return {
				...state,
				updateinternalAudit: null,
				updateinternalAuditError: null,
				updateinternalAuditLoading: true,
			};
		case types.UPDATE_INTERNAL_AUDIT_SUCCESS:
			return {
				...state,
				updateinternalAudit: action.payload,
				updateinternalAuditLoading: false,
			};
		case types.UPDATE_INTERNAL_AUDIT_ERROR:
			return {
				...state,
				updateinternalAuditError: action.payload,
				updateinternalAuditLoading: false,
			};
		case types.UPDATE_INTERNAL_AUDIT_RESET:
			return {
				...state,
				updateinternalAudit: null,
				updateinternalAuditError: null,
				updateinternalAuditLoading: false,
			};

		default:
			return { ...state };
	}
};

export default InternalAuditReducer;
