import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getequipmetsServiceHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.equipmentService(action.payload),
		);
		yield put({
			type: types.GET_EQUIPMENTS_SERVICE_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_EQUIPMENTS_SERVICE_ERROR,
			payload,
		});
	}
}

export function* getequipmetsServiceWatcherSaga() {
	yield takeLatest(
		types.GET_EQUIPMENTS_SERVICE_START,
		getequipmetsServiceHandlerSaga,
	);
}

//create
function* createequipmetsServiceHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endPoint.equipmentService(action.payload.path),
			action.payload.body,
		);
		yield put({
			type: types.CREATE_EQUIPMENTS_SERVICE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_EQUIPMENTS_SERVICE_ERROR,
			payload,
		});
	}
}

export function* createequipmetsServiceWatcherSaga() {
	yield takeLatest(
		types.CREATE_EQUIPMENTS_SERVICE_START,
		createequipmetsServiceHandlerSaga,
	);
}

//create
function* updateequipmetsServiceHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endPoint.updateequipmentService(action.payload.path),
			action.payload.body,
		);
		yield put({
			type: types.UPDATE_EQUIPMENTS_SERVICE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_EQUIPMENTS_SERVICE_ERROR,
			payload,
		});
	}
}

export function* updateequipmetsServiceWatcherSaga() {
	yield takeLatest(
		types.UPDATE_EQUIPMENTS_SERVICE_START,
		updateequipmetsServiceHandlerSaga,
	);
}

//delete

function* deleteequipmetsServiceHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteequipmentService(action.payload),
		);
		yield put({
			type: types.DELETE_EQUIPMENTS_SERVICE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_EQUIPMENTS_SERVICE_ERROR,
			payload,
		});
	}
}

export function* deleteequipmetsServiceWatcherSaga() {
	yield takeLatest(
		types.DELETE_EQUIPMENTS_SERVICE_START,
		deleteequipmetsServiceHandlerSaga,
	);
}
