const types = {
	//Get User
	GET_INTERNAL_USER_START: "GET_INTERNAL_USER_START",
	GET_INTERNAL_USER_SUCCESS: "GET_INTERNAL_USER_SUCCESS",
	GET_INTERNAL_USER_ERROR: "GET_INTERNAL_USER_ERROR",
	GET_INTERNAL_USER_RESET: "GET_INTERNAL_USER_RESET",

	//create User

	CREATE_INTERNAL_USER_START: "CREATE_INTERNAL_USER_START",
	CREATE_INTERNAL_USER_SUCCESS: "CREATE_INTERNAL_USER_SUCCESS",
	CREATE_INTERNAL_USER_ERROR: "CREATE_INTERNAL_USER_ERROR",
	CREATE_INTERNAL_USER_RESET: "CREATE_INTERNAL_USER_RESET",

	//delete user
	DELETE_INTERNAL_USER_START: "DELETE_INTERNAL_USER_START",
	DELETE_INTERNAL_USER_SUCCESS: "DELETE_INTERNAL_USER_SUCCESS",
	DELETE_INTERNAL_USER_ERROR: "DELETE_INTERNAL_USER_ERROR",
	DELETE_INTERNAL_USER_RESET: "DELETE_INTERNAL_USER_RESET",

	//update user

	UPDATE_INTERNAL_USER_START: "UPDATE_INTERNAL_USER_START",
	UPDATE_INTERNAL_USER_SUCCESS: "UPDATE_INTERNAL_USER_SUCCESS",
	UPDATE_INTERNAL_USER_ERROR: "UPDATE_INTERNAL_USER_ERROR",
	UPDATE_INTERNAL_USER_RESET: "UPDATE_INTERNAL_USER_RESET",
};

export default types;
