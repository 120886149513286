import types from "./types";

const INITIAL_STATE = {
	cmsPageList: null,
	cmsPageListError: null,
	cmsPageListLoading: false,

	//create
	createcmsPageList: null,
	createcmsPageListError: null,
	createcmsPageListLoading: false,

	//update
	updatecmsPageList: null,
	updatecmsPageListError: null,
	updatecmsPageListLoading: false,

	//delete
	deletecmsPageList: null,
	deletecmsPageListError: null,
	deletecmsPageListLoading: false,

	//single item

	singleItem: null,
};

const CMSPageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_CMS_PAGE_LIST_START:
			return {
				...state,
				cmsPageList: null,
				cmsPageListError: null,
				cmsPageListLoading: true,
			};
		case types.GET_CMS_PAGE_LIST_SUCCESS:
			return {
				...state,
				cmsPageList: action.payload,
				cmsPageListLoading: false,
			};
		case types.GET_CMS_PAGE_LIST_ERROR:
			return {
				...state,
				cmsPageListError: action.payload,
				cmsPageListLoading: false,
			};
		case types.GET_CMS_PAGE_LIST_RESET:
			return {
				...state,
				cmsPageListError: null,
				cmsPageListLoading: false,
			};
		//create

		case types.CREATE_CMS_PAGE_LIST_START:
			return {
				...state,
				createcmsPageList: null,
				createcmsPageListError: null,
				createcmsPageListLoading: true,
			};
		case types.CREATE_CMS_PAGE_LIST_SUCCESS:
			return {
				...state,
				createcmsPageList: action.payload,
				createcmsPageListLoading: false,
			};
		case types.CREATE_CMS_PAGE_LIST_ERROR:
			return {
				...state,
				createcmsPageListError: action.payload,
				createcmsPageListLoading: false,
			};
		case types.CREATE_CMS_PAGE_LIST_RESET:
			return {
				...state,
				createcmsPageListError: null,
				createcmsPageListLoading: false,
			};
		//delete

		case types.DELETE_CMS_PAGE_LIST_START:
			return {
				...state,
				deletecmsPageList: null,
				deletecmsPageListError: null,
				deletecmsPageListLoading: true,
			};
		case types.DELETE_CMS_PAGE_LIST_SUCCESS:
			return {
				...state,
				deletecmsPageList: action.payload,
				deletecmsPageListLoading: false,
			};
		case types.DELETE_CMS_PAGE_LIST_ERROR:
			return {
				...state,
				deletecmsPageListError: action.payload,
				deletecmsPageListLoading: false,
			};
		case types.DELETE_CMS_PAGE_LIST_RESET:
			return {
				...state,
				deletecmsPageList: null,
				deletecmsPageListError: null,
				deletecmsPageListLoading: false,
			};

		//update
		case types.UPDATE_CMS_PAGE_LIST_START:
			return {
				...state,
				updatecmsPageList: null,
				updatecmsPageListError: null,
				updatecmsPageListLoading: true,
			};
		case types.UPDATE_CMS_PAGE_LIST_SUCCESS:
			return {
				...state,
				updatecmsPageList: action.payload,
				updatecmsPageListLoading: false,
			};
		case types.UPDATE_CMS_PAGE_LIST_ERROR:
			return {
				...state,
				updatecmsPageListError: action.payload,
				updatecmsPageListLoading: false,
			};
		case types.UPDATE_CMS_PAGE_LIST_RESET:
			return {
				...state,
				updatecmsPageList: null,
				updatecmsPageListError: null,
				updatecmsPageListLoading: false,
			};
		//singleItem
		// case types.SINGLE_CMS_PAGE_LIST_START:
		// 	return {
		// 		...state,
		// 		updatecmsPageList: null,
		// 		updatecmsPageListError: null,
		// 		updatecmsPageListLoading: true,
		// 	};
		// case types.SINGLE_CMS_PAGE_LIST_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatecmsPageList: action.payload,
		// 		updatecmsPageListLoading: false,
		// 	};
		// case types.SINGLE_CMS_PAGE_LIST_ERROR:
		// 	return {
		// 		...state,
		// 		updatecmsPageListError: action.payload,
		// 		updatecmsPageListLoading: false,
		// 	};
		// case types.SINGLE_CMS_PAGE_LIST_RESET:
		// 	return {
		// 		...state,
		// 		updatecmsPageListError: null,
		// 		updatecmsPageListLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default CMSPageReducer;
