import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getFromBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const data = yield call(apiClient.get, endPoint.fromList(action.payload));
		yield put({
			type: types.GET_FROMBUILDER_SUCCESS,
			payload: data?.data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_FROMBUILDER_ERROR,
			payload,
		});
	}
}

export function* getFromBuilderWatcherSaga() {
	yield takeLatest(types.GET_FROMBUILDER_START, getFromBuilderHandlerSaga);
}

//get/id
function* getsingleFromBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(apiClient.get, endPoint.from(action.payload));
		yield put({
			type: types.SINGLE_FROMBUILDER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.SINGLE_FROMBUILDER_ERROR,
			payload,
		});
	}
}

export function* getSingleFromBuilderWatcherSaga() {
	yield takeLatest(
		types.SINGLE_FROMBUILDER_START,
		getsingleFromBuilderHandlerSaga,
	);
}

//create

function* createFromBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endPoint.addfrombuilder(),
			action.payload,
		);
		yield put({
			type: types.CREATE_FROMBUILDER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_FROMBUILDER_ERROR,
			payload,
		});
	}
}

export function* createFromBuilderWatcherSaga() {
	yield takeLatest(
		types.CREATE_FROMBUILDER_START,
		createFromBuilderHandlerSaga,
	);
}

//delete

function* deleteFromBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteForm(action.payload),
		);
		yield put({
			type: types.DELETE_FROMBUILDER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_FROMBUILDER_ERROR,
			payload,
		});
	}
}

export function* deleteFromBuilderWatcherSaga() {
	yield takeLatest(
		types.DELETE_FROMBUILDER_START,
		deleteFromBuilderHandlerSaga,
	);
}

//update

function* updateFromBuilderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endPoint.fromUpdate,
			action.payload,
		);
		yield put({
			type: types.UPDATE_FROMBUILDER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_FROMBUILDER_ERROR,
			payload,
		});
	}
}

export function* updateFromBuilderWatcherSaga() {
	yield takeLatest(
		types.UPDATE_FROMBUILDER_START,
		updateFromBuilderHandlerSaga,
	);
}
