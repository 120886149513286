const types = {
  //Get Template name
  GET_EQUIPMENTS_SERVICE_START: "GET_EQUIPMENTS_SERVICE_START",
  GET_EQUIPMENTS_SERVICE_SUCCESS: "GET_EQUIPMENTS_SERVICE_SUCCESS",
  GET_EQUIPMENTS_SERVICE_ERROR: "GET_EQUIPMENTS_SERVICE_ERROR",
  GET_EQUIPMENTS_SERVICE_RESET: "GET_EQUIPMENTS_SERVICE_RESET",

  //Create Template name
  CREATE_EQUIPMENTS_SERVICE_START: "CREATE_EQUIPMENTS_SERVICE_START",
  CREATE_EQUIPMENTS_SERVICE_SUCCESS: "CREATE_EQUIPMENTS_SERVICE_SUCCESS",
  CREATE_EQUIPMENTS_SERVICE_ERROR: "CREATE_EQUIPMENTS_SERVICE_ERROR",
  CREATE_EQUIPMENTS_SERVICE_RESET: "CREATE_EQUIPMENTS_SERVICE_RESET",

  //delete Template name
  DELETE_EQUIPMENTS_SERVICE_START: "DELETE_EQUIPMENTS_SERVICE_START",
  DELETE_EQUIPMENTS_SERVICE_SUCCESS: "DELETE_EQUIPMENTS_SERVICE_SUCCESS",
  DELETE_EQUIPMENTS_SERVICE_ERROR: "DELETE_EQUIPMENTS_SERVICE_ERROR",
  DELETE_EQUIPMENTS_SERVICE_RESET: "DELETE_EQUIPMENTS_SERVICE_RESET",

  //update Template name
  UPDATE_EQUIPMENTS_SERVICE_START: "UPDATE_EQUIPMENTS_SERVICE_START",
  UPDATE_EQUIPMENTS_SERVICE_SUCCESS: "UPDATE_EQUIPMENTS_SERVICE_SUCCESS",
  UPDATE_EQUIPMENTS_SERVICE_ERROR: "UPDATE_EQUIPMENTS_SERVICE_ERROR",
  UPDATE_EQUIPMENTS_SERVICE_RESET: "UPDATE_EQUIPMENTS_SERVICE_RESET",

  //single item  name
  SINGLE_EQUIPMENTS_SERVICE_START: "SINGLE_EQUIPMENTS_SERVICE_START",
  SINGLE_EQUIPMENTS_SERVICE_SUCCESS: "SINGLE_EQUIPMENTS_SERVICE_SUCCESS",
  SINGLE_EQUIPMENTS_SERVICE_ERROR: "SINGLE_EQUIPMENTS_SERVICE_ERROR",
  SINGLE_EQUIPMENTS_SERVICE_RESET: "SINGLE_EQUIPMENTS_SERVICE_RESET",
};

export default types;
