import types from "./types";

const INITIAL_STATE = {
	VendorDetails: null,
	VendorDetailsError: null,
	VendorDetailsLoading: false,

	//create
	createVendorDetails: null,
	createVendorDetailsError: null,
	createVendorDetailsLoading: false,

	//update
	updateVendorDetails: null,
	updateVendorDetailsError: null,
	updateVendorDetailsLoading: false,

	//delete
	deleteVendorDetails: null,
	deleteVendorDetailsError: null,
	deleteVendorDetailsLoading: false,

	//single item

	singleItem: null,
};

const VendorDetailsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_VENDOR_DETAILS_START:
			return {
				...state,
				VendorDetails: null,
				VendorDetailsError: null,
				VendorDetailsLoading: true,
			};
		case types.GET_VENDOR_DETAILS_SUCCESS:
			return {
				...state,
				VendorDetails: action.payload,
				VendorDetailsLoading: false,
			};
		case types.GET_VENDOR_DETAILS_ERROR:
			return {
				...state,
				VendorDetailsError: action.payload,
				VendorDetailsLoading: false,
			};
		case types.GET_VENDOR_DETAILS_RESET:
			return {
				...state,
				VendorDetailsError: null,
				VendorDetailsLoading: false,
			};
		//create

		case types.CREATE_VENDOR_DETAILS_START:
			return {
				...state,
				createVendorDetails: null,
				createVendorDetailsError: null,
				createVendorDetailsLoading: true,
			};
		case types.CREATE_VENDOR_DETAILS_SUCCESS:
			return {
				...state,
				createVendorDetails: action.payload,
				createVendorDetailsLoading: false,
			};
		case types.CREATE_VENDOR_DETAILS_ERROR:
			return {
				...state,
				createVendorDetailsError: action.payload,
				createVendorDetailsLoading: false,
			};
		case types.CREATE_VENDOR_DETAILS_RESET:
			return {
				...state,
				createVendorDetailsError: null,
				createVendorDetailsLoading: false,
			};
		//delete

		case types.DELETE_VENDOR_DETAILS_START:
			return {
				...state,
				deleteVendorDetails: null,
				deleteVendorDetailsError: null,
				deleteVendorDetailsLoading: true,
			};
		case types.DELETE_VENDOR_DETAILS_SUCCESS:
			return {
				...state,
				deleteVendorDetails: action.payload,
				deleteVendorDetailsLoading: false,
			};
		case types.DELETE_VENDOR_DETAILS_ERROR:
			return {
				...state,
				deleteVendorDetailsError: action.payload,
				deleteVendorDetailsLoading: false,
			};
		case types.DELETE_VENDOR_DETAILS_RESET:
			return {
				...state,
				deleteVendorDetails: null,
				deleteVendorDetailsError: null,
				deleteVendorDetailsLoading: false,
			};

		//update
		case types.UPDATE_VENDOR_DETAILS_START:
			return {
				...state,
				updateVendorDetails: null,
				updateVendorDetailsError: null,
				updateVendorDetailsLoading: true,
			};
		case types.UPDATE_VENDOR_DETAILS_SUCCESS:
			return {
				...state,
				updateVendorDetails: action.payload,
				updateVendorDetailsLoading: false,
			};
		case types.UPDATE_VENDOR_DETAILS_ERROR:
			return {
				...state,
				updateVendorDetailsError: action.payload,
				updateVendorDetailsLoading: false,
			};
		case types.UPDATE_VENDOR_DETAILS_RESET:
			return {
				...state,
				updateVendorDetails: null,
				updateVendorDetailsError: null,
				updateVendorDetailsLoading: false,
			};
		//singleItem
		// case types.SINGLE_VENDOR_DETAILS_START:
		// 	return {
		// 		...state,
		// 		updateVendorDetails: null,
		// 		updateVendorDetailsError: null,
		// 		updateVendorDetailsLoading: true,
		// 	};
		// case types.SINGLE_VENDOR_DETAILS_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateVendorDetails: action.payload,
		// 		updateVendorDetailsLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_DETAILS_ERROR:
		// 	return {
		// 		...state,
		// 		updateVendorDetailsError: action.payload,
		// 		updateVendorDetailsLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_DETAILS_RESET:
		// 	return {
		// 		...state,
		// 		updateVendorDetailsError: null,
		// 		updateVendorDetailsLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default VendorDetailsReducer;
