import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import userActivityAPIClientFactory from "../../backend/userActivityAPIClient";
import endPoint from "../../backend/endpoints";

/**
 * USERACTIVITY LIST API CALL
 * */
function* userActivityListStartHandlerSaga(action) {
	const userActivityAPIClient = userActivityAPIClientFactory();
	try {
		const { data } = yield call(
			userActivityAPIClient.post,
			endPoint.userlog(),
			action.payload,
		);
		// const { data } = yield call(
		// 	userActivityAPIClientFactory.post,
		// 	endPoint.listCreateTemplate(action.payload),
		// );
		yield put({
			type: types.USERACTIVITY_LIST_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.USERACTIVITY_LIST_ERROR,
			payload: e?.response?.data || e,
		});
	}
}

export function* userActivityListStartWatcherSaga() {
	yield takeLatest(
		types.USERACTIVITY_LIST_START,
		userActivityListStartHandlerSaga,
	);
}
/**
 * USERACTIVITY Details API CALL
 * */
function* userActivityDetailsStartHandlerSaga(action) {
	const userActivityAPIClient = userActivityAPIClientFactory();
	try {
		const { logId } = action.payload;
		const { data } = yield call(
			userActivityAPIClient.get,
			endPoint.userlogDetails(logId),
		);
		// const { data } = yield call(
		// 	userActivityAPIClientFactory.post,
		// 	endPoint.listCreateTemplate(action.payload),
		// );
		yield put({
			type: types.USERACTIVITY_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.USERACTIVITY_DETAILS_ERROR,
			payload: e?.response?.data || e,
		});
	}
}

export function* userActivityDetailsStartWatcherSaga() {
	yield takeLatest(
		types.USERACTIVITY_DETAILS_START,
		userActivityDetailsStartHandlerSaga,
	);
}
