const types = {
	//Get Template name
	GET_EMPLOYEE_DETAILS_START: "GET_EMPLOYEE_DETAILS_START",
	GET_EMPLOYEE_DETAILS_SUCCESS: "GET_EMPLOYEE_DETAILS_SUCCESS",
	GET_EMPLOYEE_DETAILS_ERROR: "GET_EMPLOYEE_DETAILS_ERROR",
	GET_EMPLOYEE_DETAILS_RESET: "GET_EMPLOYEE_DETAILS_RESET",

	//Create Template name
	CREATE_EMPLOYEE_DETAILS_START: "CREATE_EMPLOYEE_DETAILS_START",
	CREATE_EMPLOYEE_DETAILS_SUCCESS: "CREATE_EMPLOYEE_DETAILS_SUCCESS",
	CREATE_EMPLOYEE_DETAILS_ERROR: "CREATE_EMPLOYEE_DETAILS_ERROR",
	CREATE_EMPLOYEE_DETAILS_RESET: "CREATE_EMPLOYEE_DETAILS_RESET",

	//delete Template name
	DELETE_EMPLOYEE_DETAILS_START: "DELETE_EMPLOYEE_DETAILS_START",
	DELETE_EMPLOYEE_DETAILS_SUCCESS: "DELETE_EMPLOYEE_DETAILS_SUCCESS",
	DELETE_EMPLOYEE_DETAILS_ERROR: "DELETE_EMPLOYEE_DETAILS_ERROR",
	DELETE_EMPLOYEE_DETAILS_RESET: "DELETE_EMPLOYEE_DETAILS_RESET",

	//update Template name
	UPDATE_EMPLOYEE_DETAILS_START: "UPDATE_EMPLOYEE_DETAILS_START",
	UPDATE_EMPLOYEE_DETAILS_SUCCESS: "UPDATE_EMPLOYEE_DETAILS_SUCCESS",
	UPDATE_EMPLOYEE_DETAILS_ERROR: "UPDATE_EMPLOYEE_DETAILS_ERROR",
	UPDATE_EMPLOYEE_DETAILS_RESET: "UPDATE_EMPLOYEE_DETAILS_RESET",

	//single item  name
	SINGLE_EMPLOYEE_DETAILS_START: "SINGLE_EMPLOYEE_DETAILS_START",
	SINGLE_EMPLOYEE_DETAILS_SUCCESS: "SINGLE_EMPLOYEE_DETAILS_SUCCESS",
	SINGLE_EMPLOYEE_DETAILS_ERROR: "SINGLE_EMPLOYEE_DETAILS_ERROR",
	SINGLE_EMPLOYEE_DETAILS_RESET: "SINGLE_EMPLOYEE_DETAILS_RESET",
};

export default types;
