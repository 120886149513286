import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getManageJobHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({
				path: action.payload.path,
				...action.payload.body,
			}),
		);
		yield put({
			type: types.GET_MANAGE_JOB_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_MANAGE_JOB_ERROR,
			payload,
		});
	}
}

export function* getManageJobWatcherSaga() {
	yield takeLatest(types.GET_MANAGE_JOB_START, getManageJobHandlerSaga);
}

//create
/**
 * 
 * function* createManageJobHandlerSaga(action) {
	const apiClient=apiClientFactory();
	try {
		const { data } = yield call(apiClient.post, endPoint.addSample,action.payload);
		yield put({
			type: types.CREATE_MANAGE_JOB_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_MANAGE_JOB_ERROR,
			payload,
		});
	}
}

export function* createManageJobWatcherSaga() {
	yield  takeLatest(
		types.CREATE_MANAGE_JOB_START,
		createManageJobHandlerSaga,
	);
}
 */

//delete

function* deleteManageJobHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("job", action.payload),
		);
		yield put({
			type: types.DELETE_MANAGE_JOB_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_MANAGE_JOB_ERROR,
			payload,
		});
	}
}

export function* deleteManageJobWatcherSaga() {
	yield takeLatest(types.DELETE_MANAGE_JOB_START, deleteManageJobHandlerSaga);
}

//update
/** 
 * function* updateManageJobHandlerSaga(action) {
	const apiClient=apiClientFactory();
	try {
		const { data } = yield call(apiClient.put, endPoint.updateSample,action.payload);
		yield put({
			type: types.UPDATE_MANAGE_JOB_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_MANAGE_JOB_ERROR,
			payload,
		});
	}
}

export function* updateManageJobWatcherSaga() {
	yield  takeLatest(
		types.UPDATE_MANAGE_JOB_START,
		updateManageJobHandlerSaga,
	);
}
*/
