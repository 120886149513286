const types = {
	//Get Template name
	GET_USER_PROFILE_START: "GET_USER_PROFILE_START",
	GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
	GET_USER_PROFILE_ERROR: "GET_USER_PROFILE_ERROR",
	GET_USER_PROFILE_RESET: "GET_USER_PROFILE_RESET",

	//delete Template name
	DELETE_USER_PROFILE_START: "DELETE_USER_PROFILE_START",
	DELETE_USER_PROFILE_SUCCESS: "DELETE_USER_PROFILE_SUCCESS",
	DELETE_USER_PROFILE_ERROR: "DELETE_USER_PROFILE_ERROR",
	DELETE_USER_PROFILE_RESET: "DELETE_USER_PROFILE_RESET",
};

export default types;
