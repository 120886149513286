import types from "./types";

const INITIAL_STATE = {
	NonConfirmityReportTicket: null,
	NonConfirmityReportTicketError: null,
	NonConfirmityReportTicketLoading: false,

	//create
	createNonConfirmityReportTicket: null,
	createNonConfirmityReportTicketError: null,
	createNonConfirmityReportTicketLoading: false,

	//update
	updateNonConfirmityReportTicket: null,
	updateNonConfirmityReportTicketError: null,
	updateNonConfirmityReportTicketLoading: false,

	//delete
	deleteNonConfirmityReportTicket: null,
	deleteNonConfirmityReportTicketError: null,
	deleteNonConfirmityReportTicketLoading: false,

	//single item
};

const NonConformityReportTicketReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_NON_CONFORMITY_REPORT_TICKET_START:
			return {
				...state,
				NonConfirmityReportTicket: null,
				NonConfirmityReportTicketError: null,
				NonConfirmityReportTicketLoading: true,
			};
		case types.GET_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
			return {
				...state,
				NonConfirmityReportTicket: action.payload,
				NonConfirmityReportTicketLoading: false,
			};
		case types.GET_NON_CONFORMITY_REPORT_TICKET_ERROR:
			return {
				...state,
				NonConfirmityReportTicketError: action.payload,
				NonConfirmityReportTicketLoading: false,
			};
		case types.GET_NON_CONFORMITY_REPORT_TICKET_RESET:
			return {
				...state,
				NonConfirmityReportTicketError: null,
				NonConfirmityReportTicketLoading: false,
			};
		//create

		case types.CREATE_NON_CONFORMITY_REPORT_TICKET_START:
			return {
				...state,
				createNonConfirmityReportTicket: null,
				createNonConfirmityReportTicketError: null,
				createNonConfirmityReportTicketLoading: true,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
			return {
				...state,
				createNonConfirmityReportTicket: action.payload,
				createNonConfirmityReportTicketLoading: false,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_TICKET_ERROR:
			return {
				...state,
				createNonConfirmityReportTicketError: action.payload,
				createNonConfirmityReportTicketLoading: false,
			};
		case types.CREATE_NON_CONFORMITY_REPORT_TICKET_RESET:
			return {
				...state,
				createNonConfirmityReportTicketError: null,
				createNonConfirmityReportTicketLoading: false,
			};
		//delete

		case types.DELETE_NON_CONFORMITY_REPORT_TICKET_START:
			return {
				...state,
				deleteNonConfirmityReportTicket: null,
				deleteNonConfirmityReportTicketError: null,
				deleteNonConfirmityReportTicketLoading: true,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
			return {
				...state,
				deleteNonConfirmityReportTicket: action.payload,
				deleteNonConfirmityReportTicketLoading: false,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_TICKET_ERROR:
			return {
				...state,
				deleteNonConfirmityReportTicketError: action.payload,
				deleteNonConfirmityReportTicketLoading: false,
			};
		case types.DELETE_NON_CONFORMITY_REPORT_TICKET_RESET:
			return {
				...state,
				deleteNonConfirmityReportTicket: null,
				deleteNonConfirmityReportTicketError: null,
				deleteNonConfirmityReportTicketLoading: false,
			};

		//update
		case types.UPDATE_NON_CONFORMITY_REPORT_TICKET_START:
			return {
				...state,
				updateNonConfirmityReportTicket: null,
				updateNonConfirmityReportTicketError: null,
				updateNonConfirmityReportTicketLoading: true,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
			return {
				...state,
				updateNonConfirmityReportTicket: action.payload,
				updateNonConfirmityReportTicketLoading: false,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_TICKET_ERROR:
			return {
				...state,
				updateNonConfirmityReportTicketError: action.payload,
				updateNonConfirmityReportTicketLoading: false,
			};
		case types.UPDATE_NON_CONFORMITY_REPORT_TICKET_RESET:
			return {
				...state,
				updateNonConfirmityReportTicket: null,
				updateNonConfirmityReportTicketError: null,
				updateNonConfirmityReportTicketLoading: false,
			};
		//singleItem
		// case types.SINGLE_NON_CONFORMITY_REPORT_TICKET_START:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportTicket: null,
		// 		updateNonConfirmityReportTicketError: null,
		// 		updateNonConfirmityReportTicketLoading: true,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_TICKET_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportTicket: action.payload,
		// 		updateNonConfirmityReportTicketLoading: false,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_TICKET_ERROR:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportTicketError: action.payload,
		// 		updateNonConfirmityReportTicketLoading: false,
		// 	};
		// case types.SINGLE_NON_CONFORMITY_REPORT_TICKET_RESET:
		// 	return {
		// 		...state,
		// 		updateNonConfirmityReportTicketError: null,
		// 		updateNonConfirmityReportTicketLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default NonConformityReportTicketReducer;
