import types from "./types";

const INITIAL_STATE = {
  masterNameList: null,
  masterNameListError: null,
  masterNameListLoading: false,

  //create
  createmasterNameList: null,
  createmasterNameListError: null,
  createmasterNameListLoading: false,

  //update
  updatemasterNameList: null,
  updatemasterNameListError: null,
  updatemasterNameListLoading: false,

  //delete
  deletemasterNameList: null,
  deletemasterNameListError: null,
  deletemasterNameListLoading: false,

  //single item

  singleItem: null,
};

const MasterNameListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MASTER_NAME_LIST_START:
      return {
        ...state,
        masterNameList: null,
        masterNameListError: null,
        masterNameListLoading: true,
      };
    case types.GET_MASTER_NAME_LIST_SUCCESS:
      return {
        ...state,
        masterNameList: action.payload,
        masterNameListLoading: false,
      };
    case types.GET_MASTER_NAME_LIST_ERROR:
      return {
        ...state,
        masterNameListError: action.payload,
        masterNameListLoading: false,
      };
    case types.GET_MASTER_NAME_LIST_RESET:
      return {
        ...state,
        masterNameListError: null,
        masterNameListLoading: false,
      };
    //create

    case types.CREATE_MASTER_NAME_LIST_START:
      return {
        ...state,
        createmasterNameList: null,
        createmasterNameListError: null,
        createmasterNameListLoading: true,
      };
    case types.CREATE_MASTER_NAME_LIST_SUCCESS:
      return {
        ...state,
        createmasterNameList: action.payload,
        createmasterNameListLoading: false,
      };
    case types.CREATE_MASTER_NAME_LIST_ERROR:
      return {
        ...state,
        createmasterNameListError: action.payload,
        createmasterNameListLoading: false,
      };
    case types.CREATE_MASTER_NAME_LIST_RESET:
      return {
        ...state,
        createmasterNameListError: null,
        createmasterNameListLoading: false,
      };
    //delete

    case types.DELETE_MASTER_NAME_LIST_START:
      return {
        ...state,
        deletemasterNameList: null,
        deletemasterNameListError: null,
        deletemasterNameListLoading: true,
      };
    case types.DELETE_MASTER_NAME_LIST_SUCCESS:
      return {
        ...state,
        deletemasterNameList: action.payload,
        deletemasterNameListLoading: false,
      };
    case types.DELETE_MASTER_NAME_LIST_ERROR:
      return {
        ...state,
        deletemasterNameListError: action.payload,
        deletemasterNameListLoading: false,
      };
    case types.DELETE_MASTER_NAME_LIST_RESET:
      return {
        ...state,
        deletemasterNameList: null,
        deletemasterNameListError: null,
        deletemasterNameListLoading: false,
      };

    //update
    case types.UPDATE_MASTER_NAME_LIST_START:
      return {
        ...state,
        updatemasterNameList: null,
        updatemasterNameListError: null,
        updatemasterNameListLoading: true,
      };
    case types.UPDATE_MASTER_NAME_LIST_SUCCESS:
      return {
        ...state,
        updatemasterNameList: action.payload,
        updatemasterNameListLoading: false,
      };
    case types.UPDATE_MASTER_NAME_LIST_ERROR:
      return {
        ...state,
        updatemasterNameListError: action.payload,
        updatemasterNameListLoading: false,
      };
    case types.UPDATE_MASTER_NAME_LIST_RESET:
      return {
        ...state,
        updatemasterNameList: null,
        updatemasterNameListError: null,
        updatemasterNameListLoading: false,
      };
    //singleItem
    // case types.SINGLE_MASTER_NAME_LIST_START:
    // 	return {
    // 		...state,
    // 		updatemasterNameList: null,
    // 		updatemasterNameListError: null,
    // 		updatemasterNameListLoading: true,
    // 	};
    // case types.SINGLE_MASTER_NAME_LIST_SUCCESS:
    // 	return {
    // 		...state,
    // 		updatemasterNameList: action.payload,
    // 		updatemasterNameListLoading: false,
    // 	};
    // case types.SINGLE_MASTER_NAME_LIST_ERROR:
    // 	return {
    // 		...state,
    // 		updatemasterNameListError: action.payload,
    // 		updatemasterNameListLoading: false,
    // 	};
    // case types.SINGLE_MASTER_NAME_LIST_RESET:
    // 	return {
    // 		...state,
    // 		updatemasterNameListError: null,
    // 		updatemasterNameListLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default MasterNameListReducer;
