import types from "./types";

const INITIAL_STATE = {
  QualityManagment: null,
  QualityManagmentError: null,
  QualityManagmentLoading: false,

  //create
  createQualityManagment: null,
  createQualityManagmentError: null,
  createQualityManagmentLoading: false,

  //update
  updateQualityManagment: null,
  updateQualityManagmentError: null,
  updateQualityManagmentLoading: false,

  //delete
  deleteQualityManagment: null,
  deleteQualityManagmentError: null,
  deleteQualityManagmentLoading: false,

  //single item

  singleItem: null,
};

const QualityManagmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_QUALITY_MANAGMENT_START:
      return {
        ...state,
        QualityManagment: null,
        QualityManagmentError: null,
        QualityManagmentLoading: true,
      };
    case types.GET_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        QualityManagment: action.payload,
        QualityManagmentLoading: false,
      };
    case types.GET_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        QualityManagmentError: action.payload,
        QualityManagmentLoading: false,
      };
    case types.GET_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        QualityManagmentError: null,
        QualityManagmentLoading: false,
      };
    //create

    case types.CREATE_QUALITY_MANAGMENT_START:
      return {
        ...state,
        createQualityManagment: null,
        createQualityManagmentError: null,
        createQualityManagmentLoading: true,
      };
    case types.CREATE_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        createQualityManagment: action.payload,
        createQualityManagmentLoading: false,
      };
    case types.CREATE_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        createQualityManagmentError: action.payload,
        createQualityManagmentLoading: false,
      };
    case types.CREATE_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        createQualityManagmentError: null,
        createQualityManagmentLoading: false,
      };
    //delete

    case types.DELETE_QUALITY_MANAGMENT_START:
      return {
        ...state,
        deleteQualityManagment: null,
        deleteQualityManagmentError: null,
        deleteQualityManagmentLoading: true,
      };
    case types.DELETE_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        deleteQualityManagment: action.payload,
        deleteQualityManagmentLoading: false,
      };
    case types.DELETE_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        deleteQualityManagmentError: action.payload,
        deleteQualityManagmentLoading: false,
      };
    case types.DELETE_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        deleteQualityManagment: null,
        deleteQualityManagmentError: null,
        deleteQualityManagmentLoading: false,
      };

    //update
    case types.UPDATE_QUALITY_MANAGMENT_START:
      return {
        ...state,
        updateQualityManagment: null,
        updateQualityManagmentError: null,
        updateQualityManagmentLoading: true,
      };
    case types.UPDATE_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        updateQualityManagment: action.payload,
        updateQualityManagmentLoading: false,
      };
    case types.UPDATE_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        updateQualityManagmentError: action.payload,
        updateQualityManagmentLoading: false,
      };
    case types.UPDATE_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        updateQualityManagment: null,
        updateQualityManagmentError: null,
        updateQualityManagmentLoading: false,
      };
    //singleItem
    // case types.SINGLE_QUALITY_MANAGMENT_START:
    // 	return {
    // 		...state,
    // 		updateQualityManagment: null,
    // 		updateQualityManagmentError: null,
    // 		updateQualityManagmentLoading: true,
    // 	};
    // case types.SINGLE_QUALITY_MANAGMENT_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateQualityManagment: action.payload,
    // 		updateQualityManagmentLoading: false,
    // 	};
    // case types.SINGLE_QUALITY_MANAGMENT_ERROR:
    // 	return {
    // 		...state,
    // 		updateQualityManagmentError: action.payload,
    // 		updateQualityManagmentLoading: false,
    // 	};
    // case types.SINGLE_QUALITY_MANAGMENT_RESET:
    // 	return {
    // 		...state,
    // 		updateQualityManagmentError: null,
    // 		updateQualityManagmentLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default QualityManagmentReducer;
