import types from "./types";

const INITIAL_STATE = {
  siteSetting: null,
  siteSettingError: null,
  siteSettingLoading: false,

  //create
  createsiteSetting: null,
  createsiteSettingError: null,
  createsiteSettingLoading: false,

  //update
  updatesiteSetting: null,
  updatesiteSettingError: null,
  updatesiteSettingLoading: false,

  //delete
  deletesiteSetting: null,
  deletesiteSettingError: null,
  deletesiteSettingLoading: false,

  //single item

  singleItem: null,
};

const SiteSettingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SITE_SETTING_START:
      return {
        ...state,
        siteSetting: null,
        siteSettingError: null,
        siteSettingLoading: true,
      };
    case types.GET_SITE_SETTING_SUCCESS:
      return {
        ...state,
        siteSetting: action.payload,
        siteSettingLoading: false,
      };
    case types.GET_SITE_SETTING_ERROR:
      return {
        ...state,
        siteSettingError: action.payload,
        siteSettingLoading: false,
      };
    case types.GET_SITE_SETTING_RESET:
      return {
        ...state,
        siteSettingError: null,
        siteSettingLoading: false,
      };
    //create

    case types.CREATE_SITE_SETTING_START:
      return {
        ...state,
        createsiteSetting: null,
        createsiteSettingError: null,
        createsiteSettingLoading: true,
      };
    case types.CREATE_SITE_SETTING_SUCCESS:
      return {
        ...state,
        createsiteSetting: action.payload,
        createsiteSettingLoading: false,
      };
    case types.CREATE_SITE_SETTING_ERROR:
      return {
        ...state,
        createsiteSettingError: action.payload,
        createsiteSettingLoading: false,
      };
    case types.CREATE_SITE_SETTING_RESET:
      return {
        ...state,
        createsiteSettingError: null,
        createsiteSettingLoading: false,
      };
    //delete

    case types.DELETE_SITE_SETTING_START:
      return {
        ...state,
        deletesiteSetting: null,
        deletesiteSettingError: null,
        deletesiteSettingLoading: true,
      };
    case types.DELETE_SITE_SETTING_SUCCESS:
      return {
        ...state,
        deletesiteSetting: action.payload,
        deletesiteSettingLoading: false,
      };
    case types.DELETE_SITE_SETTING_ERROR:
      return {
        ...state,
        deletesiteSettingError: action.payload,
        deletesiteSettingLoading: false,
      };
    case types.DELETE_SITE_SETTING_RESET:
      return {
        ...state,
        deletesiteSetting: null,
        deletesiteSettingError: null,
        deletesiteSettingLoading: false,
      };

    //update
    case types.UPDATE_SITE_SETTING_START:
      return {
        ...state,
        updatesiteSetting: null,
        updatesiteSettingError: null,
        updatesiteSettingLoading: true,
      };
    case types.UPDATE_SITE_SETTING_SUCCESS:
      return {
        ...state,
        updatesiteSetting: action.payload,
        updatesiteSettingLoading: false,
      };
    case types.UPDATE_SITE_SETTING_ERROR:
      return {
        ...state,
        updatesiteSettingError: action.payload,
        updatesiteSettingLoading: false,
      };
    case types.UPDATE_SITE_SETTING_RESET:
      return {
        ...state,
        updatesiteSetting: null,
        updatesiteSettingError: null,
        updatesiteSettingLoading: false,
      };
    //singleItem
    // case types.SINGLE_SITE_SETTING_START:
    // 	return {
    // 		...state,
    // 		updatesiteSetting: null,
    // 		updatesiteSettingError: null,
    // 		updatesiteSettingLoading: true,
    // 	};
    // case types.SINGLE_SITE_SETTING_SUCCESS:
    // 	return {
    // 		...state,
    // 		updatesiteSetting: action.payload,
    // 		updatesiteSettingLoading: false,
    // 	};
    // case types.SINGLE_SITE_SETTING_ERROR:
    // 	return {
    // 		...state,
    // 		updatesiteSettingError: action.payload,
    // 		updatesiteSettingLoading: false,
    // 	};
    // case types.SINGLE_SITE_SETTING_RESET:
    // 	return {
    // 		...state,
    // 		updatesiteSettingError: null,
    // 		updatesiteSettingLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default SiteSettingReducer;
