import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get user
function* getinternalUserHandlerSage(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "users", ...action.payload }),
		);
		yield put({
			type: types.GET_INTERNAL_USER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_INTERNAL_USER_ERROR,
			payload,
		});
	}
}

export function* getInternalUserWatcherSaga() {
	yield takeLatest(types.GET_INTERNAL_USER_START, getinternalUserHandlerSage);
}

//create user
/*
function* createinternalUserHandlerSage(action) {}

export function* createInternalUserWatcherSaga() {
	yield  takeLatest(
		types.CREATE_INTERNAL_USER_START,
		createinternalUserHandlerSage,
	);
}

//update user

function* updateinternalUserHandlerSage(action) {}

export function* updateInternalUserWatcherSaga() {
	yield  takeLatest(
		types.UPDATE_INTERNAL_USER_START,
		updateinternalUserHandlerSage,
	);
}

*/
//delete user

function* deleteinternalUserHandlerSage(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("user", action.payload),
		);
		yield put({
			type: types.DELETE_INTERNAL_USER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_INTERNAL_USER_ERROR,
			payload,
		});
	}
}

export function* deleteInternalUserWatcherSaga() {
	yield takeLatest(
		types.DELETE_INTERNAL_USER_START,
		deleteinternalUserHandlerSage,
	);
}
