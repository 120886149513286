import types from "./types";

const INITIAL_STATE = {
	vendorFormBuilder: null,

	vendorFormBuilderError: null,
	vendorFormBuilderLoading: false,

	//select item

	selectItem: null,
};

const VendorFromReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_VENDORFORMLIST_START:
			return {
				...state,
				vendorFormBuilder: null,
				vendorFormBuilderError: null,
				vendorFormBuilderLoading: true,
			};
		case types.GET_VENDORFORMLIST_SUCCESS:
			return {
				...state,
				vendorFormBuilder: action.payload,
				vendorFormBuilderLoading: false,
			};
		case types.GET_VENDORFORMLIST_ERROR:
			return {
				...state,
				vendorFormBuilderError: action.payload,
				vendorFormBuilderLoading: false,
			};
		case types.GET_VENDORFORMLIST_RESET:
			return {
				...state,
				vendorFormBuilderError: null,
				vendorFormBuilderLoading: false,
			};
		//create

		case types.SELECT_TOUPDATE_ITEM:
			return {
				...state,
				selectItem: action.payload,
			};

		default:
			return { ...state };
	}
};

export default VendorFromReducer;
