const endpoints = {
	//userlogin
	login: "users/login",
	otp: "users/verifyotp",

	menu: "componentList",

	//fromBuilder

	getmethodForm: (id) => `/method/buildercomponentlist?id=${id}`,
	putmethodForm: "/method/buildercomponentupdate",

	// fromCreate: "builder/saveForm",
	fromList: ({ page, size, columnName = "", keyword = "" }) =>
		`formbuilder/listFormbuilder?page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`,

	adminFromList: ({ page, size, columnName = "", keyword = "" }) =>
		`formbuilder/adminformlist?page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`,

	employeeFromList: ({ page, size, columnName = "", keyword = "" }) =>
		`formbuilder/employeeformlist?page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`,
	clientFormList: ({ page, size, columnName = "", keyword = "" }) =>
		`formbuilder/clientformlist?page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`,
	vendorFormList: ({ page, size, columnName = "", keyword = "" }) =>
		`formbuilder/vendorformlist?page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`,
	deleteForm: ({ id, formType }) =>
		`formbuilder/deleteFormbuilder?formID=${id}&&formType=${formType}`,
	from: (id) => `componentForm?componentID=${id}`,
	adminForm: (id) => `componentForm?adminComponentForm=${id}`,
	fromUpdate: "formbuilder/updateFormbuilder",

	//method
	addfrombuilder: (path = null) => `formbuilder/addFormbuilder?path=${path}`,

	//sample
	samplelist: ({ page, size }) =>
		`sample/listSampleName?page=${page}&size=${size}`,
	addSample: "sample/addSampleName",

	deleteSample: (id) => `sample/deleteSampleName?sampleNameID=${id}`,

	//customer

	customerlist: ({ page, size }) =>
		`customer/listCustomer?page=${page}&size=${size}`,
	addCustomer: "customer/addCustomer",
	updateCustomer: "customer/updateCustomer",
	deleteCustomer: (id) => `customer/deleteCustomer?customerID=${id}`,

	//forms.io
	fromAddData: (path) => `${path}`,

	equipmentService: ({ id, service, page, size }) =>
		`equipment/service?id=${id}&service=${service}&page=${page}&size=${size}`,
	updateequipmentService: ({ id, service }) =>
		`equipment/service?id=${id}&service=${service}`,
	deleteequipmentService: ({ id, serviceid, service }) =>
		`equipment/service?id=${id}&serviceid=${serviceid}&service=${service}`,

	//Dynamic Content

	dynamicList: ({
		path,
		id = "",
		options = "",
		page = "",
		size = "",
		keyword = "",
		columnName = "",
	}) => {
		if (options.includes("mongoQuery")) {
			return `${path}?${options}`;
		} else if (options) {
			return `${path}?${options}&page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`;
		} else {
			return `${path}?&page=${page}&size=${size}&columnName=${columnName}&keyword=${keyword}`;
		}
	},

	deleteDyanamicItem: (path, id) => `${path}/${id}`,

	dynamicUpdateItem: ({ path }) => `${path}`,

	/**
	 * ENDPOINTS FOR REPORT BUILDER API START
	 *
	 * */
	listCreateTemplate: ({ page, size, columnName, keyword, archieved }) => {
		let url = "template?";
		if (page) url += `page=${page}&`;
		if (size) url += `size=${size}&`;
		if (columnName) url += `columnname=${columnName}&`;
		if (keyword) url += `keyword=${keyword}&`;
		url += `archieved=${archieved || false}&`;
		return url;
	},
	retrieveUpdateDeleteTemplate: (template_id) => `template/${template_id}`,
	sendMailWithPdf: (template_id) => `send-main/${template_id}`,
	/**
	 * ENDPOINTS FOR REPORT BUILDER API END
	 *
	 * */

	/**
	 * Endpoints related to archiving
	 */
	archieveBulk: (mode) => `/archieve/bulk?archieve=${mode}`,

	versionHistory: (formType, formID) =>
		`/formbuilder/version-history?formType=${formType}&formID=${formID}`,
	userlog: () => `userlog`,
};

export default endpoints;
