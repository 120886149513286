const types = {
	//Get Template name
	GET_APPROVAL_LIST_START: "GET_APPROVAL_LIST_START",
	GET_APPROVAL_LIST_SUCCESS: "GET_APPROVAL_LIST_SUCCESS",
	GET_APPROVAL_LIST_ERROR: "GET_APPROVAL_LIST_ERROR",
	GET_APPROVAL_LIST_RESET: "GET_APPROVAL_LIST_RESET",

	//Create Template name
	CREATE_APPROVAL_LIST_START: "CREATE_APPROVAL_LIST_START",
	CREATE_APPROVAL_LIST_SUCCESS: "CREATE_APPROVAL_LIST_SUCCESS",
	CREATE_APPROVAL_LIST_ERROR: "CREATE_APPROVAL_LIST_ERROR",
	CREATE_APPROVAL_LIST_RESET: "CREATE_APPROVAL_LIST_RESET",

	//delete Template name
	DELETE_APPROVAL_LIST_START: "DELETE_APPROVAL_LIST_START",
	DELETE_APPROVAL_LIST_SUCCESS: "DELETE_APPROVAL_LIST_SUCCESS",
	DELETE_APPROVAL_LIST_ERROR: "DELETE_APPROVAL_LIST_ERROR",
	DELETE_APPROVAL_LIST_RESET: "DELETE_APPROVAL_LIST_RESET",

	//update Template name
	UPDATE_APPROVAL_LIST_START: "UPDATE_APPROVAL_LIST_START",
	UPDATE_APPROVAL_LIST_SUCCESS: "UPDATE_APPROVAL_LIST_SUCCESS",
	UPDATE_APPROVAL_LIST_ERROR: "UPDATE_APPROVAL_LIST_ERROR",
	UPDATE_APPROVAL_LIST_RESET: "UPDATE_APPROVAL_LIST_RESET",

	//single item  name
	SINGLE_APPROVAL_LIST_START: "SINGLE_APPROVAL_LIST_START",
	SINGLE_APPROVAL_LIST_SUCCESS: "SINGLE_APPROVAL_LIST_SUCCESS",
	SINGLE_APPROVAL_LIST_ERROR: "SINGLE_APPROVAL_LIST_ERROR",
	SINGLE_APPROVAL_LIST_RESET: "SINGLE_APPROVAL_LIST_RESET",
};

export default types;
