const types = {
	//Get Template name
	GET_CALIBRATION_START: "GET_CALIBRATION_START",
	GET_CALIBRATION_SUCCESS: "GET_CALIBRATION_SUCCESS",
	GET_CALIBRATION_ERROR: "GET_CALIBRATION_ERROR",
	GET_CALIBRATION_RESET: "GET_CALIBRATION_RESET",

	//Create Template name
	CREATE_CALIBRATION_START: "CREATE_CALIBRATION_START",
	CREATE_CALIBRATION_SUCCESS: "CREATE_CALIBRATION_SUCCESS",
	CREATE_CALIBRATION_ERROR: "CREATE_CALIBRATION_ERROR",
	CREATE_CALIBRATION_RESET: "CREATE_CALIBRATION_RESET",

	//delete Template name
	DELETE_CALIBRATION_START: "DELETE_CALIBRATION_START",
	DELETE_CALIBRATION_SUCCESS: "DELETE_CALIBRATION_SUCCESS",
	DELETE_CALIBRATION_ERROR: "DELETE_CALIBRATION_ERROR",
	DELETE_CALIBRATION_RESET: "DELETE_CALIBRATION_RESET",

	//update Template name
	UPDATE_CALIBRATION_START: "UPDATE_CALIBRATION_START",
	UPDATE_CALIBRATION_SUCCESS: "UPDATE_CALIBRATION_SUCCESS",
	UPDATE_CALIBRATION_ERROR: "UPDATE_CALIBRATION_ERROR",
	UPDATE_CALIBRATION_RESET: "UPDATE_CALIBRATION_RESET",

	//single item  name
	SINGLE_CALIBRATION_START: "SINGLE_CALIBRATION_START",
	SINGLE_CALIBRATION_SUCCESS: "SINGLE_CALIBRATION_SUCCESS",
	SINGLE_CALIBRATION_ERROR: "SINGLE_CALIBRATION_ERROR",
	SINGLE_CALIBRATION_RESET: "SINGLE_CALIBRATION_RESET",
};

export default types;
