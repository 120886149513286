import types from "./types";

const INITIAL_STATE = {
	intraLabProgram: null,
	intraLabProgramError: null,
	intraLabProgramLoading: false,

	//create
	createintraLabProgram: null,
	createintraLabProgramError: null,
	createintraLabProgramLoading: false,

	//update
	updateintraLabProgram: null,
	updateintraLabProgramError: null,
	updateintraLabProgramLoading: false,

	//delete
	deleteintraLabProgram: null,
	deleteintraLabProgramError: null,
	deleteintraLabProgramLoading: false,
};

const IntraLabProgramReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_INTRA_LAB_PROGRAM_START:
			return {
				...state,
				intraLabProgram: null,
				intraLabProgramError: null,
				intraLabProgramLoading: true,
			};
		case types.GET_INTRA_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				intraLabProgram: action.payload,
				intraLabProgramLoading: false,
			};
		case types.GET_INTRA_LAB_PROGRAM_ERROR:
			return {
				...state,
				intraLabProgramError: action.payload,
				intraLabProgramLoading: false,
			};
		case types.GET_INTRA_LAB_PROGRAM_RESET:
			return {
				...state,
				intraLabProgramError: null,
				intraLabProgramLoading: false,
			};
		//create

		case types.CREATE_INTRA_LAB_PROGRAM_START:
			return {
				...state,
				createintraLabProgram: null,
				createintraLabProgramError: null,
				createintraLabProgramLoading: true,
			};
		case types.CREATE_INTRA_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				createintraLabProgram: action.payload,
				createintraLabProgramLoading: false,
			};
		case types.CREATE_INTRA_LAB_PROGRAM_ERROR:
			return {
				...state,
				createintraLabProgramError: action.payload,
				createintraLabProgramLoading: false,
			};
		case types.CREATE_INTRA_LAB_PROGRAM_RESET:
			return {
				...state,
				createintraLabProgramError: null,
				createintraLabProgramLoading: false,
			};
		//delete

		case types.DELETE_INTRA_LAB_PROGRAM_START:
			return {
				...state,
				deleteintraLabProgram: null,
				deleteintraLabProgramError: null,
				deleteintraLabProgramLoading: true,
			};
		case types.DELETE_INTRA_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				deleteintraLabProgram: action.payload,
				deleteintraLabProgramLoading: false,
			};
		case types.DELETE_INTRA_LAB_PROGRAM_ERROR:
			return {
				...state,
				deleteintraLabProgramError: action.payload,
				deleteintraLabProgramLoading: false,
			};
		case types.DELETE_INTRA_LAB_PROGRAM_RESET:
			return {
				...state,
				deleteintraLabProgram: null,
				deleteintraLabProgramError: null,
				deleteintraLabProgramLoading: false,
			};

		//update
		case types.UPDATE_INTRA_LAB_PROGRAM_START:
			return {
				...state,
				updateintraLabProgram: null,
				updateintraLabProgramError: null,
				updateintraLabProgramLoading: true,
			};
		case types.UPDATE_INTRA_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				updateintraLabProgram: action.payload,
				updateintraLabProgramLoading: false,
			};
		case types.UPDATE_INTRA_LAB_PROGRAM_ERROR:
			return {
				...state,
				updateintraLabProgramError: action.payload,
				updateintraLabProgramLoading: false,
			};
		case types.UPDATE_INTRA_LAB_PROGRAM_RESET:
			return {
				...state,
				updateintraLabProgram: null,
				updateintraLabProgramError: null,
				updateintraLabProgramLoading: false,
			};

		default:
			return { ...state };
	}
};

export default IntraLabProgramReducer;
