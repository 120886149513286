import types from "./types";

const INITIAL_STATE = {
  SubQualityManagment: null,
  SubQualityManagmentError: null,
  SubQualityManagmentLoading: false,

  //create
  createSubQualityManagment: null,
  createSubQualityManagmentError: null,
  createSubQualityManagmentLoading: false,

  //update
  updateSubQualityManagment: null,
  updateSubQualityManagmentError: null,
  updateSubQualityManagmentLoading: false,

  //delete
  deleteSubQualityManagment: null,
  deleteSubQualityManagmentError: null,
  deleteSubQualityManagmentLoading: false,

  //single item

  singleItem: null,
};

const SubQualityManagmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SUB_QUALITY_MANAGMENT_START:
      return {
        ...state,
        SubQualityManagment: null,
        SubQualityManagmentError: null,
        SubQualityManagmentLoading: true,
      };
    case types.GET_SUB_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        SubQualityManagment: action.payload,
        SubQualityManagmentLoading: false,
      };
    case types.GET_SUB_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        SubQualityManagmentError: action.payload,
        SubQualityManagmentLoading: false,
      };
    case types.GET_SUB_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        SubQualityManagmentError: null,
        SubQualityManagmentLoading: false,
      };
    //create

    case types.CREATE_SUB_QUALITY_MANAGMENT_START:
      return {
        ...state,
        createSubQualityManagment: null,
        createSubQualityManagmentError: null,
        createSubQualityManagmentLoading: true,
      };
    case types.CREATE_SUB_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        createSubQualityManagment: action.payload,
        createSubQualityManagmentLoading: false,
      };
    case types.CREATE_SUB_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        createSubQualityManagmentError: action.payload,
        createSubQualityManagmentLoading: false,
      };
    case types.CREATE_SUB_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        createSubQualityManagmentError: null,
        createSubQualityManagmentLoading: false,
      };
    //delete

    case types.DELETE_SUB_QUALITY_MANAGMENT_START:
      return {
        ...state,
        deleteSubQualityManagment: null,
        deleteSubQualityManagmentError: null,
        deleteSubQualityManagmentLoading: true,
      };
    case types.DELETE_SUB_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        deleteSubQualityManagment: action.payload,
        deleteSubQualityManagmentLoading: false,
      };
    case types.DELETE_SUB_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        deleteSubQualityManagmentError: action.payload,
        deleteSubQualityManagmentLoading: false,
      };
    case types.DELETE_SUB_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        deleteSubQualityManagment: null,
        deleteSubQualityManagmentError: null,
        deleteSubQualityManagmentLoading: false,
      };

    //update
    case types.UPDATE_SUB_QUALITY_MANAGMENT_START:
      return {
        ...state,
        updateSubQualityManagment: null,
        updateSubQualityManagmentError: null,
        updateSubQualityManagmentLoading: true,
      };
    case types.UPDATE_SUB_QUALITY_MANAGMENT_SUCCESS:
      return {
        ...state,
        updateSubQualityManagment: action.payload,
        updateSubQualityManagmentLoading: false,
      };
    case types.UPDATE_SUB_QUALITY_MANAGMENT_ERROR:
      return {
        ...state,
        updateSubQualityManagmentError: action.payload,
        updateSubQualityManagmentLoading: false,
      };
    case types.UPDATE_SUB_QUALITY_MANAGMENT_RESET:
      return {
        ...state,
        updateSubQualityManagment: null,
        updateSubQualityManagmentError: null,
        updateSubQualityManagmentLoading: false,
      };
    //singleItem
    // case types.SINGLE_SUB_QUALITY_MANAGMENT_START:
    // 	return {
    // 		...state,
    // 		updateSubQualityManagment: null,
    // 		updateSubQualityManagmentError: null,
    // 		updateSubQualityManagmentLoading: true,
    // 	};
    // case types.SINGLE_SUB_QUALITY_MANAGMENT_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateSubQualityManagment: action.payload,
    // 		updateSubQualityManagmentLoading: false,
    // 	};
    // case types.SINGLE_SUB_QUALITY_MANAGMENT_ERROR:
    // 	return {
    // 		...state,
    // 		updateSubQualityManagmentError: action.payload,
    // 		updateSubQualityManagmentLoading: false,
    // 	};
    // case types.SINGLE_SUB_QUALITY_MANAGMENT_RESET:
    // 	return {
    // 		...state,
    // 		updateSubQualityManagmentError: null,
    // 		updateSubQualityManagmentLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default SubQualityManagmentReducer;
