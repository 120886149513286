import types from "./types";

const INITIAL_STATE = {
  dataEntrylist: null,
  dataEntrylistError: null,
  dataEntrylistLoading: false,
  createDataEntrylist: null,
  createDataEntrylistError: null,
  createDataEntrylistLoading: false,
};

const dataEntryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //get

    case types.GET_DATA_ENTRY_START:
      return {
        ...state,
        dataEntry: null,
        dataEntryError: null,
        dataEntryLoading: true,
      };
    case types.GET_DATA_ENTRY_SUCCESS:
      return {
        ...state,
        dataEntry: action.payload,
        dataEntryLoading: false,
      };
    case types.GET_DATA_ENTRY_ERROR:
      return {
        ...state,
        dataEntryError: action.payload,
        dataEntryLoading: false,
      };
    case types.GET_DATA_ENTRY_RESET:
      return {
        ...state,
        dataEntry: null,
        dataEntryError: null,
        dataEntryLoading: false,
      };

    case types.CREATE_DATA_ENTRY_START:
      return {
        ...state,
        createDataEntry: null,
        createDataEntryError: null,
        createDtaEntryLoading: true,
      };
    case types.CREATE_DATA_ENTRY_SUCCESS:
      return {
        ...state,
        createDataEntry: action.payload,
        createDataEntryLoading: false,
      };
    case types.CREATE_DATA_ENTRY_ERROR:
      return {
        ...state,
        createDataEntryError: action.payload,
        createDataEntryLoading: false,
      };
    case types.CREATE_DATA_ENTRY_RESET:
      return {
        ...state,
        createDataEntry: null,
        createDataEntryError: null,
        createDataEntryLoading: false,
      };
    default:
      return { ...state };
  }
};

export default dataEntryReducer;
