const types = {
	//Get Template name
	GET_DOCUMENTCALIBRATION_START: "GET_DOCUMENTCALIBRATION_START",
	GET_DOCUMENTCALIBRATION_SUCCESS: "GET_DOCUMENTCALIBRATION_SUCCESS",
	GET_DOCUMENTCALIBRATION_ERROR: "GET_DOCUMENTCALIBRATION_ERROR",
	GET_DOCUMENTCALIBRATION_RESET: "GET_DOCUMENTCALIBRATION_RESET",

	//Create Template name
	CREATE_DOCUMENTCALIBRATION_START: "CREATE_DOCUMENTCALIBRATION_START",
	CREATE_DOCUMENTCALIBRATION_SUCCESS: "CREATE_DOCUMENTCALIBRATION_SUCCESS",
	CREATE_DOCUMENTCALIBRATION_ERROR: "CREATE_DOCUMENTCALIBRATION_ERROR",
	CREATE_DOCUMENTCALIBRATION_RESET: "CREATE_DOCUMENTCALIBRATION_RESET",

	//delete Template name
	DELETE_DOCUMENTCALIBRATION_START: "DELETE_DOCUMENTCALIBRATION_START",
	DELETE_DOCUMENTCALIBRATION_SUCCESS: "DELETE_DOCUMENTCALIBRATION_SUCCESS",
	DELETE_DOCUMENTCALIBRATION_ERROR: "DELETE_DOCUMENTCALIBRATION_ERROR",
	DELETE_DOCUMENTCALIBRATION_RESET: "DELETE_DOCUMENTCALIBRATION_RESET",

	//update Template name
	UPDATE_DOCUMENTCALIBRATION_START: "UPDATE_DOCUMENTCALIBRATION_START",
	UPDATE_DOCUMENTCALIBRATION_SUCCESS: "UPDATE_DOCUMENTCALIBRATION_SUCCESS",
	UPDATE_DOCUMENTCALIBRATION_ERROR: "UPDATE_DOCUMENTCALIBRATION_ERROR",
	UPDATE_DOCUMENTCALIBRATION_RESET: "UPDATE_DOCUMENTCALIBRATION_RESET",

	//single item  name
	SINGLE_DOCUMENTCALIBRATION_START: "SINGLE_DOCUMENTCALIBRATION_START",
	SINGLE_DOCUMENTCALIBRATION_SUCCESS: "SINGLE_DOCUMENTCALIBRATION_SUCCESS",
	SINGLE_DOCUMENTCALIBRATION_ERROR: "SINGLE_DOCUMENTCALIBRATION_ERROR",
	SINGLE_DOCUMENTCALIBRATION_RESET: "SINGLE_DOCUMENTCALIBRATION_RESET",
};

export default types;
