import types from "./types";

const INITIAL_STATE = {
	user: null,
	userError: null,
	userLoading: false,
	//create External User
	createUser: null,
	createUserError: null,
	createUserLoading: false,

	//delete External User
	deleteUser: null,
	deleteUserError: null,
	deleteUserLoading: false,

	//update External User
	updateUser: null,
	updateUserError: null,
	updateUserLoading: false,
};

const externalUserReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_EXTERNAL_USER_START:
			return {
				...state,
				user: null,
				userLoading: true,
				userError: null,
			};
		case types.GET_EXTERNAL_USER_SUCCESS:
			return {
				...state,
				user: action.payload,
				userLoading: false,
			};
		case types.GET_EXTERNAL_USER_ERROR:
			return {
				...state,

				userLoading: false,
				userError: action.payload,
			};
		case types.GET_EXTERNAL_USER_RESET:
			return {
				...state,
				user: null,
				userLoading: false,
				userError: null,
			};
		//create external User
		case types.CREATE_EXTERNAL_USER_START:
			return {
				...state,
				createUser: null,
				createUserLoading: true,
				createUserError: null,
			};
		case types.CREATE_EXTERNAL_USER_SUCCESS:
			return {
				...state,
				createUser: action.payload,
				createUserLoading: false,
			};
		case types.CREATE_EXTERNAL_USER_ERROR:
			return {
				...state,

				createUserLoading: false,
				createUserError: action.payload,
			};
		case types.CREATE_EXTERNAL_USER_RESET:
			return {
				...state,
				createUser: null,
				createUserLoading: false,
				createUserError: null,
			};

		//update external User
		case types.UPDATE_EXTERNAL_USER_START:
			return {
				...state,
				updateUser: null,
				updateUserLoading: true,
				updateUserError: null,
			};
		case types.UPDATE_EXTERNAL_USER_SUCCESS:
			return {
				...state,
				updateUser: action.payload,
				updateUserLoading: false,
			};
		case types.UPDATE_EXTERNAL_USER_ERROR:
			return {
				...state,

				updateUserLoading: false,
				updateUserError: action.payload,
			};
		case types.UPDATE_EXTERNAL_USER_RESET:
			return {
				...state,
				updateUser: null,
				updateUserLoading: false,
				updateUserError: null,
			};

		//delete external User
		case types.DELETE_EXTERNAL_USER_START:
			return {
				...state,
				deleteUser: null,
				deleteUserLoading: true,
				deleteUserError: null,
			};
		case types.DELETE_EXTERNAL_USER_SUCCESS:
			return {
				...state,
				deleteUser: action.payload,
				deleteUserLoading: false,
			};
		case types.DELETE_EXTERNAL_USER_ERROR:
			return {
				...state,

				deleteUserLoading: false,
				deleteUserError: action.payload,
			};
		case types.DELETE_EXTERNAL_USER_RESET:
			return {
				...state,
				deleteUser: null,
				deleteUserLoading: false,
				deleteUserError: null,
			};

		default:
			return { ...state };
	}
};

export default externalUserReducer;
