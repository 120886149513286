import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getNonConfirmityReportTicketHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "ncrticketreview", ...action.payload }),
		);
		yield put({
			type: types.GET_NON_CONFORMITY_REPORT_TICKET_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_NON_CONFORMITY_REPORT_TICKET_ERROR,
			payload,
		});
	}
}

export function* getNonConfirmityReportTicketWatcherSaga() {
	yield takeLatest(
		types.GET_NON_CONFORMITY_REPORT_TICKET_START,
		getNonConfirmityReportTicketHandlerSaga,
	);
}

//create

//delete

function* deleteNonConfirmityReportTicketHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("ncrticketreview", action.payload),
		);
		yield put({
			type: types.DELETE_NON_CONFORMITY_REPORT_TICKET_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_NON_CONFORMITY_REPORT_TICKET_ERROR,
			payload,
		});
	}
}

export function* deleteNonConfirmityReportTicketWatcherSaga() {
	yield takeLatest(
		types.DELETE_NON_CONFORMITY_REPORT_TICKET_START,
		deleteNonConfirmityReportTicketHandlerSaga,
	);
}

//update
