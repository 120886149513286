import types from "./types";

const INITIAL_STATE = {
	ManageJob: null,
	ManageJobError: null,
	ManageJobLoading: false,

	//create
	createManageJob: null,
	createManageJobError: null,
	createManageJobLoading: false,

	//update
	updateManageJob: null,
	updateManageJobError: null,
	updateManageJobLoading: false,

	//delete
	deleteManageJob: null,
	deleteManageJobError: null,
	deleteManageJobLoading: false,

	//single item

	singleItem: null,
};

const sampleReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_MANAGE_JOB_START:
			return {
				...state,
				ManageJob: null,
				ManageJobError: null,
				ManageJobLoading: true,
			};
		case types.GET_MANAGE_JOB_SUCCESS:
			return {
				...state,
				ManageJob: action.payload,
				ManageJobLoading: false,
			};
		case types.GET_MANAGE_JOB_ERROR:
			return {
				...state,
				ManageJobError: action.payload,
				ManageJobLoading: false,
			};
		case types.GET_MANAGE_JOB_RESET:
			return {
				...state,
				ManageJobError: null,
				ManageJobLoading: false,
			};
		//create

		case types.CREATE_MANAGE_JOB_START:
			return {
				...state,
				createManageJob: null,
				createManageJobError: null,
				createManageJobLoading: true,
			};
		case types.CREATE_MANAGE_JOB_SUCCESS:
			return {
				...state,
				createManageJob: action.payload,
				createManageJobLoading: false,
			};
		case types.CREATE_MANAGE_JOB_ERROR:
			return {
				...state,
				createManageJobError: action.payload,
				createManageJobLoading: false,
			};
		case types.CREATE_MANAGE_JOB_RESET:
			return {
				...state,
				createManageJob: null,
				createManageJobError: null,
				createManageJobLoading: false,
			};
		//delete

		case types.DELETE_MANAGE_JOB_START:
			return {
				...state,
				deleteManageJob: null,
				deleteManageJobError: null,
				deleteManageJobLoading: true,
			};
		case types.DELETE_MANAGE_JOB_SUCCESS:
			return {
				...state,
				deleteManageJob: action.payload,
				deleteManageJobLoading: false,
			};
		case types.DELETE_MANAGE_JOB_ERROR:
			return {
				...state,
				deleteManageJobError: action.payload,
				deleteManageJobLoading: false,
			};
		case types.DELETE_MANAGE_JOB_RESET:
			return {
				...state,
				deleteManageJob: null,
				deleteManageJobError: null,
				deleteManageJobLoading: false,
			};

		//update
		case types.UPDATE_MANAGE_JOB_START:
			return {
				...state,
				updateManageJob: null,
				updateManageJobError: null,
				updateManageJobLoading: true,
			};
		case types.UPDATE_MANAGE_JOB_SUCCESS:
			return {
				...state,
				updateManageJob: action.payload,
				updateManageJobLoading: false,
			};
		case types.UPDATE_MANAGE_JOB_ERROR:
			return {
				...state,
				updateManageJobError: action.payload,
				updateManageJobLoading: false,
			};
		case types.UPDATE_MANAGE_JOB_RESET:
			return {
				...state,
				updateManageJob: null,
				updateManageJobError: null,
				updateManageJobLoading: false,
			};
		//singleItem
		// case types.SINGLE_MANAGE_JOB_START:
		// 	return {
		// 		...state,
		// 		updateManageJob: null,
		// 		updateManageJobError: null,
		// 		updateManageJobLoading: true,
		// 	};
		// case types.SINGLE_MANAGE_JOB_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateManageJob: action.payload,
		// 		updateManageJobLoading: false,
		// 	};
		// case types.SINGLE_MANAGE_JOB_ERROR:
		// 	return {
		// 		...state,
		// 		updateManageJobError: action.payload,
		// 		updateManageJobLoading: false,
		// 	};
		// case types.SINGLE_MANAGE_JOB_RESET:
		// 	return {
		// 		...state,
		// 		updateManageJobError: null,
		// 		updateManageJobLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default sampleReducer;
