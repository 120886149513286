import types from "./types";

const INITIAL_STATE = {
	Building: null,
	BuildingError: null,
	BuildingLoading: false,

	//create
	createBuilding: null,
	createBuildingError: null,
	createBuildingLoading: false,

	//update
	updateBuilding: null,
	updateBuildingError: null,
	updateBuildingLoading: false,

	//delete
	deleteBuilding: null,
	deleteBuildingError: null,
	deleteBuildingLoading: false,

	//single item

	singleItem: null,
};

const BuildingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_BUILDING_START:
			return {
				...state,
				Building: null,
				BuildingError: null,
				BuildingLoading: true,
			};
		case types.GET_BUILDING_SUCCESS:
			return {
				...state,
				Building: action.payload,
				BuildingLoading: false,
			};
		case types.GET_BUILDING_ERROR:
			return {
				...state,
				BuildingError: action.payload,
				BuildingLoading: false,
			};
		case types.GET_BUILDING_RESET:
			return {
				...state,
				BuildingError: null,
				BuildingLoading: false,
			};
		//create

		case types.CREATE_BUILDING_START:
			return {
				...state,
				createBuilding: null,
				createBuildingError: null,
				createBuildingLoading: true,
			};
		case types.CREATE_BUILDING_SUCCESS:
			return {
				...state,
				createBuilding: action.payload,
				createBuildingLoading: false,
			};
		case types.CREATE_BUILDING_ERROR:
			return {
				...state,
				createBuildingError: action.payload,
				createBuildingLoading: false,
			};
		case types.CREATE_BUILDING_RESET:
			return {
				...state,
				createBuildingError: null,
				createBuildingLoading: false,
			};
		//delete

		case types.DELETE_BUILDING_START:
			return {
				...state,
				deleteBuilding: null,
				deleteBuildingError: null,
				deleteBuildingLoading: true,
			};
		case types.DELETE_BUILDING_SUCCESS:
			return {
				...state,
				deleteBuilding: action.payload,
				deleteBuildingLoading: false,
			};
		case types.DELETE_BUILDING_ERROR:
			return {
				...state,
				deleteBuildingError: action.payload,
				deleteBuildingLoading: false,
			};
		case types.DELETE_BUILDING_RESET:
			return {
				...state,
				deleteBuilding: null,
				deleteBuildingError: null,
				deleteBuildingLoading: false,
			};

		//update
		case types.UPDATE_BUILDING_START:
			return {
				...state,
				updateBuilding: null,
				updateBuildingError: null,
				updateBuildingLoading: true,
			};
		case types.UPDATE_BUILDING_SUCCESS:
			return {
				...state,
				updateBuilding: action.payload,
				updateBuildingLoading: false,
			};
		case types.UPDATE_BUILDING_ERROR:
			return {
				...state,
				updateBuildingError: action.payload,
				updateBuildingLoading: false,
			};
		case types.UPDATE_BUILDING_RESET:
			return {
				...state,
				updateBuilding: null,
				updateBuildingError: null,
				updateBuildingLoading: false,
			};
		//singleItem
		// case types.SINGLE_BUILDING_START:
		// 	return {
		// 		...state,
		// 		updateBuilding: null,
		// 		updateBuildingError: null,
		// 		updateBuildingLoading: true,
		// 	};
		// case types.SINGLE_BUILDING_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateBuilding: action.payload,
		// 		updateBuildingLoading: false,
		// 	};
		// case types.SINGLE_BUILDING_ERROR:
		// 	return {
		// 		...state,
		// 		updateBuildingError: action.payload,
		// 		updateBuildingLoading: false,
		// 	};
		// case types.SINGLE_BUILDING_RESET:
		// 	return {
		// 		...state,
		// 		updateBuildingError: null,
		// 		updateBuildingLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default BuildingReducer;
