import axios from "axios";
import { useEffect } from "react";
import useUser from "./useUser";
import { toast } from "react-toastify";

const useApi = () => {
	const { user, logout } = useUser();
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_LOCAL_URL,
		timeout: 300000,
		headers: {
			Authorization: `Bearer ${user.jwt}`,
		},
	});

	useEffect(() => {
		const responseIntercept = api.interceptors.response.use(
			(response) => {
				console.log("RESPONSE", response);
				return response;
			},
			async (error) => {
				console.log("ERROR-FRO INTERCEPT-100", error.response);
				if (error?.response?.data.statusCode === 401) {
					toast.error("Session Time-Out");
					logout();
					// logoutHandler();
				}
				return Promise.reject(error);
			},
		);

		return () => {
			api.interceptors.response.eject(responseIntercept);
		};
	}, []);

	const GET = async (path, config = {}) => {
		try {
			const response = await api.get(path, config);
			const data = response.data;
			return data;
		} catch (error) {
			const serverError = error.response.data;
			throw new Error(serverError?.message);
		}
	};

	const POST = async (path, body = {}) => {
		try {
			const response = await api.post(path, body);
			const data = response.data;
			return data;
		} catch (error) {
			console.log(error);
			const serverError = error.response.data;
			throw new Error(serverError.message);
		}
	};

	const PUT = async (path, body) => {
		try {
			const response = await api.put(path, body);
			const data = response.data;
			return data;
		} catch (error) {
			console.log(error);
			const serverError = error.response.data;
			throw new Error(serverError.message);
		}
	};

	const DELETE = async (path) => {
		try {
			const response = await api.delete(path);
			const data = response.data;
			return data;
		} catch (error) {
			console.log(error);
			const serverError = error.response.data;
			throw new Error(serverError.message);
		}
	};

	return { POST, PUT, GET, DELETE };
};

export default useApi;
