const types = {
	//Get Template name
	GET_MANAGE_JOB_START: "GET_MANAGE_JOB_START",
	GET_MANAGE_JOB_SUCCESS: "GET_MANAGE_JOB_SUCCESS",
	GET_MANAGE_JOB_ERROR: "GET_MANAGE_JOB_ERROR",
	GET_MANAGE_JOB_RESET: "GET_MANAGE_JOB_RESET",

	//Create Template name
	CREATE_MANAGE_JOB_START: "CREATE_MANAGE_JOB_START",
	CREATE_MANAGE_JOB_SUCCESS: "CREATE_MANAGE_JOB_SUCCESS",
	CREATE_MANAGE_JOB_ERROR: "CREATE_MANAGE_JOB_ERROR",
	CREATE_MANAGE_JOB_RESET: "CREATE_MANAGE_JOB_RESET",

	//delete Template name
	DELETE_MANAGE_JOB_START: "DELETE_MANAGE_JOB_START",
	DELETE_MANAGE_JOB_SUCCESS: "DELETE_MANAGE_JOB_SUCCESS",
	DELETE_MANAGE_JOB_ERROR: "DELETE_MANAGE_JOB_ERROR",
	DELETE_MANAGE_JOB_RESET: "DELETE_MANAGE_JOB_RESET",

	//update Template name
	UPDATE_MANAGE_JOB_START: "UPDATE_MANAGE_JOB_START",
	UPDATE_MANAGE_JOB_SUCCESS: "UPDATE_MANAGE_JOB_SUCCESS",
	UPDATE_MANAGE_JOB_ERROR: "UPDATE_MANAGE_JOB_ERROR",
	UPDATE_MANAGE_JOB_RESET: "UPDATE_MANAGE_JOB_RESET",

	//single item  name
	SINGLE_MANAGE_JOB_START: "SINGLE_MANAGE_JOB_START",
	SINGLE_MANAGE_JOB_SUCCESS: "SINGLE_MANAGE_JOB_SUCCESS",
	SINGLE_MANAGE_JOB_ERROR: "SINGLE_MANAGE_JOB_ERROR",
	SINGLE_MANAGE_JOB_RESET: "SINGLE_MANAGE_JOB_RESET",
};

export default types;
