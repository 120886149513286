import types from "./types";

const INITIAL_STATE = {
  reagents: null,
  reagentsError: null,
  reagentsLoading: false,

  //create
  createreagents: null,
  createreagentsError: null,
  createreagentsLoading: false,

  //update
  updatereagents: null,
  updatereagentsError: null,
  updatereagentsLoading: false,

  //delete
  deletereagents: null,
  deletereagentsError: null,
  deletereagentsLoading: false,

  //single item

  singleItem: null,
};

const ReagentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_REAGENTS_START:
      return {
        ...state,
        reagents: null,
        reagentsError: null,
        reagentsLoading: true,
      };
    case types.GET_REAGENTS_SUCCESS:
      return {
        ...state,
        reagents: action.payload,
        reagentsLoading: false,
      };
    case types.GET_REAGENTS_ERROR:
      return {
        ...state,
        reagentsError: action.payload,
        reagentsLoading: false,
      };
    case types.GET_REAGENTS_RESET:
      return {
        ...state,
        reagentsError: null,
        reagentsLoading: false,
      };
    //create

    case types.CREATE_REAGENTS_START:
      return {
        ...state,
        createreagents: null,
        createreagentsError: null,
        createreagentsLoading: true,
      };
    case types.CREATE_REAGENTS_SUCCESS:
      return {
        ...state,
        createreagents: action.payload,
        createreagentsLoading: false,
      };
    case types.CREATE_REAGENTS_ERROR:
      return {
        ...state,
        createreagentsError: action.payload,
        createreagentsLoading: false,
      };
    case types.CREATE_REAGENTS_RESET:
      return {
        ...state,
        createreagentsError: null,
        createreagentsLoading: false,
      };
    //delete

    case types.DELETE_REAGENTS_START:
      return {
        ...state,
        deletereagents: null,
        deletereagentsError: null,
        deletereagentsLoading: true,
      };
    case types.DELETE_REAGENTS_SUCCESS:
      return {
        ...state,
        deletereagents: action.payload,
        deletereagentsLoading: false,
      };
    case types.DELETE_REAGENTS_ERROR:
      return {
        ...state,
        deletereagentsError: action.payload,
        deletereagentsLoading: false,
      };
    case types.DELETE_REAGENTS_RESET:
      return {
        ...state,
        deletereagents: null,
        deletereagentsError: null,
        deletereagentsLoading: false,
      };

    //update
    case types.UPDATE_REAGENTS_START:
      return {
        ...state,
        updatereagents: null,
        updatereagentsError: null,
        updatereagentsLoading: true,
      };
    case types.UPDATE_REAGENTS_SUCCESS:
      return {
        ...state,
        updatereagents: action.payload,
        updatereagentsLoading: false,
      };
    case types.UPDATE_REAGENTS_ERROR:
      return {
        ...state,
        updatereagentsError: action.payload,
        updatereagentsLoading: false,
      };
    case types.UPDATE_REAGENTS_RESET:
      return {
        ...state,
        updatereagents: null,
        updatereagentsError: null,
        updatereagentsLoading: false,
      };
    //singleItem
    // case types.SINGLE_REAGENTS_START:
    // 	return {
    // 		...state,
    // 		updatereagents: null,
    // 		updatereagentsError: null,
    // 		updatereagentsLoading: true,
    // 	};
    // case types.SINGLE_REAGENTS_SUCCESS:
    // 	return {
    // 		...state,
    // 		updatereagents: action.payload,
    // 		updatereagentsLoading: false,
    // 	};
    // case types.SINGLE_REAGENTS_ERROR:
    // 	return {
    // 		...state,
    // 		updatereagentsError: action.payload,
    // 		updatereagentsLoading: false,
    // 	};
    // case types.SINGLE_REAGENTS_RESET:
    // 	return {
    // 		...state,
    // 		updatereagentsError: null,
    // 		updatereagentsLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default ReagentReducer;
