import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getQualityManagmentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "qualitymanagment", ...action.payload }),
		);
		yield put({
			type: types.GET_QUALITY_MANAGMENT_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_QUALITY_MANAGMENT_ERROR,
			payload,
		});
	}
}

export function* getQualityManagmentWatcherSaga() {
	yield takeLatest(types.GET_QUALITY_MANAGMENT_START, getQualityManagmentHandlerSaga);
}

//create

//delete

function* deleteQualityManagmentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("qualitymanagment", action.payload),
		);
		yield put({
			type: types.DELETE_QUALITY_MANAGMENT_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_QUALITY_MANAGMENT_ERROR,
			payload,
		});
	}
}

export function* deleteQualityManagmentWatcherSaga() {
	yield takeLatest(types.DELETE_QUALITY_MANAGMENT_START, deleteQualityManagmentHandlerSaga);
}

//update
