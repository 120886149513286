import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* archieveBulkStartHandlerSaga(action) {
	const apiClient = apiClientFactory();
	const { mode, requestPayload } = action.payload;
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.archieveBulk(mode),
			requestPayload,
		);
		yield put({
			type: types.ARCHIEVE_BULK_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.ARCHIEVE_BULK_ERROR,
			payload,
		});
	}
}

export function* archieveBulkStartWatcherSaga() {
	yield takeLatest(types.ARCHIEVE_BULK_START, archieveBulkStartHandlerSaga);
}
