const types = {
	//Get Template name
	GET_MANAGEMENT_REVIEW_START: "GET_MANAGEMENT_REVIEW_START",
	GET_MANAGEMENT_REVIEW_SUCCESS: "GET_MANAGEMENT_REVIEW_SUCCESS",
	GET_MANAGEMENT_REVIEW_ERROR: "GET_MANAGEMENT_REVIEW_ERROR",
	GET_MANAGEMENT_REVIEW_RESET: "GET_MANAGEMENT_REVIEW_RESET",

	//Create Template name
	CREATE_MANAGEMENT_REVIEW_START: "CREATE_MANAGEMENT_REVIEW_START",
	CREATE_MANAGEMENT_REVIEW_SUCCESS: "CREATE_MANAGEMENT_REVIEW_SUCCESS",
	CREATE_MANAGEMENT_REVIEW_ERROR: "CREATE_MANAGEMENT_REVIEW_ERROR",
	CREATE_MANAGEMENT_REVIEW_RESET: "CREATE_MANAGEMENT_REVIEW_RESET",

	//delete Template name
	DELETE_MANAGEMENT_REVIEW_START: "DELETE_MANAGEMENT_REVIEW_START",
	DELETE_MANAGEMENT_REVIEW_SUCCESS: "DELETE_MANAGEMENT_REVIEW_SUCCESS",
	DELETE_MANAGEMENT_REVIEW_ERROR: "DELETE_MANAGEMENT_REVIEW_ERROR",
	DELETE_MANAGEMENT_REVIEW_RESET: "DELETE_MANAGEMENT_REVIEW_RESET",

	//update Template name
	UPDATE_MANAGEMENT_REVIEW_START: "UPDATE_MANAGEMENT_REVIEW_START",
	UPDATE_MANAGEMENT_REVIEW_SUCCESS: "UPDATE_MANAGEMENT_REVIEW_SUCCESS",
	UPDATE_MANAGEMENT_REVIEW_ERROR: "UPDATE_MANAGEMENT_REVIEW_ERROR",
	UPDATE_MANAGEMENT_REVIEW_RESET: "UPDATE_MANAGEMENT_REVIEW_RESET",

	//single item  name
	SINGLE_MANAGEMENT_REVIEW_START: "SINGLE_MANAGEMENT_REVIEW_START",
	SINGLE_MANAGEMENT_REVIEW_SUCCESS: "SINGLE_MANAGEMENT_REVIEW_SUCCESS",
	SINGLE_MANAGEMENT_REVIEW_ERROR: "SINGLE_MANAGEMENT_REVIEW_ERROR",
	SINGLE_MANAGEMENT_REVIEW_RESET: "SINGLE_MANAGEMENT_REVIEW_RESET",
};

export default types;
