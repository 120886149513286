import types from "./types";


const INITIAL_STATE = {
    archieveSuccess: null,
    archieveError: null,
    loading: false,
}

const archieveReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ARCHIEVE_BULK_START: {
            return {
                ...state,
                archieveSuccess: null,
                archieveError: null,
                loading: true,
            }
        }
        case types.ARCHIEVE_BULK_SUCCESS: {
            return {
                ...state,
                archieveSuccess: action.payload,
                archieveError: null,
                loading: false,
            }
        }
        case types.ARCHIEVE_BULK_ERROR: {
            return {
                ...state,
                archieveSuccess: null,
                archieveError: action.payload,
                loading: false,
            }
        }

        default: {
            return {
                ...state,
            }
        }
    }
}

export default archieveReducer;