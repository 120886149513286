import types from "./types";

const INITIAL_STATE = {
	interLabProgram: null,
	interLabProgramError: null,
	interLabProgramLoading: false,

	//create
	createinterLabProgram: null,
	createinterLabProgramError: null,
	createinterLabProgramLoading: false,

	//update
	updateinterLabProgram: null,
	updateinterLabProgramError: null,
	updateinterLabProgramLoading: false,

	//delete
	deleteinterLabProgram: null,
	deleteinterLabProgramError: null,
	deleteinterLabProgramLoading: false,
};

const InterLabProgramReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_INTER_LAB_PROGRAM_START:
			return {
				...state,
				interLabProgram: null,
				interLabProgramError: null,
				interLabProgramLoading: true,
			};
		case types.GET_INTER_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				interLabProgram: action.payload,
				interLabProgramLoading: false,
			};
		case types.GET_INTER_LAB_PROGRAM_ERROR:
			return {
				...state,
				interLabProgramError: action.payload,
				interLabProgramLoading: false,
			};
		case types.GET_INTER_LAB_PROGRAM_RESET:
			return {
				...state,
				interLabProgramError: null,
				interLabProgramLoading: false,
			};
		//create

		case types.CREATE_INTER_LAB_PROGRAM_START:
			return {
				...state,
				createinterLabProgram: null,
				createinterLabProgramError: null,
				createinterLabProgramLoading: true,
			};
		case types.CREATE_INTER_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				createinterLabProgram: action.payload,
				createinterLabProgramLoading: false,
			};
		case types.CREATE_INTER_LAB_PROGRAM_ERROR:
			return {
				...state,
				createinterLabProgramError: action.payload,
				createinterLabProgramLoading: false,
			};
		case types.CREATE_INTER_LAB_PROGRAM_RESET:
			return {
				...state,
				createinterLabProgramError: null,
				createinterLabProgramLoading: false,
			};
		//delete

		case types.DELETE_INTER_LAB_PROGRAM_START:
			return {
				...state,
				deleteinterLabProgram: null,
				deleteinterLabProgramError: null,
				deleteinterLabProgramLoading: true,
			};
		case types.DELETE_INTER_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				deleteinterLabProgram: action.payload,
				deleteinterLabProgramLoading: false,
			};
		case types.DELETE_INTER_LAB_PROGRAM_ERROR:
			return {
				...state,
				deleteinterLabProgramError: action.payload,
				deleteinterLabProgramLoading: false,
			};
		case types.DELETE_INTER_LAB_PROGRAM_RESET:
			return {
				...state,
				deleteinterLabProgram: null,
				deleteinterLabProgramError: null,
				deleteinterLabProgramLoading: false,
			};

		//update
		case types.UPDATE_INTER_LAB_PROGRAM_START:
			return {
				...state,
				updateinterLabProgram: null,
				updateinterLabProgramError: null,
				updateinterLabProgramLoading: true,
			};
		case types.UPDATE_INTER_LAB_PROGRAM_SUCCESS:
			return {
				...state,
				updateinterLabProgram: action.payload,
				updateinterLabProgramLoading: false,
			};
		case types.UPDATE_INTER_LAB_PROGRAM_ERROR:
			return {
				...state,
				updateinterLabProgramError: action.payload,
				updateinterLabProgramLoading: false,
			};
		case types.UPDATE_INTER_LAB_PROGRAM_RESET:
			return {
				...state,
				updateinterLabProgram: null,
				updateinterLabProgramError: null,
				updateinterLabProgramLoading: false,
			};

		default:
			return { ...state };
	}
};

export default InterLabProgramReducer;
