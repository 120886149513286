import types from "./types";

const INITIAL_STATE = {
	generalInventory: null,
	generalInventoryError: null,
	generalInventoryLoading: false,

	//delete
	deletegeneralInventory: null,
	deletegeneralInventoryError: null,
	deletegeneralInventoryLoading: false,

	//single item
};

const GeneralInventoryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_GENERAL_INVENTORY_START:
			return {
				...state,
				generalInventory: null,
				generalInventoryError: null,
				generalInventoryLoading: true,
			};
		case types.GET_GENERAL_INVENTORY_SUCCESS:
			return {
				...state,
				generalInventory: action.payload,
				generalInventoryLoading: false,
			};
		case types.GET_GENERAL_INVENTORY_ERROR:
			return {
				...state,
				generalInventoryError: action.payload,
				generalInventoryLoading: false,
			};
		case types.GET_GENERAL_INVENTORY_RESET:
			return {
				...state,
				generalInventoryError: null,
				generalInventoryLoading: false,
			};

		//delete

		case types.DELETE_GENERAL_INVENTORY_START:
			return {
				...state,
				deletegeneralInventory: null,
				deletegeneralInventoryError: null,
				deletegeneralInventoryLoading: true,
			};
		case types.DELETE_GENERAL_INVENTORY_SUCCESS:
			return {
				...state,
				deletegeneralInventory: action.payload,
				deletegeneralInventoryLoading: false,
			};
		case types.DELETE_GENERAL_INVENTORY_ERROR:
			return {
				...state,
				deletegeneralInventoryError: action.payload,
				deletegeneralInventoryLoading: false,
			};
		case types.DELETE_GENERAL_INVENTORY_RESET:
			return {
				...state,
				deletegeneralInventoryError: null,
				deletegeneralInventoryLoading: false,
			};

		//update

		default:
			return { ...state };
	}
};

export default GeneralInventoryReducer;
