import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getsamplelistHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "samplelist", ...action.payload }),
		);
		yield put({
			type: types.GET_SAMPLE_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_SAMPLE_LIST_ERROR,
			payload,
		});
	}
}

export function* getsamplelistWatcherSaga() {
	yield takeLatest(types.GET_SAMPLE_LIST_START, getsamplelistHandlerSaga);
}

//create

/* 

function* createSampleListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call("url", "endpoint");
		yield put({
			type: types.CREATE_SAMPLE_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_SAMPLE_LIST_ERROR,
			payload,
		});
	}
}

export function* createSampleListWatcherSaga() {
	yield  takeLatest(types.CREATE_SAMPLE_LIST_START, createSampleListHandlerSaga);
}

*/

//delete

function* deleteSampleListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			`samplelist/${action.payload.id}`,
			{disposed:action.payload.disposed}
		);
		yield put({
			type: types.DELETE_SAMPLE_LIST_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_SAMPLE_LIST_ERROR,
			payload,
		});
	}
}

export function* deleteSampleListWatcherSaga() {
	yield takeLatest(types.DELETE_SAMPLE_LIST_START, deleteSampleListHandlerSaga);
}

//update
/*function* updateSampleListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call("url", "endpoint");
		yield put({
			type: types.UPDATE_SAMPLE_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_SAMPLE_LIST_ERROR,
			payload,
		});
	}
}

export function* updateSampleListWatcherSaga() {
	yield  takeLatest(types.UPDATE_SAMPLE_LIST_START, updateSampleListHandlerSaga);
}*/
