import types from "./types";

const INITIAL_STATE = {
	userProfile: null,
	userProfileError: null,
	userProfileLoading: false,

	//delete
	deleteuserProfile: null,
	deleteuserProfileError: null,
	deleteuserProfileLoading: false,

	//single item
};

const userProfileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_USER_PROFILE_START:
			return {
				...state,
				userProfile: null,
				userProfileError: null,
				userProfileLoading: true,
			};
		case types.GET_USER_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: action.payload,
				userProfileLoading: false,
			};
		case types.GET_USER_PROFILE_ERROR:
			return {
				...state,
				userProfileError: action.payload,
				userProfileLoading: false,
			};
		case types.GET_USER_PROFILE_RESET:
			return {
				...state,
				userProfileError: null,
				userProfileLoading: false,
			};

		//delete

		case types.DELETE_USER_PROFILE_START:
			return {
				...state,
				deleteuserProfile: null,
				deleteuserProfileError: null,
				deleteuserProfileLoading: true,
			};
		case types.DELETE_USER_PROFILE_SUCCESS:
			return {
				...state,
				deleteuserProfile: action.payload,
				deleteuserProfileLoading: false,
			};
		case types.DELETE_USER_PROFILE_ERROR:
			return {
				...state,
				deleteuserProfileError: action.payload,
				deleteuserProfileLoading: false,
			};
		case types.DELETE_USER_PROFILE_RESET:
			return {
				...state,
				deleteuserProfileError: null,
				deleteuserProfileLoading: false,
			};

		//update

		default:
			return { ...state };
	}
};

export default userProfileReducer;
