import { all } from "redux-saga/effects";
import { getAdminWatcherSaga, getAdminOTPWatcherSaga } from "./Auth/sagas";
import {
	getExtrenalUserWatcherSaga,
	deleteExtrenalUserWatcherSaga,
} from "./ExternalUser/sagas";
import {
	getInternalUserWatcherSaga,
	deleteInternalUserWatcherSaga,
} from "./InternalUser/sagas";

import {
	getEmployeeWatcherSaga,
	createEmployeeWatcherSaga,
	deleteEmployeeWatcherSaga,
	updateEmployeeWatcherSaga,
} from "./Employee/sagas";

import {
	getCustomerWatcherSaga,
	// createCustomerWatcherSaga,
	deleteCustomerWatcherSaga,
	// updateCustomerWatcherSaga,
} from "./Customer/sagas";
import {
	getequipmentsWatcherSaga,
	deleteequipmentsWatcherSaga,
} from "./Equipment/sagas";
import {
	getFromBuilderWatcherSaga,
	createFromBuilderWatcherSaga,
	deleteFromBuilderWatcherSaga,
	updateFromBuilderWatcherSaga,
	getSingleFromBuilderWatcherSaga,
} from "./FromCreator/sagas";

import { getadminFormBuilderWatcherSaga } from "./AdminFormList/sagas";
import { getClientFormBuilderWatcherSaga } from "./ClientFormList/sagas";
import {
	getMethodWatcherSaga,
	deleteMethodWatcherSaga,
	getMethodFormWatcherSaga,
	updateMethodFormWatcherSaga,
} from "./Method/sagas";
import {
	getSampleNameWatcherSaga,
	// createSampleNameWatcherSaga,
	deleteSampleNameWatcherSaga,
	updateSampleNameWatcherSaga,
	// updateSampleNameWatcherSaga,
} from "./SampleName/sagas";

import {
	getsamplelistWatcherSaga,
	// createSampleNameWatcherSaga,
	deleteSampleListWatcherSaga,
	// updateSampleNameWatcherSaga,
} from "./SampleList/sagas";

import {
	getmenuWatcherSaga,
	getpagesWatcherSaga,
	createpagesWatcherSaga,
	deletepagesWatcherSaga,
	updatepagesWatcherSaga,
} from "./Pages/sagas";

import {
	getrolesandpermissionWatcherSaga,
	deleterolesandpermissionWatcherSaga,
} from "./Roles&permission/sagas";

import {
	getcustomerOrderWatcherSaga,
	deletecustomerOrderWatcherSaga,
} from "./CustomerOrder/sagas";

import {
	getservicesWatcherSaga,
	deleteservicesWatcherSaga,
} from "./service/sagas";

import {
	getworkOrderWatcherSaga,
	deleteworkOrderWatcherSaga,
} from "./WorkOrder/sagas";

import {
	getManageJobWatcherSaga,
	deleteManageJobWatcherSaga,
} from "./ManageJob/sagas";

import { getjobWatcherSaga, getMasterJobWatcherSaga } from "./Job/sagas";
import { getdataEntryWatcherSaga } from "./DataEntry/sagas";

import {
	getequipmetsServiceWatcherSaga,
	createequipmetsServiceWatcherSaga,
	deleteequipmetsServiceWatcherSaga,
	updateequipmetsServiceWatcherSaga,
} from "./EquipmentService/sagas";

import {
	getexpiredItemsWatcherSaga,
	deleteexpiredItemsWatcherSaga,
} from "./ExpiredItems/sagas";

import {
	getreagentsWatcherSaga,
	deletereagentsWatcherSaga,
} from "./ReagentsAndChemicals/sagas";

import {
	getstoreWatcherSaga,
	deletestoreWatcherSaga,
} from "./StoreReducer/sagas";

import { getreportsWatcherSaga } from "./Reports/sagas";

import {
	getgroupsWatcherSaga,
	getmanageGroupsWatcherSaga,
} from "./Groups/sagas";
import {
	getCalibrationWatcherSaga,
	deleteCalibrationWatcherSaga,
} from "./Calibration/sagas";
import {
	getMaintenanceWatcherSaga,
	deleteMaintenanceWatcherSaga,
} from "./Maintenance/sagas";

import {
	getsubscriberWatcherSaga,
	deletesubscriberWatcherSaga,
} from "./subscriber/sagas";

import {
	getsubscriptionplanWatcherSaga,
	deletesubscriptionplanWatcherSaga,
} from "./subscriptionPlan/sagas";

import {
	getCmsPageListWatcherSaga,
	deleteCmsPageListWatcherSaga,
} from "./PageList/sagas";

import {
	getcontentPageWatcherSaga,
	deletecontentPageWatcherSaga,
} from "./PageContent/sagas";

import {
	getsiteSettingWatcherSaga,
	deletesiteSettingWatcherSaga,
} from "./SiteSetting/sagas";

import {
	getgeneralInventoryWatcherSaga,
	deletegeneralInventoryWatcherSaga,
} from "./GeneralInventory/sagas";

import {
	getuserProfileWatcherSaga,
	deleteuserProfileWatcherSaga,
} from "./UserProfile/sagas";

import { getEmployeeFormBuilderWatcherSaga } from "./EmployeeFormList/sagas";
import {
	getEmployeeDetailsWatcherSaga,
	deleteEmployeeDetailsWatcherSaga,
} from "./EmployeeDetails/sagas";

import {
	getNonConfirmityReportWatcherSaga,
	deleteNonConfirmityReportWatcherSaga,
} from "./NonConformityReport/sagas";

import {
	getNonConfirmityReportTicketWatcherSaga,
	deleteNonConfirmityReportTicketWatcherSaga,
} from "./NonConformityTicketReview/sagas";

import { archieveBulkStartWatcherSaga } from "./Archieve/sagas";

import {
	getAccommodationWatcherSaga,
	deleteAccommodationWatcherSaga,
} from "./Accommodation/sagas";
import {
	getBuildingWatcherSaga,
	deleteBuildingWatcherSaga,
} from "./Building/sagas";

import {
	getClientDetailsWatcherSaga,
	deleteClientDetailsWatcherSaga,
} from "./ClientDetails/sagas";

import { getvendorWatcherSaga, deletevendorWatcherSaga } from "./vendor/sagas";
import { getVendorFormBuilderWatcherSaga } from "./VendorFormList/sagas";
import {
	getVendorDetailsWatcherSaga,
	deleteVendorDetailsWatcherSaga,
} from "./VendorDetails/sagas";

import {
	getapprovalListWatcherSaga,
	deleteapprovalListWatcherSaga,
} from "./Approvallist/sagas";

import {
	getvendorCategoriesWatcherSaga,
	deletevendorCategoriesWatcherSaga,
} from "./VendorCategories/sagas";

import {
	getvendorSubCategoriesWatcherSaga,
	deletevendorSubCategoriesWatcherSaga,
} from "./VendorSubCategories/sagas";

import {
	getdocumentsWatcherSaga,
	deletedocumentsWatcherSaga,
} from "./Document/sagas";

import {
	getDocumentCalibrationWatcherSaga,
	deleteDocumentCalibrationWatcherSaga,
} from "./DocumentCalibration/sagas";

import {
	getDocumentMaintenanceWatcherSaga,
	deleteDocumentMaintenanceWatcherSaga,
} from "./DocumentMaintenance/sagas";

import {
	getStandardDocumentWatcherSaga,
	deleteStandardDocumentWatcherSaga,
} from "./StandardDocument/sagas";

import {
	getGeneralDocumentWatcherSaga,
	deleteGeneralDocumentWatcherSaga,
} from "./GeneralDocument/sagas";

import {
	getCustomReportWatcherSaga,
	deleteCustomReportWatcherSaga,
} from "./CustomReports/sagas";
import {
	getQualityManagmentWatcherSaga,
	deleteQualityManagmentWatcherSaga,
} from "./QualityManagment/sagas";
import {
	getSubQualityManagmentWatcherSaga,
	deleteSubQualityManagmentWatcherSaga,
} from "./SubQualityManagment/sagas";
import {
	getQuality_ManualWatcherSaga,
	deleteQuality_ManualWatcherSaga,
} from "./QualityManual/sagas";

import {
	userActivityDetailsStartWatcherSaga,
	userActivityListStartWatcherSaga,
} from "./UserActivity/sagas";
import {
	getMasterNameListWatcherSaga,
	deleteMasterNameListWatcherSaga,
} from "./MasterNameList/sagas";

import {
	getDocumentListWatcherSaga,
	deleteDocumentListWatcherSaga,
} from "./ClientDocumentList/sagas";

import {
	getMethodPackagesWatcherSaga,
	deleteMethodPackagesWatcherSaga,
	getMethodPackagesFormWatcherSaga,
	updateMethodPackagesFormWatcherSaga,
} from "./MethodPackages/sagas";

import {
	getadminUserWatcherSaga,
	deleteadminUserWatcherSaga,
} from "./AdminUsers/sagas";

import {
	getmanagementReviewWatcherSaga,
	deletemanagementReviewWatcherSaga,
} from "./ManagementReview/sagas";

import {
	getinternalAuditWatcherSaga,
	deleteinternalAuditWatcherSaga,
} from "./InternalAudit/sagas";

import {
	gettraningWatcherSaga,
	deletetraningWatcherSaga,
} from "./Traning/sagas";

import {
	getplanTrainingWatcherSaga,
	deleteplanTrainingWatcherSaga,
} from "./PlanTraining/sagas";

import {
	getmanage_participantsWatcherSaga,
	deletemanage_participantsWatcherSaga,
} from "./ManageParticipants/sagas";

import {
	getintraLabProgramWatcherSaga,
	deleteintraLabProgramWatcherSaga,
} from "./IntraLaboratoryProgram/sagas";
import {
	deleteinterLabProgramWatcherSaga,
	getinterLabProgramWatcherSaga,
} from "./InterLaboratoryProgram/sagas";

import {
	getptProgramWatcherSaga,
	deleteptProgramWatcherSaga,
} from "./PTProgram/sagas";

export default function* root() {
	yield all([
		getAdminWatcherSaga(),
		getAdminOTPWatcherSaga(),
		//External User
		getExtrenalUserWatcherSaga(),
		deleteExtrenalUserWatcherSaga(),

		//internal User
		getInternalUserWatcherSaga(),
		// createInternalUserWatcherSaga(),
		deleteInternalUserWatcherSaga(),
		// updateInternalUserWatcherSaga(),
		//employee
		getEmployeeWatcherSaga(),
		createEmployeeWatcherSaga(),
		deleteEmployeeWatcherSaga(),
		updateEmployeeWatcherSaga(),
		//customer
		getCustomerWatcherSaga(),
		// createCustomerWatcherSaga(),
		deleteCustomerWatcherSaga(),
		// updateCustomerWatcherSaga(),
		//equipment
		getequipmentsWatcherSaga(),
		deleteequipmentsWatcherSaga(),

		//from builder
		getFromBuilderWatcherSaga(),
		createFromBuilderWatcherSaga(),
		deleteFromBuilderWatcherSaga(),
		updateFromBuilderWatcherSaga(),
		getSingleFromBuilderWatcherSaga(),
		//admin form
		getadminFormBuilderWatcherSaga(),
		getClientFormBuilderWatcherSaga(),

		//method
		getMethodWatcherSaga(),
		deleteMethodWatcherSaga(),
		getMethodFormWatcherSaga(),
		updateMethodFormWatcherSaga(),
		//sampleName
		getSampleNameWatcherSaga(),
		// createSampleNameWatcherSaga(),
		deleteSampleNameWatcherSaga(),
		// updateSampleNameWatcherSaga(),
		//pages
		getmenuWatcherSaga(),
		getpagesWatcherSaga(),
		createpagesWatcherSaga(),
		deletepagesWatcherSaga(),
		updatepagesWatcherSaga(),

		//samplelist

		getsamplelistWatcherSaga(),
		deleteSampleListWatcherSaga(),
		updateSampleNameWatcherSaga(),

		//roles and permisswion
		getrolesandpermissionWatcherSaga(),
		deleterolesandpermissionWatcherSaga(),
		//service
		getservicesWatcherSaga(),
		deleteservicesWatcherSaga(),

		//customer oreder
		getcustomerOrderWatcherSaga(),
		deletecustomerOrderWatcherSaga(),

		//workorder
		getworkOrderWatcherSaga(),
		deleteworkOrderWatcherSaga(),

		//mangejob
		getManageJobWatcherSaga(),
		deleteManageJobWatcherSaga(),

		getjobWatcherSaga(),
		getMasterJobWatcherSaga(),

		getdataEntryWatcherSaga(),

		//equipment service

		getequipmetsServiceWatcherSaga(),
		createequipmetsServiceWatcherSaga(),
		deleteequipmetsServiceWatcherSaga(),
		updateequipmetsServiceWatcherSaga(),

		//expired items
		getexpiredItemsWatcherSaga(),
		deleteexpiredItemsWatcherSaga(),
		//ReagentsAndChemicals
		getreagentsWatcherSaga(),
		deletereagentsWatcherSaga(),

		//store
		getstoreWatcherSaga(),
		deletestoreWatcherSaga(),

		//report
		getreportsWatcherSaga(),

		//groups
		getgroupsWatcherSaga(),
		getmanageGroupsWatcherSaga(),
		//calibration
		getCalibrationWatcherSaga(),
		deleteCalibrationWatcherSaga(),
		//calibration
		getMaintenanceWatcherSaga(),
		deleteMaintenanceWatcherSaga(),

		//  REPORT BUILDER RELATED WATCHER SAGAS
		//Subscriber
		getsubscriberWatcherSaga(),
		deletesubscriberWatcherSaga(),

		// SubscriptionPlan
		getsubscriptionplanWatcherSaga(),
		deletesubscriptionplanWatcherSaga(),

		//contentpage
		getcontentPageWatcherSaga(),
		deletecontentPageWatcherSaga(),

		//cmspagelist
		getCmsPageListWatcherSaga(),
		deleteCmsPageListWatcherSaga(),

		//site setting

		getsiteSettingWatcherSaga(),
		deletesiteSettingWatcherSaga(),
		//  REPORT BUILDER RELATED WATCHER SAGAS

		//general Inventory
		getgeneralInventoryWatcherSaga(),
		deletegeneralInventoryWatcherSaga(),

		//userProfile
		getuserProfileWatcherSaga(),
		deleteuserProfileWatcherSaga(),

		//employeeformlist
		getEmployeeFormBuilderWatcherSaga(),

		//employedetails
		getEmployeeDetailsWatcherSaga(),
		deleteEmployeeDetailsWatcherSaga(),

		//nonconformity
		getNonConfirmityReportWatcherSaga(),
		deleteNonConfirmityReportWatcherSaga(),

		//nonconformityreview
		getNonConfirmityReportTicketWatcherSaga(),
		deleteNonConfirmityReportTicketWatcherSaga(),

		// Archieve related sagas
		archieveBulkStartWatcherSaga(),

		//acodomation
		getAccommodationWatcherSaga(),
		deleteAccommodationWatcherSaga(),

		//building
		getBuildingWatcherSaga(),
		deleteBuildingWatcherSaga(),

		getClientDetailsWatcherSaga(),
		deleteClientDetailsWatcherSaga(),

		//vendor
		getvendorWatcherSaga(),
		deletevendorWatcherSaga(),

		//vendorformlist
		getVendorFormBuilderWatcherSaga(),
		//vendorDetails
		getVendorDetailsWatcherSaga(),
		deleteVendorDetailsWatcherSaga(),
		//aprovallist
		getapprovalListWatcherSaga(),
		deleteapprovalListWatcherSaga(),
		//vendor categories
		getvendorCategoriesWatcherSaga(),
		deletevendorCategoriesWatcherSaga(),
		getvendorSubCategoriesWatcherSaga(),
		deletevendorSubCategoriesWatcherSaga(),

		//document
		getdocumentsWatcherSaga(),
		deletedocumentsWatcherSaga(),

		//document calibration
		getDocumentCalibrationWatcherSaga(),
		deleteDocumentCalibrationWatcherSaga(),
		//document maintenance
		getDocumentMaintenanceWatcherSaga(),
		deleteDocumentMaintenanceWatcherSaga(),
		//customer report
		getCustomReportWatcherSaga(),
		deleteCustomReportWatcherSaga(),
		//quality managment
		getQualityManagmentWatcherSaga(),
		deleteQualityManagmentWatcherSaga(),
		//subquality
		getSubQualityManagmentWatcherSaga(),
		deleteSubQualityManagmentWatcherSaga(),

		//quality manual
		getQuality_ManualWatcherSaga(),
		deleteQuality_ManualWatcherSaga(),

		//User Activity
		userActivityDetailsStartWatcherSaga(),
		userActivityListStartWatcherSaga(),

		//Genaral Doc
		getStandardDocumentWatcherSaga(),
		deleteStandardDocumentWatcherSaga(),
		getGeneralDocumentWatcherSaga(),
		deleteGeneralDocumentWatcherSaga(),

		//master name list
		getMasterNameListWatcherSaga(),
		deleteMasterNameListWatcherSaga(),

		//client document list
		getDocumentListWatcherSaga(),
		deleteDocumentListWatcherSaga(),

		//Method Packages
		getMethodPackagesWatcherSaga(),
		deleteMethodPackagesWatcherSaga(),
		getMethodPackagesFormWatcherSaga(),
		updateMethodPackagesFormWatcherSaga(),
		//admin users
		getadminUserWatcherSaga(),
		deleteadminUserWatcherSaga(),
		//managment review
		getmanagementReviewWatcherSaga(),
		deletemanagementReviewWatcherSaga(),
		//internalAudit
		getinternalAuditWatcherSaga(),
		deleteinternalAuditWatcherSaga(),
		//traning
		gettraningWatcherSaga(),
		deletetraningWatcherSaga(),
		//planTraining
		getplanTrainingWatcherSaga(),
		deleteplanTrainingWatcherSaga(),
		//manage praticipants
		getmanage_participantsWatcherSaga(),
		deletemanage_participantsWatcherSaga(),

		//intra lab program

		getintraLabProgramWatcherSaga(),
		deleteintraLabProgramWatcherSaga(),

		//inter lab program
		getinterLabProgramWatcherSaga(),
		deleteinterLabProgramWatcherSaga(),

		getptProgramWatcherSaga(),
		deleteptProgramWatcherSaga(),
	]);
}
