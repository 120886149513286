import types from "./types";

const INITIAL_STATE = {
  rolesAndPermission: null,
  rolesAndPermissionError: null,
  rolesAndPermissionLoading: false,

  //create
  createRolesAndPermission: null,
  createRolesAndPermissionError: null,
  createRolesAndPermissionLoading: false,

  //update
  updateRolesAndPermission: null,
  updateRolesAndPermissionError: null,
  updateRolesAndPermissionLoading: false,

  //delete
  deleteRolesAndPermission: null,
  deleteRolesAndPermissionError: null,
  deleteRolesAndPermissionLoading: false,

  //single item

  singleItem: null,
};

const RoleAndPermissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ROLE_AND_PERMISSION_START:
      return {
        ...state,
        rolesAndPermission: null,
        rolesAndPermissionError: null,
        rolesAndPermissionLoading: true,
      };
    case types.GET_ROLE_AND_PERMISSION_SUCCESS:
      return {
        ...state,
        rolesAndPermission: action.payload,
        rolesAndPermissionLoading: false,
      };
    case types.GET_ROLE_AND_PERMISSION_ERROR:
      return {
        ...state,
        rolesAndPermissionError: action.payload,
        rolesAndPermissionLoading: false,
      };
    case types.GET_ROLE_AND_PERMISSION_RESET:
      return {
        ...state,
        rolesAndPermission: null,
        rolesAndPermissionError: null,
        rolesAndPermissionLoading: false,
      };
    //create

    case types.CREATE_ROLE_AND_PERMISSION_START:
      return {
        ...state,
        createRolesAndPermission: null,
        createRolesAndPermissionError: null,
        createRolesAndPermissionLoading: true,
      };
    case types.CREATE_ROLE_AND_PERMISSION_SUCCESS:
      return {
        ...state,
        createRolesAndPermission: action.payload,
        createRolesAndPermissionLoading: false,
      };
    case types.CREATE_ROLE_AND_PERMISSION_ERROR:
      return {
        ...state,
        createRolesAndPermissionError: action.payload,
        createRolesAndPermissionLoading: false,
      };
    case types.CREATE_ROLE_AND_PERMISSION_RESET:
      return {
        ...state,
        createRolesAndPermission: null,
        createRolesAndPermissionError: null,
        createRolesAndPermissionLoading: false,
      };
    //delete

    case types.DELETE_ROLE_AND_PERMISSION_START:
      return {
        ...state,
        deleteRolesAndPermission: null,
        deleteRolesAndPermissionError: null,
        deleteRolesAndPermissionLoading: true,
      };
    case types.DELETE_ROLE_AND_PERMISSION_SUCCESS:
      return {
        ...state,
        deleteRolesAndPermission: action.payload,
        deleteRolesAndPermissionLoading: false,
      };
    case types.DELETE_ROLE_AND_PERMISSION_ERROR:
      return {
        ...state,
        deleteRolesAndPermissionError: action.payload,
        deleteRolesAndPermissionLoading: false,
      };
    case types.DELETE_ROLE_AND_PERMISSION_RESET:
      return {
        ...state,
        deleteRolesAndPermission: null,
        deleteRolesAndPermissionError: null,
        deleteRolesAndPermissionLoading: false,
      };

    //update
    case types.UPDATE_ROLE_AND_PERMISSION_START:
      return {
        ...state,
        updateRolesAndPermission: null,
        updateRolesAndPermissionError: null,
        updateRolesAndPermissionLoading: true,
      };
    case types.UPDATE_ROLE_AND_PERMISSION_SUCCESS:
      return {
        ...state,
        updateRolesAndPermission: action.payload,
        updateRolesAndPermissionLoading: false,
      };
    case types.UPDATE_ROLE_AND_PERMISSION_ERROR:
      return {
        ...state,
        updateRolesAndPermissionError: action.payload,
        updateRolesAndPermissionLoading: false,
      };
    case types.UPDATE_ROLE_AND_PERMISSION_RESET:
      return {
        ...state,
        updateRolesAndPermission: null,
        updateRolesAndPermissionError: null,
        updateRolesAndPermissionLoading: false,
      };
    //singleItem
    // case types.SINGLE_ROLE_AND_PERMISSION_START:
    // 	return {
    // 		...state,
    // 		updateRolesAndPermission: null,
    // 		updateRolesAndPermissionError: null,
    // 		updateRolesAndPermissionLoading: true,
    // 	};
    // case types.SINGLE_ROLE_AND_PERMISSION_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateRolesAndPermission: action.payload,
    // 		updateRolesAndPermissionLoading: false,
    // 	};
    // case types.SINGLE_ROLE_AND_PERMISSION_ERROR:
    // 	return {
    // 		...state,
    // 		updateRolesAndPermissionError: action.payload,
    // 		updateRolesAndPermissionLoading: false,
    // 	};
    // case types.SINGLE_ROLE_AND_PERMISSION_RESET:
    // 	return {
    // 		...state,
    // 		updateRolesAndPermissionError: null,
    // 		updateRolesAndPermissionLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default RoleAndPermissionReducer;
