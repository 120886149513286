import types from "./types";

const INITIAL_STATE = {
	subscriptionplan: null,
	subscriptionplanError: null,
	subscriptionplanLoading: false,

	//create
	createsubscriptionplan: null,
	createsubscriptionplanError: null,
	createsubscriptionplanLoading: false,

	//update
	updatesubscriptionplan: null,
	updatesubscriptionplanError: null,
	updatesubscriptionplanLoading: false,

	//delete
	deletesubscriptionplan: null,
	deletesubscriptionplanError: null,
	deletesubscriptionplanLoading: false,

	//single item

	singleItem: null,
};

const subscriptionplanReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_SUBSCRIPTIONPLAN_START:
			return {
				...state,
				subscriptionplan: null,
				subscriptionplanError: null,
				subscriptionplanLoading: true,
			};
		case types.GET_SUBSCRIPTIONPLAN_SUCCESS:
			return {
				...state,
				subscriptionplan: action.payload,
				subscriptionplanLoading: false,
			};
		case types.GET_SUBSCRIPTIONPLAN_ERROR:
			return {
				...state,
				subscriptionplanError: action.payload,
				subscriptionplanLoading: false,
			};
		case types.GET_SUBSCRIPTIONPLAN_RESET:
			return {
				...state,
				subscriptionplanError: null,
				subscriptionplanLoading: false,
			};
		//create

		case types.CREATE_SUBSCRIPTIONPLAN_START:
			return {
				...state,
				createsubscriptionplan: null,
				createsubscriptionplanError: null,
				createsubscriptionplanLoading: true,
			};
		case types.CREATE_SUBSCRIPTIONPLAN_SUCCESS:
			return {
				...state,
				createsubscriptionplan: action.payload,
				createsubscriptionplanLoading: false,
			};
		case types.CREATE_SUBSCRIPTIONPLAN_ERROR:
			return {
				...state,
				createsubscriptionplanError: action.payload,
				createsubscriptionplanLoading: false,
			};
		case types.CREATE_SUBSCRIPTIONPLAN_RESET:
			return {
				...state,
				createsubscriptionplanError: null,
				createsubscriptionplanLoading: false,
			};
		//delete

		case types.DELETE_SUBSCRIPTIONPLAN_START:
			return {
				...state,
				deletesubscriptionplan: null,
				deletesubscriptionplanError: null,
				deletesubscriptionplanLoading: true,
			};
		case types.DELETE_SUBSCRIPTIONPLAN_SUCCESS:
			return {
				...state,
				deletesubscriptionplan: action.payload,
				deletesubscriptionplanLoading: false,
			};
		case types.DELETE_SUBSCRIPTIONPLAN_ERROR:
			return {
				...state,
				deletesubscriptionplanError: action.payload,
				deletesubscriptionplanLoading: false,
			};
		case types.DELETE_SUBSCRIPTIONPLAN_RESET:
			return {
				...state,
				deletesubscriptionplan: null,
				deletesubscriptionplanError: null,
				deletesubscriptionplanLoading: false,
			};

		//update
		case types.UPDATE_SUBSCRIPTIONPLAN_START:
			return {
				...state,
				updatesubscriptionplan: null,
				updatesubscriptionplanError: null,
				updatesubscriptionplanLoading: true,
			};
		case types.UPDATE_SUBSCRIPTIONPLAN_SUCCESS:
			return {
				...state,
				updatesubscriptionplan: action.payload,
				updatesubscriptionplanLoading: false,
			};
		case types.UPDATE_SUBSCRIPTIONPLAN_ERROR:
			return {
				...state,
				updatesubscriptionplanError: action.payload,
				updatesubscriptionplanLoading: false,
			};
		case types.UPDATE_SUBSCRIPTIONPLAN_RESET:
			return {
				...state,
				updatesubscriptionplan: null,
				updatesubscriptionplanError: null,
				updatesubscriptionplanLoading: false,
			};
		//singleItem
		// case types.SINGLE_SUBSCRIPTIONPLAN_START:
		// 	return {
		// 		...state,
		// 		updatesubscriptionplan: null,
		// 		updatesubscriptionplanError: null,
		// 		updatesubscriptionplanLoading: true,
		// 	};
		// case types.SINGLE_SUBSCRIPTIONPLAN_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatesubscriptionplan: action.payload,
		// 		updatesubscriptionplanLoading: false,
		// 	};
		// case types.SINGLE_SUBSCRIPTIONPLAN_ERROR:
		// 	return {
		// 		...state,
		// 		updatesubscriptionplanError: action.payload,
		// 		updatesubscriptionplanLoading: false,
		// 	};
		// case types.SINGLE_SUBSCRIPTIONPLAN_RESET:
		// 	return {
		// 		...state,
		// 		updatesubscriptionplanError: null,
		// 		updatesubscriptionplanLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default subscriptionplanReducer;
