/**
 *
 * @param {String} name
 * @returns String
 * NOTE! dont interchage the index of names and parsenames they are corrspond to each other
 */

const nameToPath = {
	sampleinventory: "Sample Registration",

	accommodation: "Accommodation",
	adminformslist: "Admin Forms List",
	approvallist: "Approvals",
	categorymanagement: "Categories",
	chainofcustody: "Custodies",
	clientdocument: "Client",
	clientformlist: "Client Form List",
	clientorders: "Client Orders",
	customreports: "Custom Report",
	dataentery: "Data Entries",
	dataentries: "Approvals",
	documents: "Laboratory",
	employeeformlist: "Employee Form List",
	equipments: "Equipment",
	expireditems: "Expiries",
	externalusers: "External Users",
	formbuilder: "Form Builder",
	formslist: "Form Lists",
	generalinventory: "G.Inventories",
	groups: "Authorisations",
	identification: "IDs",
	internalaudit: "Internal Audit",
	internalusers: "Internal Users",
	interlaboratoryprogram: "Inter Laboratory Program",
	intralaboratoryprogram: "Intra Laboratory Program",
	job: "My Jobs",
	joblist: "Jobs List",
	listofdocument: "Quality Managment-List Of Document",
	managegroup: "Authorisations",
	managmentreview: "Management Review",
	masterlist: "Inventory",
	methodpackages: "Method Packages",
	methods: "Methods",
	methodview: "Method View",
	multipage: "Multi Page",
	nonconformityreport: "Non Conformity Report",
	plantraining: "Plan Training",
	qmsetting: "Quality Managment Setting",
	qualitymanagment: "QMS",
	reagentsandchemicals: "Reagents",
	reportcustom: "Custom",
	reports: "Job",
	reporttemplates: "Report Templates",
	rolesandpermissions: "Roles And Permissions",
	samplelist: "Samples Inventory",
	samplesinventory: "Inventory",
	samplesname: "Samples",
	singlepage: "Single Page",
	sitesetting: "Corporate Profile",
	smtp: "SMTP Setting",
	subscriber: "Subscribers",
	subscriptionplan: "Subscription Plan",
	training: "Training",
	useractivity: "User Activity",
	usersetting: "Profile Setting",
	vendor: "List",
	vendrformlist: "Vendor Form List",
	workorders: "Work Orders",
	sampledispatch: "Sample Dispatch",
	samplereceiving: "Sample Receiving",
	sampleparameter: "Sample Parameter",
	samplerejected: "Sample Rejected",
	results:"Results",
	parametermaster:"Parameter Masters",
	sampleaccepted: "Sample Accepted",
	samplereceiving1:"Sample Receiving",
	sampleallotment:"Sample Allotment",
	resultapproval:"Result Approval",
	chemistdataentry: "Chemist Data Entry",
	unitofscale: "Unit Of Scale",
};

export const pathname = (name) => {
	return decodeURI(
		name === undefined ? "Name not available" : nameToPath[name] || name,
	);
};
