import types from "./types";

const INITIAL_STATE = {
  Equipments: null,
  EquipmentsError: null,
  EquipmentsLoading: false,

  //create
  createEquipments: null,
  createEquipmentsError: null,
  createEquipmentsLoading: false,

  //update
  updateEquipments: null,
  updateEquipmentsError: null,
  updateEquipmentsLoading: false,

  //delete
  deleteEquipments: null,
  deleteEquipmentsError: null,
  deleteEquipmentsLoading: false,

  //single item

  singleItem: null,
};

const EquipmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EQUIPMENTS_START:
      return {
        ...state,
        Equipments: null,
        EquipmentsError: null,
        EquipmentsLoading: true,
      };
    case types.GET_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        Equipments: action.payload,
        EquipmentsLoading: false,
      };
    case types.GET_EQUIPMENTS_ERROR:
      return {
        ...state,
        EquipmentsError: action.payload,
        EquipmentsLoading: false,
      };
    case types.GET_EQUIPMENTS_RESET:
      return {
        ...state,
        EquipmentsError: null,
        EquipmentsLoading: false,
      };
    //create

    case types.CREATE_EQUIPMENTS_START:
      return {
        ...state,
        createEquipments: null,
        createEquipmentsError: null,
        createEquipmentsLoading: true,
      };
    case types.CREATE_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        createEquipments: action.payload,
        createEquipmentsLoading: false,
      };
    case types.CREATE_EQUIPMENTS_ERROR:
      return {
        ...state,
        createEquipmentsError: action.payload,
        createEquipmentsLoading: false,
      };
    case types.CREATE_EQUIPMENTS_RESET:
      return {
        ...state,
        createEquipments: null,
        createEquipmentsError: null,
        createEquipmentsLoading: false,
      };
    //delete

    case types.DELETE_EQUIPMENTS_START:
      return {
        ...state,
        deleteEquipments: null,
        deleteEquipmentsError: null,
        deleteEquipmentsLoading: true,
      };
    case types.DELETE_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        deleteEquipments: action.payload,
        deleteEquipmentsLoading: false,
      };
    case types.DELETE_EQUIPMENTS_ERROR:
      return {
        ...state,
        deleteEquipmentsError: action.payload,
        deleteEquipmentsLoading: false,
      };
    case types.DELETE_EQUIPMENTS_RESET:
      return {
        ...state,
        deleteEquipments: null,
        deleteEquipmentsError: null,
        deleteEquipmentsLoading: false,
      };

    //update
    case types.UPDATE_EQUIPMENTS_START:
      return {
        ...state,
        updateEquipments: null,
        updateEquipmentsError: null,
        updateEquipmentsLoading: true,
      };
    case types.UPDATE_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        updateEquipments: action.payload,
        updateEquipmentsLoading: false,
      };
    case types.UPDATE_EQUIPMENTS_ERROR:
      return {
        ...state,
        updateEquipmentsError: action.payload,
        updateEquipmentsLoading: false,
      };
    case types.UPDATE_EQUIPMENTS_RESET:
      return {
        ...state,
        updateEquipments: null,
        updateEquipmentsError: null,
        updateEquipmentsLoading: false,
      };
    //singleItem
    // case types.SINGLE_EQUIPMENTS_START:
    // 	return {
    // 		...state,
    // 		updateEquipments: null,
    // 		updateEquipmentsError: null,
    // 		updateEquipmentsLoading: true,
    // 	};
    // case types.SINGLE_EQUIPMENTS_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateEquipments: action.payload,
    // 		updateEquipmentsLoading: false,
    // 	};
    // case types.SINGLE_EQUIPMENTS_ERROR:
    // 	return {
    // 		...state,
    // 		updateEquipmentsError: action.payload,
    // 		updateEquipmentsLoading: false,
    // 	};
    // case types.SINGLE_EQUIPMENTS_RESET:
    // 	return {
    // 		...state,
    // 		updateEquipmentsError: null,
    // 		updateEquipmentsLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default EquipmentsReducer;
