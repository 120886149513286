import types from "./types";

const INITIAL_STATE = {
	calibration: null,
	calibrationError: null,
	calibrationLoading: false,

	//create
	createcalibration: null,
	createcalibrationError: null,
	createcalibrationLoading: false,

	//update
	updatecalibration: null,
	updatecalibrationError: null,
	updatecalibrationLoading: false,

	//delete
	deletecalibration: null,
	deletecalibrationError: null,
	deletecalibrationLoading: false,

	//single item

	singleItem: null,
};

const StoreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_CALIBRATION_START:
			return {
				...state,
				calibration: null,
				calibrationError: null,
				calibrationLoading: true,
			};
		case types.GET_CALIBRATION_SUCCESS:
			return {
				...state,
				calibration: action.payload,
				calibrationLoading: false,
			};
		case types.GET_CALIBRATION_ERROR:
			return {
				...state,
				calibrationError: action.payload,
				calibrationLoading: false,
			};
		case types.GET_CALIBRATION_RESET:
			return {
				...state,
				calibrationError: null,
				calibrationLoading: false,
			};
		//create

		case types.CREATE_CALIBRATION_START:
			return {
				...state,
				createcalibration: null,
				createcalibrationError: null,
				createcalibrationLoading: true,
			};
		case types.CREATE_CALIBRATION_SUCCESS:
			return {
				...state,
				createcalibration: action.payload,
				createcalibrationLoading: false,
			};
		case types.CREATE_CALIBRATION_ERROR:
			return {
				...state,
				createcalibrationError: action.payload,
				createcalibrationLoading: false,
			};
		case types.CREATE_CALIBRATION_RESET:
			return {
				...state,
				createcalibrationError: null,
				createcalibrationLoading: false,
			};
		//delete

		case types.DELETE_CALIBRATION_START:
			return {
				...state,
				deletecalibration: null,
				deletecalibrationError: null,
				deletecalibrationLoading: true,
			};
		case types.DELETE_CALIBRATION_SUCCESS:
			return {
				...state,
				deletecalibration: action.payload,
				deletecalibrationLoading: false,
			};
		case types.DELETE_CALIBRATION_ERROR:
			return {
				...state,
				deletecalibrationError: action.payload,
				deletecalibrationLoading: false,
			};
		case types.DELETE_CALIBRATION_RESET:
			return {
				...state,
				deletecalibration: null,
				deletecalibrationError: null,
				deletecalibrationLoading: false,
			};

		//update
		case types.UPDATE_CALIBRATION_START:
			return {
				...state,
				updatecalibration: null,
				updatecalibrationError: null,
				updatecalibrationLoading: true,
			};
		case types.UPDATE_CALIBRATION_SUCCESS:
			return {
				...state,
				updatecalibration: action.payload,
				updatecalibrationLoading: false,
			};
		case types.UPDATE_CALIBRATION_ERROR:
			return {
				...state,
				updatecalibrationError: action.payload,
				updatecalibrationLoading: false,
			};
		case types.UPDATE_CALIBRATION_RESET:
			return {
				...state,
				updatecalibration: null,
				updatecalibrationError: null,
				updatecalibrationLoading: false,
			};
		//singleItem
		// case types.SINGLE_CALIBRATION_START:
		// 	return {
		// 		...state,
		// 		updatecalibration: null,
		// 		updatecalibrationError: null,
		// 		updatecalibrationLoading: true,
		// 	};
		// case types.SINGLE_CALIBRATION_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatecalibration: action.payload,
		// 		updatecalibrationLoading: false,
		// 	};
		// case types.SINGLE_CALIBRATION_ERROR:
		// 	return {
		// 		...state,
		// 		updatecalibrationError: action.payload,
		// 		updatecalibrationLoading: false,
		// 	};
		// case types.SINGLE_CALIBRATION_RESET:
		// 	return {
		// 		...state,
		// 		updatecalibrationError: null,
		// 		updatecalibrationLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default StoreReducer;
