import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

//get
function* getworkOrderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.dynamicList({ path: "workorder", ...action.payload }),
		);
		yield put({
			type: types.GET_WORK_ORDER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_WORK_ORDER_ERROR,
			payload,
		});
	}
}

export function* getworkOrderWatcherSaga() {
	yield takeLatest(types.GET_WORK_ORDER_START, getworkOrderHandlerSaga);
}

//create

// function* createworkOrderHandlerSaga(action) {
//   try {
//     const { data } = yield call("url", "endpoint");
//     yield put({
//       type: types.CREATE_WORK_ORDER_SUCCESS,
//       payload: data.response,
//     });
//   } catch (e) {
//     const payload = e?.response?.data || e;
//     yield put({
//       type: types.CREATE_WORK_ORDER_ERROR,
//       payload,
//     });
//   }
// }

// export function* createworkOrderWatcherSaga() {
//   yield  takeLatest(types.CREATE_WORK_ORDER_START, createworkOrderHandlerSaga);
// }

//delete

function* deleteworkOrderHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.dynamicList({ path: "workorder", ...action.payload }),
		);
		yield put({
			type: types.DELETE_WORK_ORDER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_WORK_ORDER_ERROR,
			payload,
		});
	}
}

export function* deleteworkOrderWatcherSaga() {
	yield takeLatest(types.DELETE_WORK_ORDER_START, deleteworkOrderHandlerSaga);
}

//update

// function* updateworkOrderHandlerSaga(action) {
//   try {
//     const { data } = yield call("url", "endpoint");
//     yield put({
//       type: types.UPDATE_WORK_ORDER_SUCCESS,
//       payload: data.response,
//     });
//   } catch (e) {
//     const payload = e?.response?.data || e;
//     yield put({
//       type: types.UPDATE_WORK_ORDER_ERROR,
//       payload,
//     });
//   }
// }

// export function* updateworkOrderWatcherSaga() {
//   yield  takeLatest(types.UPDATE_WORK_ORDER_START, updateworkOrderHandlerSaga);
// }
