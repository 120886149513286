import types from "./types";

const INITIAL_STATE = {
	SampleName: null,
	SampleNameError: null,
	SampleNameLoading: false,

	//create
	createSampleName: null,
	createSampleNameError: null,
	createSampleNameLoading: false,

	//update
	updateSampleName: null,
	updateSampleNameError: null,
	updateSampleNameLoading: false,

	//delete
	deleteSampleName: null,
	deleteSampleNameError: null,
	deleteSampleNameLoading: false,

	//single item

	singleItem: null,
};

const sampleReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_SAMPLE_NAME_START:
			return {
				...state,
				SampleName: null,
				SampleNameError: null,
				SampleNameLoading: true,
			};
		case types.GET_SAMPLE_NAME_SUCCESS:
			return {
				...state,
				SampleName: action.payload,
				SampleNameLoading: false,
			};
		case types.GET_SAMPLE_NAME_ERROR:
			return {
				...state,
				SampleNameError: action.payload,
				SampleNameLoading: false,
			};
		case types.GET_SAMPLE_NAME_RESET:
			return {
				...state,
				SampleNameError: null,
				SampleNameLoading: false,
			};
		//create

		case types.CREATE_SAMPLE_NAME_START:
			return {
				...state,
				createSampleName: null,
				createSampleNameError: null,
				createSampleNameLoading: true,
			};
		case types.CREATE_SAMPLE_NAME_SUCCESS:
			return {
				...state,
				createSampleName: action.payload,
				createSampleNameLoading: false,
			};
		case types.CREATE_SAMPLE_NAME_ERROR:
			return {
				...state,
				createSampleNameError: action.payload,
				createSampleNameLoading: false,
			};
		case types.CREATE_SAMPLE_NAME_RESET:
			return {
				...state,
				createSampleName: null,
				createSampleNameError: null,
				createSampleNameLoading: false,
			};
		//delete

		case types.DELETE_SAMPLE_NAME_START:
			return {
				...state,
				deleteSampleName: null,
				deleteSampleNameError: null,
				deleteSampleNameLoading: true,
			};
		case types.DELETE_SAMPLE_NAME_SUCCESS:
			return {
				...state,
				deleteSampleName: action.payload,
				deleteSampleNameLoading: false,
			};
		case types.DELETE_SAMPLE_NAME_ERROR:
			return {
				...state,
				deleteSampleNameError: action.payload,
				deleteSampleNameLoading: false,
			};
		case types.DELETE_SAMPLE_NAME_RESET:
			return {
				...state,
				deleteSampleName: null,
				deleteSampleNameError: null,
				deleteSampleNameLoading: false,
			};

		//update
		case types.UPDATE_SAMPLE_NAME_START:
			return {
				...state,
				updateSampleName: null,
				updateSampleNameError: null,
				updateSampleNameLoading: true,
			};
		case types.UPDATE_SAMPLE_NAME_SUCCESS:
			return {
				...state,
				updateSampleName: action.payload,
				updateSampleNameLoading: false,
			};
		case types.UPDATE_SAMPLE_NAME_ERROR:
			return {
				...state,
				updateSampleNameError: action.payload,
				updateSampleNameLoading: false,
			};
		case types.UPDATE_SAMPLE_NAME_RESET:
			return {
				...state,
				updateSampleName: null,
				updateSampleNameError: null,
				updateSampleNameLoading: false,
			};
		//singleItem
		// case types.SINGLE_SAMPLE_NAME_START:
		// 	return {
		// 		...state,
		// 		updateSampleName: null,
		// 		updateSampleNameError: null,
		// 		updateSampleNameLoading: true,
		// 	};
		// case types.SINGLE_SAMPLE_NAME_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateSampleName: action.payload,
		// 		updateSampleNameLoading: false,
		// 	};
		// case types.SINGLE_SAMPLE_NAME_ERROR:
		// 	return {
		// 		...state,
		// 		updateSampleNameError: action.payload,
		// 		updateSampleNameLoading: false,
		// 	};
		// case types.SINGLE_SAMPLE_NAME_RESET:
		// 	return {
		// 		...state,
		// 		updateSampleNameError: null,
		// 		updateSampleNameLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default sampleReducer;
