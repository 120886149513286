const types = {
	//Get Template name
	GET_CMS_PAGE_LIST_START: "GET_CMS_PAGE_LIST_START",
	GET_CMS_PAGE_LIST_SUCCESS: "GET_CMS_PAGE_LIST_SUCCESS",
	GET_CMS_PAGE_LIST_ERROR: "GET_CMS_PAGE_LIST_ERROR",
	GET_CMS_PAGE_LIST_RESET: "GET_CMS_PAGE_LIST_RESET",

	//Create Template name
	CREATE_CMS_PAGE_LIST_START: "CREATE_CMS_PAGE_LIST_START",
	CREATE_CMS_PAGE_LIST_SUCCESS: "CREATE_CMS_PAGE_LIST_SUCCESS",
	CREATE_CMS_PAGE_LIST_ERROR: "CREATE_CMS_PAGE_LIST_ERROR",
	CREATE_CMS_PAGE_LIST_RESET: "CREATE_CMS_PAGE_LIST_RESET",

	//delete Template name
	DELETE_CMS_PAGE_LIST_START: "DELETE_CMS_PAGE_LIST_START",
	DELETE_CMS_PAGE_LIST_SUCCESS: "DELETE_CMS_PAGE_LIST_SUCCESS",
	DELETE_CMS_PAGE_LIST_ERROR: "DELETE_CMS_PAGE_LIST_ERROR",
	DELETE_CMS_PAGE_LIST_RESET: "DELETE_CMS_PAGE_LIST_RESET",

	//update Template name
	UPDATE_CMS_PAGE_LIST_START: "UPDATE_CMS_PAGE_LIST_START",
	UPDATE_CMS_PAGE_LIST_SUCCESS: "UPDATE_CMS_PAGE_LIST_SUCCESS",
	UPDATE_CMS_PAGE_LIST_ERROR: "UPDATE_CMS_PAGE_LIST_ERROR",
	UPDATE_CMS_PAGE_LIST_RESET: "UPDATE_CMS_PAGE_LIST_RESET",

	//single item  name
	SINGLE_CMS_PAGE_LIST_START: "SINGLE_CMS_PAGE_LIST_START",
	SINGLE_CMS_PAGE_LIST_SUCCESS: "SINGLE_CMS_PAGE_LIST_SUCCESS",
	SINGLE_CMS_PAGE_LIST_ERROR: "SINGLE_CMS_PAGE_LIST_ERROR",
	SINGLE_CMS_PAGE_LIST_RESET: "SINGLE_CMS_PAGE_LIST_RESET",
};

export default types;
