import axios from "axios";

const userActivityAPIClientFactory = () => {
	const domain =
		sessionStorage.getItem("DomainDetails") !== null
			? sessionStorage.getItem("DomainDetails")
			: "";
	const apiClient = axios.create({
		baseURL: process.env.REACT_APP_GETWAY_NODE_API_BASE_URL,
		timeout: 30000,
		headers: {
			Authorization: `Bearer ${
				localStorage.getItem("userToken") || "no token"
			}`,
			domain: domain,
		},
	});
	return apiClient;
};

export default userActivityAPIClientFactory;
