import types from "./types";

const INITIAL_STATE = {
	ptProgram: null,
	ptProgramError: null,
	ptProgramLoading: false,

	//create
	createptProgram: null,
	createptProgramError: null,
	createptProgramLoading: false,

	//update
	updateptProgram: null,
	updateptProgramError: null,
	updateptProgramLoading: false,

	//delete
	deleteptProgram: null,
	deleteptProgramError: null,
	deleteptProgramLoading: false,

	//single item

	singleItem: null,
};

const PtProgramReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_PT_PROGRAM_START:
			return {
				...state,
				ptProgram: null,
				ptProgramError: null,
				ptProgramLoading: true,
			};
		case types.GET_PT_PROGRAM_SUCCESS:
			return {
				...state,
				ptProgram: action.payload,
				ptProgramLoading: false,
			};
		case types.GET_PT_PROGRAM_ERROR:
			return {
				...state,
				ptProgramError: action.payload,
				ptProgramLoading: false,
			};
		case types.GET_PT_PROGRAM_RESET:
			return {
				...state,
				ptProgramError: null,
				ptProgramLoading: false,
			};
		//create

		case types.CREATE_PT_PROGRAM_START:
			return {
				...state,
				createptProgram: null,
				createptProgramError: null,
				createptProgramLoading: true,
			};
		case types.CREATE_PT_PROGRAM_SUCCESS:
			return {
				...state,
				createptProgram: action.payload,
				createptProgramLoading: false,
			};
		case types.CREATE_PT_PROGRAM_ERROR:
			return {
				...state,
				createptProgramError: action.payload,
				createptProgramLoading: false,
			};
		case types.CREATE_PT_PROGRAM_RESET:
			return {
				...state,
				createptProgramError: null,
				createptProgramLoading: false,
			};
		//delete

		case types.DELETE_PT_PROGRAM_START:
			return {
				...state,
				deleteptProgram: null,
				deleteptProgramError: null,
				deleteptProgramLoading: true,
			};
		case types.DELETE_PT_PROGRAM_SUCCESS:
			return {
				...state,
				deleteptProgram: action.payload,
				deleteptProgramLoading: false,
			};
		case types.DELETE_PT_PROGRAM_ERROR:
			return {
				...state,
				deleteptProgramError: action.payload,
				deleteptProgramLoading: false,
			};
		case types.DELETE_PT_PROGRAM_RESET:
			return {
				...state,
				deleteptProgram: null,
				deleteptProgramError: null,
				deleteptProgramLoading: false,
			};

		//update
		case types.UPDATE_PT_PROGRAM_START:
			return {
				...state,
				updateptProgram: null,
				updateptProgramError: null,
				updateptProgramLoading: true,
			};
		case types.UPDATE_PT_PROGRAM_SUCCESS:
			return {
				...state,
				updateptProgram: action.payload,
				updateptProgramLoading: false,
			};
		case types.UPDATE_PT_PROGRAM_ERROR:
			return {
				...state,
				updateptProgramError: action.payload,
				updateptProgramLoading: false,
			};
		case types.UPDATE_PT_PROGRAM_RESET:
			return {
				...state,
				updateptProgram: null,
				updateptProgramError: null,
				updateptProgramLoading: false,
			};

		default:
			return { ...state };
	}
};

export default PtProgramReducer;
