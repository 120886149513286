import types from "./types";

const INITIAL_STATE = {
	approvalList: null,
	approvalListError: null,
	approvalListLoading: false,

	//create
	createapprovalList: null,
	createapprovalListError: null,
	createapprovalListLoading: false,

	//update
	updateapprovalList: null,
	updateapprovalListError: null,
	updateapprovalListLoading: false,

	//delete
	deleteapprovalList: null,
	deleteapprovalListError: null,
	deleteapprovalListLoading: false,

	//single item

	singleItem: null,
};

const ApprovalListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_APPROVAL_LIST_START:
			return {
				...state,
				approvalList: null,
				approvalListError: null,
				approvalListLoading: true,
			};
		case types.GET_APPROVAL_LIST_SUCCESS:
			return {
				...state,
				approvalList: action.payload,
				approvalListLoading: false,
			};
		case types.GET_APPROVAL_LIST_ERROR:
			return {
				...state,
				approvalListError: action.payload,
				approvalListLoading: false,
			};
		case types.GET_APPROVAL_LIST_RESET:
			return {
				...state,
				approvalListError: null,
				approvalListLoading: false,
			};
		//create

		case types.CREATE_APPROVAL_LIST_START:
			return {
				...state,
				createapprovalList: null,
				createapprovalListError: null,
				createapprovalListLoading: true,
			};
		case types.CREATE_APPROVAL_LIST_SUCCESS:
			return {
				...state,
				createapprovalList: action.payload,
				createapprovalListLoading: false,
			};
		case types.CREATE_APPROVAL_LIST_ERROR:
			return {
				...state,
				createapprovalListError: action.payload,
				createapprovalListLoading: false,
			};
		case types.CREATE_APPROVAL_LIST_RESET:
			return {
				...state,
				createapprovalListError: null,
				createapprovalListLoading: false,
			};
		//delete

		case types.DELETE_APPROVAL_LIST_START:
			return {
				...state,
				deleteapprovalList: null,
				deleteapprovalListError: null,
				deleteapprovalListLoading: true,
			};
		case types.DELETE_APPROVAL_LIST_SUCCESS:
			return {
				...state,
				deleteapprovalList: action.payload,
				deleteapprovalListLoading: false,
			};
		case types.DELETE_APPROVAL_LIST_ERROR:
			return {
				...state,
				deleteapprovalListError: action.payload,
				deleteapprovalListLoading: false,
			};
		case types.DELETE_APPROVAL_LIST_RESET:
			return {
				...state,
				deleteapprovalList: null,
				deleteapprovalListError: null,
				deleteapprovalListLoading: false,
			};

		//update
		case types.UPDATE_APPROVAL_LIST_START:
			return {
				...state,
				updateapprovalList: null,
				updateapprovalListError: null,
				updateapprovalListLoading: true,
			};
		case types.UPDATE_APPROVAL_LIST_SUCCESS:
			return {
				...state,
				updateapprovalList: action.payload,
				updateapprovalListLoading: false,
			};
		case types.UPDATE_APPROVAL_LIST_ERROR:
			return {
				...state,
				updateapprovalListError: action.payload,
				updateapprovalListLoading: false,
			};
		case types.UPDATE_APPROVAL_LIST_RESET:
			return {
				...state,
				updateapprovalList: null,
				updateapprovalListError: null,
				updateapprovalListLoading: false,
			};
		//singleItem
		// case types.SINGLE_APPROVAL_LIST_START:
		// 	return {
		// 		...state,
		// 		updateapprovalList: null,
		// 		updateapprovalListError: null,
		// 		updateapprovalListLoading: true,
		// 	};
		// case types.SINGLE_APPROVAL_LIST_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateapprovalList: action.payload,
		// 		updateapprovalListLoading: false,
		// 	};
		// case types.SINGLE_APPROVAL_LIST_ERROR:
		// 	return {
		// 		...state,
		// 		updateapprovalListError: action.payload,
		// 		updateapprovalListLoading: false,
		// 	};
		// case types.SINGLE_APPROVAL_LIST_RESET:
		// 	return {
		// 		...state,
		// 		updateapprovalListError: null,
		// 		updateapprovalListLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default ApprovalListReducer;
