import types from "./types";

const INITIAL_STATE = {
	planTraining: null,
	planTrainingError: null,
	planTrainingLoading: false,

	//create
	createplanTraining: null,
	createplanTrainingError: null,
	createplanTrainingLoading: false,

	//update
	updateplanTraining: null,
	updateplanTrainingError: null,
	updateplanTrainingLoading: false,

	//delete
	deleteplanTraining: null,
	deleteplanTrainingError: null,
	deleteplanTrainingLoading: false,

	//single item

	singleItem: null,
};

const PlanTraningReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_PLAN_TRAINING_START:
			return {
				...state,
				planTraining: null,
				planTrainingError: null,
				planTrainingLoading: true,
			};
		case types.GET_PLAN_TRAINING_SUCCESS:
			return {
				...state,
				planTraining: action.payload,
				planTrainingLoading: false,
			};
		case types.GET_PLAN_TRAINING_ERROR:
			return {
				...state,
				planTrainingError: action.payload,
				planTrainingLoading: false,
			};
		case types.GET_PLAN_TRAINING_RESET:
			return {
				...state,
				planTrainingError: null,
				planTrainingLoading: false,
			};
		//create

		case types.CREATE_PLAN_TRAINING_START:
			return {
				...state,
				createplanTraining: null,
				createplanTrainingError: null,
				createplanTrainingLoading: true,
			};
		case types.CREATE_PLAN_TRAINING_SUCCESS:
			return {
				...state,
				createplanTraining: action.payload,
				createplanTrainingLoading: false,
			};
		case types.CREATE_PLAN_TRAINING_ERROR:
			return {
				...state,
				createplanTrainingError: action.payload,
				createplanTrainingLoading: false,
			};
		case types.CREATE_PLAN_TRAINING_RESET:
			return {
				...state,
				createplanTrainingError: null,
				createplanTrainingLoading: false,
			};
		//delete

		case types.DELETE_PLAN_TRAINING_START:
			return {
				...state,
				deleteplanTraining: null,
				deleteplanTrainingError: null,
				deleteplanTrainingLoading: true,
			};
		case types.DELETE_PLAN_TRAINING_SUCCESS:
			return {
				...state,
				deleteplanTraining: action.payload,
				deleteplanTrainingLoading: false,
			};
		case types.DELETE_PLAN_TRAINING_ERROR:
			return {
				...state,
				deleteplanTrainingError: action.payload,
				deleteplanTrainingLoading: false,
			};
		case types.DELETE_PLAN_TRAINING_RESET:
			return {
				...state,
				deleteplanTraining: null,
				deleteplanTrainingError: null,
				deleteplanTrainingLoading: false,
			};

		//update
		case types.UPDATE_PLAN_TRAINING_START:
			return {
				...state,
				updateplanTraining: null,
				updateplanTrainingError: null,
				updateplanTrainingLoading: true,
			};
		case types.UPDATE_PLAN_TRAINING_SUCCESS:
			return {
				...state,
				updateplanTraining: action.payload,
				updateplanTrainingLoading: false,
			};
		case types.UPDATE_PLAN_TRAINING_ERROR:
			return {
				...state,
				updateplanTrainingError: action.payload,
				updateplanTrainingLoading: false,
			};
		case types.UPDATE_PLAN_TRAINING_RESET:
			return {
				...state,
				updateplanTraining: null,
				updateplanTrainingError: null,
				updateplanTrainingLoading: false,
			};

		default:
			return { ...state };
	}
};

export default PlanTraningReducer;
