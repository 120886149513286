import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endponits from "../../backend/endpoints";

/**
 *
 * @param {*} action
 * getting menu list
 */

function* getgroupsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endponits.dynamicList(action.payload),
		);
		yield put({
			type: types.GET_GROUPS_SUCCESS,
			payload: data.data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_GROUPS_ERROR,
			payload,
		});
	}
}

export function* getgroupsWatcherSaga() {
	yield takeLatest(types.GET_GROUPS_START, getgroupsHandlerSaga);
}

function* getmanageGroupsHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endponits.dynamicList(action.payload),
			action.payload.body,
		);
		yield put({
			type: types.GET_MANAGE_GROUP_SUCCESS,
			payload: data.result?.data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_MANAGE_GROUP_ERROR,
			payload,
		});
	}
}

export function* getmanageGroupsWatcherSaga() {
	yield takeLatest(types.GET_MANAGE_GROUP_START, getmanageGroupsHandlerSaga);
}
