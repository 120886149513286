const types = {
  //Get Template name
  GET_SITE_SETTING_START: "GET_SITE_SETTING_START",
  GET_SITE_SETTING_SUCCESS: "GET_SITE_SETTING_SUCCESS",
  GET_SITE_SETTING_ERROR: "GET_SITE_SETTING_ERROR",
  GET_SITE_SETTING_RESET: "GET_SITE_SETTING_RESET",

  //Create Template name
  CREATE_SITE_SETTING_START: "CREATE_SITE_SETTING_START",
  CREATE_SITE_SETTING_SUCCESS: "CREATE_SITE_SETTING_SUCCESS",
  CREATE_SITE_SETTING_ERROR: "CREATE_SITE_SETTING_ERROR",
  CREATE_SITE_SETTING_RESET: "CREATE_SITE_SETTING_RESET",

  //delete Template name
  DELETE_SITE_SETTING_START: "DELETE_SITE_SETTING_START",
  DELETE_SITE_SETTING_SUCCESS: "DELETE_SITE_SETTING_SUCCESS",
  DELETE_SITE_SETTING_ERROR: "DELETE_SITE_SETTING_ERROR",
  DELETE_SITE_SETTING_RESET: "DELETE_SITE_SETTING_RESET",

  //update Template name
  UPDATE_SITE_SETTING_START: "UPDATE_SITE_SETTING_START",
  UPDATE_SITE_SETTING_SUCCESS: "UPDATE_SITE_SETTING_SUCCESS",
  UPDATE_SITE_SETTING_ERROR: "UPDATE_SITE_SETTING_ERROR",
  UPDATE_SITE_SETTING_RESET: "UPDATE_SITE_SETTING_RESET",

  //single item  name
  SINGLE_SITE_SETTING_START: "SINGLE_SITE_SETTING_START",
  SINGLE_SITE_SETTING_SUCCESS: "SINGLE_SITE_SETTING_SUCCESS",
  SINGLE_SITE_SETTING_ERROR: "SINGLE_SITE_SETTING_ERROR",
  SINGLE_SITE_SETTING_RESET: "SINGLE_SITE_SETTING_RESET",
};

export default types;
