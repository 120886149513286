const types = {
  //Get Template name
  GET_METHOD_START: "GET_METHOD_START",
  GET_METHOD_SUCCESS: "GET_METHOD_SUCCESS",
  GET_METHOD_ERROR: "GET_METHOD_ERROR",
  GET_METHOD_RESET: "GET_METHOD_RESET",

  //Create Template name
  CREATE_METHOD_START: "CREATE_METHOD_START",
  CREATE_METHOD_SUCCESS: "CREATE_METHOD_SUCCESS",
  CREATE_METHOD_ERROR: "CREATE_METHOD_ERROR",
  CREATE_METHOD_RESET: "CREATE_METHOD_RESET",

  //delete Template name
  DELETE_METHOD_START: "DELETE_METHOD_START",
  DELETE_METHOD_SUCCESS: "DELETE_METHOD_SUCCESS",
  DELETE_METHOD_ERROR: "DELETE_METHOD_ERROR",
  DELETE_METHOD_RESET: "DELETE_METHOD_RESET",

  //update Template name
  UPDATE_METHOD_START: "UPDATE_METHOD_START",
  UPDATE_METHOD_SUCCESS: "UPDATE_METHOD_SUCCESS",
  UPDATE_METHOD_ERROR: "UPDATE_METHOD_ERROR",
  UPDATE_METHOD_RESET: "UPDATE_METHOD_RESET",

  //single item  name
  SINGLE_METHOD_START: "SINGLE_METHOD_START",
  SINGLE_METHOD_SUCCESS: "SINGLE_METHOD_SUCCESS",
  SINGLE_METHOD_ERROR: "SINGLE_METHOD_ERROR",
  SINGLE_METHOD_RESET: "SINGLE_METHOD_RESET",

  //single item  name
  METHOD_FORM_START: "METHOD_FORM_START",
  METHOD_FORM_SUCCESS: "METHOD_FORM_SUCCESS",
  METHOD_FORM_ERROR: "METHOD_FORM_ERROR",
  METHOD_FORM_RESET: "METHOD_FORM_RESET",

  METHOD_FORM_EDIT_START: "METHOD_FORM_EDIT_START",
  METHOD_FORM_EDIT_SUCCESS: "METHOD_FORM_EDIT_SUCCESS",
  METHOD_FORM_EDIT_ERROR: "METHOD_FORM_EDIT_ERROR",
  METHOD_FORM_EDIT_RESET: "METHOD_FORM_EDIT_RESET",
};

export default types;
