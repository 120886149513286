import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getEmployeeHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "employee", ...action.payload }),
		);
		yield put({
			type: types.GET_EMPLOYEE_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_EMPLOYEE_ERROR,
			payload,
		});
	}
}

export function* getEmployeeWatcherSaga() {
	yield takeLatest(types.GET_EMPLOYEE_START, getEmployeeHandlerSaga);
}

//create

function* createEmployeeHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endPoint.addSample,
			action.payload,
		);
		yield put({
			type: types.CREATE_EMPLOYEE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_EMPLOYEE_ERROR,
			payload,
		});
	}
}

export function* createEmployeeWatcherSaga() {
	yield takeLatest(types.CREATE_EMPLOYEE_START, createEmployeeHandlerSaga);
}

//delete

function* deleteEmployeeHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("employee", action.payload),
		);
		yield put({
			type: types.DELETE_EMPLOYEE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_EMPLOYEE_ERROR,
			payload,
		});
	}
}

export function* deleteEmployeeWatcherSaga() {
	yield takeLatest(types.DELETE_EMPLOYEE_START, deleteEmployeeHandlerSaga);
}

//update

function* updateEmployeeHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endPoint.addSample,
			action.payload,
		);
		yield put({
			type: types.UPDATE_EMPLOYEE_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_EMPLOYEE_ERROR,
			payload,
		});
	}
}

export function* updateEmployeeWatcherSaga() {
	yield takeLatest(types.UPDATE_EMPLOYEE_START, updateEmployeeHandlerSaga);
}
