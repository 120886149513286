const types = {
	//Get Template name
	GET_TRANING_START: "GET_TRANING_START",
	GET_TRANING_SUCCESS: "GET_TRANING_SUCCESS",
	GET_TRANING_ERROR: "GET_TRANING_ERROR",
	GET_TRANING_RESET: "GET_TRANING_RESET",

	//Create Template name
	CREATE_TRANING_START: "CREATE_TRANING_START",
	CREATE_TRANING_SUCCESS: "CREATE_TRANING_SUCCESS",
	CREATE_TRANING_ERROR: "CREATE_TRANING_ERROR",
	CREATE_TRANING_RESET: "CREATE_TRANING_RESET",

	//delete Template name
	DELETE_TRANING_START: "DELETE_TRANING_START",
	DELETE_TRANING_SUCCESS: "DELETE_TRANING_SUCCESS",
	DELETE_TRANING_ERROR: "DELETE_TRANING_ERROR",
	DELETE_TRANING_RESET: "DELETE_TRANING_RESET",

	//update Template name
	UPDATE_TRANING_START: "UPDATE_TRANING_START",
	UPDATE_TRANING_SUCCESS: "UPDATE_TRANING_SUCCESS",
	UPDATE_TRANING_ERROR: "UPDATE_TRANING_ERROR",
	UPDATE_TRANING_RESET: "UPDATE_TRANING_RESET",

	//single item  name
	SINGLE_TRANING_START: "SINGLE_TRANING_START",
	SINGLE_TRANING_SUCCESS: "SINGLE_TRANING_SUCCESS",
	SINGLE_TRANING_ERROR: "SINGLE_TRANING_ERROR",
	SINGLE_TRANING_RESET: "SINGLE_TRANING_RESET",
};

export default types;
