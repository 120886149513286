import types from "./types";

const INITIAL_STATE = {
	manage_participants: null,
	manage_participantsError: null,
	manage_participantsLoading: false,

	//create
	createmanage_participants: null,
	createmanage_participantsError: null,
	createmanage_participantsLoading: false,

	//update
	updatemanage_participants: null,
	updatemanage_participantsError: null,
	updatemanage_participantsLoading: false,

	//delete
	deletemanage_participants: null,
	deletemanage_participantsError: null,
	deletemanage_participantsLoading: false,

	//single item

	singleItem: null,
};

const ManageParticipantsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_MANAGE_PARTICIPANTS_START:
			return {
				...state,
				manage_participants: null,
				manage_participantsError: null,
				manage_participantsLoading: true,
			};
		case types.GET_MANAGE_PARTICIPANTS_SUCCESS:
			return {
				...state,
				manage_participants: action.payload,
				manage_participantsLoading: false,
			};
		case types.GET_MANAGE_PARTICIPANTS_ERROR:
			return {
				...state,
				manage_participantsError: action.payload,
				manage_participantsLoading: false,
			};
		case types.GET_MANAGE_PARTICIPANTS_RESET:
			return {
				...state,
				manage_participantsError: null,
				manage_participantsLoading: false,
			};
		//create

		case types.CREATE_MANAGE_PARTICIPANTS_START:
			return {
				...state,
				createmanage_participants: null,
				createmanage_participantsError: null,
				createmanage_participantsLoading: true,
			};
		case types.CREATE_MANAGE_PARTICIPANTS_SUCCESS:
			return {
				...state,
				createmanage_participants: action.payload,
				createmanage_participantsLoading: false,
			};
		case types.CREATE_MANAGE_PARTICIPANTS_ERROR:
			return {
				...state,
				createmanage_participantsError: action.payload,
				createmanage_participantsLoading: false,
			};
		case types.CREATE_MANAGE_PARTICIPANTS_RESET:
			return {
				...state,
				createmanage_participantsError: null,
				createmanage_participantsLoading: false,
			};
		//delete

		case types.DELETE_MANAGE_PARTICIPANTS_START:
			return {
				...state,
				deletemanage_participants: null,
				deletemanage_participantsError: null,
				deletemanage_participantsLoading: true,
			};
		case types.DELETE_MANAGE_PARTICIPANTS_SUCCESS:
			return {
				...state,
				deletemanage_participants: action.payload,
				deletemanage_participantsLoading: false,
			};
		case types.DELETE_MANAGE_PARTICIPANTS_ERROR:
			return {
				...state,
				deletemanage_participantsError: action.payload,
				deletemanage_participantsLoading: false,
			};
		case types.DELETE_MANAGE_PARTICIPANTS_RESET:
			return {
				...state,
				deletemanage_participants: null,
				deletemanage_participantsError: null,
				deletemanage_participantsLoading: false,
			};

		//update
		case types.UPDATE_MANAGE_PARTICIPANTS_START:
			return {
				...state,
				updatemanage_participants: null,
				updatemanage_participantsError: null,
				updatemanage_participantsLoading: true,
			};
		case types.UPDATE_MANAGE_PARTICIPANTS_SUCCESS:
			return {
				...state,
				updatemanage_participants: action.payload,
				updatemanage_participantsLoading: false,
			};
		case types.UPDATE_MANAGE_PARTICIPANTS_ERROR:
			return {
				...state,
				updatemanage_participantsError: action.payload,
				updatemanage_participantsLoading: false,
			};
		case types.UPDATE_MANAGE_PARTICIPANTS_RESET:
			return {
				...state,
				updatemanage_participants: null,
				updatemanage_participantsError: null,
				updatemanage_participantsLoading: false,
			};

		default:
			return { ...state };
	}
};

export default ManageParticipantsReducer;
