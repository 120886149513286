import types from "./types";

const INITIAL_STATE = {
  customerOrder: null,
  customerOrderError: null,
  customerOrderLoading: false,

  //create
  createcustomerOrder: null,
  createcustomerOrderError: null,
  createcustomerOrderLoading: false,

  //update
  updatecustomerOrder: null,
  updatecustomerOrderError: null,
  updatecustomerOrderLoading: false,

  //delete
  deletecustomerOrder: null,
  deletecustomerOrderError: null,
  deletecustomerOrderLoading: false,

  //single item

  singleItem: null,
};

const CustomOrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_ORDER_START:
      return {
        ...state,
        customerOrder: null,
        customerOrderError: null,
        customerOrderLoading: true,
      };
    case types.GET_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        customerOrder: action.payload,
        customerOrderLoading: false,
      };
    case types.GET_CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        customerOrderError: action.payload,
        customerOrderLoading: false,
      };
    case types.GET_CUSTOMER_ORDER_RESET:
      return {
        ...state,
        customerOrderError: null,
        customerOrderLoading: false,
      };
    //create

    case types.CREATE_CUSTOMER_ORDER_START:
      return {
        ...state,
        createcustomerOrder: null,
        createcustomerOrderError: null,
        createcustomerOrderLoading: true,
      };
    case types.CREATE_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        createcustomerOrder: action.payload,
        createcustomerOrderLoading: false,
      };
    case types.CREATE_CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        createcustomerOrderError: action.payload,
        createcustomerOrderLoading: false,
      };
    case types.CREATE_CUSTOMER_ORDER_RESET:
      return {
        ...state,
        createcustomerOrderError: null,
        createcustomerOrderLoading: false,
      };
    //delete

    case types.DELETE_CUSTOMER_ORDER_START:
      return {
        ...state,
        deletecustomerOrder: null,
        deletecustomerOrderError: null,
        deletecustomerOrderLoading: true,
      };
    case types.DELETE_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        deletecustomerOrder: action.payload,
        deletecustomerOrderLoading: false,
      };
    case types.DELETE_CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        deletecustomerOrderError: action.payload,
        deletecustomerOrderLoading: false,
      };
    case types.DELETE_CUSTOMER_ORDER_RESET:
      return {
        ...state,
        deletecustomerOrder: null,
        deletecustomerOrderError: null,
        deletecustomerOrderLoading: false,
      };

    //update
    case types.UPDATE_CUSTOMER_ORDER_START:
      return {
        ...state,
        updatecustomerOrder: null,
        updatecustomerOrderError: null,
        updatecustomerOrderLoading: true,
      };
    case types.UPDATE_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        updatecustomerOrder: action.payload,
        updatecustomerOrderLoading: false,
      };
    case types.UPDATE_CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        updatecustomerOrderError: action.payload,
        updatecustomerOrderLoading: false,
      };
    case types.UPDATE_CUSTOMER_ORDER_RESET:
      return {
        ...state,
        updatecustomerOrder: null,
        updatecustomerOrderError: null,
        updatecustomerOrderLoading: false,
      };
    //singleItem
    // case types.SINGLE_CUSTOMER_ORDER_START:
    // 	return {
    // 		...state,
    // 		updatecustomerOrder: null,
    // 		updatecustomerOrderError: null,
    // 		updatecustomerOrderLoading: true,
    // 	};
    // case types.SINGLE_CUSTOMER_ORDER_SUCCESS:
    // 	return {
    // 		...state,
    // 		updatecustomerOrder: action.payload,
    // 		updatecustomerOrderLoading: false,
    // 	};
    // case types.SINGLE_CUSTOMER_ORDER_ERROR:
    // 	return {
    // 		...state,
    // 		updatecustomerOrderError: action.payload,
    // 		updatecustomerOrderLoading: false,
    // 	};
    // case types.SINGLE_CUSTOMER_ORDER_RESET:
    // 	return {
    // 		...state,
    // 		updatecustomerOrderError: null,
    // 		updatecustomerOrderLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default CustomOrderReducer;
