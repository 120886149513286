import types from "./types";

const INITIAL_STATE = {
	userActivityList: null,
  gettingUserActivityList: false,
  userActivityListError: null,


  userActivityDetails: null,
  loadingUserActivityDetails: false,
  userActivityDetailsError: null,

};

const userActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
		
		/**
		 * CASE RELATED TO USERACTIVITY DETAILS
		 * */
		case types.USERACTIVITY_DETAILS_START: {
			return {
				...state,
				userActivityDetails: null,
				loadingUserActivityDetails: true,
				templateDetailsError: null,
			};
		}
		case types.USERACTIVITY_DETAILS_SUCCESS: {
			return {
				...state,
				userActivityDetails: action.payload,
				loadingUserActivityDetails: false,
				templateDetailsError: null,
			};
		}
		case types.USERACTIVITY_DETAILS_ERROR: {
			return {
				...state,
				userActivityDetails: null,
				loadingUserActivityDetails: false,
				templateDetailsError: action.payload,
			};
		}
		case types.CLEAR_USERACTIVITY_DETAILS: {
			return {
				...state,
				userActivityDetails: null,
				loadingUserActivityDetails: false,
				templateDetailsError: null,
			};
		}

		

		/**
		 * CASE RELATED TO USERACTIVITY LIST
		 * */
		case types.USERACTIVITY_LIST_START: {
			return {
				...state,
				userActivityList: null,
				gettingUserActivityList: true,
				userActivityListError: null,
			};
		}
		case types.USERACTIVITY_LIST_SUCCESS: {
			return {
				...state,
				userActivityList: action.payload,
				gettingUserActivityList: false,
				userActivityListError: null,
			};
		}
		case types.USERACTIVITY_LIST_ERROR: {
			return {
				...state,
				userActivityList: null,
				gettingUserActivityList: false,
				userActivityListError: action.payload,
			};
		}


		default:
			return {
				...state,
			};
	}
};

export default userActivityReducer;
