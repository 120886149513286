const types = {
	//Get Template name
	GET_MAINTENANCE_START: "GET_MAINTENANCE_START",
	GET_MAINTENANCE_SUCCESS: "GET_MAINTENANCE_SUCCESS",
	GET_MAINTENANCE_ERROR: "GET_MAINTENANCE_ERROR",
	GET_MAINTENANCE_RESET: "GET_MAINTENANCE_RESET",

	//Create Template name
	CREATE_MAINTENANCE_START: "CREATE_MAINTENANCE_START",
	CREATE_MAINTENANCE_SUCCESS: "CREATE_MAINTENANCE_SUCCESS",
	CREATE_MAINTENANCE_ERROR: "CREATE_MAINTENANCE_ERROR",
	CREATE_MAINTENANCE_RESET: "CREATE_MAINTENANCE_RESET",

	//delete Template name
	DELETE_MAINTENANCE_START: "DELETE_MAINTENANCE_START",
	DELETE_MAINTENANCE_SUCCESS: "DELETE_MAINTENANCE_SUCCESS",
	DELETE_MAINTENANCE_ERROR: "DELETE_MAINTENANCE_ERROR",
	DELETE_MAINTENANCE_RESET: "DELETE_MAINTENANCE_RESET",

	//update Template name
	UPDATE_MAINTENANCE_START: "UPDATE_MAINTENANCE_START",
	UPDATE_MAINTENANCE_SUCCESS: "UPDATE_MAINTENANCE_SUCCESS",
	UPDATE_MAINTENANCE_ERROR: "UPDATE_MAINTENANCE_ERROR",
	UPDATE_MAINTENANCE_RESET: "UPDATE_MAINTENANCE_RESET",

	//single item  name
	SINGLE_MAINTENANCE_START: "SINGLE_MAINTENANCE_START",
	SINGLE_MAINTENANCE_SUCCESS: "SINGLE_MAINTENANCE_SUCCESS",
	SINGLE_MAINTENANCE_ERROR: "SINGLE_MAINTENANCE_ERROR",
	SINGLE_MAINTENANCE_RESET: "SINGLE_MAINTENANCE_RESET",
};

export default types;
