import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import createSagaMiddleware from "redux-saga";
import Sagas from "./Sagas";

import { rootReducer, whitelist } from "./rootReducer";

const persistConfig = {
	key: "lims_admin",
	storage,
	whitelist: whitelist,
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [sagaMiddleware],
	devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(Sagas);

export default store;
