import types from "./types";

const INITIAL_STATE = {
	adminFormBuilder: null,

	adminFormBuilderError: null,
	adminFormBuilderLoading: false,

	//select item

	selectItem: null,
};

const AdminFromReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_ADMINFORMLIST_START:
			return {
				...state,
				adminFormBuilder: null,
				adminFormBuilderError: null,
				adminFormBuilderLoading: true,
			};
		case types.GET_ADMINFORMLIST_SUCCESS:
			return {
				...state,
				adminFormBuilder: action.payload,
				adminFormBuilderLoading: false,
			};
		case types.GET_ADMINFORMLIST_ERROR:
			return {
				...state,
				adminFormBuilderError: action.payload,
				adminFormBuilderLoading: false,
			};
		case types.GET_ADMINFORMLIST_RESET:
			return {
				...state,
				adminFormBuilderError: null,
				adminFormBuilderLoading: false,
			};
		//create

		case types.SELECT_TOUPDATE_ITEM:
			return {
				...state,
				selectItem: action.payload,
			};

		default:
			return { ...state };
	}
};

export default AdminFromReducer;
