import { Form } from "@formio/react";
import useForms from "context/forms/useForms";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdRemoveRedEye } from "react-icons/md";
import parsedForm from "util/formParser";
import convertImageToBase64 from "util/convertToBase64";

export default function ViewForm({ data, formName }) {
	const [view, setView] = useState(false);
	const { getForm } = useForms();
	const form = parsedForm(getForm(formName));
	const [formData, setFormData] = useState({});

	useEffect(() => {
		const withImageHandler = async (data) => {
			for (const [key, value] of Object.entries(data)) {
				if (String(value).includes("amazonaws.com")) {
					const base64 = await convertImageToBase64(value);
					const alteredValue = { ...data, [key]: base64 };
					setFormData(alteredValue);
				} else {
					setFormData((prev) => ({ ...prev, [key]: value }));
				}
			}
		};
		withImageHandler(data);
	}, [data]);

	return (
		<>
			<Modal size='xl' show={view} onHide={() => setView(false)}>
				<Modal.Header closeButton>
					{" "}
					<p className='h4 m-0'>View </p>
				</Modal.Header>
				<Modal.Body>
					{" "}
					<Form
						src={form}
						options={{ readOnly: true }}
						submission={{ data: formData }}
					/>
				</Modal.Body>
			</Modal>
			<div>
				<MdRemoveRedEye
					size={"1.3em"}
					className='text-primary'
					role='button'
					onClick={() => setView(true)}
				/>
			</div>
		</>
	);
}
