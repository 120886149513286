import types from "./types";

const INITIAL_STATE = {
	documentmaintenance: null,
	documentmaintenanceError: null,
	documentmaintenanceLoading: false,

	//create
	createdocumentmaintenance: null,
	createdocumentmaintenanceError: null,
	createdocumentmaintenanceLoading: false,

	//update
	updatedocumentmaintenance: null,
	updatedocumentmaintenanceError: null,
	updatedocumentmaintenanceLoading: false,

	//delete
	deletedocumentmaintenance: null,
	deletedocumentmaintenanceError: null,
	deletedocumentmaintenanceLoading: false,

	//single item

	singleItem: null,
};

const StoreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_DOCUMENTMAINTENANCE_START:
			return {
				...state,
				documentmaintenance: null,
				documentmaintenanceError: null,
				documentmaintenanceLoading: true,
			};
		case types.GET_DOCUMENTMAINTENANCE_SUCCESS:
			return {
				...state,
				documentmaintenance: action.payload,
				documentmaintenanceLoading: false,
			};
		case types.GET_DOCUMENTMAINTENANCE_ERROR:
			return {
				...state,
				documentmaintenanceError: action.payload,
				documentmaintenanceLoading: false,
			};
		case types.GET_DOCUMENTMAINTENANCE_RESET:
			return {
				...state,
				documentmaintenanceError: null,
				documentmaintenanceLoading: false,
			};
		//create

		case types.CREATE_DOCUMENTMAINTENANCE_START:
			return {
				...state,
				createdocumentmaintenance: null,
				createdocumentmaintenanceError: null,
				createdocumentmaintenanceLoading: true,
			};
		case types.CREATE_DOCUMENTMAINTENANCE_SUCCESS:
			return {
				...state,
				createdocumentmaintenance: action.payload,
				createdocumentmaintenanceLoading: false,
			};
		case types.CREATE_DOCUMENTMAINTENANCE_ERROR:
			return {
				...state,
				createdocumentmaintenanceError: action.payload,
				createdocumentmaintenanceLoading: false,
			};
		case types.CREATE_DOCUMENTMAINTENANCE_RESET:
			return {
				...state,
				createdocumentmaintenanceError: null,
				createdocumentmaintenanceLoading: false,
			};
		//delete

		case types.DELETE_DOCUMENTMAINTENANCE_START:
			return {
				...state,
				deletedocumentmaintenance: null,
				deletedocumentmaintenanceError: null,
				deletedocumentmaintenanceLoading: true,
			};
		case types.DELETE_DOCUMENTMAINTENANCE_SUCCESS:
			return {
				...state,
				deletedocumentmaintenance: action.payload,
				deletedocumentmaintenanceLoading: false,
			};
		case types.DELETE_DOCUMENTMAINTENANCE_ERROR:
			return {
				...state,
				deletedocumentmaintenanceError: action.payload,
				deletedocumentmaintenanceLoading: false,
			};
		case types.DELETE_DOCUMENTMAINTENANCE_RESET:
			return {
				...state,
				deletedocumentmaintenance: null,
				deletedocumentmaintenanceError: null,
				deletedocumentmaintenanceLoading: false,
			};

		//update
		case types.UPDATE_DOCUMENTMAINTENANCE_START:
			return {
				...state,
				updatedocumentmaintenance: null,
				updatedocumentmaintenanceError: null,
				updatedocumentmaintenanceLoading: true,
			};
		case types.UPDATE_DOCUMENTMAINTENANCE_SUCCESS:
			return {
				...state,
				updatedocumentmaintenance: action.payload,
				updatedocumentmaintenanceLoading: false,
			};
		case types.UPDATE_DOCUMENTMAINTENANCE_ERROR:
			return {
				...state,
				updatedocumentmaintenanceError: action.payload,
				updatedocumentmaintenanceLoading: false,
			};
		case types.UPDATE_DOCUMENTMAINTENANCE_RESET:
			return {
				...state,
				updatedocumentmaintenance: null,
				updatedocumentmaintenanceError: null,
				updatedocumentmaintenanceLoading: false,
			};
		
		default:
			return { ...state };
	}
};

export default StoreReducer;
