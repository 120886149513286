import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getMethodPackagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "methodPackage", ...action.payload }),
		);
		yield put({
			type: types.GET_METHOD_PACKAGES_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_METHOD_PACKAGES_ERROR,
			payload,
		});
	}
}

export function* getMethodPackagesWatcherSaga() {
	yield takeLatest(types.GET_METHOD_PACKAGES_START, getMethodPackagesHandlerSaga);
}

//create

// function* createMethodPackagesHandlerSaga(action) {
// 	//  //console.log("test",action.payload);
// 	const apiClient = apiClientFactory();
// 	try {
// 		const { data } = yield call(
// 			apiClient.post,
// 			endPoint.fromAddData("methodPackages"),
// 			action.payload.body,
// 		);
// 		yield put({
// 			type: types.CREATE_METHOD_PACKAGES_SUCCESS,
// 			payload: data,
// 		});
// 	} catch (e) {
// 		const payload = e?.response?.data || e;
// 		yield put({
// 			type: types.CREATE_METHOD_PACKAGES_ERROR,
// 			payload,
// 		});
// 	}
// }

// export function* createMethodPackagesWatcherSaga() {
// 	yield  takeLatest(types.CREATE_METHOD_PACKAGES_START, createMethodPackagesHandlerSaga);
// }

//delete

function* deleteMethodPackagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("methodPackage", action.payload),
		);

		yield put({
			type: types.DELETE_METHOD_PACKAGES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_METHOD_PACKAGES_ERROR,
			payload,
		});
	}
}

export function* deleteMethodPackagesWatcherSaga() {
	yield takeLatest(types.DELETE_METHOD_PACKAGES_START, deleteMethodPackagesHandlerSaga);
}

//update

function* updateMethodPackagesHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endPoint.dynamicUpdateItem({
				pageId: action.payload.pageId,
				dataId: action.payload.dataId,
			}),
			action.payload.body,
		);

		yield put({
			type: types.UPDATE_METHOD_PACKAGES_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_METHOD_PACKAGES_ERROR,
			payload,
		});
	}
}

export function* updateMethodPackagesWatcherSaga() {
	yield takeLatest(types.UPDATE_METHOD_PACKAGES_START, updateMethodPackagesHandlerSaga);
}

//methodPackages form
function* updateMethodPackagesFormHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endPoint.putmethodPackagesForm,
			action.payload.body,
		);

		yield put({
			type: types.METHOD_PACKAGES_FORM_EDIT_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.METHOD_PACKAGES_FORM_EDIT_ERROR,
			payload,
		});
	}
}

export function* updateMethodPackagesFormWatcherSaga() {
	yield takeLatest(types.METHOD_PACKAGES_FORM_EDIT_START, updateMethodPackagesFormHandlerSaga);
}

function* getMethodPackagesFormHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.getmethodPackagesForm(action.payload),
			action.payload.body,
		);

		yield put({
			type: types.METHOD_PACKAGES_FORM_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.METHOD_PACKAGES_FORM_ERROR,
			payload,
		});
	}
}

export function* getMethodPackagesFormWatcherSaga() {
	yield takeLatest(types.METHOD_PACKAGES_FORM_START, getMethodPackagesFormHandlerSaga);
}
