const types = {
	//Get Template name
	GET_DOCUMENTMAINTENANCE_START: "GET_DOCUMENTMAINTENANCE_START",
	GET_DOCUMENTMAINTENANCE_SUCCESS: "GET_DOCUMENTMAINTENANCE_SUCCESS",
	GET_DOCUMENTMAINTENANCE_ERROR: "GET_DOCUMENTMAINTENANCE_ERROR",
	GET_DOCUMENTMAINTENANCE_RESET: "GET_DOCUMENTMAINTENANCE_RESET",

	//Create Template name
	CREATE_DOCUMENTMAINTENANCE_START: "CREATE_DOCUMENTMAINTENANCE_START",
	CREATE_DOCUMENTMAINTENANCE_SUCCESS: "CREATE_DOCUMENTMAINTENANCE_SUCCESS",
	CREATE_DOCUMENTMAINTENANCE_ERROR: "CREATE_DOCUMENTMAINTENANCE_ERROR",
	CREATE_DOCUMENTMAINTENANCE_RESET: "CREATE_DOCUMENTMAINTENANCE_RESET",

	//delete Template name
	DELETE_DOCUMENTMAINTENANCE_START: "DELETE_DOCUMENTMAINTENANCE_START",
	DELETE_DOCUMENTMAINTENANCE_SUCCESS: "DELETE_DOCUMENTMAINTENANCE_SUCCESS",
	DELETE_DOCUMENTMAINTENANCE_ERROR: "DELETE_DOCUMENTMAINTENANCE_ERROR",
	DELETE_DOCUMENTMAINTENANCE_RESET: "DELETE_DOCUMENTMAINTENANCE_RESET",

	//update Template name
	UPDATE_DOCUMENTMAINTENANCE_START: "UPDATE_DOCUMENTMAINTENANCE_START",
	UPDATE_DOCUMENTMAINTENANCE_SUCCESS: "UPDATE_DOCUMENTMAINTENANCE_SUCCESS",
	UPDATE_DOCUMENTMAINTENANCE_ERROR: "UPDATE_DOCUMENTMAINTENANCE_ERROR",
	UPDATE_DOCUMENTMAINTENANCE_RESET: "UPDATE_DOCUMENTMAINTENANCE_RESET",

	//single item  name
	SINGLE_DOCUMENTMAINTENANCE_START: "SINGLE_DOCUMENTMAINTENANCE_START",
	SINGLE_DOCUMENTMAINTENANCE_SUCCESS: "SINGLE_DOCUMENTMAINTENANCE_SUCCESS",
	SINGLE_DOCUMENTMAINTENANCE_ERROR: "SINGLE_DOCUMENTMAINTENANCE_ERROR",
	SINGLE_DOCUMENTMAINTENANCE_RESET: "SINGLE_DOCUMENTMAINTENANCE_RESET",
};

export default types;
