import types from "./types";

const INITIAL_STATE = {
	standarddocument: null,
	standarddocumentError: null,
	standarddocumentLoading: false,

	//create
	createstandarddocument: null,
	createstandarddocumentError: null,
	createstandarddocumentLoading: false,

	//update
	updatestandarddocument: null,
	updatestandarddocumentError: null,
	updatestandarddocumentLoading: false,

	//delete
	deletestandarddocument: null,
	deletestandarddocumentError: null,
	deletestandarddocumentLoading: false,

	//single item

	singleItem: null,
};

const StandardDocumentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_STANDARDDOCUMENT_START:
			return {
				...state,
				standarddocument: null,
				standarddocumentError: null,
				standarddocumentLoading: true,
			};
		case types.GET_STANDARDDOCUMENT_SUCCESS:
			return {
				...state,
				standarddocument: action.payload,
				standarddocumentLoading: false,
			};
		case types.GET_STANDARDDOCUMENT_ERROR:
			return {
				...state,
				standarddocumentError: action.payload,
				standarddocumentLoading: false,
			};
		case types.GET_STANDARDDOCUMENT_RESET:
			return {
				...state,
				standarddocumentError: null,
				standarddocumentLoading: false,
			};
		//create

		case types.CREATE_STANDARDDOCUMENT_START:
			return {
				...state,
				createstandarddocument: null,
				createstandarddocumentError: null,
				createstandarddocumentLoading: true,
			};
		case types.CREATE_STANDARDDOCUMENT_SUCCESS:
			return {
				...state,
				createstandarddocument: action.payload,
				createstandarddocumentLoading: false,
			};
		case types.CREATE_STANDARDDOCUMENT_ERROR:
			return {
				...state,
				createstandarddocumentError: action.payload,
				createstandarddocumentLoading: false,
			};
		case types.CREATE_STANDARDDOCUMENT_RESET:
			return {
				...state,
				createstandarddocumentError: null,
				createstandarddocumentLoading: false,
			};
		//delete

		case types.DELETE_STANDARDDOCUMENT_START:
			return {
				...state,
				deletestandarddocument: null,
				deletestandarddocumentError: null,
				deletestandarddocumentLoading: true,
			};
		case types.DELETE_STANDARDDOCUMENT_SUCCESS:
			return {
				...state,
				deletestandarddocument: action.payload,
				deletestandarddocumentLoading: false,
			};
		case types.DELETE_STANDARDDOCUMENT_ERROR:
			return {
				...state,
				deletestandarddocumentError: action.payload,
				deletestandarddocumentLoading: false,
			};
		case types.DELETE_STANDARDDOCUMENT_RESET:
			return {
				...state,
				deletestandarddocument: null,
				deletestandarddocumentError: null,
				deletestandarddocumentLoading: false,
			};

		//update
		case types.UPDATE_STANDARDDOCUMENT_START:
			return {
				...state,
				updatestandarddocument: null,
				updatestandarddocumentError: null,
				updatestandarddocumentLoading: true,
			};
		case types.UPDATE_STANDARDDOCUMENT_SUCCESS:
			return {
				...state,
				updatestandarddocument: action.payload,
				updatestandarddocumentLoading: false,
			};
		case types.UPDATE_STANDARDDOCUMENT_ERROR:
			return {
				...state,
				updatestandarddocumentError: action.payload,
				updatestandarddocumentLoading: false,
			};
		case types.UPDATE_STANDARDDOCUMENT_RESET:
			return {
				...state,
				updatestandarddocument: null,
				updatestandarddocumentError: null,
				updatestandarddocumentLoading: false,
			};
		
		default:
			return { ...state };
	}
};

export default StandardDocumentReducer;
