import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get user
function* getExternalUserHandlerSage(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "externaluser", ...action.payload }),
		);
		yield put({
			type: types.GET_EXTERNAL_USER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_EXTERNAL_USER_ERROR,
			payload,
		});
	}
}

export function* getExtrenalUserWatcherSaga() {
	yield takeLatest(types.GET_EXTERNAL_USER_START, getExternalUserHandlerSage);
}

/*
//create user
function* createExternalUserHandlerSage(action) {}

export function* createExtrenalUserWatcherSaga() {
	yield  takeLatest(
		types.CREATE_EXTERNAL_USER_START,
		createExternalUserHandlerSage,
	);
}

//update user

function* updateExternalUserHandlerSage(action) {}

export function* updateExtrenalUserWatcherSaga() {
	yield  takeLatest(
		types.UPDATE_EXTERNAL_USER_START,
		updateExternalUserHandlerSage,
	);
}
*/

//delete user

function* deleteExternalUserHandlerSage(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("externaluser", action.payload),
		);
		yield put({
			type: types.DELETE_EXTERNAL_USER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_EXTERNAL_USER_ERROR,
			payload,
		});
	}
}

export function* deleteExtrenalUserWatcherSaga() {
	yield takeLatest(
		types.DELETE_EXTERNAL_USER_START,
		deleteExternalUserHandlerSage,
	);
}
