import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getinterLabProgramHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({
				path: "interlaboratoryprogram",
				...action.payload,
			}),
		);
		yield put({
			type: types.GET_INTER_LAB_PROGRAM_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_INTER_LAB_PROGRAM_ERROR,
			payload,
		});
	}
}

export function* getinterLabProgramWatcherSaga() {
	yield takeLatest(
		types.GET_INTER_LAB_PROGRAM_START,
		getinterLabProgramHandlerSaga,
	);
}

//create

//delete

function* deleteinterLabProgramHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("interlaboratoryprogram", action.payload),
		);
		yield put({
			type: types.DELETE_INTER_LAB_PROGRAM_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_INTER_LAB_PROGRAM_ERROR,
			payload,
		});
	}
}

export function* deleteinterLabProgramWatcherSaga() {
	yield takeLatest(
		types.DELETE_INTER_LAB_PROGRAM_START,
		deleteinterLabProgramHandlerSaga,
	);
}

//update
