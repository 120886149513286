const types = {
	//Get Template name
	GET_INTRA_LAB_PROGRAM_START: "GET_INTRA_LAB_PROGRAM_START",
	GET_INTRA_LAB_PROGRAM_SUCCESS: "GET_INTRA_LAB_PROGRAM_SUCCESS",
	GET_INTRA_LAB_PROGRAM_ERROR: "GET_INTRA_LAB_PROGRAM_ERROR",
	GET_INTRA_LAB_PROGRAM_RESET: "GET_INTRA_LAB_PROGRAM_RESET",

	//Create Template name
	CREATE_INTRA_LAB_PROGRAM_START: "CREATE_INTRA_LAB_PROGRAM_START",
	CREATE_INTRA_LAB_PROGRAM_SUCCESS: "CREATE_INTRA_LAB_PROGRAM_SUCCESS",
	CREATE_INTRA_LAB_PROGRAM_ERROR: "CREATE_INTRA_LAB_PROGRAM_ERROR",
	CREATE_INTRA_LAB_PROGRAM_RESET: "CREATE_INTRA_LAB_PROGRAM_RESET",

	//delete Template name
	DELETE_INTRA_LAB_PROGRAM_START: "DELETE_INTRA_LAB_PROGRAM_START",
	DELETE_INTRA_LAB_PROGRAM_SUCCESS: "DELETE_INTRA_LAB_PROGRAM_SUCCESS",
	DELETE_INTRA_LAB_PROGRAM_ERROR: "DELETE_INTRA_LAB_PROGRAM_ERROR",
	DELETE_INTRA_LAB_PROGRAM_RESET: "DELETE_INTRA_LAB_PROGRAM_RESET",

	//update Template name
	UPDATE_INTRA_LAB_PROGRAM_START: "UPDATE_INTRA_LAB_PROGRAM_START",
	UPDATE_INTRA_LAB_PROGRAM_SUCCESS: "UPDATE_INTRA_LAB_PROGRAM_SUCCESS",
	UPDATE_INTRA_LAB_PROGRAM_ERROR: "UPDATE_INTRA_LAB_PROGRAM_ERROR",
	UPDATE_INTRA_LAB_PROGRAM_RESET: "UPDATE_INTRA_LAB_PROGRAM_RESET",

	//single item  name
	SINGLE_INTRA_LAB_PROGRAM_START: "SINGLE_INTRA_LAB_PROGRAM_START",
	SINGLE_INTRA_LAB_PROGRAM_SUCCESS: "SINGLE_INTRA_LAB_PROGRAM_SUCCESS",
	SINGLE_INTRA_LAB_PROGRAM_ERROR: "SINGLE_INTRA_LAB_PROGRAM_ERROR",
	SINGLE_INTRA_LAB_PROGRAM_RESET: "SINGLE_INTRA_LAB_PROGRAM_RESET",
};

export default types;
