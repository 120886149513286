import axios from "axios";
import API_URL from "../util/ApiUrl";

export const getSubdomain = () => {
	if (
		process.env.REACT_APP_NODE_ENV === "local" ||
		process.env.REACT_APP_NODE_ENV === "development"
	) {
		return "subDomain" in localStorage ? localStorage.getItem("subDomain") : "";
	} else {
		if (window.location.host.split(".")[0] === "login") {
			return "";
		} else {
			return window.location.host.split(".")[0];
		}
	}
};

const apiClientFactory = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const domain =
		sessionStorage.getItem("DomainDetails") !== null
			? sessionStorage.getItem("DomainDetails")
			: "";
	const apiClient = axios.create({
		baseURL: API_URL(),
		timeout: 300000,
		headers: {
			Authorization: `Bearer ${user.jwt || "no token"}`,
			domain: domain,
		},
	});

	apiClient.interceptors.response.use(
		(response) => {
			return response;
		},
		(err) => {
			const error =
				err.response === undefined || err.response.data === undefined
					? err.message
					: err.response.data.message;

			if (
				error.includes("Bad Token") ||
				error.includes("User Not logged In") ||
				error.includes("Network Error")
			) {
				const storedError = sessionStorage.getItem("axiosInterceptError");
				if (storedError === null) {
					sessionStorage.setItem(
						"axiosInterceptError",
						JSON.stringify("Bad Token: Access Denied!"),
					);
				}
			} else {
				return Promise.reject(err);
			}
		},
	);
	////console.log("SUB-DOMAIN", getSubdomain());
	return apiClient;
};

export default apiClientFactory;
