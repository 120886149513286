import types from "./types";

const INITIAL_STATE = {
	employeeFormBuilder: null,

	employeeFormBuilderError: null,
	employeeFormBuilderLoading: false,

	//select item

	selectItem: null,
};

const EmployeeFromReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_EMPLOYEEFORMLIST_START:
			return {
				...state,
				employeeFormBuilder: null,
				employeeFormBuilderError: null,
				employeeFormBuilderLoading: true,
			};
		case types.GET_EMPLOYEEFORMLIST_SUCCESS:
			return {
				...state,
				employeeFormBuilder: action.payload,
				employeeFormBuilderLoading: false,
			};
		case types.GET_EMPLOYEEFORMLIST_ERROR:
			return {
				...state,
				employeeFormBuilderError: action.payload,
				employeeFormBuilderLoading: false,
			};
		case types.GET_EMPLOYEEFORMLIST_RESET:
			return {
				...state,
				employeeFormBuilderError: null,
				employeeFormBuilderLoading: false,
			};
		//create

		case types.SELECT_TOUPDATE_ITEM:
			return {
				...state,
				selectItem: action.payload,
			};

		default:
			return { ...state };
	}
};

export default EmployeeFromReducer;
