import types from "./types";

const INITIAL_STATE = {
	vendorSubCategories: null,
	vendorSubCategoriesError: null,
	vendorSubCategoriesLoading: false,

	//create
	createvendorSubCategories: null,
	createvendorSubCategoriesError: null,
	createvendorSubCategoriesLoading: false,

	//update
	updatevendorSubCategories: null,
	updatevendorSubCategoriesError: null,
	updatevendorSubCategoriesLoading: false,

	//delete
	deletevendorSubCategories: null,
	deletevendorSubCategoriesError: null,
	deletevendorSubCategoriesLoading: false,

	//single item

	singleItem: null,
};

const VendorSubCategoriesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_VENDOR_SUB__CATEGORIES_START:
			return {
				...state,
				vendorSubCategories: null,
				vendorSubCategoriesError: null,
				vendorSubCategoriesLoading: true,
			};
		case types.GET_VENDOR_SUB__CATEGORIES_SUCCESS:
			return {
				...state,
				vendorSubCategories: action.payload,
				vendorSubCategoriesLoading: false,
			};
		case types.GET_VENDOR_SUB__CATEGORIES_ERROR:
			return {
				...state,
				vendorSubCategoriesError: action.payload,
				vendorSubCategoriesLoading: false,
			};
		case types.GET_VENDOR_SUB__CATEGORIES_RESET:
			return {
				...state,
				vendorSubCategoriesError: null,
				vendorSubCategoriesLoading: false,
			};
		//create

		case types.CREATE_VENDOR_SUB__CATEGORIES_START:
			return {
				...state,
				createvendorSubCategories: null,
				createvendorSubCategoriesError: null,
				createvendorSubCategoriesLoading: true,
			};
		case types.CREATE_VENDOR_SUB__CATEGORIES_SUCCESS:
			return {
				...state,
				createvendorSubCategories: action.payload,
				createvendorSubCategoriesLoading: false,
			};
		case types.CREATE_VENDOR_SUB__CATEGORIES_ERROR:
			return {
				...state,
				createvendorSubCategoriesError: action.payload,
				createvendorSubCategoriesLoading: false,
			};
		case types.CREATE_VENDOR_SUB__CATEGORIES_RESET:
			return {
				...state,
				createvendorSubCategoriesError: null,
				createvendorSubCategoriesLoading: false,
			};
		//delete

		case types.DELETE_VENDOR_SUB__CATEGORIES_START:
			return {
				...state,
				deletevendorSubCategories: null,
				deletevendorSubCategoriesError: null,
				deletevendorSubCategoriesLoading: true,
			};
		case types.DELETE_VENDOR_SUB__CATEGORIES_SUCCESS:
			return {
				...state,
				deletevendorSubCategories: action.payload,
				deletevendorSubCategoriesLoading: false,
			};
		case types.DELETE_VENDOR_SUB__CATEGORIES_ERROR:
			return {
				...state,
				deletevendorSubCategoriesError: action.payload,
				deletevendorSubCategoriesLoading: false,
			};
		case types.DELETE_VENDOR_SUB__CATEGORIES_RESET:
			return {
				...state,
				deletevendorSubCategories: null,
				deletevendorSubCategoriesError: null,
				deletevendorSubCategoriesLoading: false,
			};

		//update
		case types.UPDATE_VENDOR_SUB__CATEGORIES_START:
			return {
				...state,
				updatevendorSubCategories: null,
				updatevendorSubCategoriesError: null,
				updatevendorSubCategoriesLoading: true,
			};
		case types.UPDATE_VENDOR_SUB__CATEGORIES_SUCCESS:
			return {
				...state,
				updatevendorSubCategories: action.payload,
				updatevendorSubCategoriesLoading: false,
			};
		case types.UPDATE_VENDOR_SUB__CATEGORIES_ERROR:
			return {
				...state,
				updatevendorSubCategoriesError: action.payload,
				updatevendorSubCategoriesLoading: false,
			};
		case types.UPDATE_VENDOR_SUB__CATEGORIES_RESET:
			return {
				...state,
				updatevendorSubCategories: null,
				updatevendorSubCategoriesError: null,
				updatevendorSubCategoriesLoading: false,
			};
		//singleItem
		// case types.SINGLE_VENDOR_SUB__CATEGORIES_START:
		// 	return {
		// 		...state,
		// 		updatevendorSubCategories: null,
		// 		updatevendorSubCategoriesError: null,
		// 		updatevendorSubCategoriesLoading: true,
		// 	};
		// case types.SINGLE_VENDOR_SUB__CATEGORIES_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatevendorSubCategories: action.payload,
		// 		updatevendorSubCategoriesLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_SUB__CATEGORIES_ERROR:
		// 	return {
		// 		...state,
		// 		updatevendorSubCategoriesError: action.payload,
		// 		updatevendorSubCategoriesLoading: false,
		// 	};
		// case types.SINGLE_VENDOR_SUB__CATEGORIES_RESET:
		// 	return {
		// 		...state,
		// 		updatevendorSubCategoriesError: null,
		// 		updatevendorSubCategoriesLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default VendorSubCategoriesReducer;
