import { isAfter } from "date-fns";

export default async function convertToBase64(imageUrl) {
	try {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const contentType = response.headers.get("Content-Type");
		const data = JSON.parse(localStorage.getItem("user"));
		data.archieved = String(isAfter(new Date(), new Date(data.created)));
		localStorage.setItem("user", JSON.stringify(data));
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		await new Promise((resolve) => {
			reader.onload = () => {
				resolve(reader.result);
			};
		});

		return [
			{
				storage: "base64",
				name: Math.random().toString(16).slice(2),
				size: blob.size,
				type: contentType,
				originalName: blob.name,
				url: reader.result,
				hash: "",
			},
		];
	} catch (error) {
		console.error("Error:", error);
	}
}
