import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endponits from "../../backend/endpoints";

/**
 *
 * @param {*} action
 * getting menu list
 */

function* getjobHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endponits.dynamicList(action.payload),
		);
		yield put({
			type: types.GET_JOBS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_JOBS_ERROR,
			payload,
		});
	}
}

export function* getjobWatcherSaga() {
	yield takeLatest(types.GET_JOBS_START, getjobHandlerSaga);
}

function* getMasterJobHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endponits.dynamicList(action.payload),
		);
		yield put({
			type: types.GET_MASTER_JOBS_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_MASTER_JOBS_ERROR,
			payload,
		});
	}
}

export function* getMasterJobWatcherSaga() {
	yield takeLatest(types.GET_MASTER_JOBS_START, getMasterJobHandlerSaga);
}
