const types = {
	//Get Template name
	GET_BUILDING_START: "GET_BUILDING_START",
	GET_BUILDING_SUCCESS: "GET_BUILDING_SUCCESS",
	GET_BUILDING_ERROR: "GET_BUILDING_ERROR",
	GET_BUILDING_RESET: "GET_BUILDING_RESET",

	//Create Template name
	CREATE_BUILDING_START: "CREATE_BUILDING_START",
	CREATE_BUILDING_SUCCESS: "CREATE_BUILDING_SUCCESS",
	CREATE_BUILDING_ERROR: "CREATE_BUILDING_ERROR",
	CREATE_BUILDING_RESET: "CREATE_BUILDING_RESET",

	//delete Template name
	DELETE_BUILDING_START: "DELETE_BUILDING_START",
	DELETE_BUILDING_SUCCESS: "DELETE_BUILDING_SUCCESS",
	DELETE_BUILDING_ERROR: "DELETE_BUILDING_ERROR",
	DELETE_BUILDING_RESET: "DELETE_BUILDING_RESET",

	//update Template name
	UPDATE_BUILDING_START: "UPDATE_BUILDING_START",
	UPDATE_BUILDING_SUCCESS: "UPDATE_BUILDING_SUCCESS",
	UPDATE_BUILDING_ERROR: "UPDATE_BUILDING_ERROR",
	UPDATE_BUILDING_RESET: "UPDATE_BUILDING_RESET",

	//single item  name
	SINGLE_BUILDING_START: "SINGLE_BUILDING_START",
	SINGLE_BUILDING_SUCCESS: "SINGLE_BUILDING_SUCCESS",
	SINGLE_BUILDING_ERROR: "SINGLE_BUILDING_ERROR",
	SINGLE_BUILDING_RESET: "SINGLE_BUILDING_RESET",
};

export default types;
