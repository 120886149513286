const types = {
	//Get Template name
	GET_VENDORFORMLIST_START: "GET_VENDORFORMLIST_START",
	GET_VENDORFORMLIST_SUCCESS: "GET_VENDORFORMLIST_SUCCESS",
	GET_VENDORFORMLIST_ERROR: "GET_VENDORFORMLIST_ERROR",
	GET_VENDORFORMLIST_RESET: "GET_VENDORFORMLIST_RESET",

	//single item  name
	SELECT_TOUPDATE_ITEM: "SELECT_TOUPDATE_ITEM",
};

export default types;
