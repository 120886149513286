import types from "./types";

const INITIAL_STATE = {
  Service: null,
  ServiceError: null,
  ServiceLoading: false,

  //create
  createService: null,
  createServiceError: null,
  createServiceLoading: false,

  //update
  updateService: null,
  updateServiceError: null,
  updateServiceLoading: false,

  //delete
  deleteService: null,
  deleteServiceError: null,
  deleteServiceLoading: false,

  //single item

  singleItem: null,
};

const serviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SERVICES_START:
      return {
        ...state,
        Service: null,
        ServiceError: null,
        ServiceLoading: true,
      };
    case types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        Service: action.payload,
        ServiceLoading: false,
      };
    case types.GET_SERVICES_ERROR:
      return {
        ...state,
        ServiceError: action.payload,
        ServiceLoading: false,
      };
    case types.GET_SERVICES_RESET:
      return {
        ...state,
        ServiceError: null,
        ServiceLoading: false,
      };
    //create

    case types.CREATE_SERVICES_START:
      return {
        ...state,
        createService: null,
        createServiceError: null,
        createServiceLoading: true,
      };
    case types.CREATE_SERVICES_SUCCESS:
      return {
        ...state,
        createService: action.payload,
        createServiceLoading: false,
      };
    case types.CREATE_SERVICES_ERROR:
      return {
        ...state,
        createServiceError: action.payload,
        createServiceLoading: false,
      };
    case types.CREATE_SERVICES_RESET:
      return {
        ...state,
        createService: null,
        createServiceError: null,
        createServiceLoading: false,
      };
    //delete

    case types.DELETE_SERVICES_START:
      return {
        ...state,
        deleteService: null,
        deleteServiceError: null,
        deleteServiceLoading: true,
      };
    case types.DELETE_SERVICES_SUCCESS:
      return {
        ...state,
        deleteService: action.payload,
        deleteServiceLoading: false,
      };
    case types.DELETE_SERVICES_ERROR:
      return {
        ...state,
        deleteServiceError: action.payload,
        deleteServiceLoading: false,
      };
    case types.DELETE_SERVICES_RESET:
      return {
        ...state,
        deleteService: null,
        deleteServiceError: null,
        deleteServiceLoading: false,
      };

    //update
    case types.UPDATE_SERVICES_START:
      return {
        ...state,
        updateService: null,
        updateServiceError: null,
        updateServiceLoading: true,
      };
    case types.UPDATE_SERVICES_SUCCESS:
      return {
        ...state,
        updateService: action.payload,
        updateServiceLoading: false,
      };
    case types.UPDATE_SERVICES_ERROR:
      return {
        ...state,
        updateServiceError: action.payload,
        updateServiceLoading: false,
      };
    case types.UPDATE_SERVICES_RESET:
      return {
        ...state,
        updateService: null,
        updateServiceError: null,
        updateServiceLoading: false,
      };
    //singleItem
    // case types.SINGLE_SERVICES_START:
    // 	return {
    // 		...state,
    // 		updateService: null,
    // 		updateServiceError: null,
    // 		updateServiceLoading: true,
    // 	};
    // case types.SINGLE_SERVICES_SUCCESS:
    // 	return {
    // 		...state,
    // 		updateService: action.payload,
    // 		updateServiceLoading: false,
    // 	};
    // case types.SINGLE_SERVICES_ERROR:
    // 	return {
    // 		...state,
    // 		updateServiceError: action.payload,
    // 		updateServiceLoading: false,
    // 	};
    // case types.SINGLE_SERVICES_RESET:
    // 	return {
    // 		...state,
    // 		updateServiceError: null,
    // 		updateServiceLoading: false,
    // 	};

    default:
      return { ...state };
  }
};

export default serviceReducer;
