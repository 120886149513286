import types from "./types";

const INITIAL_STATE = {
	login: null,
	loginError: null,
	loginLoading: false,

	otpVerificaion: null,
	otpVerificaionError: null,
	otpVerificaionLoading: false,

	user: { role: "superUser" },
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.USER_LOGIN_START:
			return {
				...state,
				login: null,
				loginLoading: true,
				loginError: null,
				otpVerificaion:null
			};
		case types.USER_LOGIN_SUCCESS:
			return {
				...state,
				user: { ...action.payload?.response },
				login: action.payload,
				loginLoading: false,
			};
		case types.USER_LOGIN_ERROR:
			return {
				...state,
				login: null,
				loginLoading: false,
				loginError: action.payload,
			};
		case types.USER_LOGIN_RESET:
			return {
				...state,
				login: null,
				loginLoading: false,
				loginError: null,
			};

		case types.USER_OTP_START:
			return {
				...state,
				otpVerificaion: null,
				otpVerificaionLoading: true,
				otpVerificaionError: null,
			};
		case types.USER_OTP_SUCCESS:
			return {
				...state,
				otpVerificaion: action.payload,
				otpVerificaionLoading: false,
			};
		case types.USER_OTP_ERROR:
			return {
				...state,

				otpVerificaionLoading: false,
				otpVerificaionError: action.payload,
			};
		case types.USER_OTP_RESET:
			return {
				...state,
				otpVerificaion: null,
				otpVerificaionLoading: false,
				otpVerificaionError: null,
			};

		default:
			return state;
	}
};

export default authReducer;
