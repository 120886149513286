const types = {
  //Get Template name
  GET_DOCUMENT_LIST_START: "GET_DOCUMENT_LIST_START",
  GET_DOCUMENT_LIST_SUCCESS: "GET_DOCUMENT_LIST_SUCCESS",
  GET_DOCUMENT_LIST_ERROR: "GET_DOCUMENT_LIST_ERROR",
  GET_DOCUMENT_LIST_RESET: "GET_DOCUMENT_LIST_RESET",

  //Create Template name
  CREATE_DOCUMENT_LIST_START: "CREATE_DOCUMENT_LIST_START",
  CREATE_DOCUMENT_LIST_SUCCESS: "CREATE_DOCUMENT_LIST_SUCCESS",
  CREATE_DOCUMENT_LIST_ERROR: "CREATE_DOCUMENT_LIST_ERROR",
  CREATE_DOCUMENT_LIST_RESET: "CREATE_DOCUMENT_LIST_RESET",

  //delete Template name
  DELETE_DOCUMENT_LIST_START: "DELETE_DOCUMENT_LIST_START",
  DELETE_DOCUMENT_LIST_SUCCESS: "DELETE_DOCUMENT_LIST_SUCCESS",
  DELETE_DOCUMENT_LIST_ERROR: "DELETE_DOCUMENT_LIST_ERROR",
  DELETE_DOCUMENT_LIST_RESET: "DELETE_DOCUMENT_LIST_RESET",

  //update Template name
  UPDATE_DOCUMENT_LIST_START: "UPDATE_DOCUMENT_LIST_START",
  UPDATE_DOCUMENT_LIST_SUCCESS: "UPDATE_DOCUMENT_LIST_SUCCESS",
  UPDATE_DOCUMENT_LIST_ERROR: "UPDATE_DOCUMENT_LIST_ERROR",
  UPDATE_DOCUMENT_LIST_RESET: "UPDATE_DOCUMENT_LIST_RESET",

  //single item  name
  SINGLE_DOCUMENT_LIST_START: "SINGLE_DOCUMENT_LIST_START",
  SINGLE_DOCUMENT_LIST_SUCCESS: "SINGLE_DOCUMENT_LIST_SUCCESS",
  SINGLE_DOCUMENT_LIST_ERROR: "SINGLE_DOCUMENT_LIST_ERROR",
  SINGLE_DOCUMENT_LIST_RESET: "SINGLE_DOCUMENT_LIST_RESET",
};

export default types;
