import useUser from "hooks/useUser";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PublicRoutes = (props) => {
	const { user } = useUser();

	return user.role === undefined ? <Outlet /> : <Navigate to='/dashboard' />;
};

export default PublicRoutes;
