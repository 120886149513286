const types = {
	//Get Template name
	GET_VENDOR_START: "GET_VENDOR_START",
	GET_VENDOR_SUCCESS: "GET_VENDOR_SUCCESS",
	GET_VENDOR_ERROR: "GET_VENDOR_ERROR",
	GET_VENDOR_RESET: "GET_VENDOR_RESET",

	//Create Template name
	CREATE_VENDOR_START: "CREATE_VENDOR_START",
	CREATE_VENDOR_SUCCESS: "CREATE_VENDOR_SUCCESS",
	CREATE_VENDOR_ERROR: "CREATE_VENDOR_ERROR",
	CREATE_VENDOR_RESET: "CREATE_VENDOR_RESET",

	//delete Template name
	DELETE_VENDOR_START: "DELETE_VENDOR_START",
	DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
	DELETE_VENDOR_ERROR: "DELETE_VENDOR_ERROR",
	DELETE_VENDOR_RESET: "DELETE_VENDOR_RESET",

	//update Template name
	UPDATE_VENDOR_START: "UPDATE_VENDOR_START",
	UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
	UPDATE_VENDOR_ERROR: "UPDATE_VENDOR_ERROR",
	UPDATE_VENDOR_RESET: "UPDATE_VENDOR_RESET",

	//single item  name
	SINGLE_VENDOR_START: "SINGLE_VENDOR_START",
	SINGLE_VENDOR_SUCCESS: "SINGLE_VENDOR_SUCCESS",
	SINGLE_VENDOR_ERROR: "SINGLE_VENDOR_ERROR",
	SINGLE_VENDOR_RESET: "SINGLE_VENDOR_RESET",
};

export default types;
