const types = {
	//Get Template name
	GET_EMPLOYEEFORMLIST_START: "GET_EMPLOYEEFORMLIST_START",
	GET_EMPLOYEEFORMLIST_SUCCESS: "GET_EMPLOYEEFORMLIST_SUCCESS",
	GET_EMPLOYEEFORMLIST_ERROR: "GET_EMPLOYEEFORMLIST_ERROR",
	GET_EMPLOYEEFORMLIST_RESET: "GET_EMPLOYEEFORMLIST_RESET",

	//single item  name
	SELECT_TOUPDATE_ITEM: "SELECT_TOUPDATE_ITEM",
};

export default types;
