const types = {
	//login Admin
	USER_LOGIN_START: "USER_LOGIN_START",
	USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
	USER_LOGIN_ERROR: "USER_LOGIN_ERROR",
	USER_LOGIN_RESET: "USER_LOGIN_RESET",

	USER_OTP_START: "USER_OTP_START",
	USER_OTP_SUCCESS: "USER_OTP_SUCCESS",
	USER_OTP_ERROR: "USER_OTP_ERROR",
	USER_OTP_RESET: "USER_OTP_RESET",
};

export default types;
