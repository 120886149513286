import types from "./types";

const INITIAL_STATE = {
	ClientDetails: null,
	ClientDetailsError: null,
	ClientDetailsLoading: false,

	//create
	createClientDetails: null,
	createClientDetailsError: null,
	createClientDetailsLoading: false,

	//update
	updateClientDetails: null,
	updateClientDetailsError: null,
	updateClientDetailsLoading: false,

	//delete
	deleteClientDetails: null,
	deleteClientDetailsError: null,
	deleteClientDetailsLoading: false,

	//single item

	singleItem: null,
};

const ClientDetailsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_CLIENT_DETAILS_START:
			return {
				...state,
				ClientDetails: null,
				ClientDetailsError: null,
				ClientDetailsLoading: true,
			};
		case types.GET_CLIENT_DETAILS_SUCCESS:
			return {
				...state,
				ClientDetails: action.payload,
				ClientDetailsLoading: false,
			};
		case types.GET_CLIENT_DETAILS_ERROR:
			return {
				...state,
				ClientDetailsError: action.payload,
				ClientDetailsLoading: false,
			};
		case types.GET_CLIENT_DETAILS_RESET:
			return {
				...state,
				ClientDetailsError: null,
				ClientDetailsLoading: false,
			};
		//create

		case types.CREATE_CLIENT_DETAILS_START:
			return {
				...state,
				createClientDetails: null,
				createClientDetailsError: null,
				createClientDetailsLoading: true,
			};
		case types.CREATE_CLIENT_DETAILS_SUCCESS:
			return {
				...state,
				createClientDetails: action.payload,
				createClientDetailsLoading: false,
			};
		case types.CREATE_CLIENT_DETAILS_ERROR:
			return {
				...state,
				createClientDetailsError: action.payload,
				createClientDetailsLoading: false,
			};
		case types.CREATE_CLIENT_DETAILS_RESET:
			return {
				...state,
				createClientDetailsError: null,
				createClientDetailsLoading: false,
			};
		//delete

		case types.DELETE_CLIENT_DETAILS_START:
			return {
				...state,
				deleteClientDetails: null,
				deleteClientDetailsError: null,
				deleteClientDetailsLoading: true,
			};
		case types.DELETE_CLIENT_DETAILS_SUCCESS:
			return {
				...state,
				deleteClientDetails: action.payload,
				deleteClientDetailsLoading: false,
			};
		case types.DELETE_CLIENT_DETAILS_ERROR:
			return {
				...state,
				deleteClientDetailsError: action.payload,
				deleteClientDetailsLoading: false,
			};
		case types.DELETE_CLIENT_DETAILS_RESET:
			return {
				...state,
				deleteClientDetails: null,
				deleteClientDetailsError: null,
				deleteClientDetailsLoading: false,
			};

		//update
		case types.UPDATE_CLIENT_DETAILS_START:
			return {
				...state,
				updateClientDetails: null,
				updateClientDetailsError: null,
				updateClientDetailsLoading: true,
			};
		case types.UPDATE_CLIENT_DETAILS_SUCCESS:
			return {
				...state,
				updateClientDetails: action.payload,
				updateClientDetailsLoading: false,
			};
		case types.UPDATE_CLIENT_DETAILS_ERROR:
			return {
				...state,
				updateClientDetailsError: action.payload,
				updateClientDetailsLoading: false,
			};
		case types.UPDATE_CLIENT_DETAILS_RESET:
			return {
				...state,
				updateClientDetails: null,
				updateClientDetailsError: null,
				updateClientDetailsLoading: false,
			};
		//singleItem
		// case types.SINGLE_CLIENT_DETAILS_START:
		// 	return {
		// 		...state,
		// 		updateClientDetails: null,
		// 		updateClientDetailsError: null,
		// 		updateClientDetailsLoading: true,
		// 	};
		// case types.SINGLE_CLIENT_DETAILS_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateClientDetails: action.payload,
		// 		updateClientDetailsLoading: false,
		// 	};
		// case types.SINGLE_CLIENT_DETAILS_ERROR:
		// 	return {
		// 		...state,
		// 		updateClientDetailsError: action.payload,
		// 		updateClientDetailsLoading: false,
		// 	};
		// case types.SINGLE_CLIENT_DETAILS_RESET:
		// 	return {
		// 		...state,
		// 		updateClientDetailsError: null,
		// 		updateClientDetailsLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default ClientDetailsReducer;
