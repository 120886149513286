const types = {

	GET_MENU_START: "GET_MENU_START",
	GET_MENU_SUCCESS: "GET_MENU_SUCCESS",
	GET_MENU_ERROR: "GET_MENU_ERROR",
	GET_MENU_RESET: "GET_MENU_RESET",

	//Get Template name
	GET_PAGES_START: "GET_PAGES_START",
	GET_PAGES_SUCCESS: "GET_PAGES_SUCCESS",
	GET_PAGES_ERROR: "GET_PAGES_ERROR",
	GET_PAGES_RESET: "GET_PAGES_RESET",

	//Create Template name
	CREATE_PAGES_START: "CREATE_PAGES_START",
	CREATE_PAGES_SUCCESS: "CREATE_PAGES_SUCCESS",
	CREATE_PAGES_ERROR: "CREATE_PAGES_ERROR",
	CREATE_PAGES_RESET: "CREATE_PAGES_RESET",

	//delete Template name
	DELETE_PAGES_START: "DELETE_PAGES_START",
	DELETE_PAGES_SUCCESS: "DELETE_PAGES_SUCCESS",
	DELETE_PAGES_ERROR: "DELETE_PAGES_ERROR",
	DELETE_PAGES_RESET: "DELETE_PAGES_RESET",

	//update Template name
	UPDATE_PAGES_START: "UPDATE_PAGES_START",
	UPDATE_PAGES_SUCCESS: "UPDATE_PAGES_SUCCESS",
	UPDATE_PAGES_ERROR: "UPDATE_PAGES_ERROR",
	UPDATE_PAGES_RESET: "UPDATE_PAGES_RESET",

	//single item  name
	SINGLE_PAGES_START: "SINGLE_PAGES_START",
	SINGLE_PAGES_SUCCESS: "SINGLE_PAGES_SUCCESS",
	SINGLE_PAGES_ERROR: "SINGLE_PAGES_ERROR",
	SINGLE_PAGES_RESET: "SINGLE_PAGES_RESET",
};

export default types;
