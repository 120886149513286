import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getStandardDocumentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "StandardDocument", ...action.payload }),
		);
		yield put({
			type: types.GET_STANDARDDOCUMENT_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_STANDARDDOCUMENT_ERROR,
			payload,
		});
	}
}

export function* getStandardDocumentWatcherSaga() {
	yield takeLatest(types.GET_STANDARDDOCUMENT_START, getStandardDocumentHandlerSaga);
}

//create

//delete

function* deleteStandardDocumentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("StandardDocument", action.payload),
		);
		yield put({
			type: types.DELETE_STANDARDDOCUMENT_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_STANDARDDOCUMENT_ERROR,
			payload,
		});
	}
}

export function* deleteStandardDocumentWatcherSaga() {
	yield takeLatest(
		types.DELETE_STANDARDDOCUMENT_START,
		deleteStandardDocumentHandlerSaga,
	);
}

//update
