import { useEffect, useReducer } from "react";
import FormContext from "./context";
import form_reducer from "./reducer";
import { SET_FORM } from "./action";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";

const INITIAL_STATE = {
	forms: [],
	pagination: {},
};

const FormState = (props) => {
	const [formState, setFormDispatch] = useReducer(form_reducer, INITIAL_STATE);
	const { user } = useUser();
	const { GET } = useApi();
	const location = useLocation();
	const forms = useQuery({
		queryKey: ["forms"],
		queryFn: async () =>
			await GET("/formbuilder/listFormbuilder?page=0&size=100"),
		enabled: Boolean(user.role),
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		if (forms.data) {
			setFormDispatch({
				type: SET_FORM,
				payload: forms.data.response,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forms.data]);

	useEffect(() => {
		if (formState.forms.length === 0 && Boolean(user.role)) {
			forms.refetch();
		}
	}, [location.pathname]);

	const getForm = (name) => {
		const form = formState.forms.find(
			(item) => item.formTitle.toLowerCase() === name.toLowerCase(),
		);
		return form;
	};
	const getFormById = (id) => {
		const form = formState.forms.find((item) => item._id === id);
		return form;
	};

	return (
		<FormContext.Provider
			value={{
				forms: formState.forms,
				pagination: formState.pagination,
				loading: forms.isLoading,
				getForm,
				getFormById,
			}}>
			{props.children}
		</FormContext.Provider>
	);
};

FormState.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FormState;
