const types = {
  //Get Template name
  GET_STORE_START: "GET_STORE_START",
  GET_STORE_SUCCESS: "GET_STORE_SUCCESS",
  GET_STORE_ERROR: "GET_STORE_ERROR",
  GET_STORE_RESET: "GET_STORE_RESET",

  //Create Template name
  CREATE_STORE_START: "CREATE_STORE_START",
  CREATE_STORE_SUCCESS: "CREATE_STORE_SUCCESS",
  CREATE_STORE_ERROR: "CREATE_STORE_ERROR",
  CREATE_STORE_RESET: "CREATE_STORE_RESET",

  //delete Template name
  DELETE_STORE_START: "DELETE_STORE_START",
  DELETE_STORE_SUCCESS: "DELETE_STORE_SUCCESS",
  DELETE_STORE_ERROR: "DELETE_STORE_ERROR",
  DELETE_STORE_RESET: "DELETE_STORE_RESET",

  //update Template name
  UPDATE_STORE_START: "UPDATE_STORE_START",
  UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_ERROR: "UPDATE_STORE_ERROR",
  UPDATE_STORE_RESET: "UPDATE_STORE_RESET",

  //single item  name
  SINGLE_STORE_START: "SINGLE_STORE_START",
  SINGLE_STORE_SUCCESS: "SINGLE_STORE_SUCCESS",
  SINGLE_STORE_ERROR: "SINGLE_STORE_ERROR",
  SINGLE_STORE_RESET: "SINGLE_STORE_RESET",
};

export default types;
