import types from "./types";

const INITIAL_STATE = {
  user: null,
  userError: null,
  userLoading: false,
  //create internal User
  createUser: null,
  createUserError: null,
  createUserLoading: false,

  //delete internal User
  deleteUser: null,
  deleteUserError: null,
  deleteUserLoading: false,

  //update internal User
  updateUser: null,
  updateUserError: null,
  updateUserLoading: false,
};

const internalUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_INTERNAL_USER_START:
      return {
        ...state,
        user: null,
        userLoading: true,
        userError: null,
      };
    case types.GET_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };
    case types.GET_INTERNAL_USER_ERROR:
      return {
        ...state,

        userLoading: false,
        userError: action.payload,
      };
    case types.GET_INTERNAL_USER_RESET:
      return {
        ...state,
        user: null,
        userLoading: false,
        userError: null,
      };
    //create external User
    case types.CREATE_INTERNAL_USER_START:
      return {
        ...state,
        createUser: null,
        createUserLoading: true,
        createUserError: null,
      };
    case types.CREATE_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        createUser: action.payload,
        createUserLoading: false,
      };
    case types.CREATE_INTERNAL_USER_ERROR:
      return {
        ...state,

        createUserLoading: false,
        createUserError: action.payload,
      };
    case types.CREATE_INTERNAL_USER_RESET:
      return {
        ...state,
        createUser: null,
        createUserLoading: false,
        createUserError: null,
      };

    //update external User
    case types.UPDATE_INTERNAL_USER_START:
      return {
        ...state,
        updateUser: null,
        updateUserLoading: true,
        updateUserError: null,
      };
    case types.UPDATE_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        updateUser: action.payload,
        updateUserLoading: false,
      };
    case types.UPDATE_INTERNAL_USER_ERROR:
      return {
        ...state,

        updateUserLoading: false,
        updateUserError: action.payload,
      };
    case types.UPDATE_INTERNAL_USER_RESET:
      return {
        ...state,
        updateUser: null,
        updateUserLoading: false,
        updateUserError: null,
      };

    //delete external User
    case types.DELETE_INTERNAL_USER_START:
      return {
        ...state,
        deleteUser: null,
        deleteUserLoading: true,
        deleteUserError: null,
      };
    case types.DELETE_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        deleteUser: action.payload,
        deleteUserLoading: false,
      };
    case types.DELETE_INTERNAL_USER_ERROR:
      return {
        ...state,

        deleteUserLoading: false,
        deleteUserError: action.payload,
      };
    case types.DELETE_INTERNAL_USER_RESET:
      return {
        ...state,
        deleteUser: null,
        deleteUserLoading: false,
        deleteUserError: null,
      };

    default:
      return { ...state };
  }
};

export default internalUserReducer;
