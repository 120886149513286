const types = {
	//Get Template name
	GET_ROLE_AND_PERMISSION_START: "GET_ROLE_AND_PERMISSION__START",
	GET_ROLE_AND_PERMISSION_SUCCESS: "GET_ROLE_AND_PERMISSION__SUCCESS",
	GET_ROLE_AND_PERMISSION_ERROR: "GET_ROLE_AND_PERMISSION__ERROR",
	GET_ROLE_AND_PERMISSION_RESET: "GET_ROLE_AND_PERMISSION__RESET",

	//Create Template name
	CREATE_ROLE_AND_PERMISSION_START: "CREATE_ROLE_AND_PERMISSION_START",
	CREATE_ROLE_AND_PERMISSION_SUCCESS: "CREATE_ROLE_AND_PERMISSION_SUCCESS",
	CREATE_ROLE_AND_PERMISSION_ERROR: "CREATE_ROLE_AND_PERMISSION_ERROR",
	CREATE_ROLE_AND_PERMISSION_RESET: "CREATE_ROLE_AND_PERMISSION_RESET",

	//delete Template name
	DELETE_ROLE_AND_PERMISSION_START: "DELETE_ROLE_AND_PERMISSION_START",
	DELETE_ROLE_AND_PERMISSION_SUCCESS: "DELETE_ROLE_AND_PERMISSION_SUCCESS",
	DELETE_ROLE_AND_PERMISSION_ERROR: "DELETE_ROLE_AND_PERMISSION_ERROR",
	DELETE_ROLE_AND_PERMISSION_RESET: "DELETE_ROLE_AND_PERMISSION_RESET",

	//update Template name
	UPDATE_ROLE_AND_PERMISSION_START: "UPDATE_ROLE_AND_PERMISSION_START",
	UPDATE_ROLE_AND_PERMISSION_SUCCESS: "UPDATE_ROLE_AND_PERMISSION_SUCCESS",
	UPDATE_ROLE_AND_PERMISSION_ERROR: "UPDATE_ROLE_AND_PERMISSION_ERROR",
	UPDATE_ROLE_AND_PERMISSION_RESET: "UPDATE_ROLE_AND_PERMISSION_RESET",

	//single item  name
	SINGLE_ROLE_AND_PERMISSION_START: "SINGLE_ROLE_AND_PERMISSION_START",
	SINGLE_ROLE_AND_PERMISSION_SUCCESS: "SINGLE_ROLE_AND_PERMISSION_SUCCESS",
	SINGLE_ROLE_AND_PERMISSION_ERROR: "SINGLE_ROLE_AND_PERMISSION_ERROR",
	SINGLE_ROLE_AND_PERMISSION_RESET: "SINGLE_ROLE_AND_PERMISSION_RESET",
};

export default types;
