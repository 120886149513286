import types from "./types";

const INITIAL_STATE = {
	EmployeeDetails: null,
	EmployeeDetailsError: null,
	EmployeeDetailsLoading: false,

	//create
	createEmployeeDetails: null,
	createEmployeeDetailsError: null,
	createEmployeeDetailsLoading: false,

	//update
	updateEmployeeDetails: null,
	updateEmployeeDetailsError: null,
	updateEmployeeDetailsLoading: false,

	//delete
	deleteEmployeeDetails: null,
	deleteEmployeeDetailsError: null,
	deleteEmployeeDetailsLoading: false,

	//single item

	singleItem: null,
};

const EmployeeDetailsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_EMPLOYEE_DETAILS_START:
			return {
				...state,
				EmployeeDetails: null,
				EmployeeDetailsError: null,
				EmployeeDetailsLoading: true,
			};
		case types.GET_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				EmployeeDetails: action.payload,
				EmployeeDetailsLoading: false,
			};
		case types.GET_EMPLOYEE_DETAILS_ERROR:
			return {
				...state,
				EmployeeDetailsError: action.payload,
				EmployeeDetailsLoading: false,
			};
		case types.GET_EMPLOYEE_DETAILS_RESET:
			return {
				...state,
				EmployeeDetailsError: null,
				EmployeeDetailsLoading: false,
			};
		//create

		case types.CREATE_EMPLOYEE_DETAILS_START:
			return {
				...state,
				createEmployeeDetails: null,
				createEmployeeDetailsError: null,
				createEmployeeDetailsLoading: true,
			};
		case types.CREATE_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				createEmployeeDetails: action.payload,
				createEmployeeDetailsLoading: false,
			};
		case types.CREATE_EMPLOYEE_DETAILS_ERROR:
			return {
				...state,
				createEmployeeDetailsError: action.payload,
				createEmployeeDetailsLoading: false,
			};
		case types.CREATE_EMPLOYEE_DETAILS_RESET:
			return {
				...state,
				createEmployeeDetailsError: null,
				createEmployeeDetailsLoading: false,
			};
		//delete

		case types.DELETE_EMPLOYEE_DETAILS_START:
			return {
				...state,
				deleteEmployeeDetails: null,
				deleteEmployeeDetailsError: null,
				deleteEmployeeDetailsLoading: true,
			};
		case types.DELETE_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				deleteEmployeeDetails: action.payload,
				deleteEmployeeDetailsLoading: false,
			};
		case types.DELETE_EMPLOYEE_DETAILS_ERROR:
			return {
				...state,
				deleteEmployeeDetailsError: action.payload,
				deleteEmployeeDetailsLoading: false,
			};
		case types.DELETE_EMPLOYEE_DETAILS_RESET:
			return {
				...state,
				deleteEmployeeDetails: null,
				deleteEmployeeDetailsError: null,
				deleteEmployeeDetailsLoading: false,
			};

		//update
		case types.UPDATE_EMPLOYEE_DETAILS_START:
			return {
				...state,
				updateEmployeeDetails: null,
				updateEmployeeDetailsError: null,
				updateEmployeeDetailsLoading: true,
			};
		case types.UPDATE_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				updateEmployeeDetails: action.payload,
				updateEmployeeDetailsLoading: false,
			};
		case types.UPDATE_EMPLOYEE_DETAILS_ERROR:
			return {
				...state,
				updateEmployeeDetailsError: action.payload,
				updateEmployeeDetailsLoading: false,
			};
		case types.UPDATE_EMPLOYEE_DETAILS_RESET:
			return {
				...state,
				updateEmployeeDetails: null,
				updateEmployeeDetailsError: null,
				updateEmployeeDetailsLoading: false,
			};
		//singleItem
		// case types.SINGLE_EMPLOYEE_DETAILS_START:
		// 	return {
		// 		...state,
		// 		updateEmployeeDetails: null,
		// 		updateEmployeeDetailsError: null,
		// 		updateEmployeeDetailsLoading: true,
		// 	};
		// case types.SINGLE_EMPLOYEE_DETAILS_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateEmployeeDetails: action.payload,
		// 		updateEmployeeDetailsLoading: false,
		// 	};
		// case types.SINGLE_EMPLOYEE_DETAILS_ERROR:
		// 	return {
		// 		...state,
		// 		updateEmployeeDetailsError: action.payload,
		// 		updateEmployeeDetailsLoading: false,
		// 	};
		// case types.SINGLE_EMPLOYEE_DETAILS_RESET:
		// 	return {
		// 		...state,
		// 		updateEmployeeDetailsError: null,
		// 		updateEmployeeDetailsLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default EmployeeDetailsReducer;
