import types from "./types";

const INITIAL_STATE = {
	clientFormBuilder: null,

	clientFormBuilderError: null,
	clientFormBuilderLoading: false,

	//select item

	selectItem: null,
};

const ClientFromReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_CLIENTFORMLIST_START:
			return {
				...state,
				clientFormBuilder: null,
				clientFormBuilderError: null,
				clientFormBuilderLoading: true,
			};
		case types.GET_CLIENTFORMLIST_SUCCESS:
			return {
				...state,
				clientFormBuilder: action.payload,
				clientFormBuilderLoading: false,
			};
		case types.GET_CLIENTFORMLIST_ERROR:
			return {
				...state,
				clientFormBuilderError: action.payload,
				clientFormBuilderLoading: false,
			};
		case types.GET_CLIENTFORMLIST_RESET:
			return {
				...state,
				clientFormBuilderError: null,
				clientFormBuilderLoading: false,
			};
		//create

		case types.SELECT_TOUPDATE_ITEM:
			return {
				...state,
				selectItem: action.payload,
			};

		default:
			return { ...state };
	}
};

export default ClientFromReducer;
