import { useContext } from "react";
import FormProvider from "./context";

const useForms = () => {
	const context = useContext(FormProvider);
	if (context === undefined)
		throw new Error("form must be used within a FormProvider");

	return context;
};

export default useForms;
