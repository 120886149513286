const types = {
  //Get Template name
  GET_MASTER_NAME_LIST_START: "GET_MASTER_NAME_LIST_START",
  GET_MASTER_NAME_LIST_SUCCESS: "GET_MASTER_NAME_LIST_SUCCESS",
  GET_MASTER_NAME_LIST_ERROR: "GET_MASTER_NAME_LIST_ERROR",
  GET_MASTER_NAME_LIST_RESET: "GET_MASTER_NAME_LIST_RESET",

  //Create Template name
  CREATE_MASTER_NAME_LIST_START: "CREATE_MASTER_NAME_LIST_START",
  CREATE_MASTER_NAME_LIST_SUCCESS: "CREATE_MASTER_NAME_LIST_SUCCESS",
  CREATE_MASTER_NAME_LIST_ERROR: "CREATE_MASTER_NAME_LIST_ERROR",
  CREATE_MASTER_NAME_LIST_RESET: "CREATE_MASTER_NAME_LIST_RESET",

  //delete Template name
  DELETE_MASTER_NAME_LIST_START: "DELETE_MASTER_NAME_LIST_START",
  DELETE_MASTER_NAME_LIST_SUCCESS: "DELETE_MASTER_NAME_LIST_SUCCESS",
  DELETE_MASTER_NAME_LIST_ERROR: "DELETE_MASTER_NAME_LIST_ERROR",
  DELETE_MASTER_NAME_LIST_RESET: "DELETE_MASTER_NAME_LIST_RESET",

  //update Template name
  UPDATE_MASTER_NAME_LIST_START: "UPDATE_MASTER_NAME_LIST_START",
  UPDATE_MASTER_NAME_LIST_SUCCESS: "UPDATE_MASTER_NAME_LIST_SUCCESS",
  UPDATE_MASTER_NAME_LIST_ERROR: "UPDATE_MASTER_NAME_LIST_ERROR",
  UPDATE_MASTER_NAME_LIST_RESET: "UPDATE_MASTER_NAME_LIST_RESET",

  //single item  name
  SINGLE_MASTER_NAME_LIST_START: "SINGLE_MASTER_NAME_LIST_START",
  SINGLE_MASTER_NAME_LIST_SUCCESS: "SINGLE_MASTER_NAME_LIST_SUCCESS",
  SINGLE_MASTER_NAME_LIST_ERROR: "SINGLE_MASTER_NAME_LIST_ERROR",
  SINGLE_MASTER_NAME_LIST_RESET: "SINGLE_MASTER_NAME_LIST_RESET",
};

export default types;
