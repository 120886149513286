const types = {
	GET_REPORTS_START: "GET_REPORTS_START",
	GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
	GET_REPORTS_ERROR: "GET_REPORTS_ERROR",
	GET_REPORTS_RESET: "GET_REPORTS_RESET",
	CREATE_REPORTS_START: "CREATE_REPORTS_START",
	CREATE_REPORTS_SUCCESS: "CREATE_REPORTS_SUCCESS",
	CREATE_REPORTS_ERROR: "CREATE_REPORTS_ERROR",
	CREATE_REPORTS_RESET: "CREATE_REPORTS_RESET",
};

export default types;
