import { SET_FORM } from "./action";

const form_reducer = (state, action) => {
	const payload = action.payload;
	const forms = payload.data;
	const pagination = { page: payload.page, total: payload.total };
	switch (action.type) {
		case SET_FORM:
			return {
				...state,
				forms,
				pagination,
			};

		default:
			break;
	}
};

export default form_reducer;
