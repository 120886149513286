const types = {
	//Get Template name
	GET_STANDARDDOCUMENT_START: "GET_STANDARDDOCUMENT_START",
	GET_STANDARDDOCUMENT_SUCCESS: "GET_STANDARDDOCUMENT_SUCCESS",
	GET_STANDARDDOCUMENT_ERROR: "GET_STANDARDDOCUMENT_ERROR",
	GET_STANDARDDOCUMENT_RESET: "GET_STANDARDDOCUMENT_RESET",

	//Create Template name
	CREATE_STANDARDDOCUMENT_START: "CREATE_STANDARDDOCUMENT_START",
	CREATE_STANDARDDOCUMENT_SUCCESS: "CREATE_STANDARDDOCUMENT_SUCCESS",
	CREATE_STANDARDDOCUMENT_ERROR: "CREATE_STANDARDDOCUMENT_ERROR",
	CREATE_STANDARDDOCUMENT_RESET: "CREATE_STANDARDDOCUMENT_RESET",

	//delete Template name
	DELETE_STANDARDDOCUMENT_START: "DELETE_STANDARDDOCUMENT_START",
	DELETE_STANDARDDOCUMENT_SUCCESS: "DELETE_STANDARDDOCUMENT_SUCCESS",
	DELETE_STANDARDDOCUMENT_ERROR: "DELETE_STANDARDDOCUMENT_ERROR",
	DELETE_STANDARDDOCUMENT_RESET: "DELETE_STANDARDDOCUMENT_RESET",

	//update Template name
	UPDATE_STANDARDDOCUMENT_START: "UPDATE_STANDARDDOCUMENT_START",
	UPDATE_STANDARDDOCUMENT_SUCCESS: "UPDATE_STANDARDDOCUMENT_SUCCESS",
	UPDATE_STANDARDDOCUMENT_ERROR: "UPDATE_STANDARDDOCUMENT_ERROR",
	UPDATE_STANDARDDOCUMENT_RESET: "UPDATE_STANDARDDOCUMENT_RESET",

	//single item  name
	SINGLE_STANDARDDOCUMENT_START: "SINGLE_STANDARDDOCUMENT_START",
	SINGLE_STANDARDDOCUMENT_SUCCESS: "SINGLE_STANDARDDOCUMENT_SUCCESS",
	SINGLE_STANDARDDOCUMENT_ERROR: "SINGLE_STANDARDDOCUMENT_ERROR",
	SINGLE_STANDARDDOCUMENT_RESET: "SINGLE_STANDARDDOCUMENT_RESET",
};

export default types;
