import { lazy } from "react";
export { default as LazyLoader } from "./LazyLoader/LazyLoader";

export const CustomPagination = lazy(() =>
	import("./CustomPagination/CustomPagination"),
);

export const SwitchButton = lazy(() => import("./SwitchButton"));

export const UserprofileThumbnail = lazy(() =>
	import("./UserprofileThumbnail/UserprofileThumbnail"),
);

export const NestedMenu = lazy(() => import("./NestedMenu/NestedMenu"));
// export { default as UserprofileThumbnail } from "./UserprofileThumbnail/UserprofileThumbnail";
// export { default as NestedMenu } from "./NestedMenu/NestedMenu";
// export { default as SwitchButton } from "./SwitchButton/SwitchButton";
// export { default as CustomPagination } from "./CustomPagination/CustomPagination";
export const OverlayLoading = lazy(() =>
	import("./OverlayLoading/OverlayLoading"),
);
export const FilterBar = lazy(() => import("./FilterBar/FilterBar"));

export const MenuIndicator = lazy(() =>
	import("./MenuIndicator/MenuIndicator"),
);

export const ErrorHandler = lazy(() => import("./ErrorHandler/ErrorHandler"));
export const FileUpload = lazy(() => import("./FileUpload/FileUpload"));
export const FormViewOnly = lazy(() => import("./FormViewOnly/FormViewOnly"));
export const StaticFormView = lazy(() =>
	import("./StaticFormView/StaticFormView"),
);
export const PDFview = lazy(() => import("./PDFview/PDFview"));
export const Loader = lazy(() => import("./Loader/Loader"));
export const Checkbox = lazy(() => import("./Checkbox/Checkbox"));
export const CustomQueryBuilder = lazy(() =>
	import("./CustomQueryBuilder/CustomQueryBuilder"),
);
export const PopUpSelect = lazy(() => import("./PopUpSelect"));
export const ConfirmWindow = lazy(() =>
	import("./ConfirmWindow/ConfirmWindow"),
);
export const FourOone = lazy(() => import("./401/FourOone"));
export const ExportToExcel = lazy(() =>
	import("./ExporttoExcel/ExportToExcel"),
);
export const ImageViewer = lazy(() => import("./ImageViewer/ImageViewer"));

export const MultiSelect = lazy(() => import("./MultiSelect/Multiselect"));

export const Table = lazy(() => import("./Table"));
export const SearchBar = lazy(() => import("./SearchFilter"));
export const QueryFilter = lazy(() => import("./QueryFilter"));
export const ViewForm = lazy(() => import("./ViewForm"));
