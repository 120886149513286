import useUser from "hooks/useUser";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectRoutes = (props) => {
	const { user } = useUser();
	return user.role ? <Outlet /> : <Navigate to='/login' />;
};

export default ProtectRoutes;
