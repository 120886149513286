import { tableColumnParser } from "./tableColumnParser";

export default function bodyParser(body = {}, components = []) {
	if (components.length === 0) return;
	const data = {};
	const parsedComponents = tableColumnParser(components);
	const bodyKeys = Object.keys(body);
	for (let i = 0; i < parsedComponents.length; i++) {
		const component = parsedComponents[i];
		data[component.accessor] = body[component.accessor];
	}
	if (bodyKeys.includes("_id")) {
		data["_id"] = body["_id"];
	}
	return data;
}
