const types = {
  GET_DATA_ENTRY_START: "GET_DATA_ENTRY_START",
  GET_DATA_ENTRY_SUCCESS: "GET_DATA_ENTRY_SUCCESS",
  GET_DATA_ENTRY_ERROR: "GET_DATA_ENTRY_ERROR",
  GET_DATA_ENTRY_RESET: "GET_DATA_ENTRY_RESET",
  CREATE_DATA_ENTRY_START: "CREATE_DATA_ENTRY_START",
  CREATE_DATA_ENTRY_SUCCESS: "CREATE_DATA_ENTRY_SUCCESS",
  CREATE_DATA_ENTRY_ERROR: "CREATE_DATA_ENTRY_ERROR",
  CREATE_DATA_ENTRY_RESET: "CREATE_DATA_ENTRY_RESET",
};

export default types;
