import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getrolesandpermissionHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "roleandpermission", ...action.payload }),
		);
		yield put({
			type: types.GET_ROLE_AND_PERMISSION_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_ROLE_AND_PERMISSION_ERROR,
			payload,
		});
	}
}

export function* getrolesandpermissionWatcherSaga() {
	yield takeLatest(
		types.GET_ROLE_AND_PERMISSION_START,
		getrolesandpermissionHandlerSaga,
	);
}

//create

/** 
 * 
 * function* createrolesandpermissionHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call("url", "endpoint");
		yield put({
			type: types.CREATE_ROLE_AND_PERMISSION_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_ROLE_AND_PERMISSION_ERROR,
			payload,
		});
	}
}

export function* createrolesandpermissionWatcherSaga() {
	yield  takeLatest(
		types.CREATE_ROLE_AND_PERMISSION_START,
		createrolesandpermissionHandlerSaga,
	);
}

 * 
 * 
*/

//delete

function* deleterolesandpermissionHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("roleandpermission", action.payload),
		);
		yield put({
			type: types.DELETE_ROLE_AND_PERMISSION_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_ROLE_AND_PERMISSION_ERROR,
			payload,
		});
	}
}

export function* deleterolesandpermissionWatcherSaga() {
	yield takeLatest(
		types.DELETE_ROLE_AND_PERMISSION_START,
		deleterolesandpermissionHandlerSaga,
	);
}

/** 
 * 
 * //update

function* updaterolesandpermissionHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call("url", "endpoint");
		yield put({
			type: types.UPDATE_ROLE_AND_PERMISSION_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.UPDATE_ROLE_AND_PERMISSION_ERROR,
			payload,
		});
	}
}

export function* updaterolesandpermissionWatcherSaga() {
	yield  takeLatest(
		types.UPDATE_ROLE_AND_PERMISSION_START,
		updaterolesandpermissionHandlerSaga,
	);
}

 * 
 * 
*/
