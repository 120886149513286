const types = {
	//Get Template name
	GET_CUSTOMER_START: "GET_CUSTOMER_START",
	GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
	GET_CUSTOMER_ERROR: "GET_CUSTOMER_ERROR",
	GET_CUSTOMER_RESET: "GET_CUSTOMER_RESET",

	//Create Template name
	CREATE_CUSTOMER_START: "CREATE_CUSTOMER_START",
	CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
	CREATE_CUSTOMER_ERROR: "CREATE_CUSTOMER_ERROR",
	CREATE_CUSTOMER_RESET: "CREATE_CUSTOMER_RESET",

	//delete Template name
	DELETE_CUSTOMER_START: "DELETE_CUSTOMER_START",
	DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
	DELETE_CUSTOMER_ERROR: "DELETE_CUSTOMER_ERROR",
	DELETE_CUSTOMER_RESET: "DELETE_CUSTOMER_RESET",

	//update Template name
	UPDATE_CUSTOMER_START: "UPDATE_CUSTOMER_START",
	UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
	UPDATE_CUSTOMER_ERROR: "UPDATE_CUSTOMER_ERROR",
	UPDATE_CUSTOMER_RESET: "UPDATE_CUSTOMER_RESET",

	//single item  name
	SINGLE_CUSTOMER_START: "SINGLE_CUSTOMER_START",
	SINGLE_CUSTOMER_SUCCESS: "SINGLE_CUSTOMER_SUCCESS",
	SINGLE_CUSTOMER_ERROR: "SINGLE_CUSTOMER_ERROR",
	SINGLE_CUSTOMER_RESET: "SINGLE_CUSTOMER_RESET",
};

export default types;
