import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store/Store";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import { ProSidebarProvider } from "react-pro-sidebar";
import FormState from "./context/forms/state";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (process.env.NODE_ENV === "production") {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
const presistor = persistStore(store);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

root.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate
					loading={<Spinner animation='border' />}
					persistor={presistor}>
					<ProSidebarProvider>
						<FormState>
							<App />
						</FormState>
					</ProSidebarProvider>
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
