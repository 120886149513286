const types = {
	//Get Template name
	GET_GENERALDOCUMENT_START: "GET_GENERALDOCUMENT_START",
	GET_GENERALDOCUMENT_SUCCESS: "GET_GENERALDOCUMENT_SUCCESS",
	GET_GENERALDOCUMENT_ERROR: "GET_GENERALDOCUMENT_ERROR",
	GET_GENERALDOCUMENT_RESET: "GET_GENERALDOCUMENT_RESET",

	//Create Template name
	CREATE_GENERALDOCUMENT_START: "CREATE_GENERALDOCUMENT_START",
	CREATE_GENERALDOCUMENT_SUCCESS: "CREATE_GENERALDOCUMENT_SUCCESS",
	CREATE_GENERALDOCUMENT_ERROR: "CREATE_GENERALDOCUMENT_ERROR",
	CREATE_GENERALDOCUMENT_RESET: "CREATE_GENERALDOCUMENT_RESET",

	//delete Template name
	DELETE_GENERALDOCUMENT_START: "DELETE_GENERALDOCUMENT_START",
	DELETE_GENERALDOCUMENT_SUCCESS: "DELETE_GENERALDOCUMENT_SUCCESS",
	DELETE_GENERALDOCUMENT_ERROR: "DELETE_GENERALDOCUMENT_ERROR",
	DELETE_GENERALDOCUMENT_RESET: "DELETE_GENERALDOCUMENT_RESET",

	//update Template name
	UPDATE_GENERALDOCUMENT_START: "UPDATE_GENERALDOCUMENT_START",
	UPDATE_GENERALDOCUMENT_SUCCESS: "UPDATE_GENERALDOCUMENT_SUCCESS",
	UPDATE_GENERALDOCUMENT_ERROR: "UPDATE_GENERALDOCUMENT_ERROR",
	UPDATE_GENERALDOCUMENT_RESET: "UPDATE_GENERALDOCUMENT_RESET",

	//single item  name
	SINGLE_GENERALDOCUMENT_START: "SINGLE_GENERALDOCUMENT_START",
	SINGLE_GENERALDOCUMENT_SUCCESS: "SINGLE_GENERALDOCUMENT_SUCCESS",
	SINGLE_GENERALDOCUMENT_ERROR: "SINGLE_GENERALDOCUMENT_ERROR",
	SINGLE_GENERALDOCUMENT_RESET: "SINGLE_GENERALDOCUMENT_RESET",
};

export default types;
