import types from "./types";

const INITIAL_STATE = {
	menulist: null,
	menulistError: null,
	menulistLoading: false,

	Pages: null,
	PagesError: null,
	PagesLoading: false,

	//create
	createPages: null,
	createPagesError: null,
	createPagesLoading: false,

	//update
	updatePages: null,
	updatePagesError: null,
	updatePagesLoading: false,

	//delete
	deletePages: null,
	deletePagesError: null,
	deletePagesLoading: false,

	//single item

	singleItem: null,

	//recent Created

	recentCreated: null,
};

const pageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		//get menu
		case types.GET_MENU_START:
			return {
				...state,
				menulist: null,
				menulistError: null,
				menulistLoading: true,
			};
		case types.GET_MENU_SUCCESS:
			return {
				...state,
				menulist: action.payload,
				menulistLoading: false,
			};
		case types.GET_MENU_ERROR:
			return {
				...state,
				menulistError: action.payload,
				menulistLoading: false,
			};
		case types.GET_MENU_RESET:
			return {
				...state,
				menulistError: null,
				menulistLoading: false,
			};
		//get

		case types.GET_PAGES_START:
			return {
				...state,
				Pages: null,
				PagesError: null,
				PagesLoading: true,
			};
		case types.GET_PAGES_SUCCESS:
			return {
				...state,
				Pages: action.payload,
				PagesLoading: false,
			};
		case types.GET_PAGES_ERROR:
			return {
				...state,
				PagesError: action.payload,
				PagesLoading: false,
			};
		case types.GET_PAGES_RESET:
			return {
				...state,
				PagesError: null,
				PagesLoading: false,
			};
		//create

		case types.CREATE_PAGES_START:
			return {
				...state,
				createPages: null,
				createPagesError: null,
				createPagesLoading: true,
			};
		case types.CREATE_PAGES_SUCCESS:
			return {
				...state,
				recentCreated: action.payload?.response,
				createPages: action.payload,
				createPagesLoading: false,
			};
		case types.CREATE_PAGES_ERROR:
			return {
				...state,
				createPagesError: action.payload,
				createPagesLoading: false,
			};
		case types.CREATE_PAGES_RESET:
			return {
				...state,
				createPages: null,
				createPagesError: null,
				createPagesLoading: false,
			};
		//delete

		case types.DELETE_PAGES_START:
			return {
				...state,
				deletePages: null,
				deletePagesError: null,
				deletePagesLoading: true,
			};
		case types.DELETE_PAGES_SUCCESS:
			return {
				...state,
				deletePages: action.payload,
				deletePagesLoading: false,
			};
		case types.DELETE_PAGES_ERROR:
			return {
				...state,
				deletePagesError: action.payload,
				deletePagesLoading: false,
			};
		case types.DELETE_PAGES_RESET:
			return {
				...state,
				deletePages: null,
				deletePagesError: null,
				deletePagesLoading: false,
			};

		//update
		case types.UPDATE_PAGES_START:
			return {
				...state,
				updatePages: null,
				updatePagesError: null,
				updatePagesLoading: true,
			};
		case types.UPDATE_PAGES_SUCCESS:
			return {
				...state,
				updatePages: action.payload,
				updatePagesLoading: false,
			};
		case types.UPDATE_PAGES_ERROR:
			return {
				...state,
				updatePagesError: action.payload,
				updatePagesLoading: false,
			};
		case types.UPDATE_PAGES_RESET:
			return {
				...state,
				updatePages: null,
				updatePagesError: null,
				updatePagesLoading: false,
			};
		//singleItem
		// case types.SINGLE_PAGES_START:
		// 	return {
		// 		...state,
		// 		updatePages: null,
		// 		updatePagesError: null,
		// 		updatePagesLoading: true,
		// 	};
		// case types.SINGLE_PAGES_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatePages: action.payload,
		// 		updatePagesLoading: false,
		// 	};
		// case types.SINGLE_PAGES_ERROR:
		// 	return {
		// 		...state,
		// 		updatePagesError: action.payload,
		// 		updatePagesLoading: false,
		// 	};
		// case types.SINGLE_PAGES_RESET:
		// 	return {
		// 		...state,
		// 		updatePagesError: null,
		// 		updatePagesLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default pageReducer;
