import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getMasterNameListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "masterlist", ...action.payload }),
		);
		yield put({
			type: types.GET_MASTER_NAME_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_MASTER_NAME_LIST_ERROR,
			payload,
		});
	}
}

export function* getMasterNameListWatcherSaga() {
	yield takeLatest(types.GET_MASTER_NAME_LIST_START, getMasterNameListHandlerSaga);
}

//create

//delete

function* deleteMasterNameListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("masterlist", action.payload),
		);
		yield put({
			type: types.DELETE_MASTER_NAME_LIST_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_MASTER_NAME_LIST_ERROR,
			payload,
		});
	}
}

export function* deleteMasterNameListWatcherSaga() {
	yield takeLatest(types.DELETE_MASTER_NAME_LIST_START, deleteMasterNameListHandlerSaga);
}

//update
