const types = {
	//Get Template name
	GET_ADMIN_USERS_START: "GET_ADMIN_USERS_START",
	GET_ADMIN_USERS_SUCCESS: "GET_ADMIN_USERS_SUCCESS",
	GET_ADMIN_USERS_ERROR: "GET_ADMIN_USERS_ERROR",
	GET_ADMIN_USERS_RESET: "GET_ADMIN_USERS_RESET",

	//Create Template name
	CREATE_ADMIN_USERS_START: "CREATE_ADMIN_USERS_START",
	CREATE_ADMIN_USERS_SUCCESS: "CREATE_ADMIN_USERS_SUCCESS",
	CREATE_ADMIN_USERS_ERROR: "CREATE_ADMIN_USERS_ERROR",
	CREATE_ADMIN_USERS_RESET: "CREATE_ADMIN_USERS_RESET",

	//delete Template name
	DELETE_ADMIN_USERS_START: "DELETE_ADMIN_USERS_START",
	DELETE_ADMIN_USERS_SUCCESS: "DELETE_ADMIN_USERS_SUCCESS",
	DELETE_ADMIN_USERS_ERROR: "DELETE_ADMIN_USERS_ERROR",
	DELETE_ADMIN_USERS_RESET: "DELETE_ADMIN_USERS_RESET",

	//update Template name
	UPDATE_ADMIN_USERS_START: "UPDATE_ADMIN_USERS_START",
	UPDATE_ADMIN_USERS_SUCCESS: "UPDATE_ADMIN_USERS_SUCCESS",
	UPDATE_ADMIN_USERS_ERROR: "UPDATE_ADMIN_USERS_ERROR",
	UPDATE_ADMIN_USERS_RESET: "UPDATE_ADMIN_USERS_RESET",

	//single item  name
	SINGLE_ADMIN_USERS_START: "SINGLE_ADMIN_USERS_START",
	SINGLE_ADMIN_USERS_SUCCESS: "SINGLE_ADMIN_USERS_SUCCESS",
	SINGLE_ADMIN_USERS_ERROR: "SINGLE_ADMIN_USERS_ERROR",
	SINGLE_ADMIN_USERS_RESET: "SINGLE_ADMIN_USERS_RESET",
};

export default types;
