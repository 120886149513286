import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getsubscriptionplanHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "subscriptionplan", ...action.payload }),
		);
		yield put({
			type: types.GET_SUBSCRIPTIONPLAN_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_SUBSCRIPTIONPLAN_ERROR,
			payload,
		});
	}
}

export function* getsubscriptionplanWatcherSaga() {
	yield takeLatest(
		types.GET_SUBSCRIPTIONPLAN_START,
		getsubscriptionplanHandlerSaga,
	);
}

//create

//delete

function* deletesubscriptionplanHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("subscriptionplan", action.payload),
		);
		yield put({
			type: types.DELETE_SUBSCRIPTIONPLAN_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_SUBSCRIPTIONPLAN_ERROR,
			payload,
		});
	}
}

export function* deletesubscriptionplanWatcherSaga() {
	yield takeLatest(
		types.DELETE_SUBSCRIPTIONPLAN_START,
		deletesubscriptionplanHandlerSaga,
	);
}

//update
