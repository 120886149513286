const types = {
	USERACTIVITY_LIST_START: "USERACTIVITY_LIST_START",
	USERACTIVITY_LIST_SUCCESS: "USERACTIVITY_LIST_SUCCESS",
	USERACTIVITY_LIST_ERROR: "USERACTIVITY_LIST_ERROR",

	

	USERACTIVITY_DETAILS_START: "USERACTIVITY_DETAILS_START",
	USERACTIVITY_DETAILS_SUCCESS: "USERACTIVITY_DETAILS_SUCCESS",
	USERACTIVITY_DETAILS_ERROR: "USERACTIVITY_DETAILS_ERROR",

	CLEAR_USERACTIVITY_DETAILS: "CLEAR_USERACTIVITY_DETAILS",
};
export default types;