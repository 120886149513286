import types from "./types";

const INITIAL_STATE = {
	Accommodation: null,
	AccommodationError: null,
	AccommodationLoading: false,

	//create
	createAccommodation: null,
	createAccommodationError: null,
	createAccommodationLoading: false,

	//update
	updateAccommodation: null,
	updateAccommodationError: null,
	updateAccommodationLoading: false,

	//delete
	deleteAccommodation: null,
	deleteAccommodationError: null,
	deleteAccommodationLoading: false,

	//single item

	singleItem: null,
};

const AccommodationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_ACCOMMODATION_START:
			return {
				...state,
				Accommodation: null,
				AccommodationError: null,
				AccommodationLoading: true,
			};
		case types.GET_ACCOMMODATION_SUCCESS:
			return {
				...state,
				Accommodation: action.payload,
				AccommodationLoading: false,
			};
		case types.GET_ACCOMMODATION_ERROR:
			return {
				...state,
				AccommodationError: action.payload,
				AccommodationLoading: false,
			};
		case types.GET_ACCOMMODATION_RESET:
			return {
				...state,
				AccommodationError: null,
				AccommodationLoading: false,
			};
		//create

		case types.CREATE_ACCOMMODATION_START:
			return {
				...state,
				createAccommodation: null,
				createAccommodationError: null,
				createAccommodationLoading: true,
			};
		case types.CREATE_ACCOMMODATION_SUCCESS:
			return {
				...state,
				createAccommodation: action.payload,
				createAccommodationLoading: false,
			};
		case types.CREATE_ACCOMMODATION_ERROR:
			return {
				...state,
				createAccommodationError: action.payload,
				createAccommodationLoading: false,
			};
		case types.CREATE_ACCOMMODATION_RESET:
			return {
				...state,
				createAccommodationError: null,
				createAccommodationLoading: false,
			};
		//delete

		case types.DELETE_ACCOMMODATION_START:
			return {
				...state,
				deleteAccommodation: null,
				deleteAccommodationError: null,
				deleteAccommodationLoading: true,
			};
		case types.DELETE_ACCOMMODATION_SUCCESS:
			return {
				...state,
				deleteAccommodation: action.payload,
				deleteAccommodationLoading: false,
			};
		case types.DELETE_ACCOMMODATION_ERROR:
			return {
				...state,
				deleteAccommodationError: action.payload,
				deleteAccommodationLoading: false,
			};
		case types.DELETE_ACCOMMODATION_RESET:
			return {
				...state,
				deleteAccommodation: null,
				deleteAccommodationError: null,
				deleteAccommodationLoading: false,
			};

		//update
		case types.UPDATE_ACCOMMODATION_START:
			return {
				...state,
				updateAccommodation: null,
				updateAccommodationError: null,
				updateAccommodationLoading: true,
			};
		case types.UPDATE_ACCOMMODATION_SUCCESS:
			return {
				...state,
				updateAccommodation: action.payload,
				updateAccommodationLoading: false,
			};
		case types.UPDATE_ACCOMMODATION_ERROR:
			return {
				...state,
				updateAccommodationError: action.payload,
				updateAccommodationLoading: false,
			};
		case types.UPDATE_ACCOMMODATION_RESET:
			return {
				...state,
				updateAccommodation: null,
				updateAccommodationError: null,
				updateAccommodationLoading: false,
			};
		//singleItem
		// case types.SINGLE_ACCOMMODATION_START:
		// 	return {
		// 		...state,
		// 		updateAccommodation: null,
		// 		updateAccommodationError: null,
		// 		updateAccommodationLoading: true,
		// 	};
		// case types.SINGLE_ACCOMMODATION_SUCCESS:
		// 	return {
		// 		...state,
		// 		updateAccommodation: action.payload,
		// 		updateAccommodationLoading: false,
		// 	};
		// case types.SINGLE_ACCOMMODATION_ERROR:
		// 	return {
		// 		...state,
		// 		updateAccommodationError: action.payload,
		// 		updateAccommodationLoading: false,
		// 	};
		// case types.SINGLE_ACCOMMODATION_RESET:
		// 	return {
		// 		...state,
		// 		updateAccommodationError: null,
		// 		updateAccommodationLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default AccommodationReducer;
