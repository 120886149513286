const types = {
	GET_GROUPS_START: "GET_GROUPS_START",
	GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
	GET_GROUPS_ERROR: "GET_GROUPS_ERROR",
	GET_GROUPS_RESET: "GET_GROUPS_RESET",
	//manage group
	GET_MANAGE_GROUP_START: "GET_MANAGE_GROUP_START",
	GET_MANAGE_GROUP_SUCCESS: "GET_MANAGE_GROUP_SUCCESS",
	GET_MANAGE_GROUP_ERROR: "GET_MANAGE_GROUP_ERROR",
	GET_MANAGE_GROUP_RESET: "GET_MANAGE_GROUP_RESET",
};

export default types;
