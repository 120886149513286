import types from "./types";

const INITIAL_STATE = {
	documentcalibration: null,
	documentcalibrationError: null,
	documentcalibrationLoading: false,

	//create
	createdocumentcalibration: null,
	createdocumentcalibrationError: null,
	createdocumentcalibrationLoading: false,

	//update
	updatedocumentcalibration: null,
	updatedocumentcalibrationError: null,
	updatedocumentcalibrationLoading: false,

	//delete
	deletedocumentcalibration: null,
	deletedocumentcalibrationError: null,
	deletedocumentcalibrationLoading: false,

	//single item

	singleItem: null,
};

const StoreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_DOCUMENTCALIBRATION_START:
			return {
				...state,
				documentcalibration: null,
				documentcalibrationError: null,
				documentcalibrationLoading: true,
			};
		case types.GET_DOCUMENTCALIBRATION_SUCCESS:
			return {
				...state,
				documentcalibration: action.payload,
				documentcalibrationLoading: false,
			};
		case types.GET_DOCUMENTCALIBRATION_ERROR:
			return {
				...state,
				documentcalibrationError: action.payload,
				documentcalibrationLoading: false,
			};
		case types.GET_DOCUMENTCALIBRATION_RESET:
			return {
				...state,
				documentcalibrationError: null,
				documentcalibrationLoading: false,
			};
		//create

		case types.CREATE_DOCUMENTCALIBRATION_START:
			return {
				...state,
				createdocumentcalibration: null,
				createdocumentcalibrationError: null,
				createdocumentcalibrationLoading: true,
			};
		case types.CREATE_DOCUMENTCALIBRATION_SUCCESS:
			return {
				...state,
				createdocumentcalibration: action.payload,
				createdocumentcalibrationLoading: false,
			};
		case types.CREATE_DOCUMENTCALIBRATION_ERROR:
			return {
				...state,
				createdocumentcalibrationError: action.payload,
				createdocumentcalibrationLoading: false,
			};
		case types.CREATE_DOCUMENTCALIBRATION_RESET:
			return {
				...state,
				createdocumentcalibrationError: null,
				createdocumentcalibrationLoading: false,
			};
		//delete

		case types.DELETE_DOCUMENTCALIBRATION_START:
			return {
				...state,
				deletedocumentcalibration: null,
				deletedocumentcalibrationError: null,
				deletedocumentcalibrationLoading: true,
			};
		case types.DELETE_DOCUMENTCALIBRATION_SUCCESS:
			return {
				...state,
				deletedocumentcalibration: action.payload,
				deletedocumentcalibrationLoading: false,
			};
		case types.DELETE_DOCUMENTCALIBRATION_ERROR:
			return {
				...state,
				deletedocumentcalibrationError: action.payload,
				deletedocumentcalibrationLoading: false,
			};
		case types.DELETE_DOCUMENTCALIBRATION_RESET:
			return {
				...state,
				deletedocumentcalibration: null,
				deletedocumentcalibrationError: null,
				deletedocumentcalibrationLoading: false,
			};

		//update
		case types.UPDATE_DOCUMENTCALIBRATION_START:
			return {
				...state,
				updatedocumentcalibration: null,
				updatedocumentcalibrationError: null,
				updatedocumentcalibrationLoading: true,
			};
		case types.UPDATE_DOCUMENTCALIBRATION_SUCCESS:
			return {
				...state,
				updatedocumentcalibration: action.payload,
				updatedocumentcalibrationLoading: false,
			};
		case types.UPDATE_DOCUMENTCALIBRATION_ERROR:
			return {
				...state,
				updatedocumentcalibrationError: action.payload,
				updatedocumentcalibrationLoading: false,
			};
		case types.UPDATE_DOCUMENTCALIBRATION_RESET:
			return {
				...state,
				updatedocumentcalibration: null,
				updatedocumentcalibrationError: null,
				updatedocumentcalibrationLoading: false,
			};
		

		default:
			return { ...state };
	}
};

export default StoreReducer;
