import types from "./types";

const INITIAL_STATE = {
  Method: null,
  MethodError: null,
  MethodLoading: false,

  //create
  createMethod: null,
  createMethodError: null,
  createMethodLoading: false,

  createForm: null,

  //update
  updateMethod: null,
  updateMethodError: null,
  updateMethodLoading: false,

  //delete
  deleteMethod: null,
  deleteMethodError: null,
  deleteMethodLoading: false,

  //
  form: null,
  formError: null,
  FormLoading: false,
  //
  formEdit: null,
  formEditError: null,
  formEditLoading: false,
};

const methodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
		case types.GET_METHOD_START:
			return {
				...state,
				Method: null,
				MethodError: null,
				MethodLoading: true,
			};
		case types.GET_METHOD_SUCCESS:
			return {
				...state,
				Method: action.payload,
				MethodLoading: false,
			};
		case types.GET_METHOD_ERROR:
			return {
				...state,
				MethodError: action.payload,
				MethodLoading: false,
			};
		case types.GET_METHOD_RESET:
			return {
				...state,
				MethodError: null,
				MethodLoading: false,
			};
		//create

		case types.CREATE_METHOD_START:
			return {
				...state,
				createMethod: null,
				createMethodError: null,
				createMethodLoading: true,
			};
		case types.CREATE_METHOD_SUCCESS:
			return {
				...state,
				createForm: action.payload?.reponse?.data,
				createMethod: action.payload,
				createMethodLoading: false,
			};
		case types.CREATE_METHOD_ERROR:
			return {
				...state,
				createMethodError: action.payload,
				createMethodLoading: false,
			};
		case types.CREATE_METHOD_RESET:
			return {
				...state,
				createMethod: null,
				createMethodError: null,
				createMethodLoading: false,
			};
		//delete

		case types.DELETE_METHOD_START:
			return {
				...state,
				deleteMethod: null,
				deleteMethodError: null,
				deleteMethodLoading: true,
			};
		case types.DELETE_METHOD_SUCCESS:
			return {
				...state,
				deleteMethod: action.payload,
				deleteMethodLoading: false,
			};
		case types.DELETE_METHOD_ERROR:
			return {
				...state,
				deleteMethodError: action.payload,
				deleteMethodLoading: false,
			};
		case types.DELETE_METHOD_RESET:
			return {
				...state,
				deleteMethod: null,
				deleteMethodError: null,
				deleteMethodLoading: false,
			};

		//update
		case types.UPDATE_METHOD_START:
			return {
				...state,
				updateMethod: null,
				updateMethodError: null,
				updateMethodLoading: true,
			};
		case types.UPDATE_METHOD_SUCCESS:
			return {
				...state,
				updateMethod: action.payload,
				updateMethodLoading: false,
			};
		case types.UPDATE_METHOD_ERROR:
			return {
				...state,
				updateMethodError: action.payload,
				updateMethodLoading: false,
			};
		case types.UPDATE_METHOD_RESET:
			return {
				...state,
				updateMethod: null,
				updateMethodError: null,
				updateMethodLoading: false,
			};
		//form edit
		case types.METHOD_FORM_START:
			return {
				...state,
				form: null,
				formError: null,
				formLoading: true,
			};
		case types.METHOD_FORM_SUCCESS:
			//console.log("payload", action.payload);

			return {
				...state,
				form: action.payload,
				formLoading: false,
			};
		case types.METHOD_FORM_ERROR:
			return {
				...state,
				formError: action.payload,
				formLoading: false,
			};
		case types.METHOD_FORM_RESET:
			return {
				...state,
				form: null,
				formError: null,
				formLoading: false,
			};
		//form edit
		case types.METHOD_FORM_EDIT_START:
			return {
				...state,
				formEdit: null,
				formEditError: null,
				formEditLoading: true,
			};
		case types.METHOD_FORM_EDIT_SUCCESS:
			return {
				...state,
				formEdit: action.payload,
				formEditLoading: false,
			};
		case types.METHOD_FORM_EDIT_ERROR:
			return {
				...state,
				formEditError: action.payload,
				formEditLoading: false,
			};
		case types.METHOD_FORM_EDIT_RESET:
			return {
				...state,
				formEdit: null,
				formEditError: null,
				formEditLoading: false,
			};

		default:
			return { ...state };
	}
};

export default methodReducer;
