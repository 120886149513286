import types from "./types";


const INITIAL_STATE = {
    versionHistory: null,
    loadingVersionHistory: false,
    versionHistoryError: null,
}

const formBuilderReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case types.FETCH_VERSION_HISTORY_START: {
            return {
                ...state,
                versionHistory: null,
                loadingVersionHistory: true,
                versionHistoryError: null,
            }
        }

        case types.FETCH_VERSION_HISTORY_SUCCESS: {
            return {
                ...state,
                versionHistory: action.payload,
                loadingVersionHistory: false,
                versionHistoryError: null,
            }
        }

        case types.FETCH_VERSION_HISTORY_ERROR: {
            return {
                ...state,
                versionHistory: null,
                loadingVersionHistory: false,
                versionHistoryError: action.payload,
            }
        }


        default: {
            return {
                ...state,
            }
        }
    }
}


export default formBuilderReducer;