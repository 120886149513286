import types from "./types";

const INITIAL_STATE = {
	joblist: null,
	joblistError: null,
	joblistLoading: false,

	masterJob: null,
	masterJobError: null,
	masterJobLoading: null,
};

const jobsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		//get

		case types.GET_JOBS_START:
			return {
				...state,
				job: null,
				jobError: null,
				jobLoading: true,
			};
		case types.GET_JOBS_SUCCESS:
			return {
				...state,
				job: action.payload,
				jobLoading: false,
			};
		case types.GET_JOBS_ERROR:
			return {
				...state,
				jobError: action.payload,
				jobLoading: false,
			};
		case types.GET_JOBS_RESET:
			return {
				...state,
				job: null,
				jobError: null,
				jobLoading: false,
			};
		case types.GET_MASTER_JOBS_START:
			return {
				...state,
				masterJob: null,
				masterJobError: null,
				masterJobLoading: true,
			};
		case types.GET_MASTER_JOBS_SUCCESS:
			return {
				...state,
				masterJob: action.payload,
				masterJobLoading: false,
			};
		case types.GET_MASTER_JOBS_ERROR:
			return {
				...state,
				masterJobError: action.payload,
				masterJobLoading: false,
			};
		case types.GET_MASTER_JOBS_RESET:
			return {
				...state,
				masterJob: null,
				masterJobError: null,
				masterJobLoading: false,
			};
		default:
			return { ...state };
	}
};

export default jobsReducer;
