import types from "./types";

const INITIAL_STATE = {
	status: "success",
};

const GlobalAppReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_STATUS:
			return {
				...state,
				statusButton: action.payload,
			};

		default:
			return { ...state };
	}
};

export default GlobalAppReducer;
