import React from "react";
import { Spinner } from "react-bootstrap";

const OverlayLoading = (props) => {
	return (
		<div
			style={{
				position: `${props.position || "fixed"} `,
				top: "0",
				left: "0",
				right: "0",
				bottom: "0",
				backgroundColor: "rgba(0,0,0,0.7)",
				zIndex: 999999,
			}}
			className='d-flex justify-content-center align-items-center rounded'>
			<Spinner animation='border' variant='white' />
		</div>
	);
};

export default OverlayLoading;
