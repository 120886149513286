import { call, put, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endPoint from "../../backend/endpoints";

//get
function* getCalibrationHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endPoint.dynamicList({ path: "calibration", ...action.payload }),
		);
		yield put({
			type: types.GET_CALIBRATION_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.GET_CALIBRATION_ERROR,
			payload,
		});
	}
}

export function* getCalibrationWatcherSaga() {
	yield takeLatest(types.GET_CALIBRATION_START, getCalibrationHandlerSaga);
}

//create

//delete

function* deleteCalibrationHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endPoint.deleteDyanamicItem("calibration", action.payload),
		);
		yield put({
			type: types.DELETE_CALIBRATION_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_CALIBRATION_ERROR,
			payload,
		});
	}
}

export function* deleteCalibrationWatcherSaga() {
	yield takeLatest(
		types.DELETE_CALIBRATION_START,
		deleteCalibrationHandlerSaga,
	);
}

//update
