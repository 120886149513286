const types = {
  //Get Template name
  GET_EXPIRED_ITEMS_START: "GET_EXPIRED_ITEMS_START",
  GET_EXPIRED_ITEMS_SUCCESS: "GET_EXPIRED_ITEMS_SUCCESS",
  GET_EXPIRED_ITEMS_ERROR: "GET_EXPIRED_ITEMS_ERROR",
  GET_EXPIRED_ITEMS_RESET: "GET_EXPIRED_ITEMS_RESET",

  //Create Template name
  CREATE_EXPIRED_ITEMS_START: "CREATE_EXPIRED_ITEMS_START",
  CREATE_EXPIRED_ITEMS_SUCCESS: "CREATE_EXPIRED_ITEMS_SUCCESS",
  CREATE_EXPIRED_ITEMS_ERROR: "CREATE_EXPIRED_ITEMS_ERROR",
  CREATE_EXPIRED_ITEMS_RESET: "CREATE_EXPIRED_ITEMS_RESET",

  //delete Template name
  DELETE_EXPIRED_ITEMS_START: "DELETE_EXPIRED_ITEMS_START",
  DELETE_EXPIRED_ITEMS_SUCCESS: "DELETE_EXPIRED_ITEMS_SUCCESS",
  DELETE_EXPIRED_ITEMS_ERROR: "DELETE_EXPIRED_ITEMS_ERROR",
  DELETE_EXPIRED_ITEMS_RESET: "DELETE_EXPIRED_ITEMS_RESET",

  //update Template name
  UPDATE_EXPIRED_ITEMS_START: "UPDATE_EXPIRED_ITEMS_START",
  UPDATE_EXPIRED_ITEMS_SUCCESS: "UPDATE_EXPIRED_ITEMS_SUCCESS",
  UPDATE_EXPIRED_ITEMS_ERROR: "UPDATE_EXPIRED_ITEMS_ERROR",
  UPDATE_EXPIRED_ITEMS_RESET: "UPDATE_EXPIRED_ITEMS_RESET",

  //single item  name
  SINGLE_EXPIRED_ITEMS_START: "SINGLE_EXPIRED_ITEMS_START",
  SINGLE_EXPIRED_ITEMS_SUCCESS: "SINGLE_EXPIRED_ITEMS_SUCCESS",
  SINGLE_EXPIRED_ITEMS_ERROR: "SINGLE_EXPIRED_ITEMS_ERROR",
  SINGLE_EXPIRED_ITEMS_RESET: "SINGLE_EXPIRED_ITEMS_RESET",
};

export default types;
