import types from "./types";

const INITIAL_STATE = {
	subscriber: null,
	subscriberError: null,
	subscriberLoading: false,

	//create
	createsubscriber: null,
	createsubscriberError: null,
	createsubscriberLoading: false,

	//update
	updatesubscriber: null,
	updatesubscriberError: null,
	updatesubscriberLoading: false,

	//delete
	deletesubscriber: null,
	deletesubscriberError: null,
	deletesubscriberLoading: false,

	//single item

	singleItem: null,
};

const subscriberReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_SUBSCRIBER_START:
			return {
				...state,
				subscriber: null,
				subscriberError: null,
				subscriberLoading: true,
			};
		case types.GET_SUBSCRIBER_SUCCESS:
			return {
				...state,
				subscriber: action.payload,
				subscriberLoading: false,
			};
		case types.GET_SUBSCRIBER_ERROR:
			return {
				...state,
				subscriberError: action.payload,
				subscriberLoading: false,
			};
		case types.GET_SUBSCRIBER_RESET:
			return {
				...state,
				subscriberError: null,
				subscriberLoading: false,
			};
		//create

		case types.CREATE_SUBSCRIBER_START:
			return {
				...state,
				createsubscriber: null,
				createsubscriberError: null,
				createsubscriberLoading: true,
			};
		case types.CREATE_SUBSCRIBER_SUCCESS:
			return {
				...state,
				createsubscriber: action.payload,
				createsubscriberLoading: false,
			};
		case types.CREATE_SUBSCRIBER_ERROR:
			return {
				...state,
				createsubscriberError: action.payload,
				createsubscriberLoading: false,
			};
		case types.CREATE_SUBSCRIBER_RESET:
			return {
				...state,
				createsubscriberError: null,
				createsubscriberLoading: false,
			};
		//delete

		case types.DELETE_SUBSCRIBER_START:
			return {
				...state,
				deletesubscriber: null,
				deletesubscriberError: null,
				deletesubscriberLoading: true,
			};
		case types.DELETE_SUBSCRIBER_SUCCESS:
			return {
				...state,
				deletesubscriber: action.payload,
				deletesubscriberLoading: false,
			};
		case types.DELETE_SUBSCRIBER_ERROR:
			return {
				...state,
				deletesubscriberError: action.payload,
				deletesubscriberLoading: false,
			};
		case types.DELETE_SUBSCRIBER_RESET:
			return {
				...state,
				deletesubscriber: null,
				deletesubscriberError: null,
				deletesubscriberLoading: false,
			};

		//update
		case types.UPDATE_SUBSCRIBER_START:
			return {
				...state,
				updatesubscriber: null,
				updatesubscriberError: null,
				updatesubscriberLoading: true,
			};
		case types.UPDATE_SUBSCRIBER_SUCCESS:
			return {
				...state,
				updatesubscriber: action.payload,
				updatesubscriberLoading: false,
			};
		case types.UPDATE_SUBSCRIBER_ERROR:
			return {
				...state,
				updatesubscriberError: action.payload,
				updatesubscriberLoading: false,
			};
		case types.UPDATE_SUBSCRIBER_RESET:
			return {
				...state,
				updatesubscriber: null,
				updatesubscriberError: null,
				updatesubscriberLoading: false,
			};
		//singleItem
		// case types.SINGLE_SUBSCRIBER_START:
		// 	return {
		// 		...state,
		// 		updatesubscriber: null,
		// 		updatesubscriberError: null,
		// 		updatesubscriberLoading: true,
		// 	};
		// case types.SINGLE_SUBSCRIBER_SUCCESS:
		// 	return {
		// 		...state,
		// 		updatesubscriber: action.payload,
		// 		updatesubscriberLoading: false,
		// 	};
		// case types.SINGLE_SUBSCRIBER_ERROR:
		// 	return {
		// 		...state,
		// 		updatesubscriberError: action.payload,
		// 		updatesubscriberLoading: false,
		// 	};
		// case types.SINGLE_SUBSCRIBER_RESET:
		// 	return {
		// 		...state,
		// 		updatesubscriberError: null,
		// 		updatesubscriberLoading: false,
		// 	};

		default:
			return { ...state };
	}
};

export default subscriberReducer;
