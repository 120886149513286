const types = {
	//Get Template name
	GET_GENERAL_INVENTORY_START: "GET_GENERAL_INVENTORY_START",
	GET_GENERAL_INVENTORY_SUCCESS: "GET_GENERAL_INVENTORY_SUCCESS",
	GET_GENERAL_INVENTORY_ERROR: "GET_GENERAL_INVENTORY_ERROR",
	GET_GENERAL_INVENTORY_RESET: "GET_GENERAL_INVENTORY_RESET",

	//delete Template name
	DELETE_GENERAL_INVENTORY_START: "DELETE_GENERAL_INVENTORY_START",
	DELETE_GENERAL_INVENTORY_SUCCESS: "DELETE_GENERAL_INVENTORY_SUCCESS",
	DELETE_GENERAL_INVENTORY_ERROR: "DELETE_GENERAL_INVENTORY_ERROR",
	DELETE_GENERAL_INVENTORY_RESET: "DELETE_GENERAL_INVENTORY_RESET",
};

export default types;
